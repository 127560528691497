import React from 'react';

import { SteroidInteractionModal } from './SteroidInteractionModal';
import { Error, useLoadingQuery } from '@heltti/components';
import { GlobalInteractionsQuery } from '../../graphql-schema';
import { globalInteractionsQuery } from '../../data/queries';

type Props = Record<string, never>;

export const SteroidGlobalInteractions: React.FC<Props> = () => {
    const { data, error } = useLoadingQuery<GlobalInteractionsQuery>(globalInteractionsQuery);

    if (error) {
        return <Error showRefreshButton />;
    }

    if (!data?.root?.interactions.length) {
        return null;
    }

    return <SteroidInteractionModal uiInteraction={data.root.interactions[0]} />;
};

import React, { useState } from 'react';

import { Checkbox, Grid } from 'semantic-ui-react';

import {
    QuestionnaireQuestionFragment,
    QuestionnaireQuestionOption,
    QuestionnaireQuestionType
} from '../../graphql-schema';
import { QuestionnaireQuestionValue } from './QuestionnaireResponse';

const isRadio = (type: QuestionnaireQuestionType) =>
    type === QuestionnaireQuestionType.Radio ||
    type === QuestionnaireQuestionType.RadioIcons ||
    type === QuestionnaireQuestionType.Boolean;

type Props = {
    question: QuestionnaireQuestionFragment;
    onChange(question: QuestionnaireQuestionFragment, value: QuestionnaireQuestionValue): void;
    value: number[];
};

export const CheckboxGroup: React.FC<Props> = props => {
    const { question, onChange, value } = props;

    const [values, setValues] = useState<number[]>(value || []);

    const handleChange = (option: QuestionnaireQuestionOption) => {
        const { value } = option;

        let newValue;

        // Update own state
        if (isRadio(question.type)) {
            newValue = [value];
        } else if (value && values.includes(value)) {
            newValue = values.filter(selectedItem => selectedItem !== value);
        } else if (value !== undefined && value !== null) {
            newValue = values.concat(value);
        }

        setValues(newValue);

        // Fire onChange callback
        onChange(question, { listValue: newValue });
    };

    const renderOption = (option: QuestionnaireQuestionOption) => (
        <div key={option.value}>
            <Checkbox
                name={question.id}
                value={option.value || undefined}
                radio={isRadio(question.type)}
                label={option.title}
                checked={option.value !== undefined && option.value !== null && values.includes(option.value)}
                onChange={() => handleChange(option)}
            />
        </div>
    );

    const options = question?.options;

    if (options?.length && options.length > 5) {
        const halfLength = Math.floor(options.length / 2);
        const firstColumn = options.filter((option, index) => index < halfLength);
        const secondColumn = options.filter((option, index) => index >= halfLength);

        return (
            <div className="questionOptions">
                <Grid>
                    <Grid.Column tablet={16} computer={8}>
                        {firstColumn.map(renderOption)}
                    </Grid.Column>
                    <Grid.Column tablet={16} computer={8}>
                        {secondColumn.map(renderOption)}
                    </Grid.Column>
                </Grid>
            </div>
        );
    }

    return <div className="questionOptions">{options?.map(renderOption)}</div>;
};

import React, { useCallback, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { Header, Message, Modal, SemanticCOLORS } from 'semantic-ui-react';
import { Button } from '@heltti/components';

import {
    InteractionActionFragment,
    InteractionImportance,
    InteractionMutation,
    InteractionMutationVariables,
    UiInteractionFragment
} from '../../graphql-schema';
import { interactionMutation } from '../../data/mutations';
import { FormatMarkdown } from '../FormatMarkdown';
import { globalInteractionsQuery } from '../../data/queries';
import { openURL } from '../../util/navigation';

type Props = {
    uiInteraction: UiInteractionFragment;
};

export const SteroidInteractionModal: React.FC<Props> = props => {
    const { uiInteraction } = props;
    const { title, description, actions } = uiInteraction.interaction;

    const [error, setError] = useState<Error | null>(null);
    const [loading, setLoading] = useState<boolean>();
    const history = useHistory();

    const apolloClient = useApolloClient();

    const onAction = useCallback(
        (action: InteractionActionFragment) => {
            setLoading(true);
            setError(null);

            apolloClient
                .mutate<InteractionMutation, InteractionMutationVariables>({
                    mutation: interactionMutation,
                    variables: {
                        data: {
                            id: uiInteraction.id,
                            actionHandle: action.handle,
                            values: null // TODO: Values
                        }
                    },
                    // TODO: This ought to be replaced with pushing these changes through a subscription
                    refetchQueries: [globalInteractionsQuery]
                })
                .then(result => {
                    setLoading(false);

                    const interactionResult = result.data?.interaction?.interactionResult;

                    if (interactionResult?.redirectTo) {
                        openURL(new URL(interactionResult.redirectTo), history);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    setError(error);
                });
        },
        [apolloClient, history, uiInteraction.id]
    );

    const onClose = () => {
        // Don't do anything, cancel (if allowed) should be handled with a cancel action
    };

    return (
        <Modal open size="small" onClose={onClose} key={uiInteraction.id}>
            <Modal.Content>
                <Header>{title}</Header>

                {description && <FormatMarkdown source={description} />}
                {error && <Message error content={error.message} />}
            </Modal.Content>

            <Modal.Actions>
                {actions.map(action => {
                    const isPrimary = action.importance === InteractionImportance.Primary;

                    return (
                        <Button
                            key={action.handle}
                            disabled={loading}
                            loading={isPrimary && loading}
                            primary={isPrimary}
                            basic={action.importance === InteractionImportance.Normal}
                            color={action.color?.toLowerCase() as unknown as SemanticCOLORS}
                            onClick={() => onAction(action)}
                        >
                            {action.title}
                        </Button>
                    );
                })}
            </Modal.Actions>
        </Modal>
    );
};

/* tslint:disable */
/* eslint-disable */
// noinspection DuplicatedCode, SpellCheckingInspection
// This file was automatically generated and should not be edited.
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /**
     * The `Date` scalar type represents a Date
     * value as specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    Date: string;
    /**
     * The `DateTime` scalar type represents a DateTime
     * value as specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    DateTime: string;
    /**
     * The `GenericScalar` scalar type represents a generic
     * GraphQL scalar value that could be:
     * String, Boolean, Int, Float, List or Object.
     */
    GenericScalar: any;
    /**
     * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
     * in fields, resolvers and input.
     */
    UUID: any;
    /**
     * Create scalar that ignores normal serialization/deserialization, since
     * that will be handled by the multipart request spec
     */
    Upload: any;
};

export type AcceptTermsMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly feedbackAccepted?: Maybe<Scalars['Boolean']>;
    readonly marketingAccepted?: Maybe<Scalars['Boolean']>;
    readonly memberCommunicationAccepted?: Maybe<Scalars['Boolean']>;
    readonly termsAccepted: Scalars['Boolean'];
};

export type AcceptTermsMutationPayload = {
    readonly __typename?: 'AcceptTermsMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly feedbackAccepted?: Maybe<Scalars['Boolean']>;
    readonly marketingAccepted?: Maybe<Scalars['Boolean']>;
    readonly memberCommunicationAccepted?: Maybe<Scalars['Boolean']>;
    readonly termsAccepted: Scalars['Boolean'];
};

export type AddAnalyticsEventsMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly deviceUuid: Scalars['String'];
    readonly events: ReadonlyArray<Maybe<Scalars['GenericScalar']>>;
    readonly sessionId: Scalars['String'];
};

export type AddAnalyticsEventsMutationPayload = {
    readonly __typename?: 'AddAnalyticsEventsMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly count: Scalars['Int'];
};

export type AddAnalyticsEventsMutationV2Input = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly deviceUuid: Scalars['UUID'];
    readonly events: ReadonlyArray<Maybe<AnalyticsEventInput>>;
};

export type AddAnalyticsEventsMutationV2Payload = {
    readonly __typename?: 'AddAnalyticsEventsMutationV2Payload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly count: Scalars['Int'];
};

export type AnalyticsEventInput = {
    readonly action: Scalars['String'];
    readonly detail1?: Maybe<Scalars['String']>;
    readonly detail2?: Maybe<Scalars['String']>;
    readonly detail3?: Maybe<Scalars['String']>;
    readonly detail4?: Maybe<Scalars['String']>;
    readonly detail5?: Maybe<Scalars['String']>;
    readonly sessionUuid?: Maybe<Scalars['UUID']>;
    readonly timestamp: Scalars['DateTime'];
    readonly type: Scalars['String'];
    readonly uuid: Scalars['UUID'];
};

export enum AppUpdateState {
    AppOtaRequired = 'APP_OTA_REQUIRED',
    AppStoreRequired = 'APP_STORE_REQUIRED',
    Ok = 'OK'
}

export enum AppointmentContactType {
    Phone = 'PHONE',
    Video = 'VIDEO',
    Visit = 'VISIT'
}

export type AppointmentDayNode = {
    readonly __typename?: 'AppointmentDayNode';
    readonly date: Scalars['Date'];
    readonly eventCount: Scalars['Int'];
    readonly id: Scalars['ID'];
    readonly nextAppointments: ReadonlyArray<AppointmentNode>;
};

export type AppointmentFilters = {
    readonly contactTypes?: Maybe<ReadonlyArray<AppointmentContactType>>;
    readonly specialistAgeGroup?: Maybe<AppointmentSpecialistAgeGroup>;
    readonly specialistGender?: Maybe<AppointmentSpecialistGender>;
    readonly specialistLanguages?: Maybe<ReadonlyArray<AppointmentSpecialistLanguage>>;
};

export type AppointmentNode = {
    readonly __typename?: 'AppointmentNode';
    readonly contactTypes: ReadonlyArray<AppointmentContactType>;
    readonly duration: Scalars['Int'];
    readonly end: Scalars['DateTime'];
    readonly id: Scalars['String'];
    /** Specifies the clinic if clinic visit contact type is available */
    readonly location?: Maybe<LocationNode>;
    readonly meta: Scalars['String'];
    readonly start: Scalars['DateTime'];
    readonly title: Scalars['String'];
    readonly user: UserNode;
};

export enum AppointmentSpecialistAgeGroup {
    Any = 'ANY',
    Between_30_45 = 'BETWEEN_30_45',
    Between_46_59 = 'BETWEEN_46_59',
    Over_60 = 'OVER_60',
    Under_30 = 'UNDER_30'
}

export enum AppointmentSpecialistGender {
    Female = 'FEMALE',
    Male = 'MALE',
    Other = 'OTHER'
}

export enum AppointmentSpecialistLanguage {
    En = 'EN',
    Fi = 'FI',
    Sv = 'SV'
}

export enum AppointmentTimeOfDay {
    Afternoon = 'AFTERNOON',
    All = 'ALL',
    Morning = 'MORNING'
}

/** An enumeration. */
export enum ArticleCategoryDisplayType {
    /** carousel */
    Carousel = 'CAROUSEL',
    /** list */
    List = 'LIST'
}

export type ArticleCategoryNode = Node & {
    readonly __typename?: 'ArticleCategoryNode';
    readonly articles: ReadonlyArray<ArticleNode>;
    readonly description?: Maybe<Scalars['String']>;
    readonly displayType: ArticleCategoryDisplayType;
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly title?: Maybe<Scalars['String']>;
};

export type ArticleCategoryNodeConnection = {
    readonly __typename?: 'ArticleCategoryNodeConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<ArticleCategoryNodeEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ArticleCategoryNode` and its cursor. */
export type ArticleCategoryNodeEdge = {
    readonly __typename?: 'ArticleCategoryNodeEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<ArticleCategoryNode>;
};

export type ArticleNode = ArticleRelayNode & {
    readonly __typename?: 'ArticleNode';
    /** @deprecated Use categories instead, direct children will be removed */
    readonly childArticles?: Maybe<ArticleNodeConnection>;
    readonly childCategories: ArticleCategoryNodeConnection;
    readonly content?: Maybe<Scalars['String']>;
    readonly coverPhotoUrl?: Maybe<Scalars['String']>;
    readonly createdAt: Scalars['DateTime'];
    readonly highlight?: Maybe<Scalars['String']>;
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly modifiedAt: Scalars['DateTime'];
    /** @deprecated Article hierarchy comes from ArticleCategories, parent article is deprecated */
    readonly parentArticle?: Maybe<ArticleNode>;
    readonly title: Scalars['String'];
    readonly type: ArticleType;
    readonly videoHandle?: Maybe<Scalars['String']>;
};

export type ArticleNodeChildArticlesArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type ArticleNodeChildCategoriesArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type ArticleNodeConnection = {
    readonly __typename?: 'ArticleNodeConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<ArticleNodeEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ArticleNode` and its cursor. */
export type ArticleNodeEdge = {
    readonly __typename?: 'ArticleNodeEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<ArticleNode>;
};

export type ArticleRelayNode = {
    /** The ID of the object. */
    readonly id: Scalars['ID'];
};

/** An enumeration. */
export enum ArticleType {
    /** company_instructions */
    CompanyInstructions = 'COMPANY_INSTRUCTIONS',
    /** generic */
    Generic = 'GENERIC',
    /** invite_email */
    InviteEmail = 'INVITE_EMAIL',
    /** self_help */
    SelfHelp = 'SELF_HELP'
}

export type BadgeNode = {
    readonly __typename?: 'BadgeNode';
    readonly openCalendarReservationAccesses?: Maybe<Scalars['Int']>;
    readonly openQuestionnaires?: Maybe<Scalars['Int']>;
    readonly unseenChatMessages?: Maybe<Scalars['Int']>;
    readonly unseenDocuments?: Maybe<Scalars['Int']>;
};

export enum CtaContextType {
    Home = 'HOME',
    Services = 'SERVICES'
}

export type CalendarEventConnection = {
    readonly __typename?: 'CalendarEventConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<CalendarEventEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `CalendarEvent` and its cursor. */
export type CalendarEventEdge = {
    readonly __typename?: 'CalendarEventEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<CalendarEventNode>;
};

export type CalendarEventNode = Node & {
    readonly __typename?: 'CalendarEventNode';
    readonly calendar: CalendarNode;
    readonly calendarReservationAccess?: Maybe<CalendarReservationAccessNode>;
    readonly cancelledAt?: Maybe<Scalars['DateTime']>;
    readonly description?: Maybe<Scalars['String']>;
    readonly end: Scalars['DateTime'];
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly isModifiable?: Maybe<Scalars['Boolean']>;
    readonly location?: Maybe<LocationNode>;
    readonly room?: Maybe<RoomNode>;
    readonly start: Scalars['DateTime'];
    readonly title?: Maybe<Scalars['String']>;
    readonly type: CalendarEventType;
    readonly videoAppointmentDetails?: Maybe<CalendarEventVideoAppointmentDetailsNode>;
};

/** An enumeration. */
export enum CalendarEventType {
    /** Annual vacation */
    AnnualVacation = 'ANNUAL_VACATION',
    /** Appointment */
    Appointment = 'APPOINTMENT',
    /** Acute appointment */
    AppointmentAcute = 'APPOINTMENT_ACUTE',
    /** Acute appointment available */
    AppointmentAcuteAvailable = 'APPOINTMENT_ACUTE_AVAILABLE',
    /** Appointment available */
    AppointmentAvailable = 'APPOINTMENT_AVAILABLE',
    /** Laboratory appointment */
    AppointmentLaboratory = 'APPOINTMENT_LABORATORY',
    /** Laboratory appointment available */
    AppointmentLaboratoryAvailable = 'APPOINTMENT_LABORATORY_AVAILABLE',
    /** Phone appointment */
    AppointmentPhone = 'APPOINTMENT_PHONE',
    /** Phone appointment available */
    AppointmentPhoneAvailable = 'APPOINTMENT_PHONE_AVAILABLE',
    /** Video appointment */
    AppointmentVideo = 'APPOINTMENT_VIDEO',
    /** Video appointment available */
    AppointmentVideoAvailable = 'APPOINTMENT_VIDEO_AVAILABLE',
    /** Chat substitute */
    ChatSubstitute = 'CHAT_SUBSTITUTE',
    /** Fiscal period */
    CompanyFiscalPeriod = 'COMPANY_FISCAL_PERIOD',
    /** Extra work */
    ExtraWork = 'EXTRA_WORK',
    /** Standby - 35% - Extra work */
    ExtraWorkStandby = 'EXTRA_WORK_STANDBY',
    /** Holiday bonus */
    HolidayBonus = 'HOLIDAY_BONUS',
    /** Work hour reporting */
    HourReporting = 'HOUR_REPORTING',
    /** Lay-off */
    LayOff = 'LAY_OFF',
    /** Live chat */
    LiveChat = 'LIVE_CHAT',
    /** On call */
    OnCall = 'ON_CALL',
    /** Other */
    Other = 'OTHER',
    /** Paid education */
    PaidEducation = 'PAID_EDUCATION',
    /** Paid vacation */
    PaidLeave = 'PAID_LEAVE',
    /** HelttiLinja */
    PhoneService = 'PHONE_SERVICE',
    /** Shift */
    Shift = 'SHIFT',
    /** Sick leave */
    SickLeave = 'SICK_LEAVE',
    /** Sick leave child */
    SickLeaveChild = 'SICK_LEAVE_CHILD',
    /** Unpaid vacation */
    UnpaidLeave = 'UNPAID_LEAVE',
    /** Weekend Duty */
    WeekendExtraWork = 'WEEKEND_EXTRA_WORK',
    /** Working */
    Working = 'WORKING'
}

export type CalendarEventVideoAppointmentDetailsNode = Node & {
    readonly __typename?: 'CalendarEventVideoAppointmentDetailsNode';
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly participantUrl: Scalars['String'];
    readonly uuid: Scalars['UUID'];
};

export type CalendarNode = Node & {
    readonly __typename?: 'CalendarNode';
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly title: Scalars['String'];
    readonly user?: Maybe<UserNode>;
};

export type CalendarReservationAccessNode = Node & {
    readonly __typename?: 'CalendarReservationAccessNode';
    readonly appointmentDays: ReadonlyArray<AppointmentDayNode>;
    readonly appointments: ReadonlyArray<AppointmentNode>;
    readonly contactTypes: ReadonlyArray<Maybe<AppointmentContactType>>;
    readonly contextDescription?: Maybe<Scalars['String']>;
    readonly createdAt: Scalars['DateTime'];
    readonly description: Scalars['String'];
    readonly durations: ReadonlyArray<Scalars['Int']>;
    readonly expiresAt?: Maybe<Scalars['DateTime']>;
    readonly groups: ReadonlyArray<Scalars['String']>;
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    /** @deprecated Use contact_types */
    readonly type: Scalars['String'];
    readonly users: ReadonlyArray<UserNode>;
};

export type CalendarReservationAccessNodeAppointmentDaysArgs = {
    end: Scalars['Date'];
    filters?: Maybe<AppointmentFilters>;
    locationId?: Maybe<Scalars['ID']>;
    regionId?: Maybe<Scalars['ID']>;
    start: Scalars['Date'];
    timeOfDay?: Maybe<AppointmentTimeOfDay>;
};

export type CalendarReservationAccessNodeAppointmentsArgs = {
    end: Scalars['Date'];
    filters?: Maybe<AppointmentFilters>;
    locationId?: Maybe<Scalars['ID']>;
    regionId?: Maybe<Scalars['ID']>;
    start: Scalars['Date'];
    timeOfDay?: Maybe<AppointmentTimeOfDay>;
};

export type CalendarReservationAccessNodeContextDescriptionArgs = {
    filters?: Maybe<AppointmentFilters>;
    locationId?: Maybe<Scalars['ID']>;
    regionId?: Maybe<Scalars['ID']>;
};

export type CallToActionNode = {
    readonly __typename?: 'CallToActionNode';
    readonly description?: Maybe<Scalars['String']>;
    readonly id: Scalars['ID'];
    readonly imageUrl: Scalars['String'];
    readonly targetUrl: Scalars['String'];
    readonly title: Scalars['String'];
};

export type CancelAppointmentMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly eventId: Scalars['ID'];
};

/** Deprecated, use `CancelCalendarEventMutation` instead */
export type CancelAppointmentMutationPayload = {
    readonly __typename?: 'CancelAppointmentMutationPayload';
    readonly calendarEvent: CalendarEventNode;
    readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type CancelCalendarEventMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly eventId: Scalars['ID'];
};

export type CancelCalendarEventMutationPayload = {
    readonly __typename?: 'CancelCalendarEventMutationPayload';
    readonly calendarEvent: CalendarEventNode;
    readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type ChatAttachment = ChatMessageAttachmentNode | ChatMessageIdAttachmentNode | ChatMessageInteractionNode;

export type ChatConnection = {
    readonly __typename?: 'ChatConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<ChatEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `Chat` and its cursor. */
export type ChatEdge = {
    readonly __typename?: 'ChatEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<ChatNode>;
};

export type ChatKeyValueNode = {
    readonly __typename?: 'ChatKeyValueNode';
    readonly key: Scalars['ID'];
    readonly value: Scalars['GenericScalar'];
};

export type ChatMemberNode = Node & {
    readonly __typename?: 'ChatMemberNode';
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly lastMessageSeen?: Maybe<ChatMessageNode>;
    readonly leftAt?: Maybe<Scalars['DateTime']>;
    readonly member: MemberNode;
};

export type ChatMemberNodeConnection = {
    readonly __typename?: 'ChatMemberNodeConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<ChatMemberNodeEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ChatMemberNode` and its cursor. */
export type ChatMemberNodeEdge = {
    readonly __typename?: 'ChatMemberNodeEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<ChatMemberNode>;
};

export type ChatMessageAddMutationInput = {
    readonly chatId: Scalars['ID'];
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly file?: Maybe<Scalars['Upload']>;
    readonly files?: Maybe<ReadonlyArray<Maybe<Scalars['Upload']>>>;
    readonly message: Scalars['String'];
};

export type ChatMessageAddMutationPayload = {
    readonly __typename?: 'ChatMessageAddMutationPayload';
    readonly chat: ChatNode;
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly message: ChatMessageNode;
};

export type ChatMessageAttachmentNode = Node & {
    readonly __typename?: 'ChatMessageAttachmentNode';
    readonly filename: Scalars['String'];
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly url?: Maybe<Scalars['String']>;
};

export type ChatMessageAttachmentNodeConnection = {
    readonly __typename?: 'ChatMessageAttachmentNodeConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<ChatMessageAttachmentNodeEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ChatMessageAttachmentNode` and its cursor. */
export type ChatMessageAttachmentNodeEdge = {
    readonly __typename?: 'ChatMessageAttachmentNodeEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<ChatMessageAttachmentNode>;
};

export type ChatMessageDeleteMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly id: Scalars['ID'];
};

export type ChatMessageDeleteMutationPayload = {
    readonly __typename?: 'ChatMessageDeleteMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type ChatMessageDraftMutationInput = {
    readonly chatId: Scalars['ID'];
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly message: Scalars['String'];
};

export type ChatMessageDraftMutationPayload = {
    readonly __typename?: 'ChatMessageDraftMutationPayload';
    readonly chat: ChatNode;
    readonly chatMember: ChatMemberNode;
    readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type ChatMessageIdAttachmentNode = Node & {
    readonly __typename?: 'ChatMessageIdAttachmentNode';
    readonly attachmentId: Scalars['String'];
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly title?: Maybe<Scalars['String']>;
};

export type ChatMessageInteractionNode = Node & {
    readonly __typename?: 'ChatMessageInteractionNode';
    readonly chatMessage: ChatMessageNode;
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly uiInteraction: UiInteractionNode;
};

export type ChatMessageNode = Node & {
    readonly __typename?: 'ChatMessageNode';
    readonly attachments: ChatMessageAttachmentNodeConnection;
    readonly attachmentsV2: ReadonlyArray<ChatAttachment>;
    readonly createdAt: Scalars['DateTime'];
    readonly deletedAt?: Maybe<Scalars['DateTime']>;
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly member?: Maybe<MemberNode>;
    readonly message: Scalars['String'];
    readonly seen: Scalars['Boolean'];
    readonly type: Scalars['Int'];
    readonly user?: Maybe<UserNode>;
};

export type ChatMessageNodeAttachmentsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type ChatMessageNodeConnection = {
    readonly __typename?: 'ChatMessageNodeConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<ChatMessageNodeEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ChatMessageNode` and its cursor. */
export type ChatMessageNodeEdge = {
    readonly __typename?: 'ChatMessageNodeEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<ChatMessageNode>;
};

export type ChatMessagesSeenMutationInput = {
    readonly chatId: Scalars['ID'];
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly messageIds: ReadonlyArray<Maybe<Scalars['ID']>>;
};

export type ChatMessagesSeenMutationPayload = {
    readonly __typename?: 'ChatMessagesSeenMutationPayload';
    readonly chatMember?: Maybe<ChatMemberNode>;
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly messages?: Maybe<ReadonlyArray<Maybe<ChatMessageNode>>>;
};

export type ChatNode = Node & {
    readonly __typename?: 'ChatNode';
    readonly closedAt?: Maybe<Scalars['DateTime']>;
    readonly description?: Maybe<Scalars['String']>;
    readonly draftMessage?: Maybe<Scalars['String']>;
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly lastMessage?: Maybe<ChatMessageNode>;
    readonly lastMessageAt?: Maybe<Scalars['DateTime']>;
    readonly members: ChatMemberNodeConnection;
    readonly messageInteractions?: Maybe<ReadonlyArray<ChatMessageInteractionNode>>;
    readonly messages?: Maybe<ChatMessageNodeConnection>;
    readonly modifiedAt: Scalars['DateTime'];
    /** @deprecated Deprecated, use notifications */
    readonly notification?: Maybe<ChatNotificationNode>;
    readonly notifications?: Maybe<ReadonlyArray<ChatNotificationNode>>;
    readonly readonly: Scalars['Boolean'];
    readonly responseTimeSla?: Maybe<Scalars['String']>;
    readonly title: Scalars['String'];
    readonly type: ChatType;
    readonly unreadMessagesCountV2: Scalars['Int'];
    readonly users: ChatUserNodeConnection;
};

export type ChatNodeMembersArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type ChatNodeMessagesArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    messagesAfterLastMessageAt?: Maybe<Scalars['DateTime']>;
    offset?: Maybe<Scalars['Int']>;
};

export type ChatNodeUsersArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type ChatNotificationActionNode = {
    readonly __typename?: 'ChatNotificationActionNode';
    readonly contents?: Maybe<ReadonlyArray<ChatKeyValueNode>>;
    readonly id: Scalars['String'];
    readonly params?: Maybe<ReadonlyArray<ChatKeyValueNode>>;
    readonly type: ChatNotificationActionType;
};

/** An enumeration. */
export enum ChatNotificationActionType {
    Call = 'CALL',
    Escalate = 'ESCALATE'
}

/**
 *
 *     The context defines the "where" of a chat notification
 *
 */
export enum ChatNotificationContext {
    Alert = 'ALERT',
    Inline = 'INLINE'
}

export type ChatNotificationNode = {
    readonly __typename?: 'ChatNotificationNode';
    readonly actions?: Maybe<ReadonlyArray<ChatNotificationActionNode>>;
    /** @deprecated Use contents */
    readonly content?: Maybe<Scalars['String']>;
    readonly contents?: Maybe<ReadonlyArray<ChatKeyValueNode>>;
    readonly context: ChatNotificationContext;
    readonly id: Scalars['ID'];
    /** @deprecated Use id */
    readonly type?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum ChatType {
    /** Generic */
    A_0 = 'A_0',
    /** Live */
    A_1 = 'A_1',
    /** HelttiChat */
    A_2 = 'A_2',
    /** Own nurse */
    A_100 = 'A_100',
    /** Questionnaire feedback */
    A_101 = 'A_101',
    /** Support */
    A_200 = 'A_200',
    /** Feedback */
    A_201 = 'A_201',
    /** Internal */
    A_300 = 'A_300',
    /** Scheduled self help */
    A_401 = 'A_401',
    /** Supervisor channel */
    A_501 = 'A_501'
}

export type ChatUserNode = Node & {
    readonly __typename?: 'ChatUserNode';
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly lastMessageSeen?: Maybe<ChatMessageNode>;
    readonly leftAt?: Maybe<Scalars['DateTime']>;
    readonly user: UserNode;
};

export type ChatUserNodeConnection = {
    readonly __typename?: 'ChatUserNodeConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<ChatUserNodeEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ChatUserNode` and its cursor. */
export type ChatUserNodeEdge = {
    readonly __typename?: 'ChatUserNodeEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<ChatUserNode>;
};

export type CompanyNode = Node & {
    readonly __typename?: 'CompanyNode';
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly membersCanReportSickLeave: Scalars['Boolean'];
    readonly name: Scalars['String'];
    readonly sickLeaveMaxDays: Scalars['Int'];
    readonly sickLeaveMinDays: Scalars['Int'];
};

export type CreateMemberDocumentExportTokenMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly documentId: Scalars['ID'];
};

export type CreateMemberDocumentExportTokenMutationPayload = {
    readonly __typename?: 'CreateMemberDocumentExportTokenMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly document?: Maybe<MemberDocumentNode>;
};

export type DeleteMemberDocumentExportTokenMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly tokenId: Scalars['ID'];
};

export type DeleteMemberDocumentExportTokenMutationPayload = {
    readonly __typename?: 'DeleteMemberDocumentExportTokenMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly document?: Maybe<MemberDocumentNode>;
};

export type DepartmentNode = Node & {
    readonly __typename?: 'DepartmentNode';
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly name: Scalars['String'];
};

export enum DevicePlatform {
    PlatformAndroid = 'PLATFORM_ANDROID',
    PlatformElectron = 'PLATFORM_ELECTRON',
    PlatformIos = 'PLATFORM_IOS',
    PlatformWeb = 'PLATFORM_WEB'
}

export enum EIdentAppId {
    Customer = 'CUSTOMER',
    Member = 'MEMBER'
}

export type EIdentInitMutationInput = {
    readonly appId: EIdentAppId;
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly deviceId: Scalars['UUID'];
};

export type EIdentInitMutationPayload = {
    readonly __typename?: 'EIdentInitMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly url: Scalars['String'];
};

export type EIdentMutationInput = {
    readonly appId: Scalars['String'];
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly query: Scalars['String'];
};

/** TODO: Deprecated, remove when no APP clients use this */
export type EIdentMutationPayload = {
    readonly __typename?: 'EIdentMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly payload?: Maybe<Scalars['GenericScalar']>;
    readonly refreshExpiresIn: Scalars['Int'];
    readonly refreshToken: Scalars['String'];
    readonly token: Scalars['String'];
};

export type EIdentVerifyV2MutationInput = {
    readonly appId: EIdentAppId;
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly deviceId: Scalars['UUID'];
    readonly query: Scalars['String'];
};

export type EIdentVerifyV2MutationPayload = {
    readonly __typename?: 'EIdentVerifyV2MutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly payload?: Maybe<Scalars['GenericScalar']>;
    readonly refreshExpiresIn: Scalars['Int'];
    readonly refreshToken: Scalars['String'];
    readonly token: Scalars['String'];
};

export type EmploymentNode = Node & {
    readonly __typename?: 'EmploymentNode';
    readonly company: CompanyNode;
    readonly department?: Maybe<DepartmentNode>;
    readonly endDate?: Maybe<Scalars['Date']>;
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly location?: Maybe<Scalars['String']>;
    readonly position?: Maybe<Scalars['String']>;
    readonly startDate: Scalars['Date'];
    readonly superior?: Maybe<Scalars['String']>;
};

export type EmploymentNodeConnection = {
    readonly __typename?: 'EmploymentNodeConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<EmploymentNodeEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `EmploymentNode` and its cursor. */
export type EmploymentNodeEdge = {
    readonly __typename?: 'EmploymentNodeEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<EmploymentNode>;
};

export type EscalateToLiveChatMutationInput = {
    readonly chatId: Scalars['ID'];
    readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type EscalateToLiveChatMutationPayload = {
    readonly __typename?: 'EscalateToLiveChatMutationPayload';
    readonly chat?: Maybe<ChatNode>;
    readonly clientMutationId?: Maybe<Scalars['String']>;
};

export enum Feature {
    Unknown = 'UNKNOWN'
}

export type InteractionActionNode = {
    readonly __typename?: 'InteractionActionNode';
    readonly color?: Maybe<InteractionColor>;
    readonly handle: Scalars['String'];
    readonly importance?: Maybe<InteractionImportance>;
    readonly title: Scalars['String'];
    readonly value?: Maybe<Scalars['String']>;
};

/**
 *
 *     Can be used to specify color for certain interaction elements
 *
 *     Most common usage will be providing a color for the InteractionAction, which will most likely translate to
 *     the color of the action button.
 *
 */
export enum InteractionColor {
    Black = 'BLACK',
    Blue = 'BLUE',
    Brown = 'BROWN',
    Green = 'GREEN',
    Grey = 'GREY',
    Olive = 'OLIVE',
    Orange = 'ORANGE',
    Pink = 'PINK',
    Purple = 'PURPLE',
    Red = 'RED',
    Teal = 'TEAL',
    Violet = 'VIOLET',
    Yellow = 'YELLOW'
}

/**
 *
 *     Can be used to indicate importance of certain UI elements.
 *
 *     Most common usage will be highlighting the primary InteractionAction or lessening the visual importance of some
 *     rarely used InteractionAction. However, the importance can also be used to indicate how big visual importance
 *     the client should give to the certain interaction element.
 *
 */
export enum InteractionImportance {
    Low = 'LOW',
    Normal = 'NORMAL',
    Primary = 'PRIMARY'
}

export type InteractionInputNode = {
    readonly __typename?: 'InteractionInputNode';
    readonly initialValue?: Maybe<Scalars['GenericScalar']>;
    readonly label?: Maybe<Scalars['String']>;
    readonly name: Scalars['String'];
    readonly placeholder?: Maybe<Scalars['String']>;
    readonly selectAllowAdd?: Maybe<Scalars['Boolean']>;
    readonly selectMultiple?: Maybe<Scalars['Boolean']>;
    readonly selectTargetName?: Maybe<Scalars['String']>;
    readonly type: Scalars['String'];
};

export type InteractionInputValueInput = {
    readonly name: Scalars['String'];
    readonly values: ReadonlyArray<Scalars['String']>;
};

export type InteractionMutationInput = {
    readonly actionHandle: Scalars['String'];
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly id: Scalars['ID'];
    readonly values?: Maybe<ReadonlyArray<Maybe<InteractionInputValueInput>>>;
};

export type InteractionMutationPayload = {
    readonly __typename?: 'InteractionMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    /** @deprecated Use ui_interaction instead */
    readonly interaction?: Maybe<UiInteractionNode>;
    readonly interactionResult?: Maybe<UiInteractionResultNode>;
    readonly uiInteraction?: Maybe<UiInteractionNode>;
};

export type InteractionNode = {
    readonly __typename?: 'InteractionNode';
    readonly actions: ReadonlyArray<InteractionActionNode>;
    readonly color?: Maybe<Scalars['String']>;
    readonly description?: Maybe<Scalars['String']>;
    readonly id: Scalars['UUID'];
    readonly inputs: ReadonlyArray<InteractionInputNode>;
    readonly label?: Maybe<Scalars['String']>;
    readonly title?: Maybe<Scalars['String']>;
};

export enum LanguageCode {
    En = 'EN',
    Fi = 'FI'
}

export type LocationNode = Node & {
    readonly __typename?: 'LocationNode';
    readonly address1?: Maybe<Scalars['String']>;
    readonly address2?: Maybe<Scalars['String']>;
    readonly city?: Maybe<Scalars['String']>;
    readonly firstAppointmentTime?: Maybe<Scalars['String']>;
    readonly geometry?: Maybe<Scalars['GenericScalar']>;
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly name: Scalars['String'];
    readonly organizationName?: Maybe<Scalars['String']>;
    readonly type: LocationType;
    readonly zip?: Maybe<Scalars['String']>;
};

export type LocationNodeFirstAppointmentTimeArgs = {
    calendarReservationAccessId: Scalars['ID'];
};

export type LocationNodeConnection = {
    readonly __typename?: 'LocationNodeConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<LocationNodeEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `LocationNode` and its cursor. */
export type LocationNodeEdge = {
    readonly __typename?: 'LocationNodeEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<LocationNode>;
};

export enum LocationType {
    Heltti = 'HELTTI',
    Other = 'OTHER',
    Partner = 'PARTNER'
}

export type MeetingInformation = {
    readonly __typename?: 'MeetingInformation';
    readonly provider: VideoMeetingProvider;
    readonly url: Scalars['String'];
    readonly uuid: Scalars['ID'];
};

export type MemberActionMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly reasonForContact?: Maybe<Scalars['String']>;
    readonly title?: Maybe<Scalars['String']>;
    readonly typeNew: MemberActionType;
};

export type MemberActionMutationPayload = {
    readonly __typename?: 'MemberActionMutationPayload';
    readonly appUrl?: Maybe<Scalars['String']>;
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly url?: Maybe<Scalars['String']>;
};

export type MemberActionNode = {
    readonly __typename?: 'MemberActionNode';
    readonly actionUrl?: Maybe<Scalars['String']>;
    readonly category?: Maybe<Scalars['Int']>;
    readonly chat?: Maybe<ChatNode>;
    readonly color?: Maybe<Scalars['String']>;
    readonly description?: Maybe<Scalars['String']>;
    readonly descriptionShort?: Maybe<Scalars['String']>;
    readonly imageUrl?: Maybe<Scalars['String']>;
    readonly responseTimeSla?: Maybe<Scalars['String']>;
    readonly title: Scalars['String'];
    /** @deprecated Use type_new */
    readonly type: Scalars['Int'];
    readonly typeNew: MemberActionType;
};

export enum MemberActionType {
    ActionUrl = 'ACTION_URL',
    ActiveChat = 'ACTIVE_CHAT',
    Call = 'CALL',
    Faq = 'FAQ',
    Feedback = 'FEEDBACK',
    HelttiChat = 'HELTTI_CHAT',
    LiveChat = 'LIVE_CHAT',
    NoAction = 'NO_ACTION',
    NurseOnCall = 'NURSE_ON_CALL',
    OwnNurse = 'OWN_NURSE',
    ReportSickLeave = 'REPORT_SICK_LEAVE',
    Support = 'SUPPORT'
}

export type MemberActionsNode = {
    readonly __typename?: 'MemberActionsNode';
    readonly actions?: Maybe<ReadonlyArray<MemberActionNode>>;
};

export type MemberAvatarEditMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly file?: Maybe<Scalars['Upload']>;
};

export type MemberAvatarEditMutationPayload = {
    readonly __typename?: 'MemberAvatarEditMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly member?: Maybe<MemberNode>;
};

export type MemberBadgeSubscription = {
    readonly __typename?: 'MemberBadgeSubscription';
    readonly badges?: Maybe<BadgeNode>;
};

export type MemberChatSubscription = {
    readonly __typename?: 'MemberChatSubscription';
    readonly chat?: Maybe<ChatNode>;
    readonly messageAdd?: Maybe<ChatMessageNode>;
    readonly messageEdit?: Maybe<ChatMessageNode>;
};

export type MemberDocumentConnection = {
    readonly __typename?: 'MemberDocumentConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<MemberDocumentEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `MemberDocument` and its cursor. */
export type MemberDocumentEdge = {
    readonly __typename?: 'MemberDocumentEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<MemberDocumentNode>;
};

export type MemberDocumentExportTokenNode = Node & {
    readonly __typename?: 'MemberDocumentExportTokenNode';
    readonly deletedAt?: Maybe<Scalars['DateTime']>;
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly token: Scalars['String'];
    readonly validUntil?: Maybe<Scalars['DateTime']>;
};

export type MemberDocumentExportTokenNodeConnection = {
    readonly __typename?: 'MemberDocumentExportTokenNodeConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<MemberDocumentExportTokenNodeEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `MemberDocumentExportTokenNode` and its cursor. */
export type MemberDocumentExportTokenNodeEdge = {
    readonly __typename?: 'MemberDocumentExportTokenNodeEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<MemberDocumentExportTokenNode>;
};

export type MemberDocumentNode = Node & {
    readonly __typename?: 'MemberDocumentNode';
    readonly addedDate: Scalars['Date'];
    readonly createdBy?: Maybe<UserNode>;
    readonly exportTokens: MemberDocumentExportTokenNodeConnection;
    readonly filename: Scalars['String'];
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly memberSeenAt?: Maybe<Scalars['DateTime']>;
    readonly memberVisibleComment?: Maybe<Scalars['String']>;
    readonly url?: Maybe<Scalars['String']>;
};

export type MemberDocumentNodeExportTokensArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type MemberMutationObject = {
    readonly __typename?: 'MemberMutationObject';
    readonly acceptTerms?: Maybe<AcceptTermsMutationPayload>;
    /** @deprecated Use add_analytics_events_v2 */
    readonly addAnalyticsEvents?: Maybe<AddAnalyticsEventsMutationPayload>;
    readonly addAnalyticsEventsV2?: Maybe<AddAnalyticsEventsMutationV2Payload>;
    readonly avatarEdit?: Maybe<MemberAvatarEditMutationPayload>;
    /**
     * Deprecated, use `CancelCalendarEventMutation` instead
     * @deprecated Use cancelCalendarEvent instead
     */
    readonly cancelAppointment?: Maybe<CancelAppointmentMutationPayload>;
    readonly cancelCalendarEvent?: Maybe<CancelCalendarEventMutationPayload>;
    readonly chatMessageAdd?: Maybe<ChatMessageAddMutationPayload>;
    readonly chatMessageDelete?: Maybe<ChatMessageDeleteMutationPayload>;
    readonly chatMessageDraft?: Maybe<ChatMessageDraftMutationPayload>;
    readonly chatMessagesSeen?: Maybe<ChatMessagesSeenMutationPayload>;
    readonly documentExportTokenCreate?: Maybe<CreateMemberDocumentExportTokenMutationPayload>;
    readonly documentExportTokenDelete?: Maybe<DeleteMemberDocumentExportTokenMutationPayload>;
    readonly eidentInit?: Maybe<EIdentInitMutationPayload>;
    /**
     * TODO: Deprecated, remove when no APP clients use this
     * @deprecated Use eident_verify_v2
     */
    readonly eidentVerify?: Maybe<EIdentMutationPayload>;
    readonly eidentVerifyV2?: Maybe<EIdentVerifyV2MutationPayload>;
    readonly escalateToLiveChat?: Maybe<EscalateToLiveChatMutationPayload>;
    readonly interaction?: Maybe<InteractionMutationPayload>;
    readonly interactionTrigger?: Maybe<TriggerInteractionMutationPayload>;
    readonly memberAction?: Maybe<MemberActionMutationPayload>;
    /**
     * Deprecated, use `ModifyCalendarEventMutation` instead
     * @deprecated Use modifyCalendarEvent instead
     */
    readonly modifyAppointment?: Maybe<ModifyAppointmentMutationPayload>;
    readonly modifyCalendarEvent?: Maybe<ModifyCalendarEventMutationPayload>;
    readonly obtainToken?: Maybe<ObtainTokenMutation>;
    readonly obtainTokenWithSmsCode?: Maybe<ObtainTokenWithSmsCodeMutationPayload>;
    readonly profileEdit?: Maybe<ProfileEditMutationPayload>;
    readonly profileFieldEdit?: Maybe<ProfileFieldEditMutationPayload>;
    readonly questionnaireResponse?: Maybe<QuestionnaireResponseMutationPayload>;
    readonly refreshToken?: Maybe<RefreshTokenMutation>;
    readonly registerDevice?: Maybe<RegisterDeviceMutationPayload>;
    readonly reportSickLeave?: Maybe<ReportSickLeaveMutationPayload>;
    readonly requestAccountRemoval?: Maybe<RequestAccountRemovalMutationPayload>;
    readonly resendSmsToken?: Maybe<ResendSmsTokenMutationPayload>;
    readonly reserveAppointment?: Maybe<ReserveAppointmentMutationPayload>;
    readonly revokeToken?: Maybe<RevokeTokenMutation>;
    readonly selfHelpCourseSubscribe?: Maybe<SelfHelpCourseSubscribeMutationPayload>;
    readonly signUpWithEmail?: Maybe<SignUpWithEmailMutationPayload>;
    readonly updateDevicePushToken?: Maybe<UpdateDevicePushTokenPayload>;
    readonly verifyEmail?: Maybe<VerifyEmailMutationPayload>;
};

export type MemberMutationObjectAcceptTermsArgs = {
    input: AcceptTermsMutationInput;
};

export type MemberMutationObjectAddAnalyticsEventsArgs = {
    input: AddAnalyticsEventsMutationInput;
};

export type MemberMutationObjectAddAnalyticsEventsV2Args = {
    input: AddAnalyticsEventsMutationV2Input;
};

export type MemberMutationObjectAvatarEditArgs = {
    input: MemberAvatarEditMutationInput;
};

export type MemberMutationObjectCancelAppointmentArgs = {
    input: CancelAppointmentMutationInput;
};

export type MemberMutationObjectCancelCalendarEventArgs = {
    input: CancelCalendarEventMutationInput;
};

export type MemberMutationObjectChatMessageAddArgs = {
    input: ChatMessageAddMutationInput;
};

export type MemberMutationObjectChatMessageDeleteArgs = {
    input: ChatMessageDeleteMutationInput;
};

export type MemberMutationObjectChatMessageDraftArgs = {
    input: ChatMessageDraftMutationInput;
};

export type MemberMutationObjectChatMessagesSeenArgs = {
    input: ChatMessagesSeenMutationInput;
};

export type MemberMutationObjectDocumentExportTokenCreateArgs = {
    input: CreateMemberDocumentExportTokenMutationInput;
};

export type MemberMutationObjectDocumentExportTokenDeleteArgs = {
    input: DeleteMemberDocumentExportTokenMutationInput;
};

export type MemberMutationObjectEidentInitArgs = {
    input: EIdentInitMutationInput;
};

export type MemberMutationObjectEidentVerifyArgs = {
    input: EIdentMutationInput;
};

export type MemberMutationObjectEidentVerifyV2Args = {
    input: EIdentVerifyV2MutationInput;
};

export type MemberMutationObjectEscalateToLiveChatArgs = {
    input: EscalateToLiveChatMutationInput;
};

export type MemberMutationObjectInteractionArgs = {
    input: InteractionMutationInput;
};

export type MemberMutationObjectInteractionTriggerArgs = {
    input: TriggerInteractionMutationInput;
};

export type MemberMutationObjectMemberActionArgs = {
    input: MemberActionMutationInput;
};

export type MemberMutationObjectModifyAppointmentArgs = {
    input: ModifyAppointmentMutationInput;
};

export type MemberMutationObjectModifyCalendarEventArgs = {
    input: ModifyCalendarEventMutationInput;
};

export type MemberMutationObjectObtainTokenArgs = {
    password: Scalars['String'];
    username: Scalars['String'];
};

export type MemberMutationObjectObtainTokenWithSmsCodeArgs = {
    input: ObtainTokenWithSmsCodeMutationInput;
};

export type MemberMutationObjectProfileEditArgs = {
    input: ProfileEditMutationInput;
};

export type MemberMutationObjectProfileFieldEditArgs = {
    input: ProfileFieldEditMutationInput;
};

export type MemberMutationObjectQuestionnaireResponseArgs = {
    input: QuestionnaireResponseMutationInput;
};

export type MemberMutationObjectRefreshTokenArgs = {
    refreshToken?: Maybe<Scalars['String']>;
};

export type MemberMutationObjectRegisterDeviceArgs = {
    input: RegisterDeviceMutationInput;
};

export type MemberMutationObjectReportSickLeaveArgs = {
    input: ReportSickLeaveMutationInput;
};

export type MemberMutationObjectRequestAccountRemovalArgs = {
    input: RequestAccountRemovalMutationInput;
};

export type MemberMutationObjectResendSmsTokenArgs = {
    input: ResendSmsTokenMutationInput;
};

export type MemberMutationObjectReserveAppointmentArgs = {
    input: ReserveAppointmentMutationInput;
};

export type MemberMutationObjectRevokeTokenArgs = {
    refreshToken?: Maybe<Scalars['String']>;
};

export type MemberMutationObjectSelfHelpCourseSubscribeArgs = {
    input: SelfHelpCourseSubscribeMutationInput;
};

export type MemberMutationObjectSignUpWithEmailArgs = {
    input: SignUpWithEmailMutationInput;
};

export type MemberMutationObjectUpdateDevicePushTokenArgs = {
    input: UpdateDevicePushTokenInput;
};

export type MemberMutationObjectVerifyEmailArgs = {
    input: VerifyEmailMutationInput;
};

export type MemberNode = Node & {
    readonly __typename?: 'MemberNode';
    readonly address1?: Maybe<Scalars['String']>;
    readonly address2?: Maybe<Scalars['String']>;
    readonly availableFeatures: ReadonlyArray<Feature>;
    readonly avatarBigUrl?: Maybe<Scalars['String']>;
    readonly avatarSmallUrl?: Maybe<Scalars['String']>;
    readonly callName: Scalars['String'];
    readonly city?: Maybe<Scalars['String']>;
    /** @deprecated Use home email */
    readonly email: Scalars['String'];
    readonly employments?: Maybe<EmploymentNodeConnection>;
    readonly feedbackPermission: Scalars['Boolean'];
    /** @deprecated Use feedbackPermission */
    readonly feedbackPermissionAt?: Maybe<Scalars['DateTime']>;
    readonly firstName: Scalars['String'];
    readonly hasCompanyAccess: Scalars['Boolean'];
    readonly hasUsablePassword: Scalars['Boolean'];
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly lang: LanguageCode;
    /** @deprecated Use lang */
    readonly language?: Maybe<Scalars['Int']>;
    readonly lastName: Scalars['String'];
    readonly marketingPermission: Scalars['Boolean'];
    /** @deprecated Use marketingPermission */
    readonly marketingPermissionAt?: Maybe<Scalars['DateTime']>;
    readonly memberCommunicationPermission: Scalars['Boolean'];
    /** @deprecated Use memberCommunicationPermission */
    readonly memberCommunicationPermissionAt?: Maybe<Scalars['DateTime']>;
    /** @deprecated Use home phone */
    readonly phone?: Maybe<Scalars['String']>;
    readonly privateEmail?: Maybe<Scalars['String']>;
    readonly privatePhone?: Maybe<Scalars['String']>;
    readonly ssn?: Maybe<Scalars['String']>;
    readonly termsAccepted: Scalars['Boolean'];
    readonly verificationRequired: Scalars['Boolean'];
    readonly verified: Scalars['Boolean'];
    readonly workEmail?: Maybe<Scalars['String']>;
    readonly workPhone?: Maybe<Scalars['String']>;
    readonly zip?: Maybe<Scalars['String']>;
};

export type MemberNodeEmploymentsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type MemberNotificationConnection = {
    readonly __typename?: 'MemberNotificationConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<MemberNotificationEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `MemberNotification` and its cursor. */
export type MemberNotificationEdge = {
    readonly __typename?: 'MemberNotificationEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<MemberNotificationNode>;
};

export type MemberNotificationNode = Node & {
    readonly __typename?: 'MemberNotificationNode';
    readonly createdAt: Scalars['DateTime'];
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly message: Scalars['String'];
    readonly seenAt?: Maybe<Scalars['DateTime']>;
    readonly sender?: Maybe<UserNode>;
    readonly title: Scalars['String'];
};

/** Root of GraphQL query schema for Member API */
export type MemberQueryRoot = {
    readonly __typename?: 'MemberQueryRoot';
    readonly appUpdateState: AppUpdateState;
    /** @deprecated Use app update state */
    readonly clientVersion: Scalars['String'];
    readonly eidentVerificationRequired: Scalars['Boolean'];
    /** @deprecated Use eidentInit mutation */
    readonly eidentVerificationUrl?: Maybe<Scalars['String']>;
    readonly public: PublicRootNode;
    readonly root?: Maybe<MemberRootNode>;
    readonly token?: Maybe<TokenRootNode>;
};

/** Root of GraphQL query schema for Member API */
export type MemberQueryRootAppUpdateStateArgs = {
    version: Scalars['String'];
};

/** Root of GraphQL query schema for Member API */
export type MemberQueryRootEidentVerificationRequiredArgs = {
    email: Scalars['String'];
};

/** Root of GraphQL query schema for Member API */
export type MemberQueryRootEidentVerificationUrlArgs = {
    appId: Scalars['String'];
};

/** Root of GraphQL query schema for Member API */
export type MemberQueryRootTokenArgs = {
    token: Scalars['String'];
};

/** Root object for Member API */
export type MemberRootNode = {
    readonly __typename?: 'MemberRootNode';
    readonly article: ArticleNode;
    readonly availableActions?: Maybe<MemberActionsNode>;
    readonly badges?: Maybe<BadgeNode>;
    readonly calendarEvent?: Maybe<CalendarEventNode>;
    readonly calendarEvents?: Maybe<CalendarEventConnection>;
    readonly calendarReservationAccess: CalendarReservationAccessNode;
    readonly calendarReservationAccesses?: Maybe<ReadonlyArray<CalendarReservationAccessNode>>;
    readonly callToActions: ReadonlyArray<CallToActionNode>;
    readonly chat?: Maybe<ChatNode>;
    readonly chatMessageAttachment?: Maybe<ChatMessageAttachmentNode>;
    readonly chats?: Maybe<ChatConnection>;
    readonly document?: Maybe<MemberDocumentNode>;
    readonly documents?: Maybe<MemberDocumentConnection>;
    readonly globalCalendarReservationAccesses?: Maybe<ReadonlyArray<CalendarReservationAccessNode>>;
    readonly interactions: ReadonlyArray<UiInteractionNode>;
    readonly locationsWithAppointment?: Maybe<ReadonlyArray<LocationNode>>;
    readonly me?: Maybe<MemberNode>;
    readonly notifications?: Maybe<MemberNotificationConnection>;
    readonly questionnaire: QuestionnaireNode;
    readonly questionnaireResponse: QuestionnaireResponseNode;
    readonly questionnaireResponseBatches?: Maybe<QuestionnaireResponseBatchNodeConnection>;
    readonly regions?: Maybe<ReadonlyArray<RegionNode>>;
    readonly selfHelpCourse?: Maybe<SelfHelpCourseNode>;
};

/** Root object for Member API */
export type MemberRootNodeArticleArgs = {
    id?: Maybe<Scalars['ID']>;
    tag?: Maybe<Scalars['String']>;
};

/** Root object for Member API */
export type MemberRootNodeCalendarEventArgs = {
    id: Scalars['ID'];
};

/** Root object for Member API */
export type MemberRootNodeCalendarEventsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    end?: Maybe<Scalars['DateTime']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    start?: Maybe<Scalars['DateTime']>;
};

/** Root object for Member API */
export type MemberRootNodeCalendarReservationAccessArgs = {
    id: Scalars['ID'];
};

/** Root object for Member API */
export type MemberRootNodeCallToActionsArgs = {
    context: CtaContextType;
};

/** Root object for Member API */
export type MemberRootNodeChatArgs = {
    id: Scalars['ID'];
};

/** Root object for Member API */
export type MemberRootNodeChatMessageAttachmentArgs = {
    id: Scalars['ID'];
};

/** Root object for Member API */
export type MemberRootNodeChatsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    live?: Maybe<Scalars['Boolean']>;
    open?: Maybe<Scalars['Boolean']>;
    recent?: Maybe<Scalars['Boolean']>;
    unseen?: Maybe<Scalars['Boolean']>;
};

/** Root object for Member API */
export type MemberRootNodeDocumentArgs = {
    id: Scalars['ID'];
};

/** Root object for Member API */
export type MemberRootNodeDocumentsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    unseen?: Maybe<Scalars['Boolean']>;
};

/** Root object for Member API */
export type MemberRootNodeLocationsWithAppointmentArgs = {
    calendarReservationAccessId: Scalars['ID'];
    contactTypes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    date: Scalars['Date'];
    regionId: Scalars['ID'];
    timeOfDay?: Maybe<AppointmentTimeOfDay>;
};

/** Root object for Member API */
export type MemberRootNodeNotificationsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** Root object for Member API */
export type MemberRootNodeQuestionnaireArgs = {
    id: Scalars['ID'];
};

/** Root object for Member API */
export type MemberRootNodeQuestionnaireResponseArgs = {
    id: Scalars['ID'];
};

/** Root object for Member API */
export type MemberRootNodeQuestionnaireResponseBatchesArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

/** Root object for Member API */
export type MemberRootNodeSelfHelpCourseArgs = {
    id: Scalars['ID'];
};

/** Root of GraphQL subscription schema for Member API */
export type MemberSubscriptionRoot = {
    readonly __typename?: 'MemberSubscriptionRoot';
    readonly badge?: Maybe<MemberBadgeSubscription>;
    readonly chat?: Maybe<MemberChatSubscription>;
};

/** Root of GraphQL subscription schema for Member API */
export type MemberSubscriptionRootChatArgs = {
    chatId?: Maybe<Scalars['ID']>;
};

export type ModifyAppointmentMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly contactType?: Maybe<AppointmentContactType>;
    readonly duration?: Maybe<Scalars['Int']>;
    readonly eventId: Scalars['ID'];
    readonly meta?: Maybe<Scalars['String']>;
    readonly startTime?: Maybe<Scalars['DateTime']>;
};

/** Deprecated, use `ModifyCalendarEventMutation` instead */
export type ModifyAppointmentMutationPayload = {
    readonly __typename?: 'ModifyAppointmentMutationPayload';
    readonly calendarEvent: CalendarEventNode;
    readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type ModifyCalendarEventMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly duration?: Maybe<Scalars['Int']>;
    readonly eventId: Scalars['ID'];
    readonly meta?: Maybe<Scalars['String']>;
    readonly startTime?: Maybe<Scalars['DateTime']>;
};

export type ModifyCalendarEventMutationPayload = {
    readonly __typename?: 'ModifyCalendarEventMutationPayload';
    readonly calendarEvent: CalendarEventNode;
    readonly clientMutationId?: Maybe<Scalars['String']>;
};

/** An object with an ID */
export type Node = {
    /** The ID of the object. */
    readonly id: Scalars['ID'];
};

export type ObtainTokenMutation = {
    readonly __typename?: 'ObtainTokenMutation';
    readonly payload: Scalars['GenericScalar'];
    readonly refreshExpiresIn: Scalars['Int'];
    readonly refreshToken: Scalars['String'];
    readonly token: Scalars['String'];
};

export type ObtainTokenWithSmsCodeMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly codeFromSms: Scalars['String'];
    readonly tokenFromEmail: Scalars['String'];
};

export type ObtainTokenWithSmsCodeMutationPayload = {
    readonly __typename?: 'ObtainTokenWithSMSCodeMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly error?: Maybe<SmsTokenValidationError>;
    readonly payload?: Maybe<Scalars['GenericScalar']>;
    readonly refreshExpiresIn?: Maybe<Scalars['Int']>;
    readonly refreshToken?: Maybe<Scalars['String']>;
    readonly token?: Maybe<Scalars['String']>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
    readonly __typename?: 'PageInfo';
    /** When paginating forwards, the cursor to continue. */
    readonly endCursor?: Maybe<Scalars['String']>;
    /** When paginating forwards, are there more items? */
    readonly hasNextPage: Scalars['Boolean'];
    /** When paginating backwards, are there more items? */
    readonly hasPreviousPage: Scalars['Boolean'];
    /** When paginating backwards, the cursor to continue. */
    readonly startCursor?: Maybe<Scalars['String']>;
};

export type ProfileEditMutationInput = {
    readonly address1: Scalars['String'];
    readonly address2?: Maybe<Scalars['String']>;
    readonly city: Scalars['String'];
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly email?: Maybe<Scalars['String']>;
    readonly feedbackPermission?: Maybe<Scalars['Boolean']>;
    readonly language?: Maybe<Scalars['Int']>;
    readonly languageCode?: Maybe<LanguageCode>;
    readonly marketingPermission: Scalars['Boolean'];
    readonly memberCommunicationPermission?: Maybe<Scalars['Boolean']>;
    readonly nonce?: Maybe<Scalars['String']>;
    readonly password?: Maybe<Scalars['String']>;
    readonly phone: Scalars['String'];
    readonly zip: Scalars['String'];
};

export type ProfileEditMutationPayload = {
    readonly __typename?: 'ProfileEditMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly member: MemberNode;
};

export type ProfileFieldEditMutationInput = {
    readonly address1?: Maybe<Scalars['String']>;
    readonly address2?: Maybe<Scalars['String']>;
    readonly callName?: Maybe<Scalars['String']>;
    readonly city?: Maybe<Scalars['String']>;
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly email?: Maybe<Scalars['String']>;
    readonly feedbackPermission?: Maybe<Scalars['Boolean']>;
    readonly firstName?: Maybe<Scalars['String']>;
    readonly lang?: Maybe<LanguageCode>;
    readonly lastName?: Maybe<Scalars['String']>;
    readonly marketingPermission?: Maybe<Scalars['Boolean']>;
    readonly memberCommunicationPermission?: Maybe<Scalars['Boolean']>;
    readonly privatePhone?: Maybe<Scalars['String']>;
    readonly workEmail?: Maybe<Scalars['String']>;
    readonly workPhone?: Maybe<Scalars['String']>;
    readonly zip?: Maybe<Scalars['String']>;
};

export type ProfileFieldEditMutationPayload = {
    readonly __typename?: 'ProfileFieldEditMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly member: MemberNode;
};

export type PublicRootNode = {
    readonly __typename?: 'PublicRootNode';
    readonly meetingInformation?: Maybe<MeetingInformation>;
};

export type PublicRootNodeMeetingInformationArgs = {
    meetingUuid: Scalars['UUID'];
};

export type QuestionnaireNode = Node & {
    readonly __typename?: 'QuestionnaireNode';
    readonly description?: Maybe<Scalars['String']>;
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly outro?: Maybe<Scalars['String']>;
    /** @deprecated Outro questions will be removed */
    readonly outroQuestions: QuestionnaireQuestionNodeConnection;
    /** @deprecated Outro questions will be removed */
    readonly outroQuestionsTitle?: Maybe<Scalars['String']>;
    readonly questions: QuestionnaireQuestionNodeConnection;
    readonly title?: Maybe<Scalars['String']>;
    readonly type: QuestionnaireType;
};

export type QuestionnaireNodeOutroQuestionsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type QuestionnaireNodeQuestionsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type QuestionnaireQuestionCategoryNode = Node & {
    readonly __typename?: 'QuestionnaireQuestionCategoryNode';
    readonly color: Scalars['String'];
    readonly icon: Scalars['String'];
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly key: Scalars['String'];
    readonly orderNumber: Scalars['Int'];
};

export type QuestionnaireQuestionInput = {
    readonly listValue?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
    readonly questionId: Scalars['ID'];
    readonly strValue?: Maybe<Scalars['String']>;
};

export type QuestionnaireQuestionNode = Node & {
    readonly __typename?: 'QuestionnaireQuestionNode';
    readonly category?: Maybe<QuestionnaireQuestionCategoryNode>;
    readonly description?: Maybe<Scalars['String']>;
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly options?: Maybe<ReadonlyArray<QuestionnaireQuestionOption>>;
    readonly orderNumber: Scalars['Int'];
    readonly title?: Maybe<Scalars['String']>;
    readonly type: QuestionnaireQuestionType;
};

export type QuestionnaireQuestionNodeConnection = {
    readonly __typename?: 'QuestionnaireQuestionNodeConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<QuestionnaireQuestionNodeEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `QuestionnaireQuestionNode` and its cursor. */
export type QuestionnaireQuestionNodeEdge = {
    readonly __typename?: 'QuestionnaireQuestionNodeEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<QuestionnaireQuestionNode>;
};

export type QuestionnaireQuestionOption = {
    readonly __typename?: 'QuestionnaireQuestionOption';
    readonly title: Scalars['String'];
    readonly value: Scalars['Int'];
    readonly weight?: Maybe<Scalars['Float']>;
};

export type QuestionnaireQuestionResponseNode = Node & {
    readonly __typename?: 'QuestionnaireQuestionResponseNode';
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly listValue?: Maybe<ReadonlyArray<Scalars['Int']>>;
    readonly questionnaireQuestion: QuestionnaireQuestionNode;
    readonly questionnaireResponse: QuestionnaireResponseNode;
    readonly strValue?: Maybe<Scalars['String']>;
};

export type QuestionnaireQuestionResponseNodeConnection = {
    readonly __typename?: 'QuestionnaireQuestionResponseNodeConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<QuestionnaireQuestionResponseNodeEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `QuestionnaireQuestionResponseNode` and its cursor. */
export type QuestionnaireQuestionResponseNodeEdge = {
    readonly __typename?: 'QuestionnaireQuestionResponseNodeEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<QuestionnaireQuestionResponseNode>;
};

/** An enumeration. */
export enum QuestionnaireQuestionType {
    /** boolean */
    Boolean = 'BOOLEAN',
    /** int */
    Int = 'INT',
    /** radio */
    Radio = 'RADIO',
    /** radio_icons */
    RadioIcons = 'RADIO_ICONS',
    /** select */
    Select = 'SELECT',
    /** select_icons */
    SelectIcons = 'SELECT_ICONS',
    /** slider */
    Slider = 'SLIDER',
    /** text_long */
    TextLong = 'TEXT_LONG',
    /** text_short */
    TextShort = 'TEXT_SHORT'
}

export type QuestionnaireResponseBatchNode = Node & {
    readonly __typename?: 'QuestionnaireResponseBatchNode';
    readonly closeAt: Scalars['DateTime'];
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly message?: Maybe<Scalars['String']>;
    readonly openAt: Scalars['DateTime'];
    readonly questionnaireResponses?: Maybe<QuestionnaireResponseNodeConnection>;
};

export type QuestionnaireResponseBatchNodeQuestionnaireResponsesArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type QuestionnaireResponseBatchNodeConnection = {
    readonly __typename?: 'QuestionnaireResponseBatchNodeConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<QuestionnaireResponseBatchNodeEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `QuestionnaireResponseBatchNode` and its cursor. */
export type QuestionnaireResponseBatchNodeEdge = {
    readonly __typename?: 'QuestionnaireResponseBatchNodeEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<QuestionnaireResponseBatchNode>;
};

export type QuestionnaireResponseMutationInput = {
    readonly accessToken?: Maybe<Scalars['String']>;
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly id: Scalars['ID'];
    readonly questionResponses?: Maybe<ReadonlyArray<Maybe<QuestionnaireQuestionInput>>>;
};

export type QuestionnaireResponseMutationPayload = {
    readonly __typename?: 'QuestionnaireResponseMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly response?: Maybe<QuestionnaireResponseNode>;
};

export type QuestionnaireResponseNode = Node & {
    readonly __typename?: 'QuestionnaireResponseNode';
    readonly analysedAt?: Maybe<Scalars['DateTime']>;
    readonly closeAt?: Maybe<Scalars['Date']>;
    readonly closedAt?: Maybe<Scalars['DateTime']>;
    readonly createdAt: Scalars['DateTime'];
    readonly finishedAt?: Maybe<Scalars['DateTime']>;
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly modifiedAt: Scalars['DateTime'];
    readonly questionnaire: QuestionnaireNode;
    readonly responseSummaryMd?: Maybe<Scalars['String']>;
    readonly responses: QuestionnaireQuestionResponseNodeConnection;
    readonly summary?: Maybe<QuestionnaireResponseSummaryNode>;
    readonly tokenUrl?: Maybe<Scalars['String']>;
};

export type QuestionnaireResponseNodeResponsesArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type QuestionnaireResponseNodeConnection = {
    readonly __typename?: 'QuestionnaireResponseNodeConnection';
    /** Contains the nodes in this connection. */
    readonly edges: ReadonlyArray<Maybe<QuestionnaireResponseNodeEdge>>;
    /** Pagination data for this connection. */
    readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `QuestionnaireResponseNode` and its cursor. */
export type QuestionnaireResponseNodeEdge = {
    readonly __typename?: 'QuestionnaireResponseNodeEdge';
    /** A cursor for use in pagination */
    readonly cursor: Scalars['String'];
    /** The item at the end of the edge */
    readonly node?: Maybe<QuestionnaireResponseNode>;
};

export type QuestionnaireResponseSummaryCategoryNode = {
    readonly __typename?: 'QuestionnaireResponseSummaryCategoryNode';
    readonly color?: Maybe<Scalars['String']>;
    readonly icon?: Maybe<Scalars['String']>;
    readonly key?: Maybe<Scalars['String']>;
    readonly popup?: Maybe<Scalars['String']>;
    readonly title?: Maybe<Scalars['String']>;
    readonly value?: Maybe<Scalars['Float']>;
};

export type QuestionnaireResponseSummaryNode = {
    readonly __typename?: 'QuestionnaireResponseSummaryNode';
    readonly categories?: Maybe<ReadonlyArray<Maybe<QuestionnaireResponseSummaryCategoryNode>>>;
    readonly description?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum QuestionnaireType {
    /** background */
    Background = 'BACKGROUND',
    /** bdi-21 */
    Bdi_21 = 'BDI_21',
    /** brain */
    Brain = 'BRAIN',
    /** capacity */
    Capacity = 'CAPACITY',
    /** coder */
    Coder = 'CODER',
    /** core-5 */
    Core_5 = 'CORE_5',
    /** generic */
    Generic = 'GENERIC',
    /** keds */
    Keds = 'KEDS',
    /** oasis */
    Oasis = 'OASIS',
    /** work */
    Work = 'WORK'
}

export type RefreshTokenMutation = {
    readonly __typename?: 'RefreshTokenMutation';
    readonly payload: Scalars['GenericScalar'];
    readonly refreshExpiresIn: Scalars['Int'];
    readonly refreshToken: Scalars['String'];
    readonly token: Scalars['String'];
};

export type RegionNode = Node & {
    readonly __typename?: 'RegionNode';
    readonly geometry: Scalars['GenericScalar'];
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly locations?: Maybe<LocationNodeConnection>;
    readonly name: Scalars['String'];
};

export type RegionNodeLocationsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
};

export type RegisterDeviceMutationInput = {
    readonly appVersion?: Maybe<Scalars['String']>;
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly deviceInfo?: Maybe<Scalars['String']>;
    readonly deviceUuid?: Maybe<Scalars['UUID']>;
    readonly platform: DevicePlatform;
};

export type RegisterDeviceMutationPayload = {
    readonly __typename?: 'RegisterDeviceMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly deviceUuid: Scalars['String'];
};

export type ReportSickLeaveMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly sickLeaveDays: Scalars['Int'];
    readonly sickLeaveReason?: Maybe<Scalars['String']>;
    readonly sickLeaveStart: Scalars['Date'];
};

export type ReportSickLeaveMutationPayload = {
    readonly __typename?: 'ReportSickLeaveMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly sickLeave?: Maybe<SickLeaveNode>;
};

export type RequestAccountRemovalMutationInput = {
    readonly additionalInformation?: Maybe<Scalars['String']>;
    readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type RequestAccountRemovalMutationPayload = {
    readonly __typename?: 'RequestAccountRemovalMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly success: Scalars['Boolean'];
};

export type ResendSmsTokenMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly tokenFromEmail: Scalars['String'];
};

export type ResendSmsTokenMutationPayload = {
    readonly __typename?: 'ResendSMSTokenMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly success: Scalars['Boolean'];
};

export type ReserveAppointmentMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly contactType?: Maybe<AppointmentContactType>;
    readonly duration?: Maybe<Scalars['Int']>;
    readonly meta?: Maybe<Scalars['String']>;
    readonly startTime?: Maybe<Scalars['DateTime']>;
};

export type ReserveAppointmentMutationPayload = {
    readonly __typename?: 'ReserveAppointmentMutationPayload';
    readonly calendarEvent?: Maybe<CalendarEventNode>;
    readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type RevokeTokenMutation = {
    readonly __typename?: 'RevokeTokenMutation';
    readonly revoked: Scalars['Int'];
};

export type RoomNode = Node & {
    readonly __typename?: 'RoomNode';
    readonly description?: Maybe<Scalars['String']>;
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly location: LocationNode;
    readonly name: Scalars['String'];
};

export enum SmsTokenValidationError {
    InvalidCode = 'INVALID_CODE',
    InvalidToken = 'INVALID_TOKEN'
}

export type SelfHelpCourseNode = Node & {
    readonly __typename?: 'SelfHelpCourseNode';
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly title?: Maybe<Scalars['String']>;
};

export type SelfHelpCourseSubscribeMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly courseId: Scalars['ID'];
};

export type SelfHelpCourseSubscribeMutationPayload = {
    readonly __typename?: 'SelfHelpCourseSubscribeMutationPayload';
    readonly chat: ChatNode;
    readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SickLeaveNode = Node & {
    readonly __typename?: 'SickLeaveNode';
    /** The ID of the object. */
    readonly id: Scalars['ID'];
};

/** An enumeration. */
export enum SignUpType {
    Email = 'EMAIL',
    Identification = 'IDENTIFICATION',
    Unsupported = 'UNSUPPORTED'
}

export type SignUpWithEmailMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly firstName: Scalars['String'];
    readonly language?: Maybe<LanguageCode>;
    readonly lastName: Scalars['String'];
    readonly password?: Maybe<Scalars['String']>;
    readonly phone: Scalars['String'];
    readonly token: Scalars['String'];
};

export type SignUpWithEmailMutationPayload = {
    readonly __typename?: 'SignUpWithEmailMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly member: MemberNode;
    readonly payload?: Maybe<Scalars['GenericScalar']>;
    readonly refreshExpiresIn?: Maybe<Scalars['Int']>;
    readonly refreshToken?: Maybe<Scalars['String']>;
    readonly token?: Maybe<Scalars['String']>;
};

/**
 * Fields can be accessed with a valid token without an authenticated user session
 *
 * See AccessTokenUtil for documentation on access tokens.
 *
 * NOTE:   Fields under this node should return any data only if the token is valid and for the exact item
 *         (a valid token for some resource won't allow access to any other token field)
 */
export type TokenRootNode = {
    readonly __typename?: 'TokenRootNode';
    readonly questionnaireResponseBatch: QuestionnaireResponseBatchNode;
    readonly signUpType: SignUpType;
};

/**
 * Fields can be accessed with a valid token without an authenticated user session
 *
 * See AccessTokenUtil for documentation on access tokens.
 *
 * NOTE:   Fields under this node should return any data only if the token is valid and for the exact item
 *         (a valid token for some resource won't allow access to any other token field)
 */
export type TokenRootNodeQuestionnaireResponseBatchArgs = {
    batchId: Scalars['ID'];
    memberId: Scalars['ID'];
};

export type TriggerInteractionMutationInput = {
    readonly actionHandle: Scalars['String'];
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly handle: Scalars['String'];
};

export type TriggerInteractionMutationPayload = {
    readonly __typename?: 'TriggerInteractionMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly interactionResult?: Maybe<UiInteractionResultNode>;
    readonly uiInteraction?: Maybe<UiInteractionNode>;
};

/** An enumeration. */
export enum UiInteractionContext {
    /** chat */
    Chat = 'CHAT',
    /** chat_message */
    ChatMessage = 'CHAT_MESSAGE',
    /** modal */
    Modal = 'MODAL'
}

export type UiInteractionNode = Node & {
    readonly __typename?: 'UIInteractionNode';
    readonly context: UiInteractionContext;
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly interaction: InteractionNode;
};

export type UiInteractionResultNode = {
    readonly __typename?: 'UIInteractionResultNode';
    readonly redirectTo?: Maybe<Scalars['String']>;
};

export type UpdateDevicePushTokenInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly deviceUuid: Scalars['UUID'];
    readonly pushToken: Scalars['String'];
};

export type UpdateDevicePushTokenPayload = {
    readonly __typename?: 'UpdateDevicePushTokenPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type UserNode = Node & {
    readonly __typename?: 'UserNode';
    readonly avatarBigUrl?: Maybe<Scalars['String']>;
    readonly avatarSmallUrl?: Maybe<Scalars['String']>;
    readonly firstName: Scalars['String'];
    /** The ID of the object. */
    readonly id: Scalars['ID'];
    readonly lastName: Scalars['String'];
    readonly profile?: Maybe<Scalars['String']>;
    readonly title?: Maybe<Scalars['String']>;
};

export type VerifyEmailMutationInput = {
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly email: Scalars['String'];
};

export type VerifyEmailMutationPayload = {
    readonly __typename?: 'VerifyEmailMutationPayload';
    readonly clientMutationId?: Maybe<Scalars['String']>;
    readonly success: Scalars['Boolean'];
};

export enum VideoMeetingProvider {
    Whereby = 'WHEREBY'
}

export type InteractionResultFragment = {
    readonly __typename?: 'UIInteractionResultNode';
    readonly redirectTo?: Maybe<string>;
};

export type InteractionInputFragment = {
    readonly __typename?: 'InteractionInputNode';
    readonly type: string;
    readonly name: string;
    readonly placeholder?: Maybe<string>;
    readonly label?: Maybe<string>;
    readonly initialValue?: Maybe<any>;
    readonly selectTargetName?: Maybe<string>;
    readonly selectAllowAdd?: Maybe<boolean>;
    readonly selectMultiple?: Maybe<boolean>;
};

export type InteractionActionFragment = {
    readonly __typename?: 'InteractionActionNode';
    readonly handle: string;
    readonly value?: Maybe<string>;
    readonly title: string;
    readonly importance?: Maybe<InteractionImportance>;
    readonly color?: Maybe<InteractionColor>;
};

export type InteractionFragment = {
    readonly __typename?: 'InteractionNode';
    readonly id: any;
    readonly title?: Maybe<string>;
    readonly description?: Maybe<string>;
    readonly label?: Maybe<string>;
    readonly color?: Maybe<string>;
    readonly inputs: ReadonlyArray<{
        readonly __typename?: 'InteractionInputNode';
        readonly type: string;
        readonly name: string;
        readonly placeholder?: Maybe<string>;
        readonly label?: Maybe<string>;
        readonly initialValue?: Maybe<any>;
        readonly selectTargetName?: Maybe<string>;
        readonly selectAllowAdd?: Maybe<boolean>;
        readonly selectMultiple?: Maybe<boolean>;
    }>;
    readonly actions: ReadonlyArray<{
        readonly __typename?: 'InteractionActionNode';
        readonly handle: string;
        readonly value?: Maybe<string>;
        readonly title: string;
        readonly importance?: Maybe<InteractionImportance>;
        readonly color?: Maybe<InteractionColor>;
    }>;
};

export type UiInteractionFragment = {
    readonly __typename?: 'UIInteractionNode';
    readonly id: string;
    readonly context: UiInteractionContext;
    readonly interaction: {
        readonly __typename?: 'InteractionNode';
        readonly id: any;
        readonly title?: Maybe<string>;
        readonly description?: Maybe<string>;
        readonly label?: Maybe<string>;
        readonly color?: Maybe<string>;
        readonly inputs: ReadonlyArray<{
            readonly __typename?: 'InteractionInputNode';
            readonly type: string;
            readonly name: string;
            readonly placeholder?: Maybe<string>;
            readonly label?: Maybe<string>;
            readonly initialValue?: Maybe<any>;
            readonly selectTargetName?: Maybe<string>;
            readonly selectAllowAdd?: Maybe<boolean>;
            readonly selectMultiple?: Maybe<boolean>;
        }>;
        readonly actions: ReadonlyArray<{
            readonly __typename?: 'InteractionActionNode';
            readonly handle: string;
            readonly value?: Maybe<string>;
            readonly title: string;
            readonly importance?: Maybe<InteractionImportance>;
            readonly color?: Maybe<InteractionColor>;
        }>;
    };
};

export type EmploymentFragment = {
    readonly __typename?: 'EmploymentNode';
    readonly id: string;
    readonly location?: Maybe<string>;
    readonly position?: Maybe<string>;
    readonly superior?: Maybe<string>;
    readonly startDate: string;
    readonly endDate?: Maybe<string>;
    readonly department?: Maybe<{ readonly __typename?: 'DepartmentNode'; readonly id: string; readonly name: string }>;
};

export type ProfileFragment = {
    readonly __typename?: 'MemberNode';
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly language?: Maybe<number>;
    readonly lang: LanguageCode;
    readonly memberCommunicationPermissionAt?: Maybe<string>;
    readonly marketingPermissionAt?: Maybe<string>;
    readonly feedbackPermissionAt?: Maybe<string>;
    readonly termsAccepted: boolean;
    readonly verified: boolean;
    readonly verificationRequired: boolean;
    readonly phone?: Maybe<string>;
    readonly email: string;
    readonly address1?: Maybe<string>;
    readonly address2?: Maybe<string>;
    readonly zip?: Maybe<string>;
    readonly city?: Maybe<string>;
    readonly hasUsablePassword: boolean;
    readonly hasCompanyAccess: boolean;
    readonly employments?: Maybe<{
        readonly __typename?: 'EmploymentNodeConnection';
        readonly edges: ReadonlyArray<
            Maybe<{
                readonly __typename?: 'EmploymentNodeEdge';
                readonly node?: Maybe<{
                    readonly __typename?: 'EmploymentNode';
                    readonly id: string;
                    readonly location?: Maybe<string>;
                    readonly position?: Maybe<string>;
                    readonly superior?: Maybe<string>;
                    readonly startDate: string;
                    readonly endDate?: Maybe<string>;
                    readonly company: {
                        readonly __typename?: 'CompanyNode';
                        readonly name: string;
                        readonly membersCanReportSickLeave: boolean;
                        readonly sickLeaveMinDays: number;
                        readonly sickLeaveMaxDays: number;
                    };
                    readonly department?: Maybe<{
                        readonly __typename?: 'DepartmentNode';
                        readonly id: string;
                        readonly name: string;
                    }>;
                }>;
            }>
        >;
    }>;
};

export type UserFragment = {
    readonly __typename?: 'UserNode';
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly title?: Maybe<string>;
    readonly avatarBigUrl?: Maybe<string>;
    readonly avatarSmallUrl?: Maybe<string>;
};

export type CalendarReservationAccessFragment = {
    readonly __typename?: 'CalendarReservationAccessNode';
    readonly id: string;
    readonly createdAt: string;
    readonly expiresAt?: Maybe<string>;
    readonly type: string;
    readonly durations: ReadonlyArray<number>;
    readonly description: string;
    readonly contactTypes: ReadonlyArray<Maybe<AppointmentContactType>>;
    readonly groups: ReadonlyArray<string>;
    readonly users: ReadonlyArray<{
        readonly __typename?: 'UserNode';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly title?: Maybe<string>;
        readonly avatarBigUrl?: Maybe<string>;
        readonly avatarSmallUrl?: Maybe<string>;
    }>;
};

export type LocationFragment = {
    readonly __typename?: 'LocationNode';
    readonly id: string;
    readonly name: string;
    readonly type: LocationType;
    readonly address1?: Maybe<string>;
    readonly address2?: Maybe<string>;
    readonly zip?: Maybe<string>;
    readonly city?: Maybe<string>;
};

export type CalendarEventFragment = {
    readonly __typename?: 'CalendarEventNode';
    readonly id: string;
    readonly type: CalendarEventType;
    readonly start: string;
    readonly end: string;
    readonly title?: Maybe<string>;
    readonly description?: Maybe<string>;
    readonly isModifiable?: Maybe<boolean>;
    readonly location?: Maybe<{
        readonly __typename?: 'LocationNode';
        readonly id: string;
        readonly name: string;
        readonly type: LocationType;
        readonly address1?: Maybe<string>;
        readonly address2?: Maybe<string>;
        readonly zip?: Maybe<string>;
        readonly city?: Maybe<string>;
    }>;
    readonly calendarReservationAccess?: Maybe<{
        readonly __typename?: 'CalendarReservationAccessNode';
        readonly id: string;
        readonly createdAt: string;
        readonly expiresAt?: Maybe<string>;
        readonly type: string;
        readonly durations: ReadonlyArray<number>;
        readonly description: string;
        readonly contactTypes: ReadonlyArray<Maybe<AppointmentContactType>>;
        readonly groups: ReadonlyArray<string>;
        readonly users: ReadonlyArray<{
            readonly __typename?: 'UserNode';
            readonly id: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly title?: Maybe<string>;
            readonly avatarBigUrl?: Maybe<string>;
            readonly avatarSmallUrl?: Maybe<string>;
        }>;
    }>;
    readonly videoAppointmentDetails?: Maybe<{
        readonly __typename?: 'CalendarEventVideoAppointmentDetailsNode';
        readonly id: string;
        readonly uuid: any;
        readonly participantUrl: string;
    }>;
    readonly calendar: {
        readonly __typename?: 'CalendarNode';
        readonly user?: Maybe<{
            readonly __typename?: 'UserNode';
            readonly id: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly title?: Maybe<string>;
            readonly avatarBigUrl?: Maybe<string>;
            readonly avatarSmallUrl?: Maybe<string>;
        }>;
    };
};

export type AppointmentFragment = {
    readonly __typename?: 'AppointmentNode';
    readonly id: string;
    readonly start: string;
    readonly end: string;
    readonly duration: number;
    readonly meta: string;
    readonly contactTypes: ReadonlyArray<AppointmentContactType>;
    readonly location?: Maybe<{
        readonly __typename?: 'LocationNode';
        readonly id: string;
        readonly name: string;
        readonly type: LocationType;
        readonly address1?: Maybe<string>;
        readonly address2?: Maybe<string>;
        readonly zip?: Maybe<string>;
        readonly city?: Maybe<string>;
    }>;
    readonly user: {
        readonly __typename?: 'UserNode';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly title?: Maybe<string>;
        readonly avatarBigUrl?: Maybe<string>;
        readonly avatarSmallUrl?: Maybe<string>;
    };
};

export type AppointmentDayFragment = {
    readonly __typename?: 'AppointmentDayNode';
    readonly id: string;
    readonly date: string;
    readonly eventCount: number;
    readonly nextAppointments: ReadonlyArray<{
        readonly __typename?: 'AppointmentNode';
        readonly id: string;
        readonly start: string;
        readonly end: string;
        readonly meta: string;
        readonly location?: Maybe<{
            readonly __typename?: 'LocationNode';
            readonly id: string;
            readonly name: string;
            readonly type: LocationType;
            readonly address1?: Maybe<string>;
            readonly address2?: Maybe<string>;
            readonly zip?: Maybe<string>;
            readonly city?: Maybe<string>;
        }>;
    }>;
};

export type ChatMemberFragment = {
    readonly __typename?: 'MemberNode';
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly avatarBigUrl?: Maybe<string>;
    readonly avatarSmallUrl?: Maybe<string>;
};

export type ChatMessageInteractionFragment = {
    readonly __typename?: 'ChatMessageInteractionNode';
    readonly id: string;
    readonly uiInteraction: {
        readonly __typename?: 'UIInteractionNode';
        readonly id: string;
        readonly context: UiInteractionContext;
        readonly interaction: {
            readonly __typename?: 'InteractionNode';
            readonly id: any;
            readonly title?: Maybe<string>;
            readonly description?: Maybe<string>;
            readonly label?: Maybe<string>;
            readonly color?: Maybe<string>;
            readonly inputs: ReadonlyArray<{
                readonly __typename?: 'InteractionInputNode';
                readonly type: string;
                readonly name: string;
                readonly placeholder?: Maybe<string>;
                readonly label?: Maybe<string>;
                readonly initialValue?: Maybe<any>;
                readonly selectTargetName?: Maybe<string>;
                readonly selectAllowAdd?: Maybe<boolean>;
                readonly selectMultiple?: Maybe<boolean>;
            }>;
            readonly actions: ReadonlyArray<{
                readonly __typename?: 'InteractionActionNode';
                readonly handle: string;
                readonly value?: Maybe<string>;
                readonly title: string;
                readonly importance?: Maybe<InteractionImportance>;
                readonly color?: Maybe<InteractionColor>;
            }>;
        };
    };
    readonly chatMessage: { readonly __typename?: 'ChatMessageNode'; readonly id: string };
};

export type ChatMessageFileAttachmentFragment = {
    readonly __typename?: 'ChatMessageAttachmentNode';
    readonly id: string;
    readonly filename: string;
    readonly url?: Maybe<string>;
};

export type ChatMessageIdAttachmentFragment = {
    readonly __typename?: 'ChatMessageIdAttachmentNode';
    readonly id: string;
    readonly attachmentId: string;
    readonly title?: Maybe<string>;
};

type ChatMessageAttachmentFragment_ChatMessageAttachmentNode_ = {
    readonly __typename?: 'ChatMessageAttachmentNode';
    readonly id: string;
    readonly filename: string;
    readonly url?: Maybe<string>;
};

type ChatMessageAttachmentFragment_ChatMessageIdAttachmentNode_ = {
    readonly __typename?: 'ChatMessageIdAttachmentNode';
    readonly id: string;
    readonly attachmentId: string;
    readonly title?: Maybe<string>;
};

type ChatMessageAttachmentFragment_ChatMessageInteractionNode_ = {
    readonly __typename?: 'ChatMessageInteractionNode';
    readonly id: string;
    readonly uiInteraction: {
        readonly __typename?: 'UIInteractionNode';
        readonly id: string;
        readonly context: UiInteractionContext;
        readonly interaction: {
            readonly __typename?: 'InteractionNode';
            readonly id: any;
            readonly title?: Maybe<string>;
            readonly description?: Maybe<string>;
            readonly label?: Maybe<string>;
            readonly color?: Maybe<string>;
            readonly inputs: ReadonlyArray<{
                readonly __typename?: 'InteractionInputNode';
                readonly type: string;
                readonly name: string;
                readonly placeholder?: Maybe<string>;
                readonly label?: Maybe<string>;
                readonly initialValue?: Maybe<any>;
                readonly selectTargetName?: Maybe<string>;
                readonly selectAllowAdd?: Maybe<boolean>;
                readonly selectMultiple?: Maybe<boolean>;
            }>;
            readonly actions: ReadonlyArray<{
                readonly __typename?: 'InteractionActionNode';
                readonly handle: string;
                readonly value?: Maybe<string>;
                readonly title: string;
                readonly importance?: Maybe<InteractionImportance>;
                readonly color?: Maybe<InteractionColor>;
            }>;
        };
    };
    readonly chatMessage: { readonly __typename?: 'ChatMessageNode'; readonly id: string };
};

export type ChatMessageAttachmentFragment =
    | ChatMessageAttachmentFragment_ChatMessageAttachmentNode_
    | ChatMessageAttachmentFragment_ChatMessageIdAttachmentNode_
    | ChatMessageAttachmentFragment_ChatMessageInteractionNode_;

export type ChatMessageFragment = {
    readonly __typename?: 'ChatMessageNode';
    readonly id: string;
    readonly createdAt: string;
    readonly deletedAt?: Maybe<string>;
    readonly seen: boolean;
    readonly type: number;
    readonly message: string;
    readonly user?: Maybe<{
        readonly __typename?: 'UserNode';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly title?: Maybe<string>;
        readonly avatarBigUrl?: Maybe<string>;
        readonly avatarSmallUrl?: Maybe<string>;
    }>;
    readonly member?: Maybe<{
        readonly __typename?: 'MemberNode';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly avatarBigUrl?: Maybe<string>;
        readonly avatarSmallUrl?: Maybe<string>;
    }>;
    readonly attachmentsV2: ReadonlyArray<
        | {
              readonly __typename?: 'ChatMessageAttachmentNode';
              readonly id: string;
              readonly filename: string;
              readonly url?: Maybe<string>;
          }
        | {
              readonly __typename?: 'ChatMessageIdAttachmentNode';
              readonly id: string;
              readonly attachmentId: string;
              readonly title?: Maybe<string>;
          }
        | {
              readonly __typename?: 'ChatMessageInteractionNode';
              readonly id: string;
              readonly uiInteraction: {
                  readonly __typename?: 'UIInteractionNode';
                  readonly id: string;
                  readonly context: UiInteractionContext;
                  readonly interaction: {
                      readonly __typename?: 'InteractionNode';
                      readonly id: any;
                      readonly title?: Maybe<string>;
                      readonly description?: Maybe<string>;
                      readonly label?: Maybe<string>;
                      readonly color?: Maybe<string>;
                      readonly inputs: ReadonlyArray<{
                          readonly __typename?: 'InteractionInputNode';
                          readonly type: string;
                          readonly name: string;
                          readonly placeholder?: Maybe<string>;
                          readonly label?: Maybe<string>;
                          readonly initialValue?: Maybe<any>;
                          readonly selectTargetName?: Maybe<string>;
                          readonly selectAllowAdd?: Maybe<boolean>;
                          readonly selectMultiple?: Maybe<boolean>;
                      }>;
                      readonly actions: ReadonlyArray<{
                          readonly __typename?: 'InteractionActionNode';
                          readonly handle: string;
                          readonly value?: Maybe<string>;
                          readonly title: string;
                          readonly importance?: Maybe<InteractionImportance>;
                          readonly color?: Maybe<InteractionColor>;
                      }>;
                  };
              };
              readonly chatMessage: { readonly __typename?: 'ChatMessageNode'; readonly id: string };
          }
    >;
};

export type ChatUsersFragment = {
    readonly __typename?: 'ChatNode';
    readonly users: {
        readonly __typename?: 'ChatUserNodeConnection';
        readonly edges: ReadonlyArray<
            Maybe<{
                readonly __typename?: 'ChatUserNodeEdge';
                readonly node?: Maybe<{
                    readonly __typename?: 'ChatUserNode';
                    readonly id: string;
                    readonly leftAt?: Maybe<string>;
                    readonly user: {
                        readonly __typename?: 'UserNode';
                        readonly id: string;
                        readonly firstName: string;
                        readonly lastName: string;
                        readonly title?: Maybe<string>;
                        readonly avatarBigUrl?: Maybe<string>;
                        readonly avatarSmallUrl?: Maybe<string>;
                    };
                }>;
            }>
        >;
    };
};

export type ChatMembersFragment = {
    readonly __typename?: 'ChatNode';
    readonly members: {
        readonly __typename?: 'ChatMemberNodeConnection';
        readonly edges: ReadonlyArray<
            Maybe<{
                readonly __typename?: 'ChatMemberNodeEdge';
                readonly node?: Maybe<{
                    readonly __typename?: 'ChatMemberNode';
                    readonly id: string;
                    readonly leftAt?: Maybe<string>;
                    readonly member: { readonly __typename?: 'MemberNode'; readonly id: string };
                    readonly lastMessageSeen?: Maybe<{ readonly __typename?: 'ChatMessageNode'; readonly id: string }>;
                }>;
            }>
        >;
    };
};

export type ChatInformationFragment = {
    readonly __typename?: 'ChatNode';
    readonly id: string;
    readonly type: ChatType;
    readonly title: string;
    readonly responseTimeSla?: Maybe<string>;
    readonly description?: Maybe<string>;
    readonly modifiedAt: string;
    readonly lastMessageAt?: Maybe<string>;
    readonly closedAt?: Maybe<string>;
    readonly readonly: boolean;
};

export type ChatNotificationActionFragment = {
    readonly __typename?: 'ChatNotificationActionNode';
    readonly id: string;
    readonly type: ChatNotificationActionType;
    readonly params?: Maybe<
        ReadonlyArray<{ readonly __typename?: 'ChatKeyValueNode'; readonly key: string; readonly value: any }>
    >;
    readonly contents?: Maybe<
        ReadonlyArray<{ readonly __typename?: 'ChatKeyValueNode'; readonly key: string; readonly value: any }>
    >;
};

export type ChatNotificationFragment = {
    readonly __typename?: 'ChatNotificationNode';
    readonly id: string;
    readonly context: ChatNotificationContext;
    readonly contents?: Maybe<
        ReadonlyArray<{ readonly __typename?: 'ChatKeyValueNode'; readonly key: string; readonly value: any }>
    >;
    readonly actions?: Maybe<
        ReadonlyArray<{
            readonly __typename?: 'ChatNotificationActionNode';
            readonly id: string;
            readonly type: ChatNotificationActionType;
            readonly params?: Maybe<
                ReadonlyArray<{ readonly __typename?: 'ChatKeyValueNode'; readonly key: string; readonly value: any }>
            >;
            readonly contents?: Maybe<
                ReadonlyArray<{ readonly __typename?: 'ChatKeyValueNode'; readonly key: string; readonly value: any }>
            >;
        }>
    >;
};

export type ChatNotificationsFragment = {
    readonly __typename?: 'ChatNode';
    readonly notifications?: Maybe<
        ReadonlyArray<{
            readonly __typename?: 'ChatNotificationNode';
            readonly id: string;
            readonly context: ChatNotificationContext;
            readonly contents?: Maybe<
                ReadonlyArray<{ readonly __typename?: 'ChatKeyValueNode'; readonly key: string; readonly value: any }>
            >;
            readonly actions?: Maybe<
                ReadonlyArray<{
                    readonly __typename?: 'ChatNotificationActionNode';
                    readonly id: string;
                    readonly type: ChatNotificationActionType;
                    readonly params?: Maybe<
                        ReadonlyArray<{
                            readonly __typename?: 'ChatKeyValueNode';
                            readonly key: string;
                            readonly value: any;
                        }>
                    >;
                    readonly contents?: Maybe<
                        ReadonlyArray<{
                            readonly __typename?: 'ChatKeyValueNode';
                            readonly key: string;
                            readonly value: any;
                        }>
                    >;
                }>
            >;
        }>
    >;
};

export type ChatFragment = {
    readonly __typename?: 'ChatNode';
    readonly id: string;
    readonly type: ChatType;
    readonly title: string;
    readonly responseTimeSla?: Maybe<string>;
    readonly description?: Maybe<string>;
    readonly modifiedAt: string;
    readonly lastMessageAt?: Maybe<string>;
    readonly closedAt?: Maybe<string>;
    readonly readonly: boolean;
    readonly notifications?: Maybe<
        ReadonlyArray<{
            readonly __typename?: 'ChatNotificationNode';
            readonly id: string;
            readonly context: ChatNotificationContext;
            readonly contents?: Maybe<
                ReadonlyArray<{ readonly __typename?: 'ChatKeyValueNode'; readonly key: string; readonly value: any }>
            >;
            readonly actions?: Maybe<
                ReadonlyArray<{
                    readonly __typename?: 'ChatNotificationActionNode';
                    readonly id: string;
                    readonly type: ChatNotificationActionType;
                    readonly params?: Maybe<
                        ReadonlyArray<{
                            readonly __typename?: 'ChatKeyValueNode';
                            readonly key: string;
                            readonly value: any;
                        }>
                    >;
                    readonly contents?: Maybe<
                        ReadonlyArray<{
                            readonly __typename?: 'ChatKeyValueNode';
                            readonly key: string;
                            readonly value: any;
                        }>
                    >;
                }>
            >;
        }>
    >;
    readonly users: {
        readonly __typename?: 'ChatUserNodeConnection';
        readonly edges: ReadonlyArray<
            Maybe<{
                readonly __typename?: 'ChatUserNodeEdge';
                readonly node?: Maybe<{
                    readonly __typename?: 'ChatUserNode';
                    readonly id: string;
                    readonly leftAt?: Maybe<string>;
                    readonly user: {
                        readonly __typename?: 'UserNode';
                        readonly id: string;
                        readonly firstName: string;
                        readonly lastName: string;
                        readonly title?: Maybe<string>;
                        readonly avatarBigUrl?: Maybe<string>;
                        readonly avatarSmallUrl?: Maybe<string>;
                    };
                }>;
            }>
        >;
    };
    readonly members: {
        readonly __typename?: 'ChatMemberNodeConnection';
        readonly edges: ReadonlyArray<
            Maybe<{
                readonly __typename?: 'ChatMemberNodeEdge';
                readonly node?: Maybe<{
                    readonly __typename?: 'ChatMemberNode';
                    readonly id: string;
                    readonly leftAt?: Maybe<string>;
                    readonly member: { readonly __typename?: 'MemberNode'; readonly id: string };
                    readonly lastMessageSeen?: Maybe<{ readonly __typename?: 'ChatMessageNode'; readonly id: string }>;
                }>;
            }>
        >;
    };
};

export type ListChatFragment = {
    readonly __typename?: 'ChatNode';
    readonly unreadMessagesCountV2: number;
    readonly id: string;
    readonly type: ChatType;
    readonly title: string;
    readonly responseTimeSla?: Maybe<string>;
    readonly description?: Maybe<string>;
    readonly modifiedAt: string;
    readonly lastMessageAt?: Maybe<string>;
    readonly closedAt?: Maybe<string>;
    readonly readonly: boolean;
    readonly users: {
        readonly __typename?: 'ChatUserNodeConnection';
        readonly edges: ReadonlyArray<
            Maybe<{
                readonly __typename?: 'ChatUserNodeEdge';
                readonly node?: Maybe<{
                    readonly __typename?: 'ChatUserNode';
                    readonly id: string;
                    readonly leftAt?: Maybe<string>;
                    readonly user: {
                        readonly __typename?: 'UserNode';
                        readonly id: string;
                        readonly firstName: string;
                        readonly lastName: string;
                        readonly title?: Maybe<string>;
                        readonly avatarBigUrl?: Maybe<string>;
                        readonly avatarSmallUrl?: Maybe<string>;
                    };
                }>;
            }>
        >;
    };
};

export type ListDocumentFragment = {
    readonly __typename?: 'MemberDocumentNode';
    readonly id: string;
    readonly filename: string;
    readonly addedDate: string;
    readonly memberSeenAt?: Maybe<string>;
    readonly memberVisibleComment?: Maybe<string>;
};

export type DocumentFragment = {
    readonly __typename?: 'MemberDocumentNode';
    readonly id: string;
    readonly filename: string;
    readonly url?: Maybe<string>;
    readonly addedDate: string;
    readonly memberVisibleComment?: Maybe<string>;
    readonly memberSeenAt?: Maybe<string>;
    readonly createdBy?: Maybe<{
        readonly __typename?: 'UserNode';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly avatarSmallUrl?: Maybe<string>;
        readonly title?: Maybe<string>;
    }>;
    readonly exportTokens: {
        readonly __typename?: 'MemberDocumentExportTokenNodeConnection';
        readonly edges: ReadonlyArray<
            Maybe<{
                readonly __typename?: 'MemberDocumentExportTokenNodeEdge';
                readonly node?: Maybe<{
                    readonly __typename?: 'MemberDocumentExportTokenNode';
                    readonly id: string;
                    readonly token: string;
                    readonly validUntil?: Maybe<string>;
                    readonly deletedAt?: Maybe<string>;
                }>;
            }>
        >;
    };
};

export type NotificationFragment = {
    readonly __typename?: 'MemberNotificationNode';
    readonly id: string;
    readonly createdAt: string;
    readonly title: string;
    readonly message: string;
    readonly sender?: Maybe<{
        readonly __typename?: 'UserNode';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly title?: Maybe<string>;
        readonly avatarBigUrl?: Maybe<string>;
        readonly avatarSmallUrl?: Maybe<string>;
    }>;
};

export type ListQuestionnaireFragment = {
    readonly __typename?: 'QuestionnaireNode';
    readonly id: string;
    readonly title?: Maybe<string>;
    readonly description?: Maybe<string>;
};

export type QuestionnaireQuestionFragment = {
    readonly __typename?: 'QuestionnaireQuestionNode';
    readonly id: string;
    readonly type: QuestionnaireQuestionType;
    readonly orderNumber: number;
    readonly title?: Maybe<string>;
    readonly description?: Maybe<string>;
    readonly options?: Maybe<
        ReadonlyArray<{
            readonly __typename?: 'QuestionnaireQuestionOption';
            readonly title: string;
            readonly value: number;
        }>
    >;
};

export type BadgesFragment = {
    readonly __typename?: 'BadgeNode';
    readonly unseenChatMessages?: Maybe<number>;
    readonly unseenDocuments?: Maybe<number>;
    readonly openQuestionnaires?: Maybe<number>;
    readonly openCalendarReservationAccesses?: Maybe<number>;
};

export type QuestionnaireFragment = {
    readonly __typename?: 'QuestionnaireNode';
    readonly id: string;
    readonly title?: Maybe<string>;
    readonly description?: Maybe<string>;
    readonly type: QuestionnaireType;
    readonly outro?: Maybe<string>;
    readonly outroQuestionsTitle?: Maybe<string>;
    readonly questions: {
        readonly __typename?: 'QuestionnaireQuestionNodeConnection';
        readonly edges: ReadonlyArray<
            Maybe<{
                readonly __typename?: 'QuestionnaireQuestionNodeEdge';
                readonly node?: Maybe<{
                    readonly __typename?: 'QuestionnaireQuestionNode';
                    readonly id: string;
                    readonly type: QuestionnaireQuestionType;
                    readonly orderNumber: number;
                    readonly title?: Maybe<string>;
                    readonly description?: Maybe<string>;
                    readonly options?: Maybe<
                        ReadonlyArray<{
                            readonly __typename?: 'QuestionnaireQuestionOption';
                            readonly title: string;
                            readonly value: number;
                        }>
                    >;
                }>;
            }>
        >;
    };
    readonly outroQuestions: {
        readonly __typename?: 'QuestionnaireQuestionNodeConnection';
        readonly edges: ReadonlyArray<
            Maybe<{
                readonly __typename?: 'QuestionnaireQuestionNodeEdge';
                readonly node?: Maybe<{
                    readonly __typename?: 'QuestionnaireQuestionNode';
                    readonly id: string;
                    readonly type: QuestionnaireQuestionType;
                    readonly orderNumber: number;
                    readonly title?: Maybe<string>;
                    readonly description?: Maybe<string>;
                    readonly options?: Maybe<
                        ReadonlyArray<{
                            readonly __typename?: 'QuestionnaireQuestionOption';
                            readonly title: string;
                            readonly value: number;
                        }>
                    >;
                }>;
            }>
        >;
    };
};

export type ListQuestionnaireResponseFragment = {
    readonly __typename?: 'QuestionnaireResponseNode';
    readonly id: string;
    readonly analysedAt?: Maybe<string>;
    readonly createdAt: string;
    readonly modifiedAt: string;
    readonly finishedAt?: Maybe<string>;
    readonly closedAt?: Maybe<string>;
    readonly closeAt?: Maybe<string>;
    readonly questionnaire: {
        readonly __typename?: 'QuestionnaireNode';
        readonly id: string;
        readonly title?: Maybe<string>;
        readonly description?: Maybe<string>;
    };
};

export type QuestionnaireQuestionCategoryFragment = {
    readonly __typename?: 'QuestionnaireQuestionCategoryNode';
    readonly id: string;
    readonly key: string;
    readonly icon: string;
    readonly color: string;
    readonly orderNumber: number;
};

export type QuestionnaireQuestionResponseFragment = {
    readonly __typename?: 'QuestionnaireQuestionResponseNode';
    readonly id: string;
    readonly strValue?: Maybe<string>;
    readonly listValue?: Maybe<ReadonlyArray<number>>;
    readonly questionnaireQuestion: {
        readonly __typename?: 'QuestionnaireQuestionNode';
        readonly id: string;
        readonly type: QuestionnaireQuestionType;
        readonly title?: Maybe<string>;
        readonly description?: Maybe<string>;
        readonly options?: Maybe<
            ReadonlyArray<{
                readonly __typename?: 'QuestionnaireQuestionOption';
                readonly title: string;
                readonly value: number;
                readonly weight?: Maybe<number>;
            }>
        >;
        readonly category?: Maybe<{
            readonly __typename?: 'QuestionnaireQuestionCategoryNode';
            readonly id: string;
            readonly key: string;
            readonly icon: string;
            readonly color: string;
            readonly orderNumber: number;
        }>;
    };
};

export type QuestionnaireResponseFragment = {
    readonly __typename?: 'QuestionnaireResponseNode';
    readonly id: string;
    readonly analysedAt?: Maybe<string>;
    readonly finishedAt?: Maybe<string>;
    readonly closedAt?: Maybe<string>;
    readonly closeAt?: Maybe<string>;
    readonly questionnaire: {
        readonly __typename?: 'QuestionnaireNode';
        readonly id: string;
        readonly type: QuestionnaireType;
    };
    readonly responses: {
        readonly __typename?: 'QuestionnaireQuestionResponseNodeConnection';
        readonly edges: ReadonlyArray<
            Maybe<{
                readonly __typename?: 'QuestionnaireQuestionResponseNodeEdge';
                readonly node?: Maybe<{
                    readonly __typename?: 'QuestionnaireQuestionResponseNode';
                    readonly id: string;
                    readonly strValue?: Maybe<string>;
                    readonly listValue?: Maybe<ReadonlyArray<number>>;
                    readonly questionnaireQuestion: {
                        readonly __typename?: 'QuestionnaireQuestionNode';
                        readonly id: string;
                        readonly type: QuestionnaireQuestionType;
                        readonly title?: Maybe<string>;
                        readonly description?: Maybe<string>;
                        readonly options?: Maybe<
                            ReadonlyArray<{
                                readonly __typename?: 'QuestionnaireQuestionOption';
                                readonly title: string;
                                readonly value: number;
                                readonly weight?: Maybe<number>;
                            }>
                        >;
                        readonly category?: Maybe<{
                            readonly __typename?: 'QuestionnaireQuestionCategoryNode';
                            readonly id: string;
                            readonly key: string;
                            readonly icon: string;
                            readonly color: string;
                            readonly orderNumber: number;
                        }>;
                    };
                }>;
            }>
        >;
    };
    readonly summary?: Maybe<{
        readonly __typename?: 'QuestionnaireResponseSummaryNode';
        readonly description?: Maybe<string>;
        readonly categories?: Maybe<
            ReadonlyArray<
                Maybe<{
                    readonly __typename?: 'QuestionnaireResponseSummaryCategoryNode';
                    readonly key?: Maybe<string>;
                    readonly icon?: Maybe<string>;
                    readonly title?: Maybe<string>;
                    readonly popup?: Maybe<string>;
                    readonly value?: Maybe<number>;
                    readonly color?: Maybe<string>;
                }>
            >
        >;
    }>;
};

export type MemberActionFragment = {
    readonly __typename?: 'MemberActionNode';
    readonly type: number;
    readonly title: string;
    readonly description?: Maybe<string>;
    readonly responseTimeSla?: Maybe<string>;
    readonly color?: Maybe<string>;
    readonly category?: Maybe<number>;
    readonly imageUrl?: Maybe<string>;
    readonly typeNew: MemberActionType;
    readonly actionUrl?: Maybe<string>;
    readonly chat?: Maybe<{
        readonly __typename?: 'ChatNode';
        readonly unreadMessagesCountV2: number;
        readonly id: string;
        readonly type: ChatType;
        readonly title: string;
        readonly responseTimeSla?: Maybe<string>;
        readonly description?: Maybe<string>;
        readonly modifiedAt: string;
        readonly lastMessageAt?: Maybe<string>;
        readonly closedAt?: Maybe<string>;
        readonly readonly: boolean;
        readonly users: {
            readonly __typename?: 'ChatUserNodeConnection';
            readonly edges: ReadonlyArray<
                Maybe<{
                    readonly __typename?: 'ChatUserNodeEdge';
                    readonly node?: Maybe<{
                        readonly __typename?: 'ChatUserNode';
                        readonly id: string;
                        readonly leftAt?: Maybe<string>;
                        readonly user: {
                            readonly __typename?: 'UserNode';
                            readonly id: string;
                            readonly firstName: string;
                            readonly lastName: string;
                            readonly title?: Maybe<string>;
                            readonly avatarBigUrl?: Maybe<string>;
                            readonly avatarSmallUrl?: Maybe<string>;
                        };
                    }>;
                }>
            >;
        };
    }>;
};

export type MemberActionsFragment = {
    readonly __typename?: 'MemberActionsNode';
    readonly actions?: Maybe<
        ReadonlyArray<{
            readonly __typename?: 'MemberActionNode';
            readonly type: number;
            readonly title: string;
            readonly description?: Maybe<string>;
            readonly responseTimeSla?: Maybe<string>;
            readonly color?: Maybe<string>;
            readonly category?: Maybe<number>;
            readonly imageUrl?: Maybe<string>;
            readonly typeNew: MemberActionType;
            readonly actionUrl?: Maybe<string>;
            readonly chat?: Maybe<{
                readonly __typename?: 'ChatNode';
                readonly unreadMessagesCountV2: number;
                readonly id: string;
                readonly type: ChatType;
                readonly title: string;
                readonly responseTimeSla?: Maybe<string>;
                readonly description?: Maybe<string>;
                readonly modifiedAt: string;
                readonly lastMessageAt?: Maybe<string>;
                readonly closedAt?: Maybe<string>;
                readonly readonly: boolean;
                readonly users: {
                    readonly __typename?: 'ChatUserNodeConnection';
                    readonly edges: ReadonlyArray<
                        Maybe<{
                            readonly __typename?: 'ChatUserNodeEdge';
                            readonly node?: Maybe<{
                                readonly __typename?: 'ChatUserNode';
                                readonly id: string;
                                readonly leftAt?: Maybe<string>;
                                readonly user: {
                                    readonly __typename?: 'UserNode';
                                    readonly id: string;
                                    readonly firstName: string;
                                    readonly lastName: string;
                                    readonly title?: Maybe<string>;
                                    readonly avatarBigUrl?: Maybe<string>;
                                    readonly avatarSmallUrl?: Maybe<string>;
                                };
                            }>;
                        }>
                    >;
                };
            }>;
        }>
    >;
};

export type ParentArticleFragment = {
    readonly __typename?: 'ArticleNode';
    readonly id: string;
    readonly title: string;
    readonly parentArticle?: Maybe<{
        readonly __typename?: 'ArticleNode';
        readonly id: string;
        readonly title: string;
    }>;
};

export type ArticleFragment = {
    readonly __typename?: 'ArticleNode';
    readonly id: string;
    readonly createdAt: string;
    readonly modifiedAt: string;
    readonly type: ArticleType;
    readonly title: string;
    readonly highlight?: Maybe<string>;
    readonly content?: Maybe<string>;
    readonly coverPhotoUrl?: Maybe<string>;
    readonly parentArticle?: Maybe<{
        readonly __typename?: 'ArticleNode';
        readonly id: string;
        readonly title: string;
        readonly parentArticle?: Maybe<{
            readonly __typename?: 'ArticleNode';
            readonly id: string;
            readonly title: string;
        }>;
    }>;
    readonly childArticles?: Maybe<{
        readonly __typename?: 'ArticleNodeConnection';
        readonly edges: ReadonlyArray<
            Maybe<{
                readonly __typename?: 'ArticleNodeEdge';
                readonly node?: Maybe<{
                    readonly __typename?: 'ArticleNode';
                    readonly id: string;
                    readonly type: ArticleType;
                    readonly title: string;
                    readonly highlight?: Maybe<string>;
                    readonly coverPhotoUrl?: Maybe<string>;
                    readonly videoHandle?: Maybe<string>;
                }>;
            }>
        >;
    }>;
};

export type RegionFragment = {
    readonly __typename?: 'RegionNode';
    readonly id: string;
    readonly name: string;
    readonly geometry: any;
    readonly locations?: Maybe<{
        readonly __typename?: 'LocationNodeConnection';
        readonly edges: ReadonlyArray<
            Maybe<{
                readonly __typename?: 'LocationNodeEdge';
                readonly node?: Maybe<{
                    readonly __typename?: 'LocationNode';
                    readonly id: string;
                    readonly name: string;
                    readonly type: LocationType;
                    readonly address1?: Maybe<string>;
                    readonly address2?: Maybe<string>;
                    readonly zip?: Maybe<string>;
                    readonly city?: Maybe<string>;
                }>;
            }>
        >;
    }>;
};

export type VerifyEmailMutationVariables = Exact<{
    data: VerifyEmailMutationInput;
}>;

export type VerifyEmailMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly verifyEmail?: Maybe<{ readonly __typename?: 'VerifyEmailMutationPayload'; readonly success: boolean }>;
};

export type SignUpWithEmailMutationVariables = Exact<{
    data: SignUpWithEmailMutationInput;
}>;

export type SignUpWithEmailMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly signUpWithEmail?: Maybe<{
        readonly __typename?: 'SignUpWithEmailMutationPayload';
        readonly member: {
            readonly __typename?: 'MemberNode';
            readonly id: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly language?: Maybe<number>;
            readonly lang: LanguageCode;
            readonly memberCommunicationPermissionAt?: Maybe<string>;
            readonly marketingPermissionAt?: Maybe<string>;
            readonly feedbackPermissionAt?: Maybe<string>;
            readonly termsAccepted: boolean;
            readonly verified: boolean;
            readonly verificationRequired: boolean;
            readonly phone?: Maybe<string>;
            readonly email: string;
            readonly address1?: Maybe<string>;
            readonly address2?: Maybe<string>;
            readonly zip?: Maybe<string>;
            readonly city?: Maybe<string>;
            readonly hasUsablePassword: boolean;
            readonly hasCompanyAccess: boolean;
            readonly employments?: Maybe<{
                readonly __typename?: 'EmploymentNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'EmploymentNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'EmploymentNode';
                            readonly id: string;
                            readonly location?: Maybe<string>;
                            readonly position?: Maybe<string>;
                            readonly superior?: Maybe<string>;
                            readonly startDate: string;
                            readonly endDate?: Maybe<string>;
                            readonly company: {
                                readonly __typename?: 'CompanyNode';
                                readonly name: string;
                                readonly membersCanReportSickLeave: boolean;
                                readonly sickLeaveMinDays: number;
                                readonly sickLeaveMaxDays: number;
                            };
                            readonly department?: Maybe<{
                                readonly __typename?: 'DepartmentNode';
                                readonly id: string;
                                readonly name: string;
                            }>;
                        }>;
                    }>
                >;
            }>;
        };
    }>;
};

export type TriggerInteractionMutationVariables = Exact<{
    data: TriggerInteractionMutationInput;
}>;

export type TriggerInteractionMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly interactionTrigger?: Maybe<{
        readonly __typename?: 'TriggerInteractionMutationPayload';
        readonly uiInteraction?: Maybe<{
            readonly __typename?: 'UIInteractionNode';
            readonly id: string;
            readonly context: UiInteractionContext;
            readonly interaction: {
                readonly __typename?: 'InteractionNode';
                readonly id: any;
                readonly title?: Maybe<string>;
                readonly description?: Maybe<string>;
                readonly label?: Maybe<string>;
                readonly color?: Maybe<string>;
                readonly inputs: ReadonlyArray<{
                    readonly __typename?: 'InteractionInputNode';
                    readonly type: string;
                    readonly name: string;
                    readonly placeholder?: Maybe<string>;
                    readonly label?: Maybe<string>;
                    readonly initialValue?: Maybe<any>;
                    readonly selectTargetName?: Maybe<string>;
                    readonly selectAllowAdd?: Maybe<boolean>;
                    readonly selectMultiple?: Maybe<boolean>;
                }>;
                readonly actions: ReadonlyArray<{
                    readonly __typename?: 'InteractionActionNode';
                    readonly handle: string;
                    readonly value?: Maybe<string>;
                    readonly title: string;
                    readonly importance?: Maybe<InteractionImportance>;
                    readonly color?: Maybe<InteractionColor>;
                }>;
            };
        }>;
        readonly interactionResult?: Maybe<{
            readonly __typename?: 'UIInteractionResultNode';
            readonly redirectTo?: Maybe<string>;
        }>;
    }>;
};

export type InteractionMutationVariables = Exact<{
    data: InteractionMutationInput;
}>;

export type InteractionMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly interaction?: Maybe<{
        readonly __typename?: 'InteractionMutationPayload';
        readonly uiInteraction?: Maybe<{
            readonly __typename?: 'UIInteractionNode';
            readonly id: string;
            readonly context: UiInteractionContext;
            readonly interaction: {
                readonly __typename?: 'InteractionNode';
                readonly id: any;
                readonly title?: Maybe<string>;
                readonly description?: Maybe<string>;
                readonly label?: Maybe<string>;
                readonly color?: Maybe<string>;
                readonly inputs: ReadonlyArray<{
                    readonly __typename?: 'InteractionInputNode';
                    readonly type: string;
                    readonly name: string;
                    readonly placeholder?: Maybe<string>;
                    readonly label?: Maybe<string>;
                    readonly initialValue?: Maybe<any>;
                    readonly selectTargetName?: Maybe<string>;
                    readonly selectAllowAdd?: Maybe<boolean>;
                    readonly selectMultiple?: Maybe<boolean>;
                }>;
                readonly actions: ReadonlyArray<{
                    readonly __typename?: 'InteractionActionNode';
                    readonly handle: string;
                    readonly value?: Maybe<string>;
                    readonly title: string;
                    readonly importance?: Maybe<InteractionImportance>;
                    readonly color?: Maybe<InteractionColor>;
                }>;
            };
        }>;
        readonly interactionResult?: Maybe<{
            readonly __typename?: 'UIInteractionResultNode';
            readonly redirectTo?: Maybe<string>;
        }>;
    }>;
};

export type MemberActionMutationVariables = Exact<{
    data: MemberActionMutationInput;
}>;

export type MemberActionMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly memberAction?: Maybe<{
        readonly __typename?: 'MemberActionMutationPayload';
        readonly url?: Maybe<string>;
    }>;
};

export type ReserveAppointmentMutationVariables = Exact<{
    data: ReserveAppointmentMutationInput;
}>;

export type ReserveAppointmentMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly reserveAppointment?: Maybe<{
        readonly __typename?: 'ReserveAppointmentMutationPayload';
        readonly clientMutationId?: Maybe<string>;
    }>;
};

export type CancelAppointmentMutationVariables = Exact<{
    data: CancelAppointmentMutationInput;
}>;

export type CancelAppointmentMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly cancelAppointment?: Maybe<{
        readonly __typename?: 'CancelAppointmentMutationPayload';
        readonly calendarEvent: {
            readonly __typename?: 'CalendarEventNode';
            readonly id: string;
            readonly type: CalendarEventType;
            readonly start: string;
            readonly end: string;
            readonly title?: Maybe<string>;
            readonly description?: Maybe<string>;
            readonly isModifiable?: Maybe<boolean>;
            readonly location?: Maybe<{
                readonly __typename?: 'LocationNode';
                readonly id: string;
                readonly name: string;
                readonly type: LocationType;
                readonly address1?: Maybe<string>;
                readonly address2?: Maybe<string>;
                readonly zip?: Maybe<string>;
                readonly city?: Maybe<string>;
            }>;
            readonly calendarReservationAccess?: Maybe<{
                readonly __typename?: 'CalendarReservationAccessNode';
                readonly id: string;
                readonly createdAt: string;
                readonly expiresAt?: Maybe<string>;
                readonly type: string;
                readonly durations: ReadonlyArray<number>;
                readonly description: string;
                readonly contactTypes: ReadonlyArray<Maybe<AppointmentContactType>>;
                readonly groups: ReadonlyArray<string>;
                readonly users: ReadonlyArray<{
                    readonly __typename?: 'UserNode';
                    readonly id: string;
                    readonly firstName: string;
                    readonly lastName: string;
                    readonly title?: Maybe<string>;
                    readonly avatarBigUrl?: Maybe<string>;
                    readonly avatarSmallUrl?: Maybe<string>;
                }>;
            }>;
            readonly videoAppointmentDetails?: Maybe<{
                readonly __typename?: 'CalendarEventVideoAppointmentDetailsNode';
                readonly id: string;
                readonly uuid: any;
                readonly participantUrl: string;
            }>;
            readonly calendar: {
                readonly __typename?: 'CalendarNode';
                readonly user?: Maybe<{
                    readonly __typename?: 'UserNode';
                    readonly id: string;
                    readonly firstName: string;
                    readonly lastName: string;
                    readonly title?: Maybe<string>;
                    readonly avatarBigUrl?: Maybe<string>;
                    readonly avatarSmallUrl?: Maybe<string>;
                }>;
            };
        };
    }>;
};

export type ModifyAppointmentMutationVariables = Exact<{
    data: ModifyAppointmentMutationInput;
}>;

export type ModifyAppointmentMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly modifyAppointment?: Maybe<{
        readonly __typename?: 'ModifyAppointmentMutationPayload';
        readonly clientMutationId?: Maybe<string>;
    }>;
};

export type ChatMessageAddMutationVariables = Exact<{
    data: ChatMessageAddMutationInput;
}>;

export type ChatMessageAddMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly chatMessageAdd?: Maybe<{
        readonly __typename?: 'ChatMessageAddMutationPayload';
        readonly chat: {
            readonly __typename?: 'ChatNode';
            readonly id: string;
            readonly type: ChatType;
            readonly title: string;
            readonly responseTimeSla?: Maybe<string>;
            readonly description?: Maybe<string>;
            readonly modifiedAt: string;
            readonly lastMessageAt?: Maybe<string>;
            readonly closedAt?: Maybe<string>;
            readonly readonly: boolean;
            readonly notifications?: Maybe<
                ReadonlyArray<{
                    readonly __typename?: 'ChatNotificationNode';
                    readonly id: string;
                    readonly context: ChatNotificationContext;
                    readonly contents?: Maybe<
                        ReadonlyArray<{
                            readonly __typename?: 'ChatKeyValueNode';
                            readonly key: string;
                            readonly value: any;
                        }>
                    >;
                    readonly actions?: Maybe<
                        ReadonlyArray<{
                            readonly __typename?: 'ChatNotificationActionNode';
                            readonly id: string;
                            readonly type: ChatNotificationActionType;
                            readonly params?: Maybe<
                                ReadonlyArray<{
                                    readonly __typename?: 'ChatKeyValueNode';
                                    readonly key: string;
                                    readonly value: any;
                                }>
                            >;
                            readonly contents?: Maybe<
                                ReadonlyArray<{
                                    readonly __typename?: 'ChatKeyValueNode';
                                    readonly key: string;
                                    readonly value: any;
                                }>
                            >;
                        }>
                    >;
                }>
            >;
            readonly users: {
                readonly __typename?: 'ChatUserNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'ChatUserNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'ChatUserNode';
                            readonly id: string;
                            readonly leftAt?: Maybe<string>;
                            readonly user: {
                                readonly __typename?: 'UserNode';
                                readonly id: string;
                                readonly firstName: string;
                                readonly lastName: string;
                                readonly title?: Maybe<string>;
                                readonly avatarBigUrl?: Maybe<string>;
                                readonly avatarSmallUrl?: Maybe<string>;
                            };
                        }>;
                    }>
                >;
            };
            readonly members: {
                readonly __typename?: 'ChatMemberNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'ChatMemberNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'ChatMemberNode';
                            readonly id: string;
                            readonly leftAt?: Maybe<string>;
                            readonly member: { readonly __typename?: 'MemberNode'; readonly id: string };
                            readonly lastMessageSeen?: Maybe<{
                                readonly __typename?: 'ChatMessageNode';
                                readonly id: string;
                            }>;
                        }>;
                    }>
                >;
            };
        };
        readonly message: {
            readonly __typename?: 'ChatMessageNode';
            readonly id: string;
            readonly createdAt: string;
            readonly deletedAt?: Maybe<string>;
            readonly seen: boolean;
            readonly type: number;
            readonly message: string;
            readonly user?: Maybe<{
                readonly __typename?: 'UserNode';
                readonly id: string;
                readonly firstName: string;
                readonly lastName: string;
                readonly title?: Maybe<string>;
                readonly avatarBigUrl?: Maybe<string>;
                readonly avatarSmallUrl?: Maybe<string>;
            }>;
            readonly member?: Maybe<{
                readonly __typename?: 'MemberNode';
                readonly id: string;
                readonly firstName: string;
                readonly lastName: string;
                readonly avatarBigUrl?: Maybe<string>;
                readonly avatarSmallUrl?: Maybe<string>;
            }>;
            readonly attachmentsV2: ReadonlyArray<
                | {
                      readonly __typename?: 'ChatMessageAttachmentNode';
                      readonly id: string;
                      readonly filename: string;
                      readonly url?: Maybe<string>;
                  }
                | {
                      readonly __typename?: 'ChatMessageIdAttachmentNode';
                      readonly id: string;
                      readonly attachmentId: string;
                      readonly title?: Maybe<string>;
                  }
                | {
                      readonly __typename?: 'ChatMessageInteractionNode';
                      readonly id: string;
                      readonly uiInteraction: {
                          readonly __typename?: 'UIInteractionNode';
                          readonly id: string;
                          readonly context: UiInteractionContext;
                          readonly interaction: {
                              readonly __typename?: 'InteractionNode';
                              readonly id: any;
                              readonly title?: Maybe<string>;
                              readonly description?: Maybe<string>;
                              readonly label?: Maybe<string>;
                              readonly color?: Maybe<string>;
                              readonly inputs: ReadonlyArray<{
                                  readonly __typename?: 'InteractionInputNode';
                                  readonly type: string;
                                  readonly name: string;
                                  readonly placeholder?: Maybe<string>;
                                  readonly label?: Maybe<string>;
                                  readonly initialValue?: Maybe<any>;
                                  readonly selectTargetName?: Maybe<string>;
                                  readonly selectAllowAdd?: Maybe<boolean>;
                                  readonly selectMultiple?: Maybe<boolean>;
                              }>;
                              readonly actions: ReadonlyArray<{
                                  readonly __typename?: 'InteractionActionNode';
                                  readonly handle: string;
                                  readonly value?: Maybe<string>;
                                  readonly title: string;
                                  readonly importance?: Maybe<InteractionImportance>;
                                  readonly color?: Maybe<InteractionColor>;
                              }>;
                          };
                      };
                      readonly chatMessage: { readonly __typename?: 'ChatMessageNode'; readonly id: string };
                  }
            >;
        };
    }>;
};

export type ChatMessagesSeenMutationVariables = Exact<{
    data: ChatMessagesSeenMutationInput;
}>;

export type ChatMessagesSeenMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly chatMessagesSeen?: Maybe<{
        readonly __typename?: 'ChatMessagesSeenMutationPayload';
        readonly messages?: Maybe<
            ReadonlyArray<Maybe<{ readonly __typename?: 'ChatMessageNode'; readonly id: string }>>
        >;
        readonly chatMember?: Maybe<{
            readonly __typename?: 'ChatMemberNode';
            readonly id: string;
            readonly lastMessageSeen?: Maybe<{ readonly __typename?: 'ChatMessageNode'; readonly id: string }>;
        }>;
    }>;
};

export type EscalateToLiveChatMutationVariables = Exact<{
    data: EscalateToLiveChatMutationInput;
}>;

export type EscalateToLiveChatMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly escalateToLiveChat?: Maybe<{
        readonly __typename?: 'EscalateToLiveChatMutationPayload';
        readonly chat?: Maybe<{ readonly __typename?: 'ChatNode'; readonly id: string }>;
    }>;
};

export type ProfileEditMutationVariables = Exact<{
    data: ProfileEditMutationInput;
}>;

export type ProfileEditMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly profileEdit?: Maybe<{
        readonly __typename?: 'ProfileEditMutationPayload';
        readonly member: {
            readonly __typename?: 'MemberNode';
            readonly id: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly language?: Maybe<number>;
            readonly lang: LanguageCode;
            readonly memberCommunicationPermissionAt?: Maybe<string>;
            readonly marketingPermissionAt?: Maybe<string>;
            readonly feedbackPermissionAt?: Maybe<string>;
            readonly termsAccepted: boolean;
            readonly verified: boolean;
            readonly verificationRequired: boolean;
            readonly phone?: Maybe<string>;
            readonly email: string;
            readonly address1?: Maybe<string>;
            readonly address2?: Maybe<string>;
            readonly zip?: Maybe<string>;
            readonly city?: Maybe<string>;
            readonly hasUsablePassword: boolean;
            readonly hasCompanyAccess: boolean;
            readonly employments?: Maybe<{
                readonly __typename?: 'EmploymentNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'EmploymentNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'EmploymentNode';
                            readonly id: string;
                            readonly location?: Maybe<string>;
                            readonly position?: Maybe<string>;
                            readonly superior?: Maybe<string>;
                            readonly startDate: string;
                            readonly endDate?: Maybe<string>;
                            readonly company: {
                                readonly __typename?: 'CompanyNode';
                                readonly name: string;
                                readonly membersCanReportSickLeave: boolean;
                                readonly sickLeaveMinDays: number;
                                readonly sickLeaveMaxDays: number;
                            };
                            readonly department?: Maybe<{
                                readonly __typename?: 'DepartmentNode';
                                readonly id: string;
                                readonly name: string;
                            }>;
                        }>;
                    }>
                >;
            }>;
        };
    }>;
};

export type ReportSickLeaveMutationVariables = Exact<{
    data: ReportSickLeaveMutationInput;
}>;

export type ReportSickLeaveMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly reportSickLeave?: Maybe<{
        readonly __typename?: 'ReportSickLeaveMutationPayload';
        readonly clientMutationId?: Maybe<string>;
    }>;
};

export type QuestionnaireResponseMutationVariables = Exact<{
    data: QuestionnaireResponseMutationInput;
}>;

export type QuestionnaireResponseMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly questionnaireResponse?: Maybe<{
        readonly __typename?: 'QuestionnaireResponseMutationPayload';
        readonly response?: Maybe<{
            readonly __typename?: 'QuestionnaireResponseNode';
            readonly id: string;
            readonly analysedAt?: Maybe<string>;
            readonly finishedAt?: Maybe<string>;
            readonly closedAt?: Maybe<string>;
            readonly closeAt?: Maybe<string>;
            readonly questionnaire: {
                readonly __typename?: 'QuestionnaireNode';
                readonly id: string;
                readonly type: QuestionnaireType;
            };
            readonly responses: {
                readonly __typename?: 'QuestionnaireQuestionResponseNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'QuestionnaireQuestionResponseNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'QuestionnaireQuestionResponseNode';
                            readonly id: string;
                            readonly strValue?: Maybe<string>;
                            readonly listValue?: Maybe<ReadonlyArray<number>>;
                            readonly questionnaireQuestion: {
                                readonly __typename?: 'QuestionnaireQuestionNode';
                                readonly id: string;
                                readonly type: QuestionnaireQuestionType;
                                readonly title?: Maybe<string>;
                                readonly description?: Maybe<string>;
                                readonly options?: Maybe<
                                    ReadonlyArray<{
                                        readonly __typename?: 'QuestionnaireQuestionOption';
                                        readonly title: string;
                                        readonly value: number;
                                        readonly weight?: Maybe<number>;
                                    }>
                                >;
                                readonly category?: Maybe<{
                                    readonly __typename?: 'QuestionnaireQuestionCategoryNode';
                                    readonly id: string;
                                    readonly key: string;
                                    readonly icon: string;
                                    readonly color: string;
                                    readonly orderNumber: number;
                                }>;
                            };
                        }>;
                    }>
                >;
            };
            readonly summary?: Maybe<{
                readonly __typename?: 'QuestionnaireResponseSummaryNode';
                readonly description?: Maybe<string>;
                readonly categories?: Maybe<
                    ReadonlyArray<
                        Maybe<{
                            readonly __typename?: 'QuestionnaireResponseSummaryCategoryNode';
                            readonly key?: Maybe<string>;
                            readonly icon?: Maybe<string>;
                            readonly title?: Maybe<string>;
                            readonly popup?: Maybe<string>;
                            readonly value?: Maybe<number>;
                            readonly color?: Maybe<string>;
                        }>
                    >
                >;
            }>;
        }>;
    }>;
};

export type SelfHelpCourseSubscribeMutationVariables = Exact<{
    data: SelfHelpCourseSubscribeMutationInput;
}>;

export type SelfHelpCourseSubscribeMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly selfHelpCourseSubscribe?: Maybe<{
        readonly __typename?: 'SelfHelpCourseSubscribeMutationPayload';
        readonly clientMutationId?: Maybe<string>;
    }>;
};

export type CreateMemberDocumentExportTokenMutationVariables = Exact<{
    data: CreateMemberDocumentExportTokenMutationInput;
}>;

export type CreateMemberDocumentExportTokenMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly documentExportTokenCreate?: Maybe<{
        readonly __typename?: 'CreateMemberDocumentExportTokenMutationPayload';
        readonly document?: Maybe<{
            readonly __typename?: 'MemberDocumentNode';
            readonly id: string;
            readonly filename: string;
            readonly url?: Maybe<string>;
            readonly addedDate: string;
            readonly memberVisibleComment?: Maybe<string>;
            readonly memberSeenAt?: Maybe<string>;
            readonly createdBy?: Maybe<{
                readonly __typename?: 'UserNode';
                readonly id: string;
                readonly firstName: string;
                readonly lastName: string;
                readonly avatarSmallUrl?: Maybe<string>;
                readonly title?: Maybe<string>;
            }>;
            readonly exportTokens: {
                readonly __typename?: 'MemberDocumentExportTokenNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'MemberDocumentExportTokenNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'MemberDocumentExportTokenNode';
                            readonly id: string;
                            readonly token: string;
                            readonly validUntil?: Maybe<string>;
                            readonly deletedAt?: Maybe<string>;
                        }>;
                    }>
                >;
            };
        }>;
    }>;
};

export type DeleteMemberDocumentExportTokenMutationVariables = Exact<{
    data: DeleteMemberDocumentExportTokenMutationInput;
}>;

export type DeleteMemberDocumentExportTokenMutation = {
    readonly __typename?: 'MemberMutationObject';
    readonly documentExportTokenDelete?: Maybe<{
        readonly __typename?: 'DeleteMemberDocumentExportTokenMutationPayload';
        readonly document?: Maybe<{
            readonly __typename?: 'MemberDocumentNode';
            readonly id: string;
            readonly filename: string;
            readonly url?: Maybe<string>;
            readonly addedDate: string;
            readonly memberVisibleComment?: Maybe<string>;
            readonly memberSeenAt?: Maybe<string>;
            readonly createdBy?: Maybe<{
                readonly __typename?: 'UserNode';
                readonly id: string;
                readonly firstName: string;
                readonly lastName: string;
                readonly avatarSmallUrl?: Maybe<string>;
                readonly title?: Maybe<string>;
            }>;
            readonly exportTokens: {
                readonly __typename?: 'MemberDocumentExportTokenNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'MemberDocumentExportTokenNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'MemberDocumentExportTokenNode';
                            readonly id: string;
                            readonly token: string;
                            readonly validUntil?: Maybe<string>;
                            readonly deletedAt?: Maybe<string>;
                        }>;
                    }>
                >;
            };
        }>;
    }>;
};

export type SignUpTypeQueryVariables = Exact<{
    token: Scalars['String'];
}>;

export type SignUpTypeQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly token?: Maybe<{ readonly __typename?: 'TokenRootNode'; readonly signUpType: SignUpType }>;
};

export type GlobalInteractionsQueryVariables = Exact<{ [key: string]: never }>;

export type GlobalInteractionsQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly interactions: ReadonlyArray<{
            readonly __typename?: 'UIInteractionNode';
            readonly id: string;
            readonly context: UiInteractionContext;
            readonly interaction: {
                readonly __typename?: 'InteractionNode';
                readonly id: any;
                readonly title?: Maybe<string>;
                readonly description?: Maybe<string>;
                readonly label?: Maybe<string>;
                readonly color?: Maybe<string>;
                readonly inputs: ReadonlyArray<{
                    readonly __typename?: 'InteractionInputNode';
                    readonly type: string;
                    readonly name: string;
                    readonly placeholder?: Maybe<string>;
                    readonly label?: Maybe<string>;
                    readonly initialValue?: Maybe<any>;
                    readonly selectTargetName?: Maybe<string>;
                    readonly selectAllowAdd?: Maybe<boolean>;
                    readonly selectMultiple?: Maybe<boolean>;
                }>;
                readonly actions: ReadonlyArray<{
                    readonly __typename?: 'InteractionActionNode';
                    readonly handle: string;
                    readonly value?: Maybe<string>;
                    readonly title: string;
                    readonly importance?: Maybe<InteractionImportance>;
                    readonly color?: Maybe<InteractionColor>;
                }>;
            };
        }>;
    }>;
};

export type CalendarEventsQueryVariables = Exact<{
    start?: Maybe<Scalars['DateTime']>;
    end?: Maybe<Scalars['DateTime']>;
}>;

export type CalendarEventsQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly calendarEvents?: Maybe<{
            readonly __typename?: 'CalendarEventConnection';
            readonly edges: ReadonlyArray<
                Maybe<{
                    readonly __typename?: 'CalendarEventEdge';
                    readonly node?: Maybe<{
                        readonly __typename?: 'CalendarEventNode';
                        readonly id: string;
                        readonly type: CalendarEventType;
                        readonly start: string;
                        readonly end: string;
                        readonly title?: Maybe<string>;
                        readonly description?: Maybe<string>;
                        readonly isModifiable?: Maybe<boolean>;
                        readonly location?: Maybe<{
                            readonly __typename?: 'LocationNode';
                            readonly id: string;
                            readonly name: string;
                            readonly type: LocationType;
                            readonly address1?: Maybe<string>;
                            readonly address2?: Maybe<string>;
                            readonly zip?: Maybe<string>;
                            readonly city?: Maybe<string>;
                        }>;
                        readonly calendarReservationAccess?: Maybe<{
                            readonly __typename?: 'CalendarReservationAccessNode';
                            readonly id: string;
                            readonly createdAt: string;
                            readonly expiresAt?: Maybe<string>;
                            readonly type: string;
                            readonly durations: ReadonlyArray<number>;
                            readonly description: string;
                            readonly contactTypes: ReadonlyArray<Maybe<AppointmentContactType>>;
                            readonly groups: ReadonlyArray<string>;
                            readonly users: ReadonlyArray<{
                                readonly __typename?: 'UserNode';
                                readonly id: string;
                                readonly firstName: string;
                                readonly lastName: string;
                                readonly title?: Maybe<string>;
                                readonly avatarBigUrl?: Maybe<string>;
                                readonly avatarSmallUrl?: Maybe<string>;
                            }>;
                        }>;
                        readonly videoAppointmentDetails?: Maybe<{
                            readonly __typename?: 'CalendarEventVideoAppointmentDetailsNode';
                            readonly id: string;
                            readonly uuid: any;
                            readonly participantUrl: string;
                        }>;
                        readonly calendar: {
                            readonly __typename?: 'CalendarNode';
                            readonly user?: Maybe<{
                                readonly __typename?: 'UserNode';
                                readonly id: string;
                                readonly firstName: string;
                                readonly lastName: string;
                                readonly title?: Maybe<string>;
                                readonly avatarBigUrl?: Maybe<string>;
                                readonly avatarSmallUrl?: Maybe<string>;
                            }>;
                        };
                    }>;
                }>
            >;
        }>;
    }>;
};

export type ChatQueryVariables = Exact<{
    chatID: Scalars['ID'];
}>;

export type ChatQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly chat?: Maybe<{
            readonly __typename?: 'ChatNode';
            readonly id: string;
            readonly type: ChatType;
            readonly title: string;
            readonly responseTimeSla?: Maybe<string>;
            readonly description?: Maybe<string>;
            readonly modifiedAt: string;
            readonly lastMessageAt?: Maybe<string>;
            readonly closedAt?: Maybe<string>;
            readonly readonly: boolean;
            readonly notifications?: Maybe<
                ReadonlyArray<{
                    readonly __typename?: 'ChatNotificationNode';
                    readonly id: string;
                    readonly context: ChatNotificationContext;
                    readonly contents?: Maybe<
                        ReadonlyArray<{
                            readonly __typename?: 'ChatKeyValueNode';
                            readonly key: string;
                            readonly value: any;
                        }>
                    >;
                    readonly actions?: Maybe<
                        ReadonlyArray<{
                            readonly __typename?: 'ChatNotificationActionNode';
                            readonly id: string;
                            readonly type: ChatNotificationActionType;
                            readonly params?: Maybe<
                                ReadonlyArray<{
                                    readonly __typename?: 'ChatKeyValueNode';
                                    readonly key: string;
                                    readonly value: any;
                                }>
                            >;
                            readonly contents?: Maybe<
                                ReadonlyArray<{
                                    readonly __typename?: 'ChatKeyValueNode';
                                    readonly key: string;
                                    readonly value: any;
                                }>
                            >;
                        }>
                    >;
                }>
            >;
            readonly users: {
                readonly __typename?: 'ChatUserNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'ChatUserNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'ChatUserNode';
                            readonly id: string;
                            readonly leftAt?: Maybe<string>;
                            readonly user: {
                                readonly __typename?: 'UserNode';
                                readonly id: string;
                                readonly firstName: string;
                                readonly lastName: string;
                                readonly title?: Maybe<string>;
                                readonly avatarBigUrl?: Maybe<string>;
                                readonly avatarSmallUrl?: Maybe<string>;
                            };
                        }>;
                    }>
                >;
            };
            readonly members: {
                readonly __typename?: 'ChatMemberNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'ChatMemberNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'ChatMemberNode';
                            readonly id: string;
                            readonly leftAt?: Maybe<string>;
                            readonly member: { readonly __typename?: 'MemberNode'; readonly id: string };
                            readonly lastMessageSeen?: Maybe<{
                                readonly __typename?: 'ChatMessageNode';
                                readonly id: string;
                            }>;
                        }>;
                    }>
                >;
            };
        }>;
    }>;
};

export type ChatMessagesQueryVariables = Exact<{
    chatID: Scalars['ID'];
    messagesAfter?: Maybe<Scalars['String']>;
    messagesAfterLastMessageAt?: Maybe<Scalars['DateTime']>;
}>;

export type ChatMessagesQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly chat?: Maybe<{
            readonly __typename?: 'ChatNode';
            readonly id: string;
            readonly lastMessageAt?: Maybe<string>;
            readonly messages?: Maybe<{
                readonly __typename?: 'ChatMessageNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'ChatMessageNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'ChatMessageNode';
                            readonly id: string;
                            readonly createdAt: string;
                            readonly deletedAt?: Maybe<string>;
                            readonly seen: boolean;
                            readonly type: number;
                            readonly message: string;
                            readonly attachmentsV2: ReadonlyArray<
                                | {
                                      readonly __typename?: 'ChatMessageAttachmentNode';
                                      readonly id: string;
                                      readonly filename: string;
                                      readonly url?: Maybe<string>;
                                  }
                                | {
                                      readonly __typename?: 'ChatMessageIdAttachmentNode';
                                      readonly id: string;
                                      readonly attachmentId: string;
                                      readonly title?: Maybe<string>;
                                  }
                                | {
                                      readonly __typename?: 'ChatMessageInteractionNode';
                                      readonly id: string;
                                      readonly uiInteraction: {
                                          readonly __typename?: 'UIInteractionNode';
                                          readonly id: string;
                                          readonly context: UiInteractionContext;
                                          readonly interaction: {
                                              readonly __typename?: 'InteractionNode';
                                              readonly id: any;
                                              readonly title?: Maybe<string>;
                                              readonly description?: Maybe<string>;
                                              readonly label?: Maybe<string>;
                                              readonly color?: Maybe<string>;
                                              readonly inputs: ReadonlyArray<{
                                                  readonly __typename?: 'InteractionInputNode';
                                                  readonly type: string;
                                                  readonly name: string;
                                                  readonly placeholder?: Maybe<string>;
                                                  readonly label?: Maybe<string>;
                                                  readonly initialValue?: Maybe<any>;
                                                  readonly selectTargetName?: Maybe<string>;
                                                  readonly selectAllowAdd?: Maybe<boolean>;
                                                  readonly selectMultiple?: Maybe<boolean>;
                                              }>;
                                              readonly actions: ReadonlyArray<{
                                                  readonly __typename?: 'InteractionActionNode';
                                                  readonly handle: string;
                                                  readonly value?: Maybe<string>;
                                                  readonly title: string;
                                                  readonly importance?: Maybe<InteractionImportance>;
                                                  readonly color?: Maybe<InteractionColor>;
                                              }>;
                                          };
                                      };
                                      readonly chatMessage: {
                                          readonly __typename?: 'ChatMessageNode';
                                          readonly id: string;
                                      };
                                  }
                            >;
                            readonly user?: Maybe<{
                                readonly __typename?: 'UserNode';
                                readonly id: string;
                                readonly firstName: string;
                                readonly lastName: string;
                                readonly title?: Maybe<string>;
                                readonly avatarBigUrl?: Maybe<string>;
                                readonly avatarSmallUrl?: Maybe<string>;
                            }>;
                            readonly member?: Maybe<{
                                readonly __typename?: 'MemberNode';
                                readonly id: string;
                                readonly firstName: string;
                                readonly lastName: string;
                                readonly avatarBigUrl?: Maybe<string>;
                                readonly avatarSmallUrl?: Maybe<string>;
                            }>;
                        }>;
                    }>
                >;
                readonly pageInfo: {
                    readonly __typename?: 'PageInfo';
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                };
            }>;
        }>;
    }>;
};

export type ChatsQueryVariables = Exact<{
    recent?: Maybe<Scalars['Boolean']>;
    unseen?: Maybe<Scalars['Boolean']>;
    live?: Maybe<Scalars['Boolean']>;
}>;

export type ChatsQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly chats?: Maybe<{
            readonly __typename?: 'ChatConnection';
            readonly edges: ReadonlyArray<
                Maybe<{
                    readonly __typename?: 'ChatEdge';
                    readonly node?: Maybe<{
                        readonly __typename?: 'ChatNode';
                        readonly unreadMessagesCountV2: number;
                        readonly id: string;
                        readonly type: ChatType;
                        readonly title: string;
                        readonly responseTimeSla?: Maybe<string>;
                        readonly description?: Maybe<string>;
                        readonly modifiedAt: string;
                        readonly lastMessageAt?: Maybe<string>;
                        readonly closedAt?: Maybe<string>;
                        readonly readonly: boolean;
                        readonly users: {
                            readonly __typename?: 'ChatUserNodeConnection';
                            readonly edges: ReadonlyArray<
                                Maybe<{
                                    readonly __typename?: 'ChatUserNodeEdge';
                                    readonly node?: Maybe<{
                                        readonly __typename?: 'ChatUserNode';
                                        readonly id: string;
                                        readonly leftAt?: Maybe<string>;
                                        readonly user: {
                                            readonly __typename?: 'UserNode';
                                            readonly id: string;
                                            readonly firstName: string;
                                            readonly lastName: string;
                                            readonly title?: Maybe<string>;
                                            readonly avatarBigUrl?: Maybe<string>;
                                            readonly avatarSmallUrl?: Maybe<string>;
                                        };
                                    }>;
                                }>
                            >;
                        };
                    }>;
                }>
            >;
        }>;
    }>;
};

export type ProfileQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly me?: Maybe<{
            readonly __typename?: 'MemberNode';
            readonly id: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly language?: Maybe<number>;
            readonly lang: LanguageCode;
            readonly memberCommunicationPermissionAt?: Maybe<string>;
            readonly marketingPermissionAt?: Maybe<string>;
            readonly feedbackPermissionAt?: Maybe<string>;
            readonly termsAccepted: boolean;
            readonly verified: boolean;
            readonly verificationRequired: boolean;
            readonly phone?: Maybe<string>;
            readonly email: string;
            readonly address1?: Maybe<string>;
            readonly address2?: Maybe<string>;
            readonly zip?: Maybe<string>;
            readonly city?: Maybe<string>;
            readonly hasUsablePassword: boolean;
            readonly hasCompanyAccess: boolean;
            readonly employments?: Maybe<{
                readonly __typename?: 'EmploymentNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'EmploymentNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'EmploymentNode';
                            readonly id: string;
                            readonly location?: Maybe<string>;
                            readonly position?: Maybe<string>;
                            readonly superior?: Maybe<string>;
                            readonly startDate: string;
                            readonly endDate?: Maybe<string>;
                            readonly company: {
                                readonly __typename?: 'CompanyNode';
                                readonly name: string;
                                readonly membersCanReportSickLeave: boolean;
                                readonly sickLeaveMinDays: number;
                                readonly sickLeaveMaxDays: number;
                            };
                            readonly department?: Maybe<{
                                readonly __typename?: 'DepartmentNode';
                                readonly id: string;
                                readonly name: string;
                            }>;
                        }>;
                    }>
                >;
            }>;
        }>;
    }>;
};

export type DocumentsQueryVariables = Exact<{
    unseen?: Maybe<Scalars['Boolean']>;
}>;

export type DocumentsQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly documents?: Maybe<{
            readonly __typename?: 'MemberDocumentConnection';
            readonly edges: ReadonlyArray<
                Maybe<{
                    readonly __typename?: 'MemberDocumentEdge';
                    readonly node?: Maybe<{
                        readonly __typename?: 'MemberDocumentNode';
                        readonly id: string;
                        readonly filename: string;
                        readonly addedDate: string;
                        readonly memberSeenAt?: Maybe<string>;
                        readonly memberVisibleComment?: Maybe<string>;
                    }>;
                }>
            >;
        }>;
    }>;
};

export type DocumentQueryVariables = Exact<{
    documentId: Scalars['ID'];
}>;

export type DocumentQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly document?: Maybe<{
            readonly __typename?: 'MemberDocumentNode';
            readonly id: string;
            readonly filename: string;
            readonly url?: Maybe<string>;
            readonly addedDate: string;
            readonly memberVisibleComment?: Maybe<string>;
            readonly memberSeenAt?: Maybe<string>;
            readonly createdBy?: Maybe<{
                readonly __typename?: 'UserNode';
                readonly id: string;
                readonly firstName: string;
                readonly lastName: string;
                readonly avatarSmallUrl?: Maybe<string>;
                readonly title?: Maybe<string>;
            }>;
            readonly exportTokens: {
                readonly __typename?: 'MemberDocumentExportTokenNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'MemberDocumentExportTokenNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'MemberDocumentExportTokenNode';
                            readonly id: string;
                            readonly token: string;
                            readonly validUntil?: Maybe<string>;
                            readonly deletedAt?: Maybe<string>;
                        }>;
                    }>
                >;
            };
        }>;
    }>;
};

export type NotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type NotificationsQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly notifications?: Maybe<{
            readonly __typename?: 'MemberNotificationConnection';
            readonly edges: ReadonlyArray<
                Maybe<{
                    readonly __typename?: 'MemberNotificationEdge';
                    readonly node?: Maybe<{
                        readonly __typename?: 'MemberNotificationNode';
                        readonly id: string;
                        readonly createdAt: string;
                        readonly title: string;
                        readonly message: string;
                        readonly sender?: Maybe<{
                            readonly __typename?: 'UserNode';
                            readonly id: string;
                            readonly firstName: string;
                            readonly lastName: string;
                            readonly title?: Maybe<string>;
                            readonly avatarBigUrl?: Maybe<string>;
                            readonly avatarSmallUrl?: Maybe<string>;
                        }>;
                    }>;
                }>
            >;
        }>;
    }>;
};

export type QuestionnaireResponseQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type QuestionnaireResponseQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly questionnaireResponse: {
            readonly __typename?: 'QuestionnaireResponseNode';
            readonly id: string;
            readonly analysedAt?: Maybe<string>;
            readonly finishedAt?: Maybe<string>;
            readonly closedAt?: Maybe<string>;
            readonly closeAt?: Maybe<string>;
            readonly questionnaire: {
                readonly __typename?: 'QuestionnaireNode';
                readonly id: string;
                readonly type: QuestionnaireType;
                readonly title?: Maybe<string>;
                readonly description?: Maybe<string>;
                readonly outro?: Maybe<string>;
                readonly outroQuestionsTitle?: Maybe<string>;
                readonly questions: {
                    readonly __typename?: 'QuestionnaireQuestionNodeConnection';
                    readonly edges: ReadonlyArray<
                        Maybe<{
                            readonly __typename?: 'QuestionnaireQuestionNodeEdge';
                            readonly node?: Maybe<{
                                readonly __typename?: 'QuestionnaireQuestionNode';
                                readonly id: string;
                                readonly type: QuestionnaireQuestionType;
                                readonly orderNumber: number;
                                readonly title?: Maybe<string>;
                                readonly description?: Maybe<string>;
                                readonly options?: Maybe<
                                    ReadonlyArray<{
                                        readonly __typename?: 'QuestionnaireQuestionOption';
                                        readonly title: string;
                                        readonly value: number;
                                    }>
                                >;
                            }>;
                        }>
                    >;
                };
                readonly outroQuestions: {
                    readonly __typename?: 'QuestionnaireQuestionNodeConnection';
                    readonly edges: ReadonlyArray<
                        Maybe<{
                            readonly __typename?: 'QuestionnaireQuestionNodeEdge';
                            readonly node?: Maybe<{
                                readonly __typename?: 'QuestionnaireQuestionNode';
                                readonly id: string;
                                readonly type: QuestionnaireQuestionType;
                                readonly orderNumber: number;
                                readonly title?: Maybe<string>;
                                readonly description?: Maybe<string>;
                                readonly options?: Maybe<
                                    ReadonlyArray<{
                                        readonly __typename?: 'QuestionnaireQuestionOption';
                                        readonly title: string;
                                        readonly value: number;
                                    }>
                                >;
                            }>;
                        }>
                    >;
                };
            };
            readonly responses: {
                readonly __typename?: 'QuestionnaireQuestionResponseNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'QuestionnaireQuestionResponseNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'QuestionnaireQuestionResponseNode';
                            readonly id: string;
                            readonly strValue?: Maybe<string>;
                            readonly listValue?: Maybe<ReadonlyArray<number>>;
                            readonly questionnaireQuestion: {
                                readonly __typename?: 'QuestionnaireQuestionNode';
                                readonly id: string;
                                readonly type: QuestionnaireQuestionType;
                                readonly title?: Maybe<string>;
                                readonly description?: Maybe<string>;
                                readonly options?: Maybe<
                                    ReadonlyArray<{
                                        readonly __typename?: 'QuestionnaireQuestionOption';
                                        readonly title: string;
                                        readonly value: number;
                                        readonly weight?: Maybe<number>;
                                    }>
                                >;
                                readonly category?: Maybe<{
                                    readonly __typename?: 'QuestionnaireQuestionCategoryNode';
                                    readonly id: string;
                                    readonly key: string;
                                    readonly icon: string;
                                    readonly color: string;
                                    readonly orderNumber: number;
                                }>;
                            };
                        }>;
                    }>
                >;
            };
            readonly summary?: Maybe<{
                readonly __typename?: 'QuestionnaireResponseSummaryNode';
                readonly description?: Maybe<string>;
                readonly categories?: Maybe<
                    ReadonlyArray<
                        Maybe<{
                            readonly __typename?: 'QuestionnaireResponseSummaryCategoryNode';
                            readonly key?: Maybe<string>;
                            readonly icon?: Maybe<string>;
                            readonly title?: Maybe<string>;
                            readonly popup?: Maybe<string>;
                            readonly value?: Maybe<number>;
                            readonly color?: Maybe<string>;
                        }>
                    >
                >;
            }>;
        };
    }>;
};

export type QuestionnaireResponseBatchWithTokenQueryVariables = Exact<{
    memberId: Scalars['ID'];
    batchId: Scalars['ID'];
    token: Scalars['String'];
}>;

export type QuestionnaireResponseBatchWithTokenQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly token?: Maybe<{
        readonly __typename?: 'TokenRootNode';
        readonly questionnaireResponseBatch: {
            readonly __typename?: 'QuestionnaireResponseBatchNode';
            readonly id: string;
            readonly message?: Maybe<string>;
            readonly openAt: string;
            readonly closeAt: string;
            readonly questionnaireResponses?: Maybe<{
                readonly __typename?: 'QuestionnaireResponseNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'QuestionnaireResponseNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'QuestionnaireResponseNode';
                            readonly id: string;
                            readonly analysedAt?: Maybe<string>;
                            readonly finishedAt?: Maybe<string>;
                            readonly closedAt?: Maybe<string>;
                            readonly closeAt?: Maybe<string>;
                            readonly questionnaire: {
                                readonly __typename?: 'QuestionnaireNode';
                                readonly id: string;
                                readonly type: QuestionnaireType;
                                readonly title?: Maybe<string>;
                                readonly description?: Maybe<string>;
                                readonly outro?: Maybe<string>;
                                readonly outroQuestionsTitle?: Maybe<string>;
                                readonly questions: {
                                    readonly __typename?: 'QuestionnaireQuestionNodeConnection';
                                    readonly edges: ReadonlyArray<
                                        Maybe<{
                                            readonly __typename?: 'QuestionnaireQuestionNodeEdge';
                                            readonly node?: Maybe<{
                                                readonly __typename?: 'QuestionnaireQuestionNode';
                                                readonly id: string;
                                                readonly type: QuestionnaireQuestionType;
                                                readonly orderNumber: number;
                                                readonly title?: Maybe<string>;
                                                readonly description?: Maybe<string>;
                                                readonly options?: Maybe<
                                                    ReadonlyArray<{
                                                        readonly __typename?: 'QuestionnaireQuestionOption';
                                                        readonly title: string;
                                                        readonly value: number;
                                                    }>
                                                >;
                                            }>;
                                        }>
                                    >;
                                };
                                readonly outroQuestions: {
                                    readonly __typename?: 'QuestionnaireQuestionNodeConnection';
                                    readonly edges: ReadonlyArray<
                                        Maybe<{
                                            readonly __typename?: 'QuestionnaireQuestionNodeEdge';
                                            readonly node?: Maybe<{
                                                readonly __typename?: 'QuestionnaireQuestionNode';
                                                readonly id: string;
                                                readonly type: QuestionnaireQuestionType;
                                                readonly orderNumber: number;
                                                readonly title?: Maybe<string>;
                                                readonly description?: Maybe<string>;
                                                readonly options?: Maybe<
                                                    ReadonlyArray<{
                                                        readonly __typename?: 'QuestionnaireQuestionOption';
                                                        readonly title: string;
                                                        readonly value: number;
                                                    }>
                                                >;
                                            }>;
                                        }>
                                    >;
                                };
                            };
                            readonly responses: {
                                readonly __typename?: 'QuestionnaireQuestionResponseNodeConnection';
                                readonly edges: ReadonlyArray<
                                    Maybe<{
                                        readonly __typename?: 'QuestionnaireQuestionResponseNodeEdge';
                                        readonly node?: Maybe<{
                                            readonly __typename?: 'QuestionnaireQuestionResponseNode';
                                            readonly id: string;
                                            readonly strValue?: Maybe<string>;
                                            readonly listValue?: Maybe<ReadonlyArray<number>>;
                                            readonly questionnaireQuestion: {
                                                readonly __typename?: 'QuestionnaireQuestionNode';
                                                readonly id: string;
                                                readonly type: QuestionnaireQuestionType;
                                                readonly title?: Maybe<string>;
                                                readonly description?: Maybe<string>;
                                                readonly options?: Maybe<
                                                    ReadonlyArray<{
                                                        readonly __typename?: 'QuestionnaireQuestionOption';
                                                        readonly title: string;
                                                        readonly value: number;
                                                        readonly weight?: Maybe<number>;
                                                    }>
                                                >;
                                                readonly category?: Maybe<{
                                                    readonly __typename?: 'QuestionnaireQuestionCategoryNode';
                                                    readonly id: string;
                                                    readonly key: string;
                                                    readonly icon: string;
                                                    readonly color: string;
                                                    readonly orderNumber: number;
                                                }>;
                                            };
                                        }>;
                                    }>
                                >;
                            };
                            readonly summary?: Maybe<{
                                readonly __typename?: 'QuestionnaireResponseSummaryNode';
                                readonly description?: Maybe<string>;
                                readonly categories?: Maybe<
                                    ReadonlyArray<
                                        Maybe<{
                                            readonly __typename?: 'QuestionnaireResponseSummaryCategoryNode';
                                            readonly key?: Maybe<string>;
                                            readonly icon?: Maybe<string>;
                                            readonly title?: Maybe<string>;
                                            readonly popup?: Maybe<string>;
                                            readonly value?: Maybe<number>;
                                            readonly color?: Maybe<string>;
                                        }>
                                    >
                                >;
                            }>;
                        }>;
                    }>
                >;
            }>;
        };
    }>;
};

export type QuestionnaireQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type QuestionnaireQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly questionnaire: {
            readonly __typename?: 'QuestionnaireNode';
            readonly id: string;
            readonly title?: Maybe<string>;
            readonly description?: Maybe<string>;
            readonly type: QuestionnaireType;
            readonly outro?: Maybe<string>;
            readonly outroQuestionsTitle?: Maybe<string>;
            readonly questions: {
                readonly __typename?: 'QuestionnaireQuestionNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'QuestionnaireQuestionNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'QuestionnaireQuestionNode';
                            readonly id: string;
                            readonly type: QuestionnaireQuestionType;
                            readonly orderNumber: number;
                            readonly title?: Maybe<string>;
                            readonly description?: Maybe<string>;
                            readonly options?: Maybe<
                                ReadonlyArray<{
                                    readonly __typename?: 'QuestionnaireQuestionOption';
                                    readonly title: string;
                                    readonly value: number;
                                }>
                            >;
                        }>;
                    }>
                >;
            };
            readonly outroQuestions: {
                readonly __typename?: 'QuestionnaireQuestionNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'QuestionnaireQuestionNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'QuestionnaireQuestionNode';
                            readonly id: string;
                            readonly type: QuestionnaireQuestionType;
                            readonly orderNumber: number;
                            readonly title?: Maybe<string>;
                            readonly description?: Maybe<string>;
                            readonly options?: Maybe<
                                ReadonlyArray<{
                                    readonly __typename?: 'QuestionnaireQuestionOption';
                                    readonly title: string;
                                    readonly value: number;
                                }>
                            >;
                        }>;
                    }>
                >;
            };
        };
    }>;
};

export type QuestionnaireResponseBatchesQueryVariables = Exact<{ [key: string]: never }>;

export type QuestionnaireResponseBatchesQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly questionnaireResponseBatches?: Maybe<{
            readonly __typename?: 'QuestionnaireResponseBatchNodeConnection';
            readonly edges: ReadonlyArray<
                Maybe<{
                    readonly __typename?: 'QuestionnaireResponseBatchNodeEdge';
                    readonly node?: Maybe<{
                        readonly __typename?: 'QuestionnaireResponseBatchNode';
                        readonly id: string;
                        readonly openAt: string;
                        readonly closeAt: string;
                        readonly message?: Maybe<string>;
                        readonly questionnaireResponses?: Maybe<{
                            readonly __typename?: 'QuestionnaireResponseNodeConnection';
                            readonly edges: ReadonlyArray<
                                Maybe<{
                                    readonly __typename?: 'QuestionnaireResponseNodeEdge';
                                    readonly node?: Maybe<{
                                        readonly __typename?: 'QuestionnaireResponseNode';
                                        readonly id: string;
                                        readonly analysedAt?: Maybe<string>;
                                        readonly createdAt: string;
                                        readonly modifiedAt: string;
                                        readonly finishedAt?: Maybe<string>;
                                        readonly closedAt?: Maybe<string>;
                                        readonly closeAt?: Maybe<string>;
                                        readonly questionnaire: {
                                            readonly __typename?: 'QuestionnaireNode';
                                            readonly id: string;
                                            readonly title?: Maybe<string>;
                                            readonly description?: Maybe<string>;
                                        };
                                    }>;
                                }>
                            >;
                        }>;
                    }>;
                }>
            >;
        }>;
    }>;
};

export type MemberActionsQueryVariables = Exact<{ [key: string]: never }>;

export type MemberActionsQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly availableActions?: Maybe<{
            readonly __typename?: 'MemberActionsNode';
            readonly actions?: Maybe<
                ReadonlyArray<{
                    readonly __typename?: 'MemberActionNode';
                    readonly type: number;
                    readonly title: string;
                    readonly description?: Maybe<string>;
                    readonly responseTimeSla?: Maybe<string>;
                    readonly color?: Maybe<string>;
                    readonly category?: Maybe<number>;
                    readonly imageUrl?: Maybe<string>;
                    readonly typeNew: MemberActionType;
                    readonly actionUrl?: Maybe<string>;
                    readonly chat?: Maybe<{
                        readonly __typename?: 'ChatNode';
                        readonly unreadMessagesCountV2: number;
                        readonly id: string;
                        readonly type: ChatType;
                        readonly title: string;
                        readonly responseTimeSla?: Maybe<string>;
                        readonly description?: Maybe<string>;
                        readonly modifiedAt: string;
                        readonly lastMessageAt?: Maybe<string>;
                        readonly closedAt?: Maybe<string>;
                        readonly readonly: boolean;
                        readonly users: {
                            readonly __typename?: 'ChatUserNodeConnection';
                            readonly edges: ReadonlyArray<
                                Maybe<{
                                    readonly __typename?: 'ChatUserNodeEdge';
                                    readonly node?: Maybe<{
                                        readonly __typename?: 'ChatUserNode';
                                        readonly id: string;
                                        readonly leftAt?: Maybe<string>;
                                        readonly user: {
                                            readonly __typename?: 'UserNode';
                                            readonly id: string;
                                            readonly firstName: string;
                                            readonly lastName: string;
                                            readonly title?: Maybe<string>;
                                            readonly avatarBigUrl?: Maybe<string>;
                                            readonly avatarSmallUrl?: Maybe<string>;
                                        };
                                    }>;
                                }>
                            >;
                        };
                    }>;
                }>
            >;
        }>;
    }>;
};

export type ArticleQueryVariables = Exact<{
    id?: Maybe<Scalars['ID']>;
    tag?: Maybe<Scalars['String']>;
}>;

export type ArticleQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly article: {
            readonly __typename?: 'ArticleNode';
            readonly id: string;
            readonly createdAt: string;
            readonly modifiedAt: string;
            readonly type: ArticleType;
            readonly title: string;
            readonly highlight?: Maybe<string>;
            readonly content?: Maybe<string>;
            readonly coverPhotoUrl?: Maybe<string>;
            readonly parentArticle?: Maybe<{
                readonly __typename?: 'ArticleNode';
                readonly id: string;
                readonly title: string;
                readonly parentArticle?: Maybe<{
                    readonly __typename?: 'ArticleNode';
                    readonly id: string;
                    readonly title: string;
                }>;
            }>;
            readonly childArticles?: Maybe<{
                readonly __typename?: 'ArticleNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'ArticleNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'ArticleNode';
                            readonly id: string;
                            readonly type: ArticleType;
                            readonly title: string;
                            readonly highlight?: Maybe<string>;
                            readonly coverPhotoUrl?: Maybe<string>;
                            readonly videoHandle?: Maybe<string>;
                        }>;
                    }>
                >;
            }>;
        };
    }>;
};

export type SelfHelpCourseQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type SelfHelpCourseQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly selfHelpCourse?: Maybe<{
            readonly __typename?: 'SelfHelpCourseNode';
            readonly id: string;
            readonly title?: Maybe<string>;
        }>;
    }>;
};

export type CalendarReservationAppointmentsQueryVariables = Exact<{
    craId: Scalars['ID'];
    start: Scalars['Date'];
    end: Scalars['Date'];
    regionId?: Maybe<Scalars['ID']>;
    locationId?: Maybe<Scalars['ID']>;
    filters?: Maybe<AppointmentFilters>;
}>;

export type CalendarReservationAppointmentsQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly calendarReservationAccess: {
            readonly __typename?: 'CalendarReservationAccessNode';
            readonly id: string;
            readonly appointments: ReadonlyArray<{
                readonly __typename?: 'AppointmentNode';
                readonly id: string;
                readonly start: string;
                readonly end: string;
                readonly duration: number;
                readonly meta: string;
                readonly contactTypes: ReadonlyArray<AppointmentContactType>;
                readonly location?: Maybe<{
                    readonly __typename?: 'LocationNode';
                    readonly id: string;
                    readonly name: string;
                    readonly type: LocationType;
                    readonly address1?: Maybe<string>;
                    readonly address2?: Maybe<string>;
                    readonly zip?: Maybe<string>;
                    readonly city?: Maybe<string>;
                }>;
                readonly user: {
                    readonly __typename?: 'UserNode';
                    readonly id: string;
                    readonly firstName: string;
                    readonly lastName: string;
                    readonly title?: Maybe<string>;
                    readonly avatarBigUrl?: Maybe<string>;
                    readonly avatarSmallUrl?: Maybe<string>;
                };
            }>;
        };
    }>;
};

export type CalendarReservationAppointmentDaysQueryVariables = Exact<{
    craId: Scalars['ID'];
    start: Scalars['Date'];
    end: Scalars['Date'];
    regionId?: Maybe<Scalars['ID']>;
    locationId?: Maybe<Scalars['ID']>;
    filters?: Maybe<AppointmentFilters>;
}>;

export type CalendarReservationAppointmentDaysQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly calendarReservationAccess: {
            readonly __typename?: 'CalendarReservationAccessNode';
            readonly id: string;
            readonly appointmentDays: ReadonlyArray<{
                readonly __typename?: 'AppointmentDayNode';
                readonly id: string;
                readonly date: string;
                readonly eventCount: number;
                readonly nextAppointments: ReadonlyArray<{
                    readonly __typename?: 'AppointmentNode';
                    readonly id: string;
                    readonly start: string;
                    readonly end: string;
                    readonly meta: string;
                    readonly location?: Maybe<{
                        readonly __typename?: 'LocationNode';
                        readonly id: string;
                        readonly name: string;
                        readonly type: LocationType;
                        readonly address1?: Maybe<string>;
                        readonly address2?: Maybe<string>;
                        readonly zip?: Maybe<string>;
                        readonly city?: Maybe<string>;
                    }>;
                }>;
            }>;
        };
    }>;
};

export type CalendarReservationAccessesQueryVariables = Exact<{ [key: string]: never }>;

export type CalendarReservationAccessesQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly calendarReservationAccesses?: Maybe<
            ReadonlyArray<{
                readonly __typename?: 'CalendarReservationAccessNode';
                readonly id: string;
                readonly createdAt: string;
                readonly expiresAt?: Maybe<string>;
                readonly type: string;
                readonly durations: ReadonlyArray<number>;
                readonly description: string;
                readonly contactTypes: ReadonlyArray<Maybe<AppointmentContactType>>;
                readonly groups: ReadonlyArray<string>;
                readonly users: ReadonlyArray<{
                    readonly __typename?: 'UserNode';
                    readonly id: string;
                    readonly firstName: string;
                    readonly lastName: string;
                    readonly title?: Maybe<string>;
                    readonly avatarBigUrl?: Maybe<string>;
                    readonly avatarSmallUrl?: Maybe<string>;
                }>;
            }>
        >;
    }>;
};

export type GlobalCalendarReservationAccessesQueryVariables = Exact<{ [key: string]: never }>;

export type GlobalCalendarReservationAccessesQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly globalCalendarReservationAccesses?: Maybe<
            ReadonlyArray<{
                readonly __typename?: 'CalendarReservationAccessNode';
                readonly id: string;
                readonly createdAt: string;
                readonly expiresAt?: Maybe<string>;
                readonly type: string;
                readonly durations: ReadonlyArray<number>;
                readonly description: string;
                readonly contactTypes: ReadonlyArray<Maybe<AppointmentContactType>>;
                readonly groups: ReadonlyArray<string>;
                readonly users: ReadonlyArray<{
                    readonly __typename?: 'UserNode';
                    readonly id: string;
                    readonly firstName: string;
                    readonly lastName: string;
                    readonly title?: Maybe<string>;
                    readonly avatarBigUrl?: Maybe<string>;
                    readonly avatarSmallUrl?: Maybe<string>;
                }>;
            }>
        >;
    }>;
};

export type BadgesQueryVariables = Exact<{ [key: string]: never }>;

export type BadgesQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly badges?: Maybe<{
            readonly __typename?: 'BadgeNode';
            readonly unseenChatMessages?: Maybe<number>;
            readonly unseenDocuments?: Maybe<number>;
            readonly openQuestionnaires?: Maybe<number>;
            readonly openCalendarReservationAccesses?: Maybe<number>;
        }>;
    }>;
};

export type RegionsQueryVariables = Exact<{ [key: string]: never }>;

export type RegionsQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly root?: Maybe<{
        readonly __typename?: 'MemberRootNode';
        readonly regions?: Maybe<
            ReadonlyArray<{
                readonly __typename?: 'RegionNode';
                readonly id: string;
                readonly name: string;
                readonly geometry: any;
                readonly locations?: Maybe<{
                    readonly __typename?: 'LocationNodeConnection';
                    readonly edges: ReadonlyArray<
                        Maybe<{
                            readonly __typename?: 'LocationNodeEdge';
                            readonly node?: Maybe<{
                                readonly __typename?: 'LocationNode';
                                readonly id: string;
                                readonly name: string;
                                readonly type: LocationType;
                                readonly address1?: Maybe<string>;
                                readonly address2?: Maybe<string>;
                                readonly zip?: Maybe<string>;
                                readonly city?: Maybe<string>;
                            }>;
                        }>
                    >;
                }>;
            }>
        >;
    }>;
};

export type VideoMeetingInfoQueryVariables = Exact<{
    meetingUUID: Scalars['UUID'];
}>;

export type VideoMeetingInfoQuery = {
    readonly __typename?: 'MemberQueryRoot';
    readonly public: {
        readonly __typename?: 'PublicRootNode';
        readonly meetingInformation?: Maybe<{
            readonly __typename?: 'MeetingInformation';
            readonly uuid: string;
            readonly provider: VideoMeetingProvider;
            readonly url: string;
        }>;
    };
};

export type BadgeSubscriptionVariables = Exact<{ [key: string]: never }>;

export type BadgeSubscription = {
    readonly __typename?: 'MemberSubscriptionRoot';
    readonly badge?: Maybe<{
        readonly __typename?: 'MemberBadgeSubscription';
        readonly badges?: Maybe<{
            readonly __typename?: 'BadgeNode';
            readonly unseenChatMessages?: Maybe<number>;
            readonly unseenDocuments?: Maybe<number>;
            readonly openQuestionnaires?: Maybe<number>;
            readonly openCalendarReservationAccesses?: Maybe<number>;
        }>;
    }>;
};

export type ChatSubscriptionVariables = Exact<{
    chatId: Scalars['ID'];
}>;

export type ChatSubscription = {
    readonly __typename?: 'MemberSubscriptionRoot';
    readonly chat?: Maybe<{
        readonly __typename?: 'MemberChatSubscription';
        readonly chat?: Maybe<{
            readonly __typename?: 'ChatNode';
            readonly id: string;
            readonly type: ChatType;
            readonly title: string;
            readonly responseTimeSla?: Maybe<string>;
            readonly description?: Maybe<string>;
            readonly modifiedAt: string;
            readonly lastMessageAt?: Maybe<string>;
            readonly closedAt?: Maybe<string>;
            readonly readonly: boolean;
            readonly notifications?: Maybe<
                ReadonlyArray<{
                    readonly __typename?: 'ChatNotificationNode';
                    readonly id: string;
                    readonly context: ChatNotificationContext;
                    readonly contents?: Maybe<
                        ReadonlyArray<{
                            readonly __typename?: 'ChatKeyValueNode';
                            readonly key: string;
                            readonly value: any;
                        }>
                    >;
                    readonly actions?: Maybe<
                        ReadonlyArray<{
                            readonly __typename?: 'ChatNotificationActionNode';
                            readonly id: string;
                            readonly type: ChatNotificationActionType;
                            readonly params?: Maybe<
                                ReadonlyArray<{
                                    readonly __typename?: 'ChatKeyValueNode';
                                    readonly key: string;
                                    readonly value: any;
                                }>
                            >;
                            readonly contents?: Maybe<
                                ReadonlyArray<{
                                    readonly __typename?: 'ChatKeyValueNode';
                                    readonly key: string;
                                    readonly value: any;
                                }>
                            >;
                        }>
                    >;
                }>
            >;
            readonly users: {
                readonly __typename?: 'ChatUserNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'ChatUserNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'ChatUserNode';
                            readonly id: string;
                            readonly leftAt?: Maybe<string>;
                            readonly user: {
                                readonly __typename?: 'UserNode';
                                readonly id: string;
                                readonly firstName: string;
                                readonly lastName: string;
                                readonly title?: Maybe<string>;
                                readonly avatarBigUrl?: Maybe<string>;
                                readonly avatarSmallUrl?: Maybe<string>;
                            };
                        }>;
                    }>
                >;
            };
            readonly members: {
                readonly __typename?: 'ChatMemberNodeConnection';
                readonly edges: ReadonlyArray<
                    Maybe<{
                        readonly __typename?: 'ChatMemberNodeEdge';
                        readonly node?: Maybe<{
                            readonly __typename?: 'ChatMemberNode';
                            readonly id: string;
                            readonly leftAt?: Maybe<string>;
                            readonly member: { readonly __typename?: 'MemberNode'; readonly id: string };
                            readonly lastMessageSeen?: Maybe<{
                                readonly __typename?: 'ChatMessageNode';
                                readonly id: string;
                            }>;
                        }>;
                    }>
                >;
            };
        }>;
    }>;
};

export type ChatMessagesSubscriptionVariables = Exact<{
    chatId: Scalars['ID'];
}>;

export type ChatMessagesSubscription = {
    readonly __typename?: 'MemberSubscriptionRoot';
    readonly chat?: Maybe<{
        readonly __typename?: 'MemberChatSubscription';
        readonly messageAdd?: Maybe<{
            readonly __typename?: 'ChatMessageNode';
            readonly id: string;
            readonly createdAt: string;
            readonly deletedAt?: Maybe<string>;
            readonly seen: boolean;
            readonly type: number;
            readonly message: string;
            readonly user?: Maybe<{
                readonly __typename?: 'UserNode';
                readonly id: string;
                readonly firstName: string;
                readonly lastName: string;
                readonly title?: Maybe<string>;
                readonly avatarBigUrl?: Maybe<string>;
                readonly avatarSmallUrl?: Maybe<string>;
            }>;
            readonly member?: Maybe<{
                readonly __typename?: 'MemberNode';
                readonly id: string;
                readonly firstName: string;
                readonly lastName: string;
                readonly avatarBigUrl?: Maybe<string>;
                readonly avatarSmallUrl?: Maybe<string>;
            }>;
            readonly attachmentsV2: ReadonlyArray<
                | {
                      readonly __typename?: 'ChatMessageAttachmentNode';
                      readonly id: string;
                      readonly filename: string;
                      readonly url?: Maybe<string>;
                  }
                | {
                      readonly __typename?: 'ChatMessageIdAttachmentNode';
                      readonly id: string;
                      readonly attachmentId: string;
                      readonly title?: Maybe<string>;
                  }
                | {
                      readonly __typename?: 'ChatMessageInteractionNode';
                      readonly id: string;
                      readonly uiInteraction: {
                          readonly __typename?: 'UIInteractionNode';
                          readonly id: string;
                          readonly context: UiInteractionContext;
                          readonly interaction: {
                              readonly __typename?: 'InteractionNode';
                              readonly id: any;
                              readonly title?: Maybe<string>;
                              readonly description?: Maybe<string>;
                              readonly label?: Maybe<string>;
                              readonly color?: Maybe<string>;
                              readonly inputs: ReadonlyArray<{
                                  readonly __typename?: 'InteractionInputNode';
                                  readonly type: string;
                                  readonly name: string;
                                  readonly placeholder?: Maybe<string>;
                                  readonly label?: Maybe<string>;
                                  readonly initialValue?: Maybe<any>;
                                  readonly selectTargetName?: Maybe<string>;
                                  readonly selectAllowAdd?: Maybe<boolean>;
                                  readonly selectMultiple?: Maybe<boolean>;
                              }>;
                              readonly actions: ReadonlyArray<{
                                  readonly __typename?: 'InteractionActionNode';
                                  readonly handle: string;
                                  readonly value?: Maybe<string>;
                                  readonly title: string;
                                  readonly importance?: Maybe<InteractionImportance>;
                                  readonly color?: Maybe<InteractionColor>;
                              }>;
                          };
                      };
                      readonly chatMessage: { readonly __typename?: 'ChatMessageNode'; readonly id: string };
                  }
            >;
        }>;
        readonly messageEdit?: Maybe<{
            readonly __typename?: 'ChatMessageNode';
            readonly id: string;
            readonly createdAt: string;
            readonly deletedAt?: Maybe<string>;
            readonly seen: boolean;
            readonly type: number;
            readonly message: string;
            readonly user?: Maybe<{
                readonly __typename?: 'UserNode';
                readonly id: string;
                readonly firstName: string;
                readonly lastName: string;
                readonly title?: Maybe<string>;
                readonly avatarBigUrl?: Maybe<string>;
                readonly avatarSmallUrl?: Maybe<string>;
            }>;
            readonly member?: Maybe<{
                readonly __typename?: 'MemberNode';
                readonly id: string;
                readonly firstName: string;
                readonly lastName: string;
                readonly avatarBigUrl?: Maybe<string>;
                readonly avatarSmallUrl?: Maybe<string>;
            }>;
            readonly attachmentsV2: ReadonlyArray<
                | {
                      readonly __typename?: 'ChatMessageAttachmentNode';
                      readonly id: string;
                      readonly filename: string;
                      readonly url?: Maybe<string>;
                  }
                | {
                      readonly __typename?: 'ChatMessageIdAttachmentNode';
                      readonly id: string;
                      readonly attachmentId: string;
                      readonly title?: Maybe<string>;
                  }
                | {
                      readonly __typename?: 'ChatMessageInteractionNode';
                      readonly id: string;
                      readonly uiInteraction: {
                          readonly __typename?: 'UIInteractionNode';
                          readonly id: string;
                          readonly context: UiInteractionContext;
                          readonly interaction: {
                              readonly __typename?: 'InteractionNode';
                              readonly id: any;
                              readonly title?: Maybe<string>;
                              readonly description?: Maybe<string>;
                              readonly label?: Maybe<string>;
                              readonly color?: Maybe<string>;
                              readonly inputs: ReadonlyArray<{
                                  readonly __typename?: 'InteractionInputNode';
                                  readonly type: string;
                                  readonly name: string;
                                  readonly placeholder?: Maybe<string>;
                                  readonly label?: Maybe<string>;
                                  readonly initialValue?: Maybe<any>;
                                  readonly selectTargetName?: Maybe<string>;
                                  readonly selectAllowAdd?: Maybe<boolean>;
                                  readonly selectMultiple?: Maybe<boolean>;
                              }>;
                              readonly actions: ReadonlyArray<{
                                  readonly __typename?: 'InteractionActionNode';
                                  readonly handle: string;
                                  readonly value?: Maybe<string>;
                                  readonly title: string;
                                  readonly importance?: Maybe<InteractionImportance>;
                                  readonly color?: Maybe<InteractionColor>;
                              }>;
                          };
                      };
                      readonly chatMessage: { readonly __typename?: 'ChatMessageNode'; readonly id: string };
                  }
            >;
        }>;
    }>;
};

export const InteractionResultFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InteractionResultFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UIInteractionResultNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'redirectTo' } }]
            }
        }
    ]
} as unknown as DocumentNode<InteractionResultFragment, unknown>;
export const EmploymentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'EmploymentFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmploymentNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                            ]
                        }
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'superior' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'endDate' } }
                ]
            }
        }
    ]
} as unknown as DocumentNode<EmploymentFragment, unknown>;
export const ProfileFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ProfileFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemberNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'memberCommunicationPermissionAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'marketingPermissionAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'feedbackPermissionAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'termsAccepted' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'verificationRequired' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'hasUsablePassword' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'hasCompanyAccess' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'employments' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'EmploymentFragment' }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'company' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'name' }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'membersCanReportSickLeave'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'sickLeaveMinDays'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'sickLeaveMaxDays'
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...EmploymentFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ProfileFragment, unknown>;
export const LocationFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'LocationFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LocationNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'city' } }
                ]
            }
        }
    ]
} as unknown as DocumentNode<LocationFragment, unknown>;
export const UserFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'UserFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'avatarBigUrl' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'avatarSmallUrl' } }
                ]
            }
        }
    ]
} as unknown as DocumentNode<UserFragment, unknown>;
export const CalendarReservationAccessFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CalendarReservationAccessFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CalendarReservationAccessNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'durations' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'contactTypes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } }]
                        }
                    }
                ]
            }
        },
        ...UserFragmentDoc.definitions
    ]
} as unknown as DocumentNode<CalendarReservationAccessFragment, unknown>;
export const CalendarEventFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CalendarEventFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CalendarEventNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LocationFragment' } }]
                        }
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'isModifiable' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendarReservationAccess' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'CalendarReservationAccessFragment' }
                                }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'videoAppointmentDetails' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'participantUrl' } }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendar' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...LocationFragmentDoc.definitions,
        ...CalendarReservationAccessFragmentDoc.definitions,
        ...UserFragmentDoc.definitions
    ]
} as unknown as DocumentNode<CalendarEventFragment, unknown>;
export const AppointmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AppointmentFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppointmentNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LocationFragment' } }]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } }]
                        }
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'contactTypes' } }
                ]
            }
        },
        ...LocationFragmentDoc.definitions,
        ...UserFragmentDoc.definitions
    ]
} as unknown as DocumentNode<AppointmentFragment, unknown>;
export const AppointmentDayFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AppointmentDayFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppointmentDayNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'eventCount' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextAppointments' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'LocationFragment' }
                                            }
                                        ]
                                    }
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'meta' } }
                            ]
                        }
                    }
                ]
            }
        },
        ...LocationFragmentDoc.definitions
    ]
} as unknown as DocumentNode<AppointmentDayFragment, unknown>;
export const ChatMemberFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ChatMemberFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemberNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'avatarBigUrl' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'avatarSmallUrl' } }
                ]
            }
        }
    ]
} as unknown as DocumentNode<ChatMemberFragment, unknown>;
export const ChatMessageFileAttachmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ChatMessageFileAttachmentFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessageAttachmentNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                ]
            }
        }
    ]
} as unknown as DocumentNode<ChatMessageFileAttachmentFragment, unknown>;
export const ChatMessageIdAttachmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ChatMessageIdAttachmentFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessageIdAttachmentNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'attachmentId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } }
                ]
            }
        }
    ]
} as unknown as DocumentNode<ChatMessageIdAttachmentFragment, unknown>;
export const InteractionInputFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InteractionInputFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InteractionInputNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'initialValue' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'selectTargetName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'selectAllowAdd' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'selectMultiple' } }
                ]
            }
        }
    ]
} as unknown as DocumentNode<InteractionInputFragment, unknown>;
export const InteractionActionFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InteractionActionFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InteractionActionNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'importance' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'color' } }
                ]
            }
        }
    ]
} as unknown as DocumentNode<InteractionActionFragment, unknown>;
export const InteractionFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InteractionFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InteractionNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inputs' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InteractionInputFragment' } }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InteractionActionFragment' } }
                            ]
                        }
                    }
                ]
            }
        },
        ...InteractionInputFragmentDoc.definitions,
        ...InteractionActionFragmentDoc.definitions
    ]
} as unknown as DocumentNode<InteractionFragment, unknown>;
export const UiInteractionFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'UIInteractionFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UIInteractionNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interaction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InteractionFragment' } }
                            ]
                        }
                    }
                ]
            }
        },
        ...InteractionFragmentDoc.definitions
    ]
} as unknown as DocumentNode<UiInteractionFragment, unknown>;
export const ChatMessageInteractionFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ChatMessageInteractionFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessageInteractionNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uiInteraction' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UIInteractionFragment' } }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chatMessage' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
                        }
                    }
                ]
            }
        },
        ...UiInteractionFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ChatMessageInteractionFragment, unknown>;
export const ChatMessageAttachmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ChatMessageAttachmentFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatAttachment' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ChatMessageAttachmentNode' }
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ChatMessageFileAttachmentFragment' }
                                }
                            ]
                        }
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ChatMessageIdAttachmentNode' }
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ChatMessageIdAttachmentFragment' }
                                }
                            ]
                        }
                    },
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ChatMessageInteractionNode' }
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ChatMessageInteractionFragment' }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...ChatMessageFileAttachmentFragmentDoc.definitions,
        ...ChatMessageIdAttachmentFragmentDoc.definitions,
        ...ChatMessageInteractionFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ChatMessageAttachmentFragment, unknown>;
export const ChatMessageFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ChatMessageFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessageNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'seen' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } }]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'member' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatMemberFragment' } }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attachmentsV2' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ChatMessageAttachmentFragment' }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...UserFragmentDoc.definitions,
        ...ChatMemberFragmentDoc.definitions,
        ...ChatMessageAttachmentFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ChatMessageFragment, unknown>;
export const ChatInformationFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ChatInformationFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'responseTimeSla' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lastMessageAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'readonly' } }
                ]
            }
        }
    ]
} as unknown as DocumentNode<ChatInformationFragment, unknown>;
export const ChatNotificationActionFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ChatNotificationActionFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatNotificationActionNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'params' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contents' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode<ChatNotificationActionFragment, unknown>;
export const ChatNotificationFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ChatNotificationFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatNotificationNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contents' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ChatNotificationActionFragment' }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...ChatNotificationActionFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ChatNotificationFragment, unknown>;
export const ChatNotificationsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ChatNotificationsFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notifications' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatNotificationFragment' } }
                            ]
                        }
                    }
                ]
            }
        },
        ...ChatNotificationFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ChatNotificationsFragment, unknown>;
export const ChatUsersFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ChatUsersFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'user' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'FragmentSpread',
                                                                        name: { kind: 'Name', value: 'UserFragment' }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'leftAt' } }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...UserFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ChatUsersFragment, unknown>;
export const ChatMembersFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ChatMembersFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'member' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'id' }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'lastMessageSeen' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'id' }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'leftAt' } }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode<ChatMembersFragment, unknown>;
export const ChatFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ChatFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatInformationFragment' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatNotificationsFragment' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatUsersFragment' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatMembersFragment' } }
                ]
            }
        },
        ...ChatInformationFragmentDoc.definitions,
        ...ChatNotificationsFragmentDoc.definitions,
        ...ChatUsersFragmentDoc.definitions,
        ...ChatMembersFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ChatFragment, unknown>;
export const ListDocumentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ListDocumentFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemberDocumentNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'addedDate' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'memberSeenAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'memberVisibleComment' } }
                ]
            }
        }
    ]
} as unknown as DocumentNode<ListDocumentFragment, unknown>;
export const DocumentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DocumentFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemberDocumentNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'addedDate' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'memberVisibleComment' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'memberSeenAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'avatarSmallUrl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'exportTokens' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'validUntil' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode<DocumentFragment, unknown>;
export const NotificationFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'NotificationFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemberNotificationNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sender' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } }]
                        }
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                ]
            }
        },
        ...UserFragmentDoc.definitions
    ]
} as unknown as DocumentNode<NotificationFragment, unknown>;
export const BadgesFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BadgesFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BadgeNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'unseenChatMessages' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'unseenDocuments' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'openQuestionnaires' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'openCalendarReservationAccesses' } }
                ]
            }
        }
    ]
} as unknown as DocumentNode<BadgesFragment, unknown>;
export const QuestionnaireQuestionFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'QuestionnaireQuestionFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuestionnaireQuestionNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'options' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode<QuestionnaireQuestionFragment, unknown>;
export const QuestionnaireFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'QuestionnaireFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuestionnaireNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'outro' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'outroQuestionsTitle' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'QuestionnaireQuestionFragment'
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outroQuestions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'QuestionnaireQuestionFragment'
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...QuestionnaireQuestionFragmentDoc.definitions
    ]
} as unknown as DocumentNode<QuestionnaireFragment, unknown>;
export const ListQuestionnaireFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ListQuestionnaireFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuestionnaireNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'description' } }
                ]
            }
        }
    ]
} as unknown as DocumentNode<ListQuestionnaireFragment, unknown>;
export const ListQuestionnaireResponseFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ListQuestionnaireResponseFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuestionnaireResponseNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'analysedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'finishedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closeAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionnaire' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ListQuestionnaireFragment' } }
                            ]
                        }
                    }
                ]
            }
        },
        ...ListQuestionnaireFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ListQuestionnaireResponseFragment, unknown>;
export const QuestionnaireQuestionCategoryFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'QuestionnaireQuestionCategoryFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuestionnaireQuestionCategoryNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } }
                ]
            }
        }
    ]
} as unknown as DocumentNode<QuestionnaireQuestionCategoryFragment, unknown>;
export const QuestionnaireQuestionResponseFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'QuestionnaireQuestionResponseFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuestionnaireQuestionResponseNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'strValue' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'listValue' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionnaireQuestion' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'weight' } }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'category' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'QuestionnaireQuestionCategoryFragment' }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...QuestionnaireQuestionCategoryFragmentDoc.definitions
    ]
} as unknown as DocumentNode<QuestionnaireQuestionResponseFragment, unknown>;
export const QuestionnaireResponseFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'QuestionnaireResponseFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuestionnaireResponseNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'analysedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'finishedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'closeAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionnaire' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responses' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'QuestionnaireQuestionResponseFragment'
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'summary' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'categories' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'popup' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'color' } }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...QuestionnaireQuestionResponseFragmentDoc.definitions
    ]
} as unknown as DocumentNode<QuestionnaireResponseFragment, unknown>;
export const ListChatFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ListChatFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatInformationFragment' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatUsersFragment' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'unreadMessagesCountV2' } }
                ]
            }
        },
        ...ChatInformationFragmentDoc.definitions,
        ...ChatUsersFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ListChatFragment, unknown>;
export const MemberActionFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MemberActionFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemberActionNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'responseTimeSla' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'responseTimeSla' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'typeNew' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'actionUrl' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chat' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ListChatFragment' } }]
                        }
                    }
                ]
            }
        },
        ...ListChatFragmentDoc.definitions
    ]
} as unknown as DocumentNode<MemberActionFragment, unknown>;
export const MemberActionsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MemberActionsFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemberActionsNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MemberActionFragment' } }
                            ]
                        }
                    }
                ]
            }
        },
        ...MemberActionFragmentDoc.definitions
    ]
} as unknown as DocumentNode<MemberActionsFragment, unknown>;
export const ParentArticleFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ParentArticleFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentArticle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode<ParentArticleFragment, unknown>;
export const ArticleFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ArticleFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ArticleNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'highlight' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'coverPhotoUrl' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentArticle' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ParentArticleFragment' } }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'childArticles' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'highlight' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'coverPhotoUrl' }
                                                        },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'videoHandle' } }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...ParentArticleFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ArticleFragment, unknown>;
export const RegionFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RegionFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RegionNode' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locations' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'LocationFragment' }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...LocationFragmentDoc.definitions
    ]
} as unknown as DocumentNode<RegionFragment, unknown>;
export const VerifyEmailMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'VerifyEmailMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'VerifyEmailMutationInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'verifyEmail' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const SignUpWithEmailMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'SignUpWithEmailMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'SignUpWithEmailMutationInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signUpWithEmail' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'member' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileFragment' } }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...ProfileFragmentDoc.definitions
    ]
} as unknown as DocumentNode<SignUpWithEmailMutation, SignUpWithEmailMutationVariables>;
export const TriggerInteractionMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'TriggerInteractionMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'TriggerInteractionMutationInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interactionTrigger' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uiInteraction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'UIInteractionFragment' }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'interactionResult' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'InteractionResultFragment' }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...UiInteractionFragmentDoc.definitions,
        ...InteractionResultFragmentDoc.definitions
    ]
} as unknown as DocumentNode<TriggerInteractionMutation, TriggerInteractionMutationVariables>;
export const InteractionMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'InteractionMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'InteractionMutationInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interaction' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uiInteraction' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'UIInteractionFragment' }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'interactionResult' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'InteractionResultFragment' }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...UiInteractionFragmentDoc.definitions,
        ...InteractionResultFragmentDoc.definitions
    ]
} as unknown as DocumentNode<InteractionMutation, InteractionMutationVariables>;
export const MemberActionMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'MemberActionMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'MemberActionMutationInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'memberAction' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode<MemberActionMutation, MemberActionMutationVariables>;
export const ReserveAppointmentMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'ReserveAppointmentMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReserveAppointmentMutationInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reserveAppointment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'clientMutationId' } }]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode<ReserveAppointmentMutation, ReserveAppointmentMutationVariables>;
export const CancelAppointmentMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'CancelAppointmentMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'CancelAppointmentMutationInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cancelAppointment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'calendarEvent' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'CalendarEventFragment' }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...CalendarEventFragmentDoc.definitions
    ]
} as unknown as DocumentNode<CancelAppointmentMutation, CancelAppointmentMutationVariables>;
export const ModifyAppointmentMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'ModifyAppointmentMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModifyAppointmentMutationInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modifyAppointment' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'clientMutationId' } }]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode<ModifyAppointmentMutation, ModifyAppointmentMutationVariables>;
export const ChatMessageAddMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'ChatMessageAddMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessageAddMutationInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chatMessageAdd' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'chat' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatFragment' } }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'message' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ChatMessageFragment' }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...ChatFragmentDoc.definitions,
        ...ChatMessageFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ChatMessageAddMutation, ChatMessageAddMutationVariables>;
export const ChatMessagesSeenMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'ChatMessagesSeenMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessagesSeenMutationInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chatMessagesSeen' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'messages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'chatMember' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lastMessageSeen' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode<ChatMessagesSeenMutation, ChatMessagesSeenMutationVariables>;
export const EscalateToLiveChatMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'EscalateToLiveChatMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'EscalateToLiveChatMutationInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'escalateToLiveChat' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'chat' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode<EscalateToLiveChatMutation, EscalateToLiveChatMutationVariables>;
export const ProfileEditMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'ProfileEditMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileEditMutationInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profileEdit' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'member' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileFragment' } }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...ProfileFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ProfileEditMutation, ProfileEditMutationVariables>;
export const ReportSickLeaveMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'ReportSickLeaveMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportSickLeaveMutationInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportSickLeave' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'clientMutationId' } }]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode<ReportSickLeaveMutation, ReportSickLeaveMutationVariables>;
export const QuestionnaireResponseMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'QuestionnaireResponseMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'QuestionnaireResponseMutationInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionnaireResponse' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'response' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'QuestionnaireResponseFragment' }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...QuestionnaireResponseFragmentDoc.definitions
    ]
} as unknown as DocumentNode<QuestionnaireResponseMutation, QuestionnaireResponseMutationVariables>;
export const SelfHelpCourseSubscribeMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'SelfHelpCourseSubscribeMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SelfHelpCourseSubscribeMutationInput' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'selfHelpCourseSubscribe' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'clientMutationId' } }]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode<SelfHelpCourseSubscribeMutation, SelfHelpCourseSubscribeMutationVariables>;
export const CreateMemberDocumentExportTokenMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'CreateMemberDocumentExportTokenMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CreateMemberDocumentExportTokenMutationInput' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documentExportTokenCreate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'document' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DocumentFragment' }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...DocumentFragmentDoc.definitions
    ]
} as unknown as DocumentNode<CreateMemberDocumentExportTokenMutation, CreateMemberDocumentExportTokenMutationVariables>;
export const DeleteMemberDocumentExportTokenMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'DeleteMemberDocumentExportTokenMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'DeleteMemberDocumentExportTokenMutationInput' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documentExportTokenDelete' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'document' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DocumentFragment' }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...DocumentFragmentDoc.definitions
    ]
} as unknown as DocumentNode<DeleteMemberDocumentExportTokenMutation, DeleteMemberDocumentExportTokenMutationVariables>;
export const SignUpTypeQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'SignUpTypeQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'token' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'token' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'signUpType' } }]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode<SignUpTypeQuery, SignUpTypeQueryVariables>;
export const GlobalInteractionsQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GlobalInteractionsQuery' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'interactions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'UIInteractionFragment' }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...UiInteractionFragmentDoc.definitions
    ]
} as unknown as DocumentNode<GlobalInteractionsQuery, GlobalInteractionsQueryVariables>;
export const CalendarEventsQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CalendarEventsQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'start' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'calendarEvents' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'start' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'start' } }
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'end' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'end' } }
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'first' },
                                            value: { kind: 'IntValue', value: '10' }
                                        }
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'edges' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'node' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'FragmentSpread',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'CalendarEventFragment'
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...CalendarEventFragmentDoc.definitions
    ]
} as unknown as DocumentNode<CalendarEventsQuery, CalendarEventsQueryVariables>;
export const ChatQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'ChatQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'chatID' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'chat' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'id' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'chatID' } }
                                        }
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatFragment' } }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...ChatFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ChatQuery, ChatQueryVariables>;
export const ChatMessagesQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'ChatMessagesQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'chatID' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'messagesAfter' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'messagesAfterLastMessageAt' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'chat' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'id' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'chatID' } }
                                        }
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastMessageAt' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'messages' },
                                                arguments: [
                                                    {
                                                        kind: 'Argument',
                                                        name: { kind: 'Name', value: 'first' },
                                                        value: { kind: 'IntValue', value: '30' }
                                                    },
                                                    {
                                                        kind: 'Argument',
                                                        name: { kind: 'Name', value: 'after' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'messagesAfter' }
                                                        }
                                                    },
                                                    {
                                                        kind: 'Argument',
                                                        name: { kind: 'Name', value: 'messagesAfterLastMessageAt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'messagesAfterLastMessageAt' }
                                                        }
                                                    }
                                                ],
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'edges' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'node' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'FragmentSpread',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'ChatMessageFragment'
                                                                                    }
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'attachmentsV2'
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            {
                                                                                                kind: 'FragmentSpread',
                                                                                                name: {
                                                                                                    kind: 'Name',
                                                                                                    value: 'ChatMessageAttachmentFragment'
                                                                                                }
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                }
                                                                            ]
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'pageInfo' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'hasPreviousPage' }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'hasNextPage' }
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...ChatMessageFragmentDoc.definitions,
        ...ChatMessageAttachmentFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ChatMessagesQuery, ChatMessagesQueryVariables>;
export const ChatsQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'ChatsQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'recent' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'unseen' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'live' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'chats' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'recent' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'recent' } }
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'unseen' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'unseen' } }
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'live' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'live' } }
                                        }
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'edges' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'node' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'FragmentSpread',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'ListChatFragment'
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...ListChatFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ChatsQuery, ChatsQueryVariables>;
export const ProfileQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'ProfileQuery' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'me' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileFragment' } }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...ProfileFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ProfileQuery, ProfileQueryVariables>;
export const DocumentsQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'DocumentsQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'unseen' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documents' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'unseen' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'unseen' } }
                                        }
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'edges' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'node' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'FragmentSpread',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'ListDocumentFragment'
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...ListDocumentFragmentDoc.definitions
    ]
} as unknown as DocumentNode<DocumentsQuery, DocumentsQueryVariables>;
export const DocumentQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'DocumentQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'document' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'id' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } }
                                        }
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DocumentFragment' }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...DocumentFragmentDoc.definitions
    ]
} as unknown as DocumentNode<DocumentQuery, DocumentQueryVariables>;
export const NotificationsQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'NotificationsQuery' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'notifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'edges' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'node' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'FragmentSpread',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'NotificationFragment'
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...NotificationFragmentDoc.definitions
    ]
} as unknown as DocumentNode<NotificationsQuery, NotificationsQueryVariables>;
export const QuestionnaireResponseQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'QuestionnaireResponseQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'questionnaireResponse' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'id' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                                        }
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'QuestionnaireResponseFragment' }
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'questionnaire' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'QuestionnaireFragment' }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...QuestionnaireResponseFragmentDoc.definitions,
        ...QuestionnaireFragmentDoc.definitions
    ]
} as unknown as DocumentNode<QuestionnaireResponseQuery, QuestionnaireResponseQueryVariables>;
export const QuestionnaireResponseBatchWithTokenQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'QuestionnaireResponseBatchWithTokenQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'memberId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'token' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'token' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'questionnaireResponseBatch' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'memberId' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'memberId' } }
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'batchId' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } }
                                        }
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'openAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'closeAt' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'questionnaireResponses' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'edges' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'node' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'FragmentSpread',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'QuestionnaireResponseFragment'
                                                                                    }
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'questionnaire'
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            {
                                                                                                kind: 'FragmentSpread',
                                                                                                name: {
                                                                                                    kind: 'Name',
                                                                                                    value: 'QuestionnaireFragment'
                                                                                                }
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                }
                                                                            ]
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...QuestionnaireResponseFragmentDoc.definitions,
        ...QuestionnaireFragmentDoc.definitions
    ]
} as unknown as DocumentNode<
    QuestionnaireResponseBatchWithTokenQuery,
    QuestionnaireResponseBatchWithTokenQueryVariables
>;
export const QuestionnaireQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'QuestionnaireQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'questionnaire' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'id' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                                        }
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'QuestionnaireFragment' }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...QuestionnaireFragmentDoc.definitions
    ]
} as unknown as DocumentNode<QuestionnaireQuery, QuestionnaireQueryVariables>;
export const QuestionnaireResponseBatchesQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'QuestionnaireResponseBatchesQuery' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'questionnaireResponseBatches' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'edges' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'node' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'id' }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'openAt' }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'closeAt' }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'message' }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'questionnaireResponses'
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'edges'
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                    kind: 'Name',
                                                                                                    value: 'node'
                                                                                                },
                                                                                                selectionSet: {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections: [
                                                                                                        {
                                                                                                            kind: 'FragmentSpread',
                                                                                                            name: {
                                                                                                                kind: 'Name',
                                                                                                                value: 'ListQuestionnaireResponseFragment'
                                                                                                            }
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                }
                                                                            ]
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...ListQuestionnaireResponseFragmentDoc.definitions
    ]
} as unknown as DocumentNode<QuestionnaireResponseBatchesQuery, QuestionnaireResponseBatchesQueryVariables>;
export const MemberActionsQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'MemberActionsQuery' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availableActions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'MemberActionsFragment' }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...MemberActionsFragmentDoc.definitions
    ]
} as unknown as DocumentNode<MemberActionsQuery, MemberActionsQueryVariables>;
export const ArticleQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'ArticleQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tag' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'article' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'id' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                                        },
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'tag' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'tag' } }
                                        }
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArticleFragment' } }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...ArticleFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ArticleQuery, ArticleQueryVariables>;
export const SelfHelpCourseQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'SelfHelpCourseQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'selfHelpCourse' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'id' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                                        }
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'title' } }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode<SelfHelpCourseQuery, SelfHelpCourseQueryVariables>;
export const CalendarReservationAppointmentsQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CalendarReservationAppointmentsQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'craId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'start' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'regionId' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'locationId' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'AppointmentFilters' } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'calendarReservationAccess' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'id' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'craId' } }
                                        }
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'appointments' },
                                                arguments: [
                                                    {
                                                        kind: 'Argument',
                                                        name: { kind: 'Name', value: 'start' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'start' }
                                                        }
                                                    },
                                                    {
                                                        kind: 'Argument',
                                                        name: { kind: 'Name', value: 'end' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'end' }
                                                        }
                                                    },
                                                    {
                                                        kind: 'Argument',
                                                        name: { kind: 'Name', value: 'regionId' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'regionId' }
                                                        }
                                                    },
                                                    {
                                                        kind: 'Argument',
                                                        name: { kind: 'Name', value: 'locationId' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'locationId' }
                                                        }
                                                    },
                                                    {
                                                        kind: 'Argument',
                                                        name: { kind: 'Name', value: 'filters' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'filters' }
                                                        }
                                                    }
                                                ],
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'AppointmentFragment' }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...AppointmentFragmentDoc.definitions
    ]
} as unknown as DocumentNode<CalendarReservationAppointmentsQuery, CalendarReservationAppointmentsQueryVariables>;
export const CalendarReservationAppointmentDaysQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CalendarReservationAppointmentDaysQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'craId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'start' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'regionId' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'locationId' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'AppointmentFilters' } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'calendarReservationAccess' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'id' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'craId' } }
                                        }
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'appointmentDays' },
                                                arguments: [
                                                    {
                                                        kind: 'Argument',
                                                        name: { kind: 'Name', value: 'start' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'start' }
                                                        }
                                                    },
                                                    {
                                                        kind: 'Argument',
                                                        name: { kind: 'Name', value: 'end' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'end' }
                                                        }
                                                    },
                                                    {
                                                        kind: 'Argument',
                                                        name: { kind: 'Name', value: 'regionId' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'regionId' }
                                                        }
                                                    },
                                                    {
                                                        kind: 'Argument',
                                                        name: { kind: 'Name', value: 'locationId' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'locationId' }
                                                        }
                                                    },
                                                    {
                                                        kind: 'Argument',
                                                        name: { kind: 'Name', value: 'filters' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'filters' }
                                                        }
                                                    }
                                                ],
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'AppointmentDayFragment' }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...AppointmentDayFragmentDoc.definitions
    ]
} as unknown as DocumentNode<CalendarReservationAppointmentDaysQuery, CalendarReservationAppointmentDaysQueryVariables>;
export const CalendarReservationAccessesQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CalendarReservationAccessesQuery' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'calendarReservationAccesses' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'CalendarReservationAccessFragment' }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...CalendarReservationAccessFragmentDoc.definitions
    ]
} as unknown as DocumentNode<CalendarReservationAccessesQuery, CalendarReservationAccessesQueryVariables>;
export const GlobalCalendarReservationAccessesQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GlobalCalendarReservationAccessesQuery' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'globalCalendarReservationAccesses' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'CalendarReservationAccessFragment' }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...CalendarReservationAccessFragmentDoc.definitions
    ]
} as unknown as DocumentNode<GlobalCalendarReservationAccessesQuery, GlobalCalendarReservationAccessesQueryVariables>;
export const BadgesQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'BadgesQuery' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'badges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BadgesFragment' } }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...BadgesFragmentDoc.definitions
    ]
} as unknown as DocumentNode<BadgesQuery, BadgesQueryVariables>;
export const RegionsQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'RegionsQuery' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'root' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'regions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RegionFragment' } }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...RegionFragmentDoc.definitions
    ]
} as unknown as DocumentNode<RegionsQuery, RegionsQueryVariables>;
export const VideoMeetingInfoQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'VideoMeetingInfoQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'meetingUUID' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'public' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'meetingInformation' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'meetingUuid' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'meetingUUID' } }
                                        }
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode<VideoMeetingInfoQuery, VideoMeetingInfoQueryVariables>;
export const BadgeSubscriptionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'BadgeSubscription' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'badge' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'badges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BadgesFragment' } }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...BadgesFragmentDoc.definitions
    ]
} as unknown as DocumentNode<BadgeSubscription, BadgeSubscriptionVariables>;
export const ChatSubscriptionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'ChatSubscription' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chat' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'chatId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'chat' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChatFragment' } }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...ChatFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ChatSubscription, ChatSubscriptionVariables>;
export const ChatMessagesSubscriptionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'ChatMessagesSubscription' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chat' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'chatId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'messageAdd' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ChatMessageFragment' }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'messageEdit' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ChatMessageFragment' }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...ChatMessageFragmentDoc.definitions
    ]
} as unknown as DocumentNode<ChatMessagesSubscription, ChatMessagesSubscriptionVariables>;
export const OperationNames = {
    Query: {
        SignUpTypeQuery: 'SignUpTypeQuery',
        GlobalInteractionsQuery: 'GlobalInteractionsQuery',
        CalendarEventsQuery: 'CalendarEventsQuery',
        ChatQuery: 'ChatQuery',
        ChatMessagesQuery: 'ChatMessagesQuery',
        ChatsQuery: 'ChatsQuery',
        ProfileQuery: 'ProfileQuery',
        DocumentsQuery: 'DocumentsQuery',
        DocumentQuery: 'DocumentQuery',
        NotificationsQuery: 'NotificationsQuery',
        QuestionnaireResponseQuery: 'QuestionnaireResponseQuery',
        QuestionnaireResponseBatchWithTokenQuery: 'QuestionnaireResponseBatchWithTokenQuery',
        QuestionnaireQuery: 'QuestionnaireQuery',
        QuestionnaireResponseBatchesQuery: 'QuestionnaireResponseBatchesQuery',
        MemberActionsQuery: 'MemberActionsQuery',
        ArticleQuery: 'ArticleQuery',
        SelfHelpCourseQuery: 'SelfHelpCourseQuery',
        CalendarReservationAppointmentsQuery: 'CalendarReservationAppointmentsQuery',
        CalendarReservationAppointmentDaysQuery: 'CalendarReservationAppointmentDaysQuery',
        CalendarReservationAccessesQuery: 'CalendarReservationAccessesQuery',
        GlobalCalendarReservationAccessesQuery: 'GlobalCalendarReservationAccessesQuery',
        BadgesQuery: 'BadgesQuery',
        RegionsQuery: 'RegionsQuery',
        VideoMeetingInfoQuery: 'VideoMeetingInfoQuery'
    },
    Mutation: {
        VerifyEmailMutation: 'VerifyEmailMutation',
        SignUpWithEmailMutation: 'SignUpWithEmailMutation',
        TriggerInteractionMutation: 'TriggerInteractionMutation',
        InteractionMutation: 'InteractionMutation',
        MemberActionMutation: 'MemberActionMutation',
        ReserveAppointmentMutation: 'ReserveAppointmentMutation',
        CancelAppointmentMutation: 'CancelAppointmentMutation',
        ModifyAppointmentMutation: 'ModifyAppointmentMutation',
        ChatMessageAddMutation: 'ChatMessageAddMutation',
        ChatMessagesSeenMutation: 'ChatMessagesSeenMutation',
        EscalateToLiveChatMutation: 'EscalateToLiveChatMutation',
        ProfileEditMutation: 'ProfileEditMutation',
        ReportSickLeaveMutation: 'ReportSickLeaveMutation',
        QuestionnaireResponseMutation: 'QuestionnaireResponseMutation',
        SelfHelpCourseSubscribeMutation: 'SelfHelpCourseSubscribeMutation',
        CreateMemberDocumentExportTokenMutation: 'CreateMemberDocumentExportTokenMutation',
        DeleteMemberDocumentExportTokenMutation: 'DeleteMemberDocumentExportTokenMutation'
    },
    Subscription: {
        BadgeSubscription: 'BadgeSubscription',
        ChatSubscription: 'ChatSubscription',
        ChatMessagesSubscription: 'ChatMessagesSubscription'
    },
    Fragment: {
        InteractionResultFragment: 'InteractionResultFragment',
        InteractionInputFragment: 'InteractionInputFragment',
        InteractionActionFragment: 'InteractionActionFragment',
        InteractionFragment: 'InteractionFragment',
        UIInteractionFragment: 'UIInteractionFragment',
        EmploymentFragment: 'EmploymentFragment',
        ProfileFragment: 'ProfileFragment',
        UserFragment: 'UserFragment',
        CalendarReservationAccessFragment: 'CalendarReservationAccessFragment',
        LocationFragment: 'LocationFragment',
        CalendarEventFragment: 'CalendarEventFragment',
        AppointmentFragment: 'AppointmentFragment',
        AppointmentDayFragment: 'AppointmentDayFragment',
        ChatMemberFragment: 'ChatMemberFragment',
        ChatMessageInteractionFragment: 'ChatMessageInteractionFragment',
        ChatMessageFileAttachmentFragment: 'ChatMessageFileAttachmentFragment',
        ChatMessageIdAttachmentFragment: 'ChatMessageIdAttachmentFragment',
        ChatMessageAttachmentFragment: 'ChatMessageAttachmentFragment',
        ChatMessageFragment: 'ChatMessageFragment',
        ChatUsersFragment: 'ChatUsersFragment',
        ChatMembersFragment: 'ChatMembersFragment',
        ChatInformationFragment: 'ChatInformationFragment',
        ChatNotificationActionFragment: 'ChatNotificationActionFragment',
        ChatNotificationFragment: 'ChatNotificationFragment',
        ChatNotificationsFragment: 'ChatNotificationsFragment',
        ChatFragment: 'ChatFragment',
        ListChatFragment: 'ListChatFragment',
        ListDocumentFragment: 'ListDocumentFragment',
        DocumentFragment: 'DocumentFragment',
        NotificationFragment: 'NotificationFragment',
        ListQuestionnaireFragment: 'ListQuestionnaireFragment',
        QuestionnaireQuestionFragment: 'QuestionnaireQuestionFragment',
        BadgesFragment: 'BadgesFragment',
        QuestionnaireFragment: 'QuestionnaireFragment',
        ListQuestionnaireResponseFragment: 'ListQuestionnaireResponseFragment',
        QuestionnaireQuestionCategoryFragment: 'QuestionnaireQuestionCategoryFragment',
        QuestionnaireQuestionResponseFragment: 'QuestionnaireQuestionResponseFragment',
        QuestionnaireResponseFragment: 'QuestionnaireResponseFragment',
        MemberActionFragment: 'MemberActionFragment',
        MemberActionsFragment: 'MemberActionsFragment',
        ParentArticleFragment: 'ParentArticleFragment',
        ArticleFragment: 'ArticleFragment',
        RegionFragment: 'RegionFragment'
    }
};

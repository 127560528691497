export type KeyValue = {
    key: string;
    value: any;
};

export const valueForKey = (values: KeyValue[] | null, key: string): any | null => {
    if (values) {
        const i = values?.findIndex(({ key: currKey, value }) => currKey === key);
        if (i >= 0) {
            return values?.[i].value;
        }
    }
    return null;
};

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Popup } from 'semantic-ui-react';

import './Bubble.less';

interface Props {
    id?: string;
    onClick: Function;
    onClose?: Function;
    hidden?: boolean;
    wide?: boolean;
    tooltip?: any;
    children: any;
    offset?: boolean;
}

interface State {
    isHidden: boolean;
}

class Bubble extends PureComponent<Props, State> {
    public state: State = {
        isHidden: true
    };

    public componentDidMount() {
        setTimeout(() => this.setState({ isHidden: false }), 10);
    }

    public render() {
        const { onClick, onClose, hidden, offset, id, tooltip, wide, children } = this.props;

        const classes = classNames(
            'floatingBubble',
            { hidden: hidden || this.state.isHidden },
            { offset },
            { wide: wide && !onClose },
            { close: onClose }
        );

        const Button = (
            <button id={id} className={classes} onClick={(onClose || onClick) as any}>
                {onClose ? <span>&times;</span> : children}
            </button>
        );

        if (!tooltip) {
            return Button;
        }

        return <Popup trigger={Button} content={tooltip} position="top right" />;
    }
}

export default Bubble;

import React, { PureComponent } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { flowRight as compose } from 'lodash';

import { Card, Embed, Image, Modal } from 'semantic-ui-react';

import { RootState } from '../../ducks';

import { closeModal, openVideo } from '../../ducks/modal';

type Props = OwnProps & RouteComponentProps<any> & DispatchProp;

interface OwnProps {
    title: string;
    description: string;
    photoUrl?: string;
    videoHandle?: string;
    highlight?: string;
    button?: any;
    onClick?(): void;
    linkTo?: string;

    fluid?: boolean;
    modalVideoHandle: string;
}

class ArticleCard extends PureComponent<Props> {
    private onClick = () => {
        const { videoHandle, onClick, linkTo, history, dispatch } = this.props;

        if (videoHandle) {
            dispatch(openVideo(videoHandle));
        } else if (onClick) {
            onClick();
        } else if (linkTo) {
            history.push(linkTo);
        }
    };

    private closeModal = () => {
        this.props.dispatch(closeModal());
    };

    public render() {
        const {
            title,
            description,
            photoUrl,
            videoHandle,
            highlight,
            button,
            onClick,
            linkTo,
            fluid,
            modalVideoHandle
        } = this.props;

        return (
            <Card onClick={onClick || linkTo || videoHandle ? this.onClick : undefined} fluid={fluid}>
                {videoHandle && (
                    <Embed
                        id={videoHandle}
                        active={false}
                        source="youtube"
                        placeholder={`https://img.youtube.com/vi/${videoHandle}/hqdefault.jpg`}
                        onClick={this.onClick}
                    />
                )}
                {photoUrl && !videoHandle && <Image src={photoUrl} />}

                <Card.Content>
                    <Card.Header>
                        {!!videoHandle && (
                            <Modal
                                open={!!modalVideoHandle && !!videoHandle && modalVideoHandle === videoHandle}
                                onClose={this.closeModal}
                            >
                                <Modal.Content>
                                    <Embed
                                        active
                                        brandedUI
                                        autoplay
                                        id={videoHandle}
                                        source="youtube"
                                        placeholder={`https://img.youtube.com/vi/${videoHandle}/hqdefault.jpg`}
                                        iframe={{ allowFullScreen: true, allow: 'autoplay; encrypted-media' }}
                                    />
                                </Modal.Content>
                            </Modal>
                        )}
                    </Card.Header>

                    <Card.Description>
                        <h3>{title}</h3>

                        {description && <p>{description}</p>}

                        {highlight && (
                            <p>
                                <small>{highlight}</small>
                            </p>
                        )}

                        {button}
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        modalVideoHandle: state.modal.videoHandle
    };
};

export default compose(withRouter, connect(mapStateToProps))(ArticleCard);

import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FormattedDate as Date, FormattedTime as Time } from 'react-intl';

import { Button, Header, Icon, Image, Modal } from 'semantic-ui-react';

import { Translation } from './Message';
import { APPOINTMENT_EVENT_TYPE_TRANSLATIONS } from '../constants';
import { CalendarEventFragment } from '../graphql-schema';
import t from '../translations';
import ConfirmCancelModal from './ConfirmCancelModal';
import { ReservationMode, ReserveAppointmentModal } from '../views/Appointments/ReserveAppointmentModal';

interface Props {
    calendarEvent: CalendarEventFragment;
    onDismiss: () => void;
    onCancelEvent: () => void;
    loading: boolean;
}
enum ModalState {
    VIEW,
    CONFIRM_CANCEL,
    MODIFY
}

interface State {
    viewState: ModalState;
}

export class CalendarEventModal extends Component<Props, State> {
    public state: State = {
        viewState: ModalState.VIEW
    };

    private onClose = () => {
        this.props.onDismiss();
    };

    private onCancel = () => {
        this.setState({ viewState: ModalState.CONFIRM_CANCEL });
    };

    private onAbortCancel = () => {
        this.setState({ viewState: ModalState.VIEW });
    };

    private onModify = () => {
        this.setState({ viewState: ModalState.MODIFY });
    };

    public render() {
        const { calendarEvent, onCancelEvent, loading } = this.props;
        const { viewState } = this.state;

        const { start, description, isModifiable, calendarReservationAccess, id: calendarEventId } = calendarEvent;

        const location = calendarEvent.location;
        const user = calendarEvent.calendar.user;

        const startsInFuture = moment(start).isAfter();

        const videoAppointmentUUID = calendarEvent.videoAppointmentDetails?.uuid;

        return (
            <div>
                {viewState === ModalState.CONFIRM_CANCEL && (
                    <ConfirmCancelModal
                        loading={loading}
                        onClose={this.onClose}
                        onCancel={onCancelEvent}
                        onAbortCancel={this.onAbortCancel}
                    />
                )}
                {viewState === ModalState.MODIFY && calendarReservationAccess && (
                    <ReserveAppointmentModal
                        reservationAccess={calendarReservationAccess}
                        mode={ReservationMode.MODIFY}
                        dismiss={this.onAbortCancel}
                        onConfirm={this.onClose}
                        cancellableEventId={calendarEventId}
                    />
                )}
                {viewState === ModalState.VIEW && (
                    <Modal open centered={false} size="small" onClose={this.onClose}>
                        <Modal.Header>
                            <Header as="h3">
                                <Translation message={APPOINTMENT_EVENT_TYPE_TRANSLATIONS[calendarEvent.type]} />
                            </Header>
                        </Modal.Header>

                        <Modal.Content>
                            <Header>
                                <Icon name="clock outline" />
                                <Header.Content>
                                    <span style={{ marginRight: '20px' }}>
                                        <Date value={start} />
                                    </span>
                                    <Time value={start} />
                                </Header.Content>
                            </Header>

                            <Header>
                                {user?.avatarSmallUrl ? (
                                    <Image avatar size="tiny" src={user.avatarSmallUrl} />
                                ) : (
                                    <Icon name="user circle outline" />
                                )}

                                {user?.firstName ? (
                                    <Header.Content>
                                        {user.firstName} {user.lastName}
                                        <Header.Subheader>{user.title}</Header.Subheader>
                                    </Header.Content>
                                ) : (
                                    <Header.Content className="newlines">{description}</Header.Content>
                                )}
                            </Header>

                            {location && (
                                <Header>
                                    <Icon name="map pin" />
                                    <Header.Content>
                                        <strong>{location.name}</strong>
                                        <Header.Subheader>
                                            {location.address1} {location.address2}, {location.zip} {location.city}
                                        </Header.Subheader>
                                    </Header.Content>
                                </Header>
                            )}
                        </Modal.Content>

                        <Modal.Actions>
                            {videoAppointmentUUID && (
                                <Link
                                    to={`/meet/${videoAppointmentUUID}`}
                                    className="button-link"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Button primary floated="left" disabled={loading}>
                                        <Translation message={t.appointmentTimeVideoJoin} />
                                    </Button>
                                </Link>
                            )}
                            {startsInFuture && (
                                <Button basic negative onClick={this.onCancel} floated="left" disabled={loading}>
                                    <Translation message={t.appointmentCancel} />
                                </Button>
                            )}
                            {isModifiable && (
                                <Button basic onClick={this.onModify} floated="left" disabled={loading}>
                                    <Translation message={t.appointmentModify} />
                                </Button>
                            )}

                            <Button basic onClick={this.onClose}>
                                <Translation message={t.close} />
                            </Button>
                        </Modal.Actions>
                    </Modal>
                )}
            </div>
        );
    }
}

import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { setContext } from '@apollo/client/link/context';
import { v4 as uuid } from 'uuid';

import { getCookie } from '@heltti/common';

import { config } from './config';
import { logout } from './ducks/auth';
import { apolloClient, reduxStore } from './index';
import { appointmentDaysTypePolicy } from './views/Appointments/useAppointmentDaysHook';

// addEventListener version
window.addEventListener('offline', () => {
    console.warn('The network connection has been lost.');

    window.dispatchEvent(appOfflineEvent);
});

// onoffline version
window.addEventListener('online', () => {
    console.info('The network connection has been restored.');

    window.dispatchEvent(appOnlineEvent);
});

const appOnlineEvent = new Event('app-online');
const appOfflineEvent = new Event('app-offline');

const doLogout = () => {
    logout()(reduxStore.dispatch).catch(error => {
        console.log(error);
    });

    apolloClient.resetStore().catch(error => {
        console.log(error);
    });
};

/**
 * Custom fetch function for adding operation name as query param to requests
 * NOTE:    The parameter is added solely for logging / monitoring / debugging purposes, the actual GraphQL payload
 *          shall be used from the request body
 *
 * @param uri       GraphQL endpoint configured when creating the http link
 * @param options   fetch options
 */
const customFetch = (uri: string, options: RequestInit) => {
    let operationName;

    try {
        operationName = JSON.parse(options.body as unknown as string)?.operationName;
    } catch {
        // No op
    }

    return fetch(`${uri}?o=${operationName}`, options);
};

const httpLink = createUploadLink({
    uri: `${config.apiUrl}/graphql/`,
    credentials: 'same-origin',
    fetch: customFetch
});

const contextLink = setContext(() => ({
    headers: {
        'X-Request-ID': uuid(),
        'X-CSRFToken': getCookie('csrftoken')
    }
}));

const protocol = __ENV__ !== 'development' ? 'wss://' : 'ws://';

export const subscriptionClient = new SubscriptionClient(`${protocol}${window.location.host}${config.wsUrl}`, {
    reconnect: true,
    lazy: true,
    inactivityTimeout: 3000,
    connectionCallback: (errors: Error[]) => {
        // NOTE: The parameter type is wrong for connectionCallback:
        //       the error seems to be single Error (or actually an plain object) not a list of errors
        const error = errors as unknown as Error;

        if (error) {
            console.log(error);

            if (error.message.includes('HTTPUnauthorized')) {
                doLogout();
            }
        }
    }
});

['connected', 'reconnected'].forEach(item => {
    subscriptionClient.on(item, () => {
        console.info('WS ' + item);

        window.dispatchEvent(appOnlineEvent);
    });
});

subscriptionClient.onDisconnected(code => {
    // NOTE: The patched subscription client will fire this event twice, once without code and once with it
    if (code === undefined) {
        return;
    }

    console.info(`WS disconnected (code: ${code})`);

    if (code === 4004) {
        doLogout();
    } else {
        window.dispatchEvent(appOfflineEvent);
    }
});

const wsLink = new WebSocketLink(subscriptionClient);

const cache = new InMemoryCache({
    possibleTypes: {
        ChatAttachment: ['ChatMessageAttachmentNode', 'ChatMessageIdAttachmentNode', 'ChatMessageInteractionNode']
    },
    dataIdFromObject: (o: any) => o.id,
    typePolicies: {
        MemberRootNode: {
            merge: true
        },
        MemberChatSubscription: {
            merge: true
        },
        CalendarReservationAccessNode: {
            fields: {
                appointmentDays: appointmentDaysTypePolicy
            }
        }
    }
});

export const setupWithAuthentication = () => {
    const logoutLink = onError(({ networkError, graphQLErrors }) => {
        const unauthError = graphQLErrors?.find(
            ({ message }) => message === 'You do not have permission to perform this action'
        );
        if (networkError?.message?.includes('403') || unauthError) {
            doLogout();
        }
    });

    const link = split(
        // Split based on operation type
        ({ query }) => {
            const definition = getMainDefinition(query);

            if (definition.kind !== 'OperationDefinition') {
                return false;
            }

            if (definition.operation === 'subscription') {
                return true;
            }

            // False for the rest
            return false;
        },
        wsLink,
        contextLink.concat(logoutLink.concat(httpLink))
    );

    return new ApolloClient({
        link,
        cache
    });
};

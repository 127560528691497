import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { QueryResult } from '@apollo/client';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';

import { Divider, Grid } from 'semantic-ui-react';

import { Error, Loader } from '@heltti/components';

import { ProfileQuery } from '../../graphql-schema';
import { profileQuery } from '../../data/queries';

import translations from '../../translations';

import Card from '../../components/Card';
import { ProfileForm } from '../../components/ProfileForm';
import { Footer } from '../../components/Footer';
import { Content } from '../../components/Content';
import { Page } from '../../components/Page';

import './index.less';

type LocationState = {
    from?: Location;
    nonce?: string;
};

type Props = {
    data: QueryResult & ProfileQuery;
} & WrappedComponentProps &
    RouteComponentProps<any, any, LocationState>;

type State = {
    nonce?: string;
};

export class SignUpIndex extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            nonce: props.history.location.state.nonce
        };
    }

    public onSubmit(): void {
        const { history } = this.props;

        const redirectUrl = history.location.state?.from?.pathname;

        history.replace(redirectUrl ? redirectUrl : '/');
    }

    public render(): React.ReactNode {
        const { data, intl } = this.props;
        const { nonce } = this.state;

        if (data.loading || !data.root?.me) {
            return <Loader />;
        } else if (data.error) {
            return <Error />;
        }

        return (
            <Page id="member-sign-up-form">
                <Content>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Card title={intl.formatMessage(translations.signUpFormTitle)}>
                                    <Divider />

                                    <ProfileForm
                                        initialValues={data.root.me}
                                        onSuccess={() => this.onSubmit()}
                                        hideNameChangeHintPopUp={true}
                                        nonce={nonce}
                                        allowInlinePasswordEditing={true}
                                        allowFeedbackPermissionEditing={false}
                                    />
                                </Card>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Footer />
                </Content>
            </Page>
        );
    }
}

export default compose(injectIntl, graphql(profileQuery))(SignUpIndex);

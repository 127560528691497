import React, { Component } from 'react';

import { Error } from '@heltti/components';

import { getUrlForEident } from '../api';
import { Button } from 'semantic-ui-react';
import { Translation } from './Message';
import messages from '../translations';

type State = {
    showError: boolean;
};

export default class VerifyIdentityButton extends Component<{}> {
    public state: State = {
        showError: false
    };

    private onVerifyButtonClicked = () => {
        getUrlForEident()
            .then(response => {
                window.location = response.data.result.url;
            })
            .catch(_ => {
                this.setState({ showError: true });
            });
    };

    private onErrorDismiss = () => {
        this.setState({ showError: false });
    };

    public render() {
        const { showError } = this.state;

        return (
            <div>
                {showError && <Error onDismiss={this.onErrorDismiss} />}

                <Button primary size="medium" onClick={this.onVerifyButtonClicked}>
                    <Translation message={messages.loginVerificationButtonTitle} />
                </Button>
            </div>
        );
    }
}

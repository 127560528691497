import React, { PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import classNames from 'classnames';
import LiveChatBubble from '../views/Chat/LiveChatBubble';

import './BubbleContainer.less';

type Props = RouteComponentProps<any>;

class BubbleContainer extends PureComponent<Props> {
    public render() {
        const { location } = this.props;

        const classes = classNames(
            { hidden: location.pathname.match('/questionnaire/') },
            { hidden: location.pathname.match('/chats/(.+)') },
            { hidden: location.pathname.match('/(support|faq)') }
        );

        return (
            <div id="floatingBubbles" className={classes}>
                <LiveChatBubble />
            </div>
        );
    }
}

export default withRouter(BubbleContainer);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { Divider, Header, Modal } from 'semantic-ui-react';
import { Error as ErrorComponent, useLoadingQuery } from '@heltti/components';

import { mapMultipleBy } from '@heltti/common';
import {
    AppointmentContactType,
    AppointmentFilters,
    AppointmentFragment,
    CalendarReservationAccessFragment,
    CalendarReservationAppointmentsQuery,
    LocationFragment,
    Maybe,
    RegionFragment,
    RegionsQuery
} from '../../graphql-schema';
import messages from '../../translations';
import t from '../../translations/index';
import { calendarReservationAppointmentsQuery, regionsQuery } from '../../data/queries';
import { Translation } from '../../components/Message';
import { ReserveAppointmentModalDateSelect } from './ReserveAppointmentModalDateSelect';
import { getNodes } from '../../util/object';
import { ReserveAppointmentModalRegionMenu } from './ReserveAppointmentModalRegionMenu';
import { ReserveAppointmentModalAppointmentList } from './ReserveAppointmentModalAppointmentList';
import { ReserveAppointmentModalLocationSelect } from './ReserveAppointmentModalLocationSelect';

const includesRemoteType = (contactTypes: readonly Maybe<AppointmentContactType>[] | AppointmentContactType[]) => {
    return contactTypes.includes(AppointmentContactType.Phone) || contactTypes.includes(AppointmentContactType.Video);
};

type Props = {
    calendarReservationAccess: CalendarReservationAccessFragment;

    onSelectAppointment: (appointment: AppointmentFragment) => void;
};

export const ReserveAppointmentModalAvailableAppointmentsList: React.FC<Props> = props => {
    const { calendarReservationAccess, onSelectAppointment } = props;

    const [selectedRegion, setSelectedRegion] = useState<RegionFragment | undefined>(undefined);
    const [selectedLocation, setSelectedLocation] = useState<LocationFragment | undefined>(undefined);
    const [showRemoteOnly, setShowRemoteOnly] = useState(false);
    const [selectedDate, setSelectedDate] = useState<string | null | undefined>(undefined);

    const remoteAvailable = useMemo(
        () => includesRemoteType(calendarReservationAccess.contactTypes),
        [calendarReservationAccess.contactTypes]
    );
    const remoteOnly = useMemo(
        () =>
            calendarReservationAccess.contactTypes.find(contactType => contactType === AppointmentContactType.Visit) ===
            undefined,
        [calendarReservationAccess.contactTypes]
    );
    const filters: AppointmentFilters = useMemo(
        () => (remoteOnly ? { contactTypes: [AppointmentContactType.Video, AppointmentContactType.Phone] } : {}),
        [remoteOnly]
    );

    const {
        data: regionData,
        loading: loadingRegions,
        error: regionError
    } = useLoadingQuery<RegionsQuery>(regionsQuery);

    const {
        data: appointmentData,
        loading: loadingAppointments,
        error: appointmentError
    } = useLoadingQuery<CalendarReservationAppointmentsQuery>(calendarReservationAppointmentsQuery, {
        variables: {
            craId: calendarReservationAccess.id,
            start: moment(selectedDate, 'l').startOf('day').format('YYYY-MM-DD'),
            end: moment(selectedDate, 'l').endOf('day').format('YYYY-MM-DD'),
            regionId: selectedRegion?.id,
            locationId: selectedLocation?.id,
            filters
        },
        skip: !selectedRegion || !selectedDate
    });

    const regions = useMemo(() => {
        return regionData?.root?.regions ?? [];
    }, [regionData?.root?.regions]);

    const locations = useMemo(() => {
        return getNodes(selectedRegion?.locations);
    }, [selectedRegion]);

    const loading = loadingAppointments || loadingRegions;
    const error = appointmentError ?? regionError;

    // Sort by times -- may arrive out of order
    let sortedAppointments =
        appointmentData?.root?.calendarReservationAccess.appointments
            .filter(item => item)
            .sort((a, b) => a.start.localeCompare(b.start)) ?? [];

    // Filter by location
    if (selectedLocation) {
        sortedAppointments = sortedAppointments.filter(appointment => {
            return appointment.location?.name === selectedLocation.name;
        });
    }
    if (showRemoteOnly) {
        sortedAppointments = sortedAppointments.filter(appointment => includesRemoteType(appointment.contactTypes));
    }

    // Group by hour
    const appointmentsByHour = Array.from(
        mapMultipleBy(sortedAppointments, appointment => moment(appointment.start).format('H')).entries()
    );

    // There are no dates available when selectedDate === null, if it's undefined we don't know yet
    const noAppointmentsAvailable = !loading && selectedDate === null && appointmentsByHour.length === 0;

    /**
     * Select callbacks
     **/

    const onSelectDate = useCallback((selectDate: string) => {
        setSelectedDate(selectDate);
    }, []);

    const onSelectRegion = useCallback((region: RegionFragment) => {
        setSelectedLocation(undefined);
        setSelectedRegion(region);
    }, []);

    const onSelectLocation = useCallback(
        (locationId?: string) => {
            setSelectedLocation(locationId ? locations.find(location => location.id === locationId) : undefined);
            setSelectedDate(null);
        },
        [locations]
    );

    const onShowRemoteOnlyToggle = useCallback((remoteOnly: boolean) => {
        setSelectedLocation(undefined);
        setSelectedDate(null);
        setShowRemoteOnly(remoteOnly);
    }, []);

    useEffect(() => {
        if (!selectedRegion && regions.length > 0) {
            setSelectedRegion(regions[0]);
        }
        if (remoteOnly && !showRemoteOnly) {
            setShowRemoteOnly(remoteOnly);
        }
    }, [regions, selectedRegion, remoteOnly, showRemoteOnly]);

    if (error) {
        return (
            <Modal.Content>
                <ErrorComponent title={error.name} description={error.message} />
            </Modal.Content>
        );
    }

    return (
        <>
            <Modal.Content>
                {!remoteOnly && !loadingRegions && (
                    <ReserveAppointmentModalRegionMenu
                        regions={regions}
                        onSelect={onSelectRegion}
                        selectedRegion={selectedRegion}
                    />
                )}

                {!!selectedRegion && (
                    <ReserveAppointmentModalLocationSelect
                        locations={locations}
                        onLocationSelect={onSelectLocation}
                        selectedLocation={selectedLocation}
                        remoteAvailable={remoteAvailable}
                        remoteOnly={showRemoteOnly}
                        showRemoteOnly={showRemoteOnly}
                        onShowRemoteOnlyToggle={onShowRemoteOnlyToggle}
                    />
                )}

                {selectedRegion && (
                    <ReserveAppointmentModalDateSelect
                        craId={calendarReservationAccess.id}
                        selectedDate={selectedDate}
                        onSelectDate={onSelectDate}
                        regionId={selectedRegion?.id}
                        locationId={selectedLocation?.id}
                        showRemoteOnly={showRemoteOnly}
                    />
                )}
            </Modal.Content>

            <Modal.Content scrolling>
                <div className="hideScrollbars">
                    <Divider hidden />

                    {loading && (
                        <div>
                            <Translation message={t.loading} />
                        </div>
                    )}

                    {noAppointmentsAvailable && <Translation message={messages.appointmentsNotAvailable} />}

                    {appointmentsByHour.map(([hour, hourAppointments]) => (
                        <div key={hour} style={{ marginBottom: '20px' }}>
                            <Header color="grey">{hour}</Header>

                            <ReserveAppointmentModalAppointmentList
                                appointments={hourAppointments}
                                onSelect={onSelectAppointment}
                            />
                        </div>
                    ))}
                </div>
            </Modal.Content>
        </>
    );
};

import { CalendarEventType } from './graphql-schema';
import t from './translations';

export const APPOINTMENT_EVENT_TYPE_TRANSLATIONS = {
    [CalendarEventType.Appointment]: t.appointmentTime,
    [CalendarEventType.AppointmentAvailable]: t.appointmentTime,
    [CalendarEventType.AppointmentAcute]: t.appointmentTimeAcute,
    [CalendarEventType.AppointmentAcuteAvailable]: t.appointmentTimeAcute,
    [CalendarEventType.AppointmentLaboratory]: t.appointmentTimeLaboratory,
    [CalendarEventType.AppointmentLaboratoryAvailable]: t.appointmentTimeLaboratory,
    [CalendarEventType.AppointmentPhone]: t.appointmentTimePhone,
    [CalendarEventType.AppointmentPhoneAvailable]: t.appointmentTimePhone,
    [CalendarEventType.AppointmentVideo]: t.appointmentTimeVideo,
    [CalendarEventType.AppointmentVideoAvailable]: t.appointmentTimeVideo
};

// NOTE: remember to keep these in sync with variables.less
export const SCREEN_WIDTH_MAX_MOBILE = 767;
export const SCREEN_WIDTH_MAX_TABLET = 1024;

export const SIGN_UP_PREFIX = '/signUp';

export const paths = {
    open: '/open', // Path prefix for deeplinks

    welcome: '/welcome',
    profile: '/profile',
    startHere: '/startHere',

    paths: '/paths',
    home: '/',
    login: '/login',
    notVerifiedIndex: '/notVerified',

    // NOTE: Don't change these without changing the paths in back-end to match.
    eIdentIndex: SIGN_UP_PREFIX + '/index',
    eIdentError: SIGN_UP_PREFIX + '/error',
    eIdentCallback: '/e-ident',
    signUpIndex: SIGN_UP_PREFIX + '/edit-profile-form'
};

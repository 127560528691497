import React from 'react';
import { FunctionComponent } from 'react';
import { FormatMarkdown } from '../../components/FormatMarkdown';

import { ChatNotificationFragment } from '../../graphql-schema';
import { valueForKey } from '../../util/keyvalue';

type ChatNotificatonInlineMessagesProps = {
    inlines: ChatNotificationFragment[];
};

const ChatNotificationInlineMessages: FunctionComponent<ChatNotificatonInlineMessagesProps> = props => {
    const { inlines } = props;

    return (
        <>
            {inlines.map(({ id, contents }) => {
                const message = valueForKey(contents, 'message');
                return (
                    <div key={id} className="messageSeparator">
                        <div>{contents && <FormatMarkdown source={message} />}</div>
                    </div>
                );
            })}
        </>
    );
};

export default ChatNotificationInlineMessages;

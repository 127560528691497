import { gql } from '@apollo/client';
import {
    appointmentDayFragment,
    appointmentFragment,
    articleFragment,
    badgesFragment,
    calendarEventFragment,
    calendarReservationAccessFragment,
    chatFragment,
    chatListFragment,
    chatMessageAttachmentFragment,
    chatMessageFragment,
    documentFragment,
    listDocumentFragment,
    listQuestionnaireResponseFragment,
    memberActionsFragment,
    notificationFragment,
    profileFragment,
    questionnaireFragment,
    questionnaireResponseFragment,
    regionFragment,
    uiInteractionFragment
} from './fragments';

export const signUpTypeQuery = gql`
    query SignUpTypeQuery($token: String!) {
        token(token: $token) {
            signUpType
        }
    }
`;

export const globalInteractionsQuery = gql`
    query GlobalInteractionsQuery {
        root {
            interactions {
                ...UIInteractionFragment
            }
        }
    }

    ${uiInteractionFragment}
`;

export const calendarEventsQuery = gql`
    query CalendarEventsQuery($start: DateTime, $end: DateTime) {
        root {
            calendarEvents(start: $start, end: $end, first: 10) {
                edges {
                    node {
                        ...CalendarEventFragment
                    }
                }
            }
        }
    }

    ${calendarEventFragment}
`;

export const chatQuery = gql`
    query ChatQuery($chatID: ID!) {
        root {
            chat(id: $chatID) {
                ...ChatFragment
            }
        }
    }

    ${chatFragment}
`;

export const chatMessagesQuery = gql`
    query ChatMessagesQuery($chatID: ID!, $messagesAfter: String, $messagesAfterLastMessageAt: DateTime) {
        root {
            chat(id: $chatID) {
                id
                lastMessageAt

                messages(first: 30, after: $messagesAfter, messagesAfterLastMessageAt: $messagesAfterLastMessageAt) {
                    edges {
                        node {
                            ...ChatMessageFragment

                            attachmentsV2 {
                                ...ChatMessageAttachmentFragment
                            }
                        }
                    }

                    pageInfo {
                        hasPreviousPage
                        hasNextPage
                    }
                }
            }
        }
    }

    ${chatMessageFragment}
    ${chatMessageAttachmentFragment}
`;

export const chatsQuery = gql`
    query ChatsQuery($recent: Boolean, $unseen: Boolean, $live: Boolean) {
        root {
            chats(recent: $recent, unseen: $unseen, live: $live) {
                edges {
                    node {
                        ...ListChatFragment
                    }
                }
            }
        }
    }

    ${chatListFragment}
`;

export const profileQuery = gql`
    query ProfileQuery {
        root {
            me {
                ...ProfileFragment
            }
        }
    }

    ${profileFragment}
`;

export const documentsQuery = gql`
    query DocumentsQuery($unseen: Boolean) {
        root {
            documents(unseen: $unseen) {
                edges {
                    node {
                        ...ListDocumentFragment
                    }
                }
            }
        }
    }

    ${listDocumentFragment}
`;

export const documentQuery = gql`
    query DocumentQuery($documentId: ID!) {
        root {
            document(id: $documentId) {
                ...DocumentFragment
            }
        }
    }

    ${documentFragment}
`;

export const notificationsQuery = gql`
    query NotificationsQuery {
        root {
            notifications {
                edges {
                    node {
                        ...NotificationFragment
                    }
                }
            }
        }
    }

    ${notificationFragment}
`;

export const questionnaireResponseQuery = gql`
    query QuestionnaireResponseQuery($id: ID!) {
        root {
            questionnaireResponse(id: $id) {
                ...QuestionnaireResponseFragment

                questionnaire {
                    ...QuestionnaireFragment
                }
            }
        }
    }

    ${questionnaireResponseFragment}
    ${questionnaireFragment}
`;

export const questionnaireResponseBatchWithTokenQuery = gql`
    query QuestionnaireResponseBatchWithTokenQuery($memberId: ID!, $batchId: ID!, $token: String!) {
        token(token: $token) {
            questionnaireResponseBatch(memberId: $memberId, batchId: $batchId) {
                id
                message
                openAt
                closeAt

                questionnaireResponses {
                    edges {
                        node {
                            ...QuestionnaireResponseFragment

                            questionnaire {
                                ...QuestionnaireFragment
                            }
                        }
                    }
                }
            }
        }
    }

    ${questionnaireResponseFragment}
    ${questionnaireFragment}
`;

export const questionnaireQuery = gql`
    query QuestionnaireQuery($id: ID!) {
        root {
            questionnaire(id: $id) {
                ...QuestionnaireFragment
            }
        }
    }

    ${questionnaireFragment}
`;

export const questionnaireResponseBatchesQuery = gql`
    query QuestionnaireResponseBatchesQuery {
        root {
            questionnaireResponseBatches {
                edges {
                    node {
                        id
                        openAt
                        closeAt
                        message

                        questionnaireResponses {
                            edges {
                                node {
                                    ...ListQuestionnaireResponseFragment
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    ${listQuestionnaireResponseFragment}
`;

export const memberActionsQuery = gql`
    query MemberActionsQuery {
        root {
            availableActions {
                ...MemberActionsFragment
            }
        }
    }

    ${memberActionsFragment}
`;

export const articleQuery = gql`
    query ArticleQuery($id: ID, $tag: String) {
        root {
            article(id: $id, tag: $tag) {
                ...ArticleFragment
            }
        }
    }

    ${articleFragment}
`;

export const selfHelpCourseQuery = gql`
    query SelfHelpCourseQuery($id: ID!) {
        root {
            selfHelpCourse(id: $id) {
                id
                title
            }
        }
    }
`;

export const calendarReservationAppointmentsQuery = gql`
    query CalendarReservationAppointmentsQuery(
        $craId: ID!
        $start: Date!
        $end: Date!
        $regionId: ID
        $locationId: ID
        $filters: AppointmentFilters
    ) {
        root {
            calendarReservationAccess(id: $craId) {
                id

                appointments(
                    start: $start
                    end: $end
                    regionId: $regionId
                    locationId: $locationId
                    filters: $filters
                ) {
                    ...AppointmentFragment
                }
            }
        }
    }

    ${appointmentFragment}
`;

export const calendarReservationAppointmentDaysQuery = gql`
    query CalendarReservationAppointmentDaysQuery(
        $craId: ID!
        $start: Date!
        $end: Date!
        $regionId: ID
        $locationId: ID
        $filters: AppointmentFilters
    ) {
        root {
            calendarReservationAccess(id: $craId) {
                id

                appointmentDays(
                    start: $start
                    end: $end
                    regionId: $regionId
                    locationId: $locationId
                    filters: $filters
                ) {
                    ...AppointmentDayFragment
                }
            }
        }
    }

    ${appointmentDayFragment}
`;

export const calendarReservationAccessesQuery = gql`
    query CalendarReservationAccessesQuery {
        root {
            calendarReservationAccesses {
                ...CalendarReservationAccessFragment
            }
        }
    }

    ${calendarReservationAccessFragment}
`;

export const globalCalendarReservationAccessesQuery = gql`
    query GlobalCalendarReservationAccessesQuery {
        root {
            globalCalendarReservationAccesses {
                ...CalendarReservationAccessFragment
            }
        }
    }

    ${calendarReservationAccessFragment}
`;

export const badgesQuery = gql`
    query BadgesQuery {
        root {
            badges {
                ...BadgesFragment
            }
        }
    }
    ${badgesFragment}
`;

export const regionsQuery = gql`
    query RegionsQuery {
        root {
            regions {
                ...RegionFragment
            }
        }
    }
    ${regionFragment}
`;

export const videoMeetingInfoQuery = gql`
    query VideoMeetingInfoQuery($meetingUUID: UUID!) {
        public {
            meetingInformation(meetingUuid: $meetingUUID) {
                uuid
                provider
                url
            }
        }
    }
`;

const getCursorForIndex = index => btoa(`arrayconnection:${index}`);

export const getCursorForLastEdge = edges => getCursorForIndex(edges.length - 1);
export const getCursorForFirstEdge = () => getCursorForIndex(0);

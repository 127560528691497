import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';

import { Button } from 'semantic-ui-react';
import { isValidGlobalId, parseDbId, getNodes } from '@heltti/common';
import {
    TopNavigationBar,
    TopNavigationBarItem,
    TopNavigationBarItemLink,
    TopNavigationBarSubmenu,
    TopNavigationBarSubmenuItem,
    TopNavigationBarSubmenuItemDivider,
    TopNavigationBarSubmenuItemLink,
    useDeviceType,
    useDocumentTitle,
    useLoadingQuery
} from '@heltti/components';

import t from '../translations';
import { ProfileQuery } from '../graphql-schema';
import { profileQuery } from '../data/queries';
import { logout } from '../ducks/auth';
import { useBadges } from '../hooks/useBadges';
import { helttiViewHostName } from '../util/hostname';
import { paths } from '../constants';

import './Menu.less';

export const HelttiMenu: React.FC = () => {
    const history = useHistory();
    const intl = useIntl();
    const dispatch = useDispatch();
    const onLogout = useCallback(() => logout?.()(dispatch), [dispatch, logout]);

    const { data: profileData } = useLoadingQuery<ProfileQuery>(profileQuery);
    const { mobile, tablet } = useDeviceType();

    const { id, firstName = null, hasCompanyAccess = false, employments } = profileData?.root?.me ?? {};
    const [notificationCount, setNotificationCount] = useState(0);
    const { unseenChatMessages, unseenDocuments, openCalendarReservationAccesses, openQuestionnaires } = useBadges();

    const hasActiveEmployment = getNodes(employments).length > 0;

    useEffect(() => {
        try {
            Sentry.setUser({ id: !!id && isValidGlobalId(id) ? `${parseDbId(id)}` : undefined });
        } catch (e) {
            // Just ignore the error in case id parsing fails
        }
    }, [id]);

    useEffect(() => {
        setNotificationCount(
            (unseenChatMessages ?? 0) +
                (unseenDocuments ?? 0) +
                (openCalendarReservationAccesses ?? 0) +
                (openQuestionnaires ?? 0)
        );
    }, [unseenChatMessages, unseenDocuments, openCalendarReservationAccesses, openQuestionnaires]);

    useDocumentTitle(`MyHeltti${notificationCount > 0 ? ` (${notificationCount})` : ''}`);

    return mobile ? (
        <TopNavigationBar>
            <TopNavigationBarItemLink title={intl.formatMessage(t.menuHome)} exact to="/" iconName="home" />
            <TopNavigationBarItemLink
                title={intl.formatMessage(t.menuPaths)}
                to="/paths"
                iconName="comments outline"
                notificationCount={notificationCount}
            />
            <TopNavigationBarItem>
                <Button
                    style={{ boxShadow: '0 5px 5px #ccc', height: '40px', width: '40px', padding: 0 }}
                    circular
                    color="red"
                    icon="plus"
                    onClick={() => {
                        history.push(paths.startHere);
                    }}
                />
            </TopNavigationBarItem>
            {hasActiveEmployment && (
                <TopNavigationBarItemLink title={intl.formatMessage(t.menuExplore)} to="/explore" iconName="idea" />
            )}
            <TopNavigationBarItemLink title={intl.formatMessage(t.menuProfile)} to="/profile" iconName="user" />
        </TopNavigationBar>
    ) : (
        <TopNavigationBar>
            <TopNavigationBarItemLink title={intl.formatMessage(t.menuHome)} exact to="/" />
            <TopNavigationBarItemLink
                title={intl.formatMessage(t.menuPaths)}
                to="/paths"
                iconName="comments"
                notificationCount={notificationCount}
            />
            {hasActiveEmployment && (
                <TopNavigationBarItemLink title={intl.formatMessage(t.menuExplore)} to="/explore" iconName="idea" />
            )}
            <TopNavigationBarSubmenu
                title={tablet || !firstName ? intl.formatMessage(t.menuProfile) : firstName}
                position="right"
            >
                <TopNavigationBarSubmenuItemLink
                    title={intl.formatMessage(t.menuProfile)}
                    to="/profile"
                    className="profile-link"
                />
                {hasCompanyAccess && (
                    <>
                        <TopNavigationBarSubmenuItem
                            title="HelttiView"
                            as={'a'}
                            href={`${helttiViewHostName()}/home`}
                            iconName="external"
                            className="external"
                        />
                        <TopNavigationBarSubmenuItemDivider />
                    </>
                )}
                <TopNavigationBarSubmenuItem
                    title={intl.formatMessage(t.logoutButton)}
                    onClick={onLogout}
                    iconName="log out"
                />
            </TopNavigationBarSubmenu>
            <TopNavigationBarItem>
                <Button
                    primary
                    color="red"
                    onClick={() => {
                        history.push(paths.startHere);
                    }}
                >
                    {intl.formatMessage(t.contactFlowButtonTitle)}
                </Button>
            </TopNavigationBarItem>
        </TopNavigationBar>
    );
};

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Content } from '../../components/Content';
import t from '../../translations';
import Article from '../Article';
import { Page } from '../../components/Page';
import { CompactHeaderWithNavigation } from '../../components/CompactHeaderWithNavigation';
import { Translation } from '../../components/Message';

type RouteMatchProps = {
    id: string;
};

type Props = RouteComponentProps<RouteMatchProps>;

export const FAQPage: React.FC<Props> = props => {
    const handleGoBack = () => {
        props.history.goBack();
    };

    const { id } = props.match.params;
    const tagOrNull = id ? null : 'root_faq';

    return (
        <Page subNavigation id="faq">
            <CompactHeaderWithNavigation
                backTo={handleGoBack}
                title={<Translation message={t.supportFAQPageHeader} />}
                color="red"
            />

            <Content containerWidth="wide">
                <div className="scrollableContent">
                    <Article id={id} tag={tagOrNull} urlPath="faq" />
                </div>
            </Content>
        </Page>
    );
};

import { Maybe } from '../graphql-schema';

/**
 * Filters null and undefined edges and nodes from a Relay style edges list, returns an array of non null nodes
 *
 * @param connection     a connection field result fetched using Apollo / graphql
 */
export const getNodes = <T>(connection: RelayConnection<T>): T[] => {
    return connection?.edges?.flatMap(edge => (edge?.node ? [edge.node] : [])) ?? [];
};

export type RelayNode<T> = { readonly __typename: string } & Partial<T>;
export type RelayEdge<T> = Maybe<{ readonly node?: Maybe<T> }>;
export type RelayConnection<T> = Maybe<{ readonly edges?: readonly Maybe<RelayEdge<T>>[] }> | undefined;

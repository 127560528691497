import React, { PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { QueryResult } from '@apollo/client';
import { graphql } from '@apollo/react-hoc';
import { last, flowRight as compose } from 'lodash';

import { chatsQuery } from '../../data/queries';
import { ChatsQuery, ChatType } from '../../graphql-schema';
import Bubble from './../../components/Bubble';

import './LiveChatBubble.less';

import popSound from './../../assets/sounds/Heltti-Pop1.wav';

type Props = OwnProps & RouteComponentProps<any>;

interface OwnProps {
    data: QueryResult & ChatsQuery;
}

const getActiveLiveChat = chats =>
    chats && chats.edges.map(e => e.node).filter(n => n.type === ChatType.A_1 && !n.closedAt)[0];

class LiveChatBubble extends PureComponent<Props> {
    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, prevContext: any): void {
        const { data: oldData } = prevProps;
        const { data } = this.props;
        const { page, id: pageId } = this.props.match.params;

        if (
            ((page === 'chats' && pageId === undefined) || page !== 'chats') &&
            oldData.root &&
            oldData.root.chats &&
            data.root &&
            data.root.chats
        ) {
            const oldChats = oldData?.root?.chats?.edges.filter(edge => !!edge && !!edge.node).map(edge => edge!.node!);
            const chats = data?.root?.chats?.edges.filter(edge => !!edge && !!edge.node).map(edge => edge!.node!);

            const newMessages = chats.map(chat => {
                const oldChat = oldChats.find(({ id }) => id === chat.id);

                if (oldChat) {
                    const [oldMessageEdge] = oldChat?.messages?.edges ?? [];
                    const [newMessageEdge] = chat?.messages?.edges ?? [];

                    if (!oldMessageEdge && newMessageEdge) {
                        return true;
                    } else if (
                        oldMessageEdge &&
                        newMessageEdge &&
                        oldMessageEdge.node?.id !== newMessageEdge.node?.id &&
                        !newMessageEdge.node?.seen
                    ) {
                        return true;
                    }
                }

                return false;
            });

            if (newMessages.indexOf(true) >= 0) {
                const sound = new Audio(popSound);

                sound.play().catch(e => {
                    // Sorry, no sound.
                });
            }
        }
    }

    private goToLiveChat = () => {
        const chat = getActiveLiveChat(this.props.data?.root?.chats);

        if (!chat) {
            return false;
        }

        this.props.history.push(`/chats/${chat.id}`);
    };

    public render() {
        const { data } = this.props;

        if (data.loading || data.error || !data.root) {
            return null;
        }

        const chat = getActiveLiveChat(data.root.chats);

        if (!chat) {
            return null;
        }

        const users = chat && chat.users.edges.map(e => e.node).filter(n => !n.leftAt);
        const user = users && users.length > 0 && (last(users) as any).user;

        return (
            <Bubble id="liveChatBubble" onClick={this.goToLiveChat} offset tooltip={chat.title}>
                {user && <img src={user.avatarSmallUrl} alt={user.firstName} />}
            </Bubble>
        );
    }
}

export default compose(withRouter, graphql(chatsQuery, { options: { variables: { live: true } } }))(LiveChatBubble);

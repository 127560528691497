import React from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { FormatMarkdown } from './FormatMarkdown';

type Props = {
    message: MessageDescriptor;
};

export const Translation: React.FC<Props> = props => {
    if (!props.message || !props.message.id) {
        // tslint:disable:no-console
        console.error('Missing translation message');
        // tslint:enable

        return null;
    }

    return <FormattedMessage id={props.message.id} />;
};

export const MarkdownTranslation: React.FC<Props> = props => {
    const { formatMessage } = useIntl();

    if (!props.message || !props.message.id) {
        // tslint:disable:no-console
        console.error('Missing translation message');
        // tslint:enable

        return null;
    }

    return <FormatMarkdown source={formatMessage(props.message)} />;
};

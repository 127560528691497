import React, { Component } from 'react';

import './Notification.less';

interface Props {
    children?: any;
}

export default class Notification extends Component<Props> {
    public render() {
        const { children } = this.props;

        return <span className="notification">{children}</span>;
    }
}

import React, { PureComponent } from 'react';
import cx from 'classnames';
import './Card.less';

interface Props {
    children: any;
    title?: string | JSX.Element;
    subTitle?: string;
    footer?: any;
    callToAction?: any;
    className?: string;
    backgroundColor?: 'blueLight' | 'white';
    imageUrl?: string;
}

export default class Card extends PureComponent<Props> {
    public render() {
        const { children, title, subTitle, footer, className, callToAction, backgroundColor, imageUrl } = this.props;

        const classNames = cx('card', { [backgroundColor]: backgroundColor }, className, { illustrated: imageUrl });

        return (
            <div className={classNames}>
                <div className="content">
                    {title && (
                        <div className="title">
                            {title}

                            {subTitle && <div className="subTitle">{subTitle}</div>}
                        </div>
                    )}
                    {callToAction && <div className="action">{callToAction}</div>}
                    {imageUrl && <img src={imageUrl} className="illustration" />}

                    <div className="description">{children}</div>
                </div>

                {footer && <footer>{footer}</footer>}
            </div>
        );
    }
}

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Button, Card, Divider, Header, Image } from 'semantic-ui-react';

import { Page } from '../../components/Page';
import { Content } from '../../components/Content';
import messages from '../../translations/index';
import { Translation } from '../../components/Message';

import './eident.less';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import supportIcon from '../../assets/images/tuki.png';

type Props = RouteComponentProps & WrappedComponentProps;

export class EidentErrorPage extends Component<Props> {
    private supportButtonClicked = () => {
        const { intl } = this.props;

        window.location.href = 'mailto:' + intl.formatMessage(messages.helttiTechnicalSupportEmail);
    };

    public render() {
        return (
            <Page id="e-indent-error-page">
                <Content containerWidth="wide">
                    <Card fluid centered>
                        <Card.Content>
                            <Card.Header>
                                <Header as="h1">
                                    <Translation message={messages.signUpErrorTitle} />
                                </Header>
                            </Card.Header>

                            <Divider hidden />

                            <Image src={supportIcon} />

                            <Card.Description>
                                <Divider hidden />
                                <Translation message={messages.signUpErrorDescription} />
                            </Card.Description>

                            <Button negative onClick={this.supportButtonClicked}>
                                <Translation message={messages.signUpErrorContactSupport} />
                            </Button>
                        </Card.Content>
                    </Card>
                </Content>
            </Page>
        );
    }
}

export default injectIntl(EidentErrorPage);

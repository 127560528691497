import React, { Component } from 'react';
import { Button, Divider, Form, Input, Message } from 'semantic-ui-react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import isEmail from 'validator/lib/isEmail';

import t from '../../translations';
import { forgotPassword } from '../../ducks/password';
import { Translation } from '../../components/Message';
import VerifyIdentityButton from '../../components/VerifyIdentityButton';

type Props = OwnProps & WrappedComponentProps;

interface OwnProps {
    forgotPassword: Function;
    email?: string;
}

interface State {
    email: string;
    error?: Error;
    isSent: boolean;
    isLoading: boolean;
    useStrongIdentification: boolean;
}

class PasswordForgotForm extends Component<Props, State> {
    public state: State = {
        email: '',
        error: null,
        isSent: false,
        isLoading: false,
        useStrongIdentification: false
    };

    public componentDidMount() {
        if (this.props.email) {
            this.setState({ email: this.props.email });
        }
    }

    private handleChange = (e: any, { value }: any) => {
        this.setState({ [e.target.name]: value } as State);
    };

    private handleSubmit = () => {
        this.setState({ isLoading: true, error: null });

        this.props
            .forgotPassword(this.state.email)
            .catch(error => {
                this.setState({ error });
            })
            .then(result => {
                if (result) {
                    if (result.success === false && result.result === 'use_strong_identification') {
                        this.setState({ useStrongIdentification: true });
                    } else {
                        this.setState({ isSent: true });
                    }
                }
            });
    };

    public render() {
        const { intl } = this.props;
        const { error, isSent, isLoading, useStrongIdentification, email } = this.state;

        if (isSent) {
            return (
                <div style={{ marginTop: '40px' }}>
                    <Message success>
                        <Translation message={t.forgotPasswordEmailSent} />
                    </Message>

                    <p>
                        <Translation message={t.forgotPasswordEmailSentHelp} />
                        &nbsp;
                        <a href={`mailto:${intl.formatMessage(t.helttiTechnicalSupportEmail)}`}>
                            <Translation message={t.helttiTechnicalSupportEmail} />
                        </a>
                    </p>
                </div>
            );
        } else if (useStrongIdentification) {
            return (
                <div>
                    <Message warning style={{ marginTop: '40px' }}>
                        <Translation message={t.forgotPasswordUseStrongIdentification} />
                    </Message>

                    <Divider hidden />

                    <VerifyIdentityButton />
                </div>
            );
        }

        return (
            <div style={{ marginTop: '40px' }}>
                <Form error onSubmit={this.handleSubmit}>
                    <h3>
                        <Translation message={t.forgotPasswordTitle} />
                    </h3>

                    {error && (
                        <Message error>
                            <Translation message={t.errorGeneralDescription} />
                        </Message>
                    )}

                    <Form.Field>
                        <Input
                            name="email"
                            value={email}
                            onChange={this.handleChange}
                            placeholder={intl.formatMessage(t.forgotPasswordEmail)}
                        />
                    </Form.Field>

                    <Button fluid primary size="large" disabled={!isEmail(email) || isLoading}>
                        <Translation message={t.forgotPasswordSend} />
                    </Button>
                </Form>
            </div>
        );
    }
}

export default connect(state => ({}), {
    forgotPassword
})(injectIntl(PasswordForgotForm));

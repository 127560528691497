import React, { FunctionComponent } from 'react';
import { flowRight as compose } from 'lodash';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import { Label, Menu } from 'semantic-ui-react';

import { Footer } from '../../components/Footer';
import { SCREEN_WIDTH_MAX_MOBILE } from '../../constants';
import { openFlow as openFlowAction } from '../../ducks/flow';
import { Appointments } from '../Appointments';
import Documents from '../Documents';
import { Content } from '../../components/Content';
import t from './../../translations';
import { Translation } from '../../components/Message';
import { RootState } from '../../ducks';
import PrivateRoute from '../../components/PrivateRoute';
import NotEmployeeRoute from '../../components/NotEmployeeRoute';
import { useBadges } from '../../hooks/useBadges';
import { ChatsHighlight } from './ChatsHighlight';
import { QuestionnaireList } from '../Questionnaire/QuestionnaireList';
import { AuthState } from '../../ducks/auth';

import './paths.less';

type RedLabelProps = { value: number };

const RedLabel: FunctionComponent<RedLabelProps> = props => (
    <Label circular color="red" size="tiny">
        {props.value}
    </Label>
);

interface OwnProps {
    memberAuthState: AuthState;
}

type Props = OwnProps & RouteComponentProps<any>;

const Paths: FunctionComponent<Props> = props => {
    const { memberAuthState } = props;

    const { unseenChatMessages, unseenDocuments, openCalendarReservationAccesses, openQuestionnaires } = useBadges();

    return (
        <div id="paths">
            <Menu secondary pointing widths={window.innerWidth <= SCREEN_WIDTH_MAX_MOBILE ? 4 : undefined}>
                <Menu.Item as={NavLink} exact to="/paths">
                    <Translation message={t.menuDiscussion} />{' '}
                    {!!unseenChatMessages && <RedLabel value={unseenChatMessages} />}
                </Menu.Item>

                {memberAuthState.isEmployee && (
                    <Menu.Item as={NavLink} exact to={'/paths/questionnaires'}>
                        <Translation message={t.profileQuestionnaires} />{' '}
                        {!!openQuestionnaires && <RedLabel value={openQuestionnaires} />}
                    </Menu.Item>
                )}

                <Menu.Item as={NavLink} exact to="/paths/documents">
                    <Translation message={t.profileDocuments} />{' '}
                    {!!unseenDocuments && <RedLabel value={unseenDocuments} />}
                </Menu.Item>

                <Menu.Item as={NavLink} exact to="/paths/appointments">
                    <Translation message={t.appointmentAppointments} />{' '}
                    {!!openCalendarReservationAccesses && <RedLabel value={openCalendarReservationAccesses} />}
                </Menu.Item>
            </Menu>

            <Content>
                <NotEmployeeRoute exact path="/paths" component={ChatsHighlight} memberAuthState={memberAuthState} />

                <PrivateRoute
                    exact
                    path="/paths/questionnaires"
                    component={QuestionnaireList}
                    memberAuthState={memberAuthState}
                />

                <NotEmployeeRoute
                    exact
                    path="/paths/appointments"
                    component={Appointments}
                    memberAuthState={memberAuthState}
                />

                <NotEmployeeRoute
                    exact
                    path="/paths/appointments/:craId"
                    component={Appointments}
                    memberAuthState={memberAuthState}
                />

                <NotEmployeeRoute
                    exact
                    path="/paths/documents"
                    component={Documents}
                    memberAuthState={memberAuthState}
                />
            </Content>

            <Footer />
        </div>
    );
};

export default compose(
    connect(
        (state: RootState) => ({
            memberAuthState: {
                isAuthenticated: state.auth.isAuthenticated,
                isVerified: state.auth.isVerified,
                isEmployee: state.auth.isEmployee
            }
        }),
        {
            openFlow: openFlowAction
        }
    )
)(Paths);

import React, { PureComponent } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { Grid, Header, Icon, Popup, SemanticICONS } from 'semantic-ui-react';

import { QuestionnaireResponseFragment } from '../../graphql-schema';

import categoryHealth from './../../assets/images/category-health.svg';
import categoryMove from './../../assets/images/category-move.svg';
import categoryNutrition from './../../assets/images/category-nutrition.svg';
import categoryRest from './../../assets/images/category-rest.svg';
import categoryStress from './../../assets/images/category-stress.svg';
import categoryWork from './../../assets/images/category-work.svg';

export const iconForCategoryIconName = (category: string) => {
    switch (category) {
        case 'health':
            return categoryHealth;
        case 'move':
            return categoryMove;
        case 'nutrition':
            return categoryNutrition;
        case 'rest':
            return categoryRest;
        case 'stress':
            return categoryStress;
        case 'work':
            return categoryWork;
        default:
            return null;
    }
};

interface SummaryItemProps {
    title: string;
    popup: string;
    value: number;
    color: string;
    icon: JSX.Element;
}

class QuestionnaireSummaryItem extends PureComponent<SummaryItemProps> {
    public render() {
        const { title, popup, value, color, icon } = this.props;

        const placing = 130 + -45 * value;

        return (
            <Grid.Column className="questionnaireSummaryItem">
                <Header>
                    <div>{title}</div>
                </Header>

                {icon}

                <Popup
                    inverted
                    position="bottom center"
                    trigger={
                        <div className="bar" style={{ top: `${placing}px`, backgroundColor: color }}>
                            <div className="plus">+</div>
                            <div className="minus">-</div>
                        </div>
                    }
                    content={popup}
                />
            </Grid.Column>
        );
    }
}

type Props = WrappedComponentProps & OwnProps;

interface OwnProps {
    questionnaireResponse: QuestionnaireResponseFragment;
}

class QuestionnaireSummaryBars extends PureComponent<Props> {
    public render() {
        const { questionnaireResponse } = this.props;

        if (!questionnaireResponse.summary) {
            return null;
        }

        const { categories } = questionnaireResponse.summary;

        if (!categories || categories.length === 0) {
            return null;
        }

        return (
            <Grid padded>
                <Grid.Row centered columns={categories.length as any} className="questionnaireSummaryBars">
                    {categories.map((item, index) => (
                        <QuestionnaireSummaryItem
                            key={index}
                            title={item.title}
                            popup={item.popup}
                            value={item.value}
                            color={item.color}
                            icon={
                                iconForCategoryIconName(item.icon) ? (
                                    <img src={iconForCategoryIconName(item.icon)} alt={item.title} />
                                ) : (
                                    <Icon name={item.icon as SemanticICONS} size={'large'} />
                                )
                            }
                        />
                    ))}

                    <div className="horizontalLine" />
                </Grid.Row>
            </Grid>
        );
    }
}

export default injectIntl(QuestionnaireSummaryBars);

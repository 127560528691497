import React, { useCallback, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { Checkbox, Divider, DropdownProps, Form, Input, Message } from 'semantic-ui-react';

import { signUpWithEmailMutation } from '../../data/mutations';
import t from '../../translations';
import { LanguageCode, SignUpWithEmailMutation } from '../../graphql-schema';
import { MarkdownTranslation, Translation } from '../../components/Message';
import PasswordIndicator from '../../components/PasswordIndicator';
import { LOGIN_SUCCESS } from '../../ducks/auth';
import { LanguageOption, languageOptions } from '../../components/ProfileForm';

type FormData = {
    firstName: string;
    lastName: string;
    phone: string;
    password: string;
    passwordAgain: string;
    termsAccepted: boolean;
    language: LanguageCode;
};

type Props = {
    token: string;
};

const getNavigatorLanguage = (): LanguageOption | undefined => {
    const navigatorLanguage = window?.navigator?.language;

    if (navigatorLanguage) {
        if (navigatorLanguage.startsWith('fi')) {
            return languageOptions.find(option => option.text === 'suomi');
        } else if (navigatorLanguage.startsWith('en')) {
            return languageOptions.find(option => option.text === 'English');
        }
    }

    return undefined;
};

const navigatorLanguageOption: LanguageOption | undefined = getNavigatorLanguage();

export const EmailSignUpForm: React.FC<Props> = props => {
    const { token } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const { formatMessage } = useIntl();
    const [error, setError] = useState<Error | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [signUpWithEmail] = useMutation<SignUpWithEmailMutation>(signUpWithEmailMutation);
    const intl = useIntl();

    let defaultLanguageOption: LanguageOption | undefined = navigatorLanguageOption;

    if (intl.locale === 'fi') {
        defaultLanguageOption = languageOptions.find(option => option.text === 'suomi');
    } else {
        defaultLanguageOption = languageOptions.find(option => option.text === 'English');
    }

    const handleSave = useCallback(
        (values: FormData) => {
            if (!values.termsAccepted || values.password != values.passwordAgain) {
                return;
            }

            setLoading(true);
            setError(null);

            const { firstName, lastName, phone, password, language } = values;

            signUpWithEmail({ variables: { data: { token, firstName, lastName, phone, password, language } } })
                .then(result => {
                    const member = result.data?.signUpWithEmail?.member;

                    if (member) {
                        dispatch({
                            type: LOGIN_SUCCESS,
                            payload: {
                                verified: member.verified,
                                verification_required: member.verificationRequired,
                                terms_accepted: member.termsAccepted,
                                is_employee: member.employments?.edges.length !== 0
                            }
                        });

                        history.replace('/');
                    }
                })
                .catch((error: Error) => {
                    setLoading(false);
                    setError(error);
                });
        },
        [signUpWithEmail, token, dispatch, history]
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                firstName: Yup.string().required(formatMessage(t.validatorsRequired)),
                lastName: Yup.string().required(formatMessage(t.validatorsRequired)),
                phone: Yup.string().required(formatMessage(t.validatorsRequired)),
                password: Yup.string().required(formatMessage(t.validatorsRequired)),
                passwordAgain: Yup.string().required(formatMessage(t.validatorsRequired))
            }),
        []
    );

    const {
        handleChange,
        handleSubmit,
        values: formValues,
        errors,
        isValid,
        setFieldValue
    } = useFormik<FormData>({
        initialValues: {
            firstName: '',
            lastName: '',
            phone: '',
            password: '',
            passwordAgain: '',
            termsAccepted: false,
            language: defaultLanguageOption?.value || LanguageCode.En
        },
        onSubmit: handleSave,
        validationSchema
    });

    const handleLanguageSelect = (_, data: DropdownProps) => {
        setFieldValue('language', data.value).catch(error => setError(error));
    };

    return (
        <Form onSubmit={handleSubmit} loading={loading} error={!isValid || !!error}>
            {error && (
                <Message error>
                    <MarkdownTranslation message={t.emailSignUp.errorGeneric} />
                </Message>
            )}

            <Form.Group widths="equal">
                <Form.Field>
                    <Input
                        name="firstName"
                        placeholder={formatMessage(t.profile.firstName)}
                        onChange={handleChange}
                        error={!!errors.firstName}
                        value={formValues.firstName}
                    />
                </Form.Field>
                <Form.Field>
                    <Input
                        name="lastName"
                        placeholder={formatMessage(t.profile.lastName)}
                        onChange={handleChange}
                        error={!!errors.lastName}
                        value={formValues.lastName}
                    />
                </Form.Field>
            </Form.Group>

            <Form.Field>
                <Input
                    name="phone"
                    placeholder={formatMessage(t.profile.phone)}
                    onChange={handleChange}
                    error={!!errors.phone}
                    value={formValues.phone}
                />
            </Form.Field>

            <Form.Field>
                <Form.Select
                    id="language"
                    name="language"
                    options={languageOptions}
                    onChange={handleLanguageSelect}
                    label={formatMessage(t.profile.language)}
                    placeholder={formatMessage(t.profile.language)}
                    value={formValues.language}
                />
            </Form.Field>

            <Form.Group widths="equal">
                <Form.Field>
                    <Divider hidden />
                    <Input
                        name="password"
                        type="password"
                        placeholder={formatMessage(t.profile.password)}
                        onChange={handleChange}
                        error={!!errors.password}
                        value={formValues.password}
                    />

                    <Input
                        name="passwordAgain"
                        type="password"
                        placeholder={formatMessage(t.profile.passwordAgain)}
                        onChange={handleChange}
                        error={!!errors.passwordAgain}
                        value={formValues.passwordAgain}
                        style={{ marginTop: '16px' }}
                    />
                </Form.Field>
                <Form.Field>
                    <PasswordIndicator password={formValues.password} passwordAgain={formValues.passwordAgain} />
                </Form.Field>
            </Form.Group>

            <Divider hidden />

            <Form.Field>
                <Checkbox
                    id="termsAccepted"
                    name="termsAccepted"
                    type="checkbox"
                    label={
                        <label>
                            <MarkdownTranslation message={t.emailSignUp.acceptTerms} />
                        </label>
                    }
                    onChange={handleChange}
                    value={formValues.termsAccepted ? 1 : 0}
                />
            </Form.Field>

            <Form.Button
                primary
                type="submit"
                disabled={!isValid || loading || !formValues.termsAccepted}
                loading={loading}
            >
                <Translation message={t.emailSignUp.signUpButton} />
            </Form.Button>
        </Form>
    );
};

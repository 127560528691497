import React, { ErrorInfo, useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';

// Import App styles before components to define App styles first
import './app.less';

import { AcceptTermsModal, ErrorBoundary, Version } from '@heltti/components';

import { RootState } from '../ducks';
import { acceptTerms, AuthState, initAuthentication, refreshAuthentication } from '../ducks/auth';
import PrivateRoute from './PrivateRoute';
import Bubbles from './BubbleContainer';
import { LandingPage } from '../views/LandingPage';
import { Home } from '../views/Home';
import Profile from './../views/Profile';
import { Chat } from '../views/Chat';
import { Chats } from '../views/Chats';
import Paths from '../views/Paths';
import SickLeave from '../views/SickLeave';
import SelfHelpCourse from '../views/SelfHelpCourse';
import NotFound404 from '../views/404';
import { Questionnaire } from '../views/Questionnaire';
import { PasswordForgot } from '../views/PasswordForgot';
import { PasswordReset } from '../views/PasswordReset';
import { CompanyEventNPSResponse, MindyNPSResponse, MindyOrgNPSResponse, NPSResponse } from '../views/NPS';
import SignUpIndex from '../views/SignUp';
import { FAQPage } from '../views/FAQ';
import { Explore } from '../views/Explore';
import Login from '../views/Login';
import { ReferralDownloadPage } from '../views/ReferralDownload';
import { paths } from '../constants';
import EidentErrorPage from '../views/Eident/error';
import EidentIndex from '../views/Eident/index';
import { TopNavigationBar } from './TopNavigationBar';
import NotEmployeeRoute from './NotEmployeeRoute';
import NotVerifiedIndex from '../views/NotVerified';
import { Hello } from '../views/Hello';
import { Translation } from './Message';
import t from '../translations';
import StartHere from '../views/StartHere';
import { VideoMeeting } from '../views/VideoMeeting';
import { TokenAuthQuestionnaire } from '../views/Questionnaire/TokenAuthQuestionnaire';
import { EmailSignUp } from '../views/SignUp/EmailSignUp';

import { VerifyEmail } from '../views/SignUp/VerifyEmail';
import { SteroidGlobalInteractions } from './Steroids/SteroidGlobalInteractions';

export const landingPageRoute = '/welcome';

const OpenLinkRedirector = () => {
    useEffect(() => {
        if (window.location.pathname.startsWith(paths.open)) {
            window.location.href = window.location.href.replace(paths.open, '');
        }
    }, []);

    return null;
};

type AppProps = {
    memberAuthState: AuthState;
    acceptTerms: (
        memberCommunicationAccepted: boolean,
        marketingAccepted: boolean,
        feedbackAccepted: boolean
    ) => Promise<void>;
    initAuthentication: () => void;
    refreshAuthentication: () => void;
};

const App: React.FC<AppProps> = props => {
    const { memberAuthState, acceptTerms, initAuthentication, refreshAuthentication } = props;
    const { isAuthenticated, hasAcceptedTerms } = memberAuthState;

    const hasFullAccess = memberAuthState.isAuthenticated && memberAuthState.isEmployee;

    const authRefreshIntervalRef = useRef<NodeJS.Timer | undefined>(undefined);

    const [showTermsAcceptedModal, setShowTermsAcceptedModal] = useState<boolean>(true);

    const onTermsAccepted = useCallback(
        async (marketingAccepted: boolean, memberCommunicationAccepted: boolean, feedbackAccepted: boolean) => {
            await acceptTerms(memberCommunicationAccepted, marketingAccepted, feedbackAccepted);

            setShowTermsAcceptedModal(false);
        },
        [acceptTerms]
    );

    const refreshAuth = useCallback(() => {
        if (props.memberAuthState.isAuthenticated === true) {
            refreshAuthentication();
        }
    }, [props.memberAuthState.isAuthenticated, refreshAuthentication]);

    const onError = useCallback((error: Error, errorInfo: ErrorInfo) => {
        Sentry.addBreadcrumb({
            type: 'error',
            level: Sentry.Severity.Error,
            category: 'exception',
            message: errorInfo.componentStack
        });

        Sentry.captureException(error);
    }, []);

    useEffect(() => {
        if (!authRefreshIntervalRef.current) {
            // Do not init/refresh authentication on EidentSuccess page because it's doing the authentication against backend.
            // Initializing or refreshing the auth on that page will cause the application to redirect into landing page and cause
            // the Eident verification to fail.
            if (window.location.pathname !== paths.eIdentCallback) {
                initAuthentication();

                authRefreshIntervalRef.current = setInterval(refreshAuth, 60 * 15 * 1000);
            }
        }

        return () => {
            if (authRefreshIntervalRef.current) {
                clearInterval(authRefreshIntervalRef.current);

                authRefreshIntervalRef.current = undefined;
            }
        };
    }, [initAuthentication, refreshAuth]);

    return (
        <BrowserRouter>
            <div id="app">
                <TopNavigationBar memberAuthState={memberAuthState} />

                <ErrorBoundary
                    errorTitle={<Translation message={t.errorGeneralTitle} />}
                    tryAgainTitle={<Translation message={t.errorGeneralRefresh} />}
                    onError={onError}
                >
                    <div id="contentScroll">
                        <Route exact path={paths.eIdentCallback} component={EidentIndex} />
                        <Route path={paths.eIdentError} component={EidentErrorPage} />

                        {isAuthenticated === true && <SteroidGlobalInteractions />}

                        {isAuthenticated === true && hasAcceptedTerms === false && showTermsAcceptedModal && (
                            <AcceptTermsModal onAcceptTerms={onTermsAccepted} />
                        )}

                        {memberAuthState.isAuthenticated !== undefined && (
                            <Switch>
                                <Route exact path="/hello" component={Hello} />
                                <Route path="/login" component={Login} />
                                <Route path="/referrals" component={ReferralDownloadPage} />

                                <NotEmployeeRoute exact path="/" component={Home} memberAuthState={memberAuthState} />

                                <NotEmployeeRoute
                                    exact
                                    path="/chats/archive"
                                    component={Chats}
                                    memberAuthState={memberAuthState}
                                />

                                <NotEmployeeRoute
                                    exact
                                    path="/chats/:id/:chatMessageId?"
                                    component={Chat}
                                    memberAuthState={memberAuthState}
                                />

                                <PrivateRoute
                                    exact
                                    path="/explore"
                                    component={Explore}
                                    memberAuthState={memberAuthState}
                                />

                                <PrivateRoute
                                    exact
                                    path="/explore/:id"
                                    component={Explore}
                                    memberAuthState={memberAuthState}
                                    forceUnmountOnPathChange
                                />

                                <PrivateRoute
                                    exact
                                    path="/articles/light-coaching/subscribe/:id"
                                    component={SelfHelpCourse}
                                    memberAuthState={memberAuthState}
                                />

                                <PrivateRoute
                                    exact
                                    path="/questionnaire/:id"
                                    component={Questionnaire}
                                    memberAuthState={memberAuthState}
                                />

                                <PrivateRoute
                                    exact
                                    path="/sick-leave"
                                    component={SickLeave}
                                    memberAuthState={memberAuthState}
                                />

                                <PrivateRoute
                                    exact
                                    path={paths.startHere}
                                    component={StartHere}
                                    memberAuthState={memberAuthState}
                                />

                                <NotEmployeeRoute
                                    path="/profile"
                                    component={Profile}
                                    memberAuthState={memberAuthState}
                                />

                                <NotEmployeeRoute
                                    exact
                                    path={paths.signUpIndex}
                                    component={SignUpIndex}
                                    memberAuthState={memberAuthState}
                                />

                                <NotEmployeeRoute path="/paths" component={Paths} memberAuthState={memberAuthState} />

                                <Route exact path={paths.notVerifiedIndex} component={NotVerifiedIndex} />

                                <Route
                                    exact
                                    path="/questionnaire/:memberId/:batchId/:token/:responseId?"
                                    component={TokenAuthQuestionnaire}
                                />

                                <Route exact path="/meet/:meetingUUID" component={VideoMeeting} />

                                <Route exact path="/nps/:npsUUID/:scoreDetail1?" component={NPSResponse} />
                                <Route
                                    exact
                                    path="/company_event_nps/:npsUUID/:scoreDetail1?"
                                    component={CompanyEventNPSResponse}
                                />
                                <Route exact path="/mindyNPS/:npsUUID/:scoreDetail1?" component={MindyNPSResponse} />
                                <Route
                                    exact
                                    path="/mindyOrgNPS/:npsUUID/:scoreDetail1?"
                                    component={MindyOrgNPSResponse}
                                />
                                <Route exact path="/forgot-password" component={PasswordForgot} />
                                <Route exact path="/reset-password/:email?/:token?" component={PasswordReset} />

                                <Route exact path="/email-signup" component={VerifyEmail} />
                                <Route exact path="/email-signup/:token" component={EmailSignUp} />

                                <Route exact path={landingPageRoute} component={LandingPage} />

                                <PrivateRoute exact path="/faq" component={FAQPage} memberAuthState={memberAuthState} />
                                <PrivateRoute
                                    exact
                                    path="/faq/:id"
                                    component={FAQPage}
                                    memberAuthState={memberAuthState}
                                    forceUnmountOnPathChange
                                />

                                <Redirect exact from="/lähete" to="/referrals" />
                                <Redirect exact from="/home" to="/" />
                                <Redirect from="/articles" to="/explore" />
                                <Redirect from="/mentalToolkit" to="/explore" />
                                <Route path={paths.open} component={OpenLinkRedirector} />

                                <Route exact path="*" component={NotFound404} />
                            </Switch>
                        )}
                    </div>
                    {hasFullAccess && <Route path="/:page?/:id?" component={Bubbles} />}
                </ErrorBoundary>
            </div>
            <Version name={__VERSION__} />
        </BrowserRouter>
    );
};

const ReduxApp = connect(
    (state: RootState) => ({
        memberAuthState: state.auth
    }),
    {
        initAuthentication,
        refreshAuthentication,
        acceptTerms
    }
)(App);

export { ReduxApp as App };

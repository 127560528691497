import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './CompactHeaderWithNavigation.less';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import backButton from './../assets/images/back.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import backButtonDark from './../assets/images/back-dark.svg';

interface Props {
    title: string | JSX.Element;
    backTo?: string | (() => void);
    absolute?: boolean;
    color?: 'red' | 'blue' | 'gray';
}

export const CompactHeaderWithNavigation: React.FC<Props> = props => {
    const { title, backTo, color, absolute } = props;

    const classes = classNames([{ [color as string]: color }, { absolute }]);

    return (
        <div id="compactHeaderWithNavigation" className={classes}>
            {typeof backTo === 'string' && (
                <Link to={backTo} className="backLink">
                    <img src={color !== 'gray' ? backButton : backButtonDark} alt="" />
                </Link>
            )}

            {backTo instanceof Function && (
                <img
                    className="backLink"
                    onClick={backTo}
                    src={color !== 'gray' ? backButton : backButtonDark}
                    alt=""
                />
            )}

            {title}
        </div>
    );
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { QueryResult } from '@apollo/client';
import { graphql } from '@apollo/react-hoc';

import { languages, setLanguage } from '../ducks/profile';
import { logout } from '../ducks/auth';
import { profileQuery } from '../data/queries';
import { ProfileFragment as ProfileType } from '../graphql-schema';

interface Result {
    root: {
        me: ProfileType;
    };
}

interface Props {
    data: QueryResult & Result;
    language: string;
    setLanguage: Function;
    logout: Function;
}

class WithProfile extends Component<Props> {
    public componentWillReceiveProps(nextProps: Props) {
        if (!nextProps.data.loading) {
            if (nextProps.data.error || !nextProps.data.root.me) {
                return nextProps.logout();
            }

            const languageInBackend = nextProps.data.root.me.language;
            const currentLanguageShortName = nextProps.language;

            const newLanguage = languages[languageInBackend];

            if (currentLanguageShortName !== newLanguage.shortName) {
                nextProps.setLanguage(newLanguage.fallbackUILanguage || newLanguage.shortName);
            }
        }
    }

    public render() {
        return this.props.children;
    }
}

export default graphql(profileQuery)(
    connect(
        state => ({
            language: state.profile.language
        }),
        {
            setLanguage,
            logout
        }
    )(WithProfile)
);

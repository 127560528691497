import React, { Component } from 'react';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import { ApolloClient } from '@apollo/client';
import { RouteComponentProps } from 'react-router-dom';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { withApollo } from '@apollo/react-hoc';

import { Divider, Grid, Header } from 'semantic-ui-react';
import { Button } from '@heltti/components';

import { acceptTerms as acceptTermsAction, login as loginAction, logout as logoutAction } from '../../ducks/auth';
import t from '../../translations';
import { RootState } from '../../ducks';
import { Content } from '../../components/Content';
import PageHeader from '../../components/PageHeader';
import { HelttiLogo } from '../../components/Logo';
import { MarkdownTranslation, Translation } from '../../components/Message';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import helttiClinicImage from '../../assets/images/helttila-tausta1.jpg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import contactImage from '../../assets/images/header_otayhteytta_2000x731.jpg';

import './landing.less';

type Props = OwnProps & RouteComponentProps<any> & WrappedComponentProps;

interface OwnProps {
    error?: string;
    isAuthenticated: boolean;
    hasAcceptedTerms?: boolean;
    isLoading: boolean;

    login(email: string, password: string): Promise<any>;

    acceptTerms(
        isMemberCommunicationAccepted: boolean,
        isMarketingAccepted: boolean,
        isFeedbackAccepted: boolean
    ): Promise<any>;

    logout: () => void;
    client: ApolloClient<any>;
}

class PlainLandingPage extends Component<Props> {
    public componentDidMount() {
        const { isAuthenticated, logout, client } = this.props;

        if (isAuthenticated) {
            logout();
        }

        client.resetStore();
    }

    public render() {
        const { intl } = this.props;

        return (
            <div id="landing-page">
                <Content>
                    <HelttiLogo />

                    <div className="pitchSection pitchWhite">
                        <PageHeader
                            hideBackgroundImage
                            title={`${intl.formatMessage(t.landingPitchTitle)}`}
                            description={intl.formatMessage(t.homeTopBannerSubHeader)}
                        />
                    </div>

                    <div
                        className="pitchSection pitchBackground pitchBackgroundMobileRight"
                        style={{ backgroundImage: `url(${contactImage})` }}
                    >
                        <Grid stackable verticalAlign="middle">
                            <Grid.Row columns={2}>
                                <Grid.Column width={6} />

                                <Grid.Column width={10}>
                                    <Header as="h1">
                                        <Header.Subheader>
                                            <Translation message={t.landingPitchContactSubtitle} />
                                        </Header.Subheader>

                                        <Divider hidden />

                                        <Translation message={t.landingPitchContactTitle} />
                                    </Header>

                                    <p>
                                        <b>
                                            <Translation message={t.landingPitchContactHighlight} />
                                        </b>
                                    </p>
                                    <p>
                                        <MarkdownTranslation message={t.landingPitchContactDescription} />
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>

                    <div className="pitchSection pitchWhite" style={{ textAlign: 'center' }}>
                        <Grid stackable verticalAlign="top">
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <Header as="h2">HelttiLinja</Header>
                                    <a href={`tel:${intl.formatMessage(t.helttiPhoneNumber)}`} className="buttonLink">
                                        <Button color="red">
                                            <Translation message={t.helttiPhoneNumberForDisplay} />
                                        </Button>
                                    </a>

                                    <Divider hidden />

                                    <p>
                                        <MarkdownTranslation message={t.landingPitchContactCallDescription} />
                                    </p>
                                </Grid.Column>

                                <Grid.Column>
                                    <Header as="h2">HelttiChat</Header>
                                    <a href="login" className="buttonLink">
                                        <Button color="red">
                                            <Translation message={t.loginTitle} />
                                        </Button>
                                    </a>

                                    <Divider hidden />

                                    <p>
                                        <MarkdownTranslation message={t.landingPitchContactChatDescription} />
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>

                    <div
                        className="pitchSection pitchWhite pitchBackground pitchBackgroundMobileLeft"
                        style={{ backgroundImage: `url(${helttiClinicImage})` }}
                    >
                        <Grid stackable verticalAlign="middle">
                            <Grid.Row columns={2}>
                                <Grid.Column width={10}>
                                    <Header as="h1">
                                        <Header.Subheader>
                                            <Translation message={t.landingPitchVisitUsSubtitle} />
                                        </Header.Subheader>
                                        <Divider hidden />

                                        <Translation message={t.landingPitchVisitUsTitle} />
                                    </Header>

                                    <p>
                                        <MarkdownTranslation message={t.landingPitchVisitUsDescription} />
                                    </p>

                                    <a
                                        href={intl.formatMessage(t.landingPitchVisitUsButtonUrl)}
                                        target="_blank"
                                        className="buttonLink"
                                        rel="noreferrer"
                                    >
                                        <Button>{intl.formatMessage(t.landingPitchVisitUsButton)}</Button>
                                    </a>
                                </Grid.Column>
                                <Grid.Column width={6} />
                            </Grid.Row>
                        </Grid>
                    </div>

                    <div className="pitchSection pitchWhite" style={{ textAlign: 'center' }}>
                        <Grid stackable verticalAlign="top">
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Header as="h1">
                                        <Translation message={t.landingPitchSupportTitle} />
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <a
                                        href="https://view.heltti.fi/"
                                        target="_blank"
                                        className="buttonLink"
                                        rel="noreferrer"
                                    >
                                        <Button color="blue">
                                            <Translation message={t.landingPitchHelttiViewButton} />
                                        </Button>
                                    </a>

                                    <Divider hidden />

                                    <p style={{ textAlign: 'left' }}>
                                        <MarkdownTranslation message={t.landingPitchHelttiViewDescriptionPart1} />
                                    </p>
                                </Grid.Column>

                                <Grid.Column>
                                    <a
                                        href="https://heltti.atlassian.net/servicedesk/customer/portals/"
                                        target="_blank"
                                        className="buttonLink"
                                        rel="noreferrer"
                                    >
                                        <Button color="red">
                                            <Translation message={t.landingPitchSupportPortalTitle} />
                                        </Button>
                                    </a>

                                    <Divider hidden />

                                    <p style={{ textAlign: 'left' }}>
                                        <MarkdownTranslation message={t.landingPitchSupportPortalDescription} />
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>

                    <div className="pitchSection">
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column textAlign="center">
                                    <small>
                                        <Translation message={t.loginAboutCookies} />{' '}
                                        <a href={intl.formatMessage(t.loginAboutCookiesUrl)}>
                                            <Translation message={t.loginAboutCookiesReadMore} />
                                        </a>
                                    </small>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Content>
            </div>
        );
    }
}

export const LandingPage = withApollo(
    compose(
        injectIntl,
        connect(
            (state: RootState) => ({
                isAuthenticated: state.auth.isAuthenticated,
                hasAcceptedTerms: state.auth.hasAcceptedTerms,
                isLoading: state.auth.isLoading,
                error: state.auth.error
            }),
            {
                login: loginAction,
                logout: logoutAction,
                acceptTerms: acceptTermsAction
            }
        )
    )(PlainLandingPage)
);

import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

type Props = {
    id: string;
    subNavigation?: boolean;
};

export const Page: React.FC<PropsWithChildren<Props>> = props => {
    const { children, subNavigation, ...restProps } = props;

    const classes = classNames({ subNavigation: !!subNavigation });

    return (
        <div className={classes} {...restProps}>
            {children}
        </div>
    );
};

import {
    ChatMessageAttachmentFragment,
    ChatMessageFileAttachmentFragment,
    ChatMessageIdAttachmentFragment,
    ChatMessageInteractionFragment,
    InteractionActionFragment
} from '../graphql-schema';

export type InteractionTheme = 'blue';

export type InteractionAction = {
    handle: string;
    title: string;
    value?: string;
};

export type ChatMessageInteraction = {
    id: string;
    title?: string;
    description?: string;
    label?: string;
    theme: InteractionTheme;
    actions: InteractionAction[];
};

export const isFileAttachment = (
    attachment: ChatMessageAttachmentFragment
): attachment is ChatMessageFileAttachmentFragment => {
    return 'filename' in attachment;
};

export const isInteractionAttachment = (
    attachment: ChatMessageAttachmentFragment
): attachment is ChatMessageInteractionFragment => {
    return 'uiInteraction' in attachment;
};

export const isIdAttachment = (
    attachment: ChatMessageAttachmentFragment
): attachment is ChatMessageIdAttachmentFragment => {
    return 'attachmentId' in attachment;
};

const actionToInteractionAction = (action: InteractionActionFragment): InteractionAction => {
    const { handle, title, value } = action;
    return {
        handle,
        title,
        value: value ?? undefined
    };
};

export const chatMessageInteractionToInteraction = (
    chatMessageInteraction: ChatMessageInteractionFragment
): ChatMessageInteraction => {
    const {
        uiInteraction: {
            interaction: { id, title, description, label, color, actions }
        }
    } = chatMessageInteraction;
    return {
        id,
        title: title ?? undefined,
        description: description ?? undefined,
        label: label ?? undefined,
        theme: color as InteractionTheme,
        actions: actions.map(actionToInteractionAction)
    };
};

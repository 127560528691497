import React, { PropsWithChildren } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { Button, Dropdown } from 'semantic-ui-react';

import { FormatMarkdown } from '../../components/FormatMarkdown';
import ChatMessageText from './ChatMessageText';
import t from '../../translations';

import './ChatMessage.less';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import attachmentIcon from './../../assets/images/attachment.svg';

const GreyItalicText: React.FC<PropsWithChildren<unknown>> = props => {
    return <span style={{ color: 'grey', fontStyle: 'italic' }}>{props.children}</span>;
};

const messageTypeGeneric = 10; // Events.
const messageTypeAction = 200; // Actions.

export const isEvent = type => type >= messageTypeGeneric && type < messageTypeAction;

type Props = {
    id: string;
    type: number;
    createdAt: string;
    deletedAt?: string | null;
    message: string;
    who: string;
    whoType: 'member' | 'heltti';
    avatarUrl?: string | null;
    attachments?: Array<{ filename: string; id: string; url: string | null }> | null;
    onDelete?: () => void;
    hideName?: boolean;
};

export const ChatMessage: React.FC<Props> = props => {
    const intl = useIntl();
    const { createdAt, deletedAt, message, who, whoType, hideName, attachments, onDelete } = props;

    const isFromHeltti = whoType === 'heltti';

    return (
        <div key="chat-message" className={`chatMessage from-${whoType}`}>
            {!hideName && <div className="sender">{who}</div>}

            {onDelete && (
                <Dropdown className="chatMessageActions" text="...">
                    <Dropdown.Menu>
                        {onDelete && (
                            <Dropdown.Item text={intl.formatMessage(t.chatDeleteMessage)} onClick={onDelete} />
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            )}

            <div className="content">
                {!deletedAt &&
                    (isFromHeltti ? <FormatMarkdown source={message} /> : <ChatMessageText text={message} />)}

                {deletedAt && <GreyItalicText>{intl.formatMessage(t.chatMessageDeleted)}</GreyItalicText>}
            </div>

            {attachments?.map((attachment, key) => (
                <a href={`${attachment.url}`} target="_blank" key={key} rel="noreferrer">
                    <Button basic className="chatMessageAttachment">
                        <img src={attachmentIcon} title={attachment.filename} /> {attachment.filename}
                    </Button>
                </a>
            ))}

            <div className="time">{moment(createdAt).format('HH:mm')}</div>
        </div>
    );
};

import { useIntl } from 'react-intl';

import { AppointmentContactType } from '../graphql-schema';
import t from '../translations/index';

export const formatContactType = (contactType: AppointmentContactType) => {
    const { formatMessage } = useIntl();

    switch (contactType) {
        case AppointmentContactType.Visit:
            return formatMessage(t.appointmentTime);
        case AppointmentContactType.Phone:
            return formatMessage(t.appointmentTimePhone);
        case AppointmentContactType.Video:
            return formatMessage(t.appointmentTimeVideo);
        default:
            return '';
    }
};

import React from 'react';
import { range } from 'lodash';

import { Button } from 'semantic-ui-react';

import { QuestionnaireQuestionFragment, QuestionnaireQuestionType } from '../../graphql-schema';
import { QuestionnaireQuestionValue } from './QuestionnaireResponse';

type Props = {
    question: QuestionnaireQuestionFragment;
    value: number[];
    onChange(question: QuestionnaireQuestionFragment, value: QuestionnaireQuestionValue): void;
};

export const SliderInput: React.FC<Props> = props => {
    const { question, onChange, value } = props;
    const options = question.options;

    const leftMostOption = options?.[0];
    const rightMostOption = options?.[options.length - 1];

    if (
        question.type !== QuestionnaireQuestionType.Slider ||
        leftMostOption?.value === undefined ||
        leftMostOption?.value === null ||
        rightMostOption?.value === undefined ||
        rightMostOption?.value === null
    ) {
        console.error('Invalid Question provided for SliderInput');
        return null;
    }

    const scores = range(leftMostOption.value, rightMostOption.value + 1, 1);

    return (
        <div className="questionInput">
            <div style={{ display: 'flex', marginTop: '40px' }}>
                {scores.map(scoreItem => (
                    <Button
                        compact
                        key={scoreItem}
                        className="scoreButton"
                        style={{ flex: 1, paddingLeft: 0, paddingRight: 0 }}
                        primary={scoreItem === value?.[0]}
                        onClick={() => onChange(question, { listValue: [scoreItem] })}
                    >
                        {scoreItem}
                    </Button>
                ))}
            </div>
            <div style={{ float: 'left', marginTop: '20px' }}>{leftMostOption.title}</div>
            <div style={{ float: 'right', marginTop: '20px' }}>{rightMostOption.title}</div>
        </div>
    );
};

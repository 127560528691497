import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Loader } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { usePageVisibility } from 'react-page-visibility';

import { Error, useLoadingQuery } from '@heltti/components';
import { getNodes } from '@heltti/common';

import t from '../../translations';
import { ChatFragment, ChatNotificationContext, ChatType as ChatEnum, ProfileQuery } from '../../graphql-schema';
import { ChatMessagesGrouped } from './ChatMessagesGrouped';
import ChatNotificationAlerts from './ChatNotificationAlerts';
import ChatNotificationInlineMessages from './ChatNotificationInlineMessages';
import { useChatMessages } from '../../hooks/useChatMessages';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import popSound from '../../assets/sounds/Heltti-Pop1.wav';
import { profileQuery } from '../../data/queries';

type Props = {
    chat: ChatFragment;
    chatType: ChatEnum;
    responseTimeSla?: string | null;

    onMarkAsSeen(messageIds: string[]): void;
};

export const ChatMessages = (props: Props) => {
    const intl = useIntl();
    const containerRef = useRef<HTMLDivElement | null>(null);

    const { chat, onMarkAsSeen } = props;

    const { loading, error, data, initialData, fetchHistory } = useChatMessages(chat.id);
    const { data: profileData } = useLoadingQuery<ProfileQuery>(profileQuery);

    const messages = getNodes(data?.root?.chat?.messages);
    const latestMessage = messages[0];

    const hasMore = data?.root?.chat?.messages?.pageInfo.hasNextPage ?? false;
    const notifications = chat.notifications ?? [];

    const isVisible = usePageVisibility();
    const [isReady, setIsReady] = useState(false);
    const [latestNotifiedMessage, setLatestNotifiedMessage] = useState<string | null>(null);

    const scrollToLatestMessage = useCallback((isSmooth: boolean) => {
        setTimeout(() => {
            if (!containerRef.current) {
                return;
            }

            // This is for (at least) IE 11.
            if (!containerRef.current.scroll) {
                containerRef.current.scrollTop = containerRef.current.scrollHeight;
            } else {
                containerRef.current.scroll({
                    top: containerRef.current.scrollHeight,
                    behavior: isSmooth ? 'smooth' : 'auto'
                });
            }
        }, 1);
    }, []);

    useEffect(() => {
        if (loading || isReady) {
            return;
        }
        setTimeout(() => setIsReady(true), 400);

        scrollToLatestMessage(false);
    }, [isReady, loading, scrollToLatestMessage]);

    /**
     * When the latest message changes, scroll to it
     * The message might be sent by the current user or some other user in the chat.
     * TODO: To improve, this, we could omit scrolling to the latest message when the user has scrolled to read
     *       some older messages. This improvement would also require adding a "newer messages exist" indicator.
     */
    useEffect(() => {
        scrollToLatestMessage(true);
    }, [scrollToLatestMessage, latestMessage?.id]);

    useEffect(() => {
        const lastMessageAt = chat?.lastMessageAt;

        if (messages.length) {
            const lastMessage = messages[0];

            if (lastMessageAt !== latestNotifiedMessage && lastMessage !== undefined) {
                const isLastMessageSentByMember: boolean = !!lastMessage.member;

                if (!isLastMessageSentByMember) {
                    scrollToLatestMessage(true);

                    if (!initialData) {
                        const sound = new Audio(popSound);

                        sound.play().catch(() => {
                            // Sorry, no sound.
                        });
                    }
                }
            }
        }

        setLatestNotifiedMessage(lastMessageAt ?? null);
    }, [chat, initialData, latestNotifiedMessage, messages, scrollToLatestMessage]);

    useEffect(() => {
        const memberId = profileData?.root?.me?.id;
        if (memberId && isVisible) {
            const lastMessageSeenId = getNodes(chat.members).find(member => member.member.id === memberId)
                ?.lastMessageSeen?.id;
            const lastSeenIndex = messages.findIndex(message => message.id === lastMessageSeenId);
            // If message is not found, everything is unseen
            const unseenMessages = lastSeenIndex >= 0 ? messages.slice(0, lastSeenIndex) : messages;

            if (unseenMessages.length > 0) {
                onMarkAsSeen(unseenMessages.map(message => message.id));
            }
        }
    }, [chat, isVisible, messages, onMarkAsSeen, profileData]);

    const handleFetchMore = useCallback(() => {
        setIsReady(false);

        fetchHistory().finally(() => {
            // Not optimal error handling, but at least user can try again
            setIsReady(true);
        });
    }, [fetchHistory]);

    const alerts = notifications.filter(({ context }) => context == ChatNotificationContext.Alert);
    const inlines = notifications.filter(({ context }) => context == ChatNotificationContext.Inline);

    return (
        <>
            <ChatNotificationAlerts alerts={alerts} chat={chat} />

            <div className="messagesContainer" ref={containerRef}>
                <div style={{ height: alerts.length > 0 ? 300 : 100 }}>&nbsp;</div>

                {hasMore && (
                    <div className="loadMoreMessagesButtonContainer">
                        <Button onClick={handleFetchMore} className="loadMoreMessagesButton">
                            {!isReady && <Loader active inline size="mini" className="loadMoreMessagesLoader" />}
                            {isReady && intl.formatMessage(t.chatMessagesShowMore)}
                        </Button>
                    </div>
                )}

                {error && <Error showRefreshButton />}

                {loading || !chat ? (
                    <Loader />
                ) : (
                    <>
                        <ChatMessagesGrouped messages={messages} />

                        <ChatNotificationInlineMessages inlines={inlines} />
                    </>
                )}
            </div>
        </>
    );
};

import React, { Component } from 'react';
import { flowRight as compose, groupBy, keyBy } from 'lodash';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { Button, Divider, Form, Grid, Header, Progress } from 'semantic-ui-react';

import { Loader } from '@heltti/components';

import { QuestionnaireQuestion } from './QuestionnaireQuestion';
import Section from '../../components/Section';
import PageHeader from '../../components/PageHeader';
import { CompactHeaderWithNavigation } from '../../components/CompactHeaderWithNavigation';
import t from '../../translations';
import {
    QuestionnaireFragment,
    QuestionnaireQuestionFragment,
    QuestionnaireResponseFragment
} from '../../graphql-schema';
import { questionnaireResponseMutation } from '../../data/mutations';
import QuestionnaireOutro from './QuestionnaireOutro';
import { QuestionnaireSummary } from './QuestionnaireSummary';
import QuestionnaireEndCredits from './QuestionnaireEndCredits';
import { FormatMarkdown } from '../../components/FormatMarkdown';
import { apolloClient } from '../../index';

export interface QuestionnaireQuestionValue {
    strValue?: string;
    listValue?: number[];
}

import backgroundImage from './../../assets/images/heltti-kuosi.svg';

interface QuestionMap {
    [orderNumber: number]: QuestionnaireQuestionFragment[];
}

type Props = OwnProps & WrappedComponentProps;

interface OwnProps {
    questionnaire: QuestionnaireFragment;
    questionnaireResponse: QuestionnaireResponseFragment;

    accessToken?: string;
    returnPath?: string;
}

interface State {
    mappedQuestions: QuestionMap;
    progressIndex: number | null;
    responses: { [questionId: string]: QuestionnaireQuestionValue };
}

class QuestionnaireResponse extends Component<Props, State> {
    private unMounted: boolean = true;

    public state: State = {
        mappedQuestions: {},
        responses: {},
        progressIndex: null
    };

    public componentDidMount(): void {
        this.unMounted = false;

        this.setStateFromProps(this.props);
    }

    public componentWillUnmount(): void {
        this.unMounted = true;
    }

    private setStateFromProps(props: Props) {
        const { questionnaireResponse, questionnaire } = props;

        if (!questionnaire) {
            return;
        }

        const state: State = {
            mappedQuestions: groupBy(
                questionnaire.questions.edges.map(edge => edge?.node),
                'orderNumber'
            ) as QuestionMap,
            responses: keyBy(
                questionnaireResponse.responses.edges.map(edge => edge?.node),
                item => item!.questionnaireQuestion.id
            ),
            progressIndex: this.state.progressIndex
        } as State;

        const pageCount = Object.keys(state.mappedQuestions).length;

        if (this.state.progressIndex === null) {
            if (questionnaireResponse.closedAt) {
                state.progressIndex = pageCount + 2;
            } else {
                const responseIds = new Set(
                    questionnaireResponse.responses.edges.map(edge => edge?.node?.questionnaireQuestion.id)
                );

                const questions = questionnaire.questions.edges.map(edge => edge?.node);
                const outroQuestions = questionnaire.outroQuestions.edges.map(edge => edge?.node);

                const firstUnansweredQuestion = questions.find(question => !responseIds.has(question!.id));
                const firstUnansweredIndex = firstUnansweredQuestion ? firstUnansweredQuestion.orderNumber : null;
                const hasUnansweredOutroQuestion = !!outroQuestions.find(question => !responseIds.has(question!.id));

                if (firstUnansweredIndex === 1) {
                    state.progressIndex = null;
                } else if (firstUnansweredIndex && firstUnansweredIndex > 0) {
                    state.progressIndex = firstUnansweredIndex;
                } else if (hasUnansweredOutroQuestion) {
                    state.progressIndex = pageCount + 1;
                } else {
                    state.progressIndex = pageCount + 2;
                }
            }
        }

        this.setState(state);
    }

    private onQuestionValueChanged = (question: QuestionnaireQuestionFragment, value: QuestionnaireQuestionValue) => {
        const { responses } = this.state;

        this.setState({
            responses: {
                ...responses,
                [question.id]: value
            }
        });
    };

    private saveResponse = (onSuccess: () => void) => {
        const { questionnaireResponse } = this.props;
        const { progressIndex, mappedQuestions, responses } = this.state;

        if (progressIndex === null || progressIndex > Object.keys(mappedQuestions).length) {
            onSuccess();

            return;
        }

        const questions: QuestionnaireQuestionFragment[] | null = mappedQuestions[progressIndex] || null;

        if (!questions || !questions.length) {
            onSuccess();

            return;
        }

        const mutationResponses: any[] = [];

        questions.forEach(question => {
            if (question) {
                mutationResponses.push(this.createMutationResponseObject(responses[question.id], question));
            }
        });

        this.doSaveResponse(questionnaireResponse.id, mutationResponses)
            .catch(() => {
                // TODO? Retry etc.
                // No op.
            })
            .then(() => {
                onSuccess();
            });
    };

    private doSaveResponse = (questionnaireResponseId: string, questionResponses: any[]) => {
        const { accessToken } = this.props;

        return apolloClient.mutate({
            mutation: questionnaireResponseMutation,
            variables: {
                data: {
                    id: questionnaireResponseId,
                    questionResponses,
                    accessToken
                }
            }
        });
    };

    private saveOutroQuestions = () => {
        const { questionnaireResponse, questionnaire } = this.props;
        const { responses } = this.state;

        const mutationResponses: any[] = [];

        questionnaire.outroQuestions.edges
            .map(e => e?.node)
            .forEach(question => {
                if (question) {
                    mutationResponses.push(this.createMutationResponseObject(responses[question.id], question));
                }
            });

        return this.doSaveResponse(questionnaireResponse.id, mutationResponses).then(() => {
            const pageCount = Object.keys(this.state.mappedQuestions).length;

            this.setState({ progressIndex: pageCount + 2 });
        });
    };

    private hasOutroQuestions = () => {
        const { questionnaire } = this.props;

        return questionnaire.outroQuestions.edges.length > 0;
    };

    private createMutationResponseObject = (response: QuestionnaireQuestionValue, question: any) => {
        const value = (response && (response.strValue || response.listValue)) || null;

        if (typeof value === 'string') {
            return { questionId: question.id, strValue: value };
        }

        return { questionId: question.id, listValue: value };
    };

    private goBack = () => {
        const { progressIndex, mappedQuestions } = this.state;

        if (progressIndex === null) {
            return;
        }

        const pageCount = Object.keys(mappedQuestions).length;

        this.saveResponse(() => {
            let newIndex = progressIndex && progressIndex > 1 ? progressIndex - 1 : null;

            if (newIndex && progressIndex === pageCount + 2 && !this.hasOutroQuestions()) {
                newIndex -= 1;
            }

            if (!this.unMounted) {
                this.setState({ progressIndex: newIndex });
            }
        });
    };

    private goForward = () => {
        const { progressIndex, mappedQuestions } = this.state;

        if (progressIndex && progressIndex > Object.keys(mappedQuestions).length) {
            return;
        }

        const pageCount = Object.keys(mappedQuestions).length;

        this.saveResponse(() => {
            let newIndex = progressIndex !== null ? progressIndex + 1 : 1;

            if (progressIndex === pageCount && !this.hasOutroQuestions()) {
                newIndex += 1;
            }

            if (!this.unMounted) {
                this.setState({ progressIndex: newIndex });
            }
        });
    };

    private renderIntro = () => {
        const { intl, questionnaire } = this.props;

        if (!questionnaire) {
            return null;
        }

        return (
            <div>
                <Section width="medium" padded>
                    {!!questionnaire.description && <FormatMarkdown source={questionnaire.description} />}

                    <Divider hidden />

                    <Button primary fluid onClick={this.goForward}>
                        {intl.formatMessage(t.questionnaireLetsStart)}
                    </Button>
                </Section>
            </div>
        );
    };

    private renderQuestion = (question: any, index: number) => {
        const { responses } = this.state;

        const response = responses[question.id];
        const value = (response && (response.strValue || response.listValue)) || null;

        return (
            <QuestionnaireQuestion
                key={question.id}
                question={question}
                renderTitle={index > 0}
                value={value}
                onChange={this.onQuestionValueChanged}
            />
        );
    };

    private renderQuestions = () => {
        const { intl } = this.props;
        const { progressIndex, mappedQuestions } = this.state;

        return (
            <Section width="medium" padded>
                <Grid>
                    <Grid.Row columns={1} className="questionRow">
                        <Grid.Column>
                            {progressIndex &&
                                mappedQuestions[progressIndex] &&
                                mappedQuestions[progressIndex].map(this.renderQuestion)}
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Button basic fluid onClick={this.goBack} tabIndex="2" type="button">
                                {intl.formatMessage(t.questionnairePreviousButton)}
                            </Button>
                        </Grid.Column>

                        <Grid.Column>
                            <Button primary fluid onClick={this.goForward} tabIndex="1" type="submit">
                                {intl.formatMessage(t.questionnaireNextButton)}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Section>
        );
    };

    public render() {
        const { questionnaire, accessToken, returnPath } = this.props;

        if (!questionnaire) {
            return <Loader />;
        }

        const { intl, questionnaireResponse } = this.props;
        const { progressIndex, mappedQuestions } = this.state;

        const pageCount = Object.keys(mappedQuestions).length;
        const questions =
            progressIndex !== null && progressIndex > 0 && progressIndex <= pageCount
                ? mappedQuestions[progressIndex]
                : null;

        const questionIndex = Math.min(pageCount, progressIndex || 0);

        const renderIntro = progressIndex === null;
        const renderOutro = progressIndex === pageCount + 1;
        const renderEndCredits = progressIndex === pageCount + 2;
        const renderQuestion = !renderIntro && !renderOutro && !renderEndCredits;

        return (
            <React.Fragment>
                <CompactHeaderWithNavigation
                    title={(renderQuestion && questionnaire.title) || ''}
                    backTo="/paths/questionnaires"
                    color="blue"
                />

                <PageHeader banner hideBackgroundImage>
                    <img src={backgroundImage} className="logo" />

                    <Section width="medium" height="full">
                        <div className="questionnaireFormHeaderContainer">
                            <Header as="h1">
                                {(renderOutro || renderEndCredits) && (
                                    <Header.Subheader>{intl.formatMessage(t.questionnaireTitleDone)}</Header.Subheader>
                                )}
                                {!renderQuestion && questionnaire.title}
                                {questions?.[0].title}
                            </Header>

                            {renderQuestion && <Progress progress="ratio" value={questionIndex} total={pageCount} />}
                        </div>
                    </Section>
                </PageHeader>

                <Form className="questionnaireForm" style={{ backgroundColor: 'white' }}>
                    {renderIntro && this.renderIntro()}

                    {renderQuestion && this.renderQuestions()}

                    {renderOutro && <QuestionnaireSummary questionnaireResponse={questionnaireResponse} />}

                    {renderOutro && (
                        <QuestionnaireOutro
                            questionnaire={questionnaire}
                            saveOutroQuestions={this.saveOutroQuestions}
                            renderQuestion={this.renderQuestion}
                            goBack={this.goBack}
                        />
                    )}

                    {renderEndCredits && (
                        <QuestionnaireEndCredits
                            usingTokenAuth={!!accessToken}
                            returnPath={returnPath}
                            questionnaire={questionnaire}
                            questionnaireResponse={questionnaireResponse}
                            goBack={this.goBack}
                        />
                    )}
                </Form>
            </React.Fragment>
        );
    }
}

export default compose(injectIntl)(QuestionnaireResponse);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';

import { Dropdown, Flag } from 'semantic-ui-react';

import { setLanguage } from '../ducks/profile';
import { SCREEN_WIDTH_MAX_MOBILE } from '../constants';

import './ChangeLanguage.less';

interface Props {
    language: string;
    setLanguage: Function;
}

class ChangeLanguage extends Component<Props> {
    private changeLanguage = (language: string) => {
        return (e: any) => {
            e.preventDefault();

            this.props.setLanguage(language);
        };
    };

    public render() {
        const { language } = this.props;

        const isMobile = window.innerWidth <= SCREEN_WIDTH_MAX_MOBILE;

        let selectedLanguageFlag;
        let otherLanguageFlag;
        let changeToLanguage;

        if (language === 'fi') {
            selectedLanguageFlag = <Flag name="finland" />;
            otherLanguageFlag = <Flag name="gb" />;
            changeToLanguage = 'en';
        } else if (language === 'en') {
            selectedLanguageFlag = <Flag name="gb" />;
            otherLanguageFlag = <Flag name="finland" />;
            changeToLanguage = 'fi';
        }

        return (
            <Dropdown id="changeLanguage" trigger={selectedLanguageFlag} icon={isMobile ? null : 'dropdown'}>
                <Dropdown.Menu>
                    <Dropdown.Item className="changeLanguageItem" onClick={this.changeLanguage(changeToLanguage)}>
                        {otherLanguageFlag}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default compose(
    connect(
        state => ({
            language: state.profile.language
        }),
        {
            setLanguage
        }
    )
)(ChangeLanguage);

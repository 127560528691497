import { gql } from '@apollo/client';

export const interactionResultFragment = gql`
    fragment InteractionResultFragment on UIInteractionResultNode {
        redirectTo
    }
`;

export const uiInteractionFragment = gql`
    fragment InteractionInputFragment on InteractionInputNode {
        type
        name
        placeholder
        label
        initialValue

        selectTargetName
        selectAllowAdd
        selectMultiple
    }

    fragment InteractionActionFragment on InteractionActionNode {
        handle
        value
        title
        importance
        color
    }

    fragment InteractionFragment on InteractionNode {
        id
        title
        description
        label
        color

        inputs {
            ...InteractionInputFragment
        }

        actions {
            ...InteractionActionFragment
        }
    }

    fragment UIInteractionFragment on UIInteractionNode {
        id
        context

        interaction {
            ...InteractionFragment
        }
    }
`;

export const employmentFragment = gql`
    fragment EmploymentFragment on EmploymentNode {
        id

        department {
            id
            name
        }

        location
        position
        superior

        startDate
        endDate
    }
`;

export const profileFragment = gql`
    fragment ProfileFragment on MemberNode {
        id
        firstName
        lastName
        language
        lang
        memberCommunicationPermissionAt
        marketingPermissionAt
        feedbackPermissionAt
        termsAccepted
        verified
        verificationRequired
        phone
        email
        address1
        address2
        zip
        city
        hasUsablePassword
        hasCompanyAccess

        employments {
            edges {
                node {
                    ...EmploymentFragment

                    company {
                        name

                        membersCanReportSickLeave
                        sickLeaveMinDays
                        sickLeaveMaxDays
                    }
                }
            }
        }
    }

    ${employmentFragment}
`;

export const userFragment = gql`
    fragment UserFragment on UserNode {
        id

        firstName
        lastName

        title

        avatarBigUrl
        avatarSmallUrl
    }
`;

export const calendarReservationAccessFragment = gql`
    fragment CalendarReservationAccessFragment on CalendarReservationAccessNode {
        id
        createdAt
        expiresAt

        type
        durations
        description
        contactTypes

        groups

        users {
            ...UserFragment
        }
    }

    ${userFragment}
`;

export const locationFragment = gql`
    fragment LocationFragment on LocationNode {
        id
        name
        type
        address1
        address2
        zip
        city
    }
`;

export const calendarEventFragment = gql`
    fragment CalendarEventFragment on CalendarEventNode {
        id

        location {
            ...LocationFragment
        }

        type
        start
        end
        title
        description
        isModifiable

        calendarReservationAccess {
            ...CalendarReservationAccessFragment
        }

        videoAppointmentDetails {
            id
            uuid
            participantUrl
        }

        calendar {
            user {
                ...UserFragment
            }
        }
    }

    ${calendarReservationAccessFragment}
    ${userFragment}
    ${locationFragment}
`;

export const appointmentFragment = gql`
    fragment AppointmentFragment on AppointmentNode {
        id
        start
        end
        duration

        location {
            ...LocationFragment
        }

        user {
            ...UserFragment
        }

        meta

        contactTypes
    }

    ${locationFragment}
    ${userFragment}
`;

export const appointmentDayFragment = gql`
    fragment AppointmentDayFragment on AppointmentDayNode {
        id
        date
        eventCount

        nextAppointments {
            id
            start
            end

            location {
                ...LocationFragment
            }

            meta
        }
    }

    ${locationFragment}
`;

export const chatMemberFragment = gql`
    fragment ChatMemberFragment on MemberNode {
        id
        firstName
        lastName

        avatarBigUrl
        avatarSmallUrl
    }
`;

export const chatMessageInteractionFragment = gql`
    fragment ChatMessageInteractionFragment on ChatMessageInteractionNode {
        id
        uiInteraction {
            ...UIInteractionFragment
        }
        chatMessage {
            id
        }
    }

    ${uiInteractionFragment}
`;

export const chatMessageFileAttachmentFragment = gql`
    fragment ChatMessageFileAttachmentFragment on ChatMessageAttachmentNode {
        id
        filename
        url
    }
`;

export const chatMessageIdAttachmentFragment = gql`
    fragment ChatMessageIdAttachmentFragment on ChatMessageIdAttachmentNode {
        id
        attachmentId
        title
    }
`;

export const chatMessageAttachmentFragment = gql`
    fragment ChatMessageAttachmentFragment on ChatAttachment {
        ... on ChatMessageAttachmentNode {
            ...ChatMessageFileAttachmentFragment
        }
        ... on ChatMessageIdAttachmentNode {
            ...ChatMessageIdAttachmentFragment
        }
        ... on ChatMessageInteractionNode {
            ...ChatMessageInteractionFragment
        }
    }

    ${chatMessageFileAttachmentFragment}
    ${chatMessageIdAttachmentFragment}
    ${chatMessageInteractionFragment}
`;

export const chatMessageFragment = gql`
    fragment ChatMessageFragment on ChatMessageNode {
        id
        createdAt
        deletedAt

        seen
        type

        message

        user {
            ...UserFragment
        }

        member {
            ...ChatMemberFragment
        }

        attachmentsV2 {
            ...ChatMessageAttachmentFragment
        }
    }

    ${userFragment}
    ${chatMemberFragment}
    ${chatMessageAttachmentFragment}
`;

export const chatUsersFragment = gql`
    fragment ChatUsersFragment on ChatNode {
        users {
            edges {
                node {
                    id
                    user {
                        ...UserFragment
                    }

                    leftAt
                }
            }
        }
    }

    ${userFragment}
`;

export const chatMembersFragment = gql`
    fragment ChatMembersFragment on ChatNode {
        members {
            edges {
                node {
                    id
                    member {
                        id
                    }

                    lastMessageSeen {
                        id
                    }
                    leftAt
                }
            }
        }
    }

    ${userFragment}
`;

export const chatInformationFragment = gql`
    fragment ChatInformationFragment on ChatNode {
        id
        type
        title
        responseTimeSla
        description
        modifiedAt
        lastMessageAt
        closedAt
        readonly
    }
`;

export const chatNotificationActionFragment = gql`
    fragment ChatNotificationActionFragment on ChatNotificationActionNode {
        id
        type
        params {
            key
            value
        }
        contents {
            key
            value
        }
    }
`;

export const chatNotificationFragment = gql`
    fragment ChatNotificationFragment on ChatNotificationNode {
        id
        context
        contents {
            key
            value
        }
        actions {
            ...ChatNotificationActionFragment
        }
    }

    ${chatNotificationActionFragment}
`;

export const chatNotificationsFragment = gql`
    fragment ChatNotificationsFragment on ChatNode {
        notifications {
            ...ChatNotificationFragment
        }
    }

    ${chatNotificationFragment}
`;

export const chatFragment = gql`
    fragment ChatFragment on ChatNode {
        ...ChatInformationFragment
        ...ChatNotificationsFragment
        ...ChatUsersFragment
        ...ChatMembersFragment
    }

    ${chatInformationFragment}
    ${chatUsersFragment}
    ${chatNotificationsFragment}
    ${chatMembersFragment}
`;

export const chatListFragment = gql`
    fragment ListChatFragment on ChatNode {
        ...ChatInformationFragment
        ...ChatUsersFragment

        unreadMessagesCountV2
    }

    ${chatInformationFragment}
    ${chatUsersFragment}
`;

export const listDocumentFragment = gql`
    fragment ListDocumentFragment on MemberDocumentNode {
        id

        filename

        addedDate
        memberSeenAt
        memberVisibleComment
    }
`;

export const documentFragment = gql`
    fragment DocumentFragment on MemberDocumentNode {
        id

        filename
        url

        addedDate
        memberVisibleComment
        memberSeenAt

        createdBy {
            id

            firstName
            lastName

            avatarSmallUrl
            title
        }

        exportTokens {
            edges {
                node {
                    id
                    token
                    validUntil
                    deletedAt
                }
            }
        }
    }
`;

export const notificationFragment = gql`
    fragment NotificationFragment on MemberNotificationNode {
        id
        createdAt

        sender {
            ...UserFragment
        }

        title
        message
    }

    ${userFragment}
`;

export const listQuestionnaireFragment = gql`
    fragment ListQuestionnaireFragment on QuestionnaireNode {
        id
        title
        description
    }
`;

export const questionnaireQuestionFragment = gql`
    fragment QuestionnaireQuestionFragment on QuestionnaireQuestionNode {
        id

        type
        orderNumber

        title
        description

        options {
            title
            value
        }
    }
`;

export const badgesFragment = gql`
    fragment BadgesFragment on BadgeNode {
        unseenChatMessages
        unseenDocuments
        openQuestionnaires
        openCalendarReservationAccesses
    }
`;

export const questionnaireFragment = gql`
    fragment QuestionnaireFragment on QuestionnaireNode {
        id

        title
        description
        type

        outro
        outroQuestionsTitle

        questions {
            edges {
                node {
                    ...QuestionnaireQuestionFragment
                }
            }
        }

        outroQuestions {
            edges {
                node {
                    ...QuestionnaireQuestionFragment
                }
            }
        }
    }

    ${questionnaireQuestionFragment}
`;

export const listQuestionnaireResponseFragment = gql`
    fragment ListQuestionnaireResponseFragment on QuestionnaireResponseNode {
        id

        analysedAt
        createdAt
        modifiedAt
        finishedAt
        closedAt
        closeAt

        questionnaire {
            ...ListQuestionnaireFragment
        }
    }

    ${listQuestionnaireFragment}
`;

export const questionnaireQuestionCategoryFragment = gql`
    fragment QuestionnaireQuestionCategoryFragment on QuestionnaireQuestionCategoryNode {
        id
        key
        icon
        color
        orderNumber
    }
`;

export const questionnaireQuestionResponseFragment = gql`
    fragment QuestionnaireQuestionResponseFragment on QuestionnaireQuestionResponseNode {
        id

        strValue
        listValue

        questionnaireQuestion {
            id
            type
            title
            description

            options {
                title
                value
                weight
            }

            category {
                ...QuestionnaireQuestionCategoryFragment
            }
        }
    }

    ${questionnaireQuestionCategoryFragment}
`;

export const questionnaireResponseFragment = gql`
    fragment QuestionnaireResponseFragment on QuestionnaireResponseNode {
        id

        analysedAt
        finishedAt
        closedAt
        closeAt

        questionnaire {
            id
            type
        }

        responses {
            edges {
                node {
                    ...QuestionnaireQuestionResponseFragment
                }
            }
        }

        summary {
            description

            categories {
                key
                icon
                title
                popup
                value
                color
            }
        }
    }

    ${questionnaireQuestionResponseFragment}
`;

export const memberActionFragment = gql`
    fragment MemberActionFragment on MemberActionNode {
        type
        title
        description
        responseTimeSla
        color
        category
        imageUrl
        responseTimeSla
        typeNew
        actionUrl
        chat {
            ...ListChatFragment
        }
    }

    ${chatListFragment}
`;

export const memberActionsFragment = gql`
    fragment MemberActionsFragment on MemberActionsNode {
        actions {
            ...MemberActionFragment
        }
    }

    ${memberActionFragment}
`;

export const parentArticleFragment = gql`
    fragment ParentArticleFragment on ArticleNode {
        id
        title

        parentArticle {
            id
            title
        }
    }
`;

export const articleFragment = gql`
    fragment ArticleFragment on ArticleNode {
        id

        createdAt
        modifiedAt

        type
        title
        highlight
        content

        coverPhotoUrl

        parentArticle {
            ...ParentArticleFragment
        }

        childArticles {
            edges {
                node {
                    id

                    type
                    title
                    highlight

                    coverPhotoUrl
                    videoHandle
                }
            }
        }
    }

    ${parentArticleFragment}
`;

export const regionFragment = gql`
    fragment RegionFragment on RegionNode {
        id

        name
        geometry

        locations {
            edges {
                node {
                    ...LocationFragment
                }
            }
        }
    }

    ${locationFragment}
`;

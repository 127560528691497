import React from 'react';
import { AuthState } from '../ducks/auth';
import { Redirect, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteProps } from 'react-router';

import { paths } from '../constants';
import WithProfile from './WithProfile';
import { Feature } from '../graphql-schema';

type Props = OwnProps & RouteProps & RouteComponentProps<any>;

interface FeatureFlagCheckProps {
    memberFeatures: Readonly<Feature[]>;
    requiredFeatures: Readonly<Feature[]>;
}

interface OwnProps {
    memberAuthState: AuthState;
    component: React.Component<any> | React.FC<any>;
    performFeatureFlagCheckWith?: FeatureFlagCheckProps;
    forceUnmountOnPathChange?: boolean; // Sometimes we need to unmount the whole Component when the URL changes.
}

class PrivateRoute extends React.Component<Props> {
    private hasFeature = () => {
        const { performFeatureFlagCheckWith } = this.props;

        // If feature flags are not passed (either performFeatureFlagCheckWith or its requiredFeatures is empty),
        // assume that the inexistence of a feature flag implicitly means that access = true.
        if (performFeatureFlagCheckWith) {
            const { memberFeatures, requiredFeatures } = performFeatureFlagCheckWith;

            if (requiredFeatures.length > 0) {
                return (
                    !!memberFeatures &&
                    !!requiredFeatures &&
                    requiredFeatures
                        .map(feature => memberFeatures.includes(feature))
                        .every(hasAccessToFeature => hasAccessToFeature === true)
                );
            }
        }

        return true;
    };

    public render() {
        const { history, memberAuthState, component: Component, forceUnmountOnPathChange, ...rest } = this.props;

        const renderWithProfileOrRedirect = props => {
            const { isAuthenticated, isVerified, verificationRequired, isEmployee } = memberAuthState;

            if (!isAuthenticated) {
                // By default, unauthenticated user is always redirected to login. As an exception, if the
                // user is navigating to home '/', redirect to '/welcome'.
                if (props.match.path === paths.home) {
                    return <Redirect to={{ pathname: paths.welcome, state: { from: props.location } }} />;
                }
                return <Redirect to={{ pathname: paths.login, state: { from: props.location } }} />;
            }

            // User is verified and has access to occupational services
            if (!isVerified && verificationRequired) {
                return <Redirect to={{ pathname: paths.notVerifiedIndex, state: { from: props.location } }} />;
            }

            // User is authenticated and verified, but not an employee
            if (!isEmployee) {
                return <Redirect to={{ pathname: paths.profile, state: { from: props.location } }} />;
            }

            // If the user does not have the requested feature, redirect to home (/welcome).
            if (!this.hasFeature()) {
                return <Redirect to={{ pathname: paths.home, state: { from: props.location } }} />;
            }

            return (
                <WithProfile>
                    <Component {...props} />
                </WithProfile>
            );
        };

        return (
            <Route
                {...rest}
                render={renderWithProfileOrRedirect}
                key={forceUnmountOnPathChange ? rest.location.key : undefined}
            />
        );
    }
}

export default withRouter(PrivateRoute);

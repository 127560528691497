import React, { FunctionComponent, useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { ChatFragment, ChatNotificationFragment } from '../../graphql-schema';
import { FormatMarkdown } from '../../components/FormatMarkdown';
import t from '../../translations';
import Action from './ChatNotificationActions/Action';

import { valueForKey } from '../../util/keyvalue';

type ChatNotificatonAlertProps = WrappedComponentProps & {
    alert: ChatNotificationFragment;
    chat: ChatFragment;
};

const ChatNotificationAlert: FunctionComponent<ChatNotificatonAlertProps> = ({ alert, chat, intl }) => {
    const { contents, actions } = alert;
    const [visible, setVisible] = useState<boolean>(true);

    const message = valueForKey(contents, 'message');

    return (
        <div className={`chatNotificationBox ${visible && 'isVisible'}`}>
            <div>{contents && <FormatMarkdown source={message} />}</div>
            <aside>
                <a href="#" onClick={() => setVisible(false)}>
                    {intl.formatMessage(t.chatNotificationHide)}
                </a>
                {actions?.map(action => (
                    <Action key={action.id} action={action} chat={chat} />
                ))}
            </aside>
        </div>
    );
};

type ChatNotificatonAlertsProps = WrappedComponentProps & {
    alerts: readonly ChatNotificationFragment[];
    chat: ChatFragment;
};

const ChatNotificationAlerts: FunctionComponent<ChatNotificatonAlertsProps> = props => {
    const { alerts, chat, intl } = props;

    return (
        <div className="chatNotificationBoxContainer">
            {alerts.map(alert => (
                <ChatNotificationAlert key={alert.id} alert={alert} intl={intl} chat={chat} />
            ))}
        </div>
    );
};

export default injectIntl(ChatNotificationAlerts);

import React, { PropsWithChildren } from 'react';

interface Props {
    id?: string;
    containerWidth?: 'full' | 'wide' | 'medium' | 'narrow';
    containerHeight?: 'full';
    handleRef?: any;
}

export const Content: React.FC<PropsWithChildren<Props>> = props => {
    const { id, containerWidth, containerHeight, handleRef } = props;

    const classNames = [
        `contentContainer pageContent`,
        containerWidth || 'wide',
        containerHeight === 'full' ? 'fullHeight' : ''
    ].join(' ');

    return (
        <div id={id} className={classNames} ref={handleRef}>
            {props.children}
        </div>
    );
};

import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Header } from 'semantic-ui-react';
import { Error, useLoadingQuery } from '@heltti/components';

import { ChatsQuery } from '../../graphql-schema';
import { chatsQuery } from '../../data/queries';
import t from './../../translations';
import { Loader } from '../../components/Loader';
import { ListChat } from '../../components/ListChat';
import { getNodes } from '@heltti/common';
import { Translation } from '../../components/Message';

export const ChatsHighlight: React.FC = () => {
    const { formatMessage } = useIntl();

    const { data, loading, error } = useLoadingQuery<ChatsQuery>(chatsQuery, {
        variables: { recent: true }
    });

    const chats = getNodes(data?.root?.chats);
    const noChatsAvailable = chats.length === 0;

    return (
        <div>
            <Header>
                <Translation message={t.chatsTitle} />

                <div style={{ float: 'right', fontSize: '80%' }}>
                    <Link to="/chats/archive">{formatMessage(t.showAll)}</Link>
                </div>
            </Header>

            {loading && <Loader />}
            {error && <Error showRefreshButton />}

            {!loading && noChatsAvailable && <p>{formatMessage(t.chatsNoAvailableChats)}</p>}

            {chats.map(chat => (
                <ListChat key={chat.id} chat={chat} />
            ))}
        </div>
    );
};

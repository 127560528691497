import axios from 'axios';

import { getCookie } from '@heltti/common';

import { config } from './config';
import { v4 as uuid } from 'uuid';

const api = `${config.apiUrl}`;

const axiosInstance = axios.create({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    baseURL: api
});

axiosInstance.interceptors.request.use(axiosConfig => {
    axiosConfig.headers.common = {
        ...axiosConfig.headers.common,
        'X-CSRFToken': getCookie('csrftoken'),
        'X-Request-ID': uuid()
    };

    return axiosConfig;
});

export function authenticate(username: string, password: string): Promise<any> {
    return axiosInstance.post('/auth/authenticate', { username, password }).then(res => res.data);
}

export function logout(): Promise<any> {
    return axiosInstance
        .post('/auth/logout')
        .catch(e => e)
        .then(_ => true);
}

export function refreshAuthentication(): Promise<any> {
    return axiosInstance.post('/auth/refresh').then(res => res.data);
}

export function forgotPassword(email: string): Promise<any> {
    return axiosInstance.post('/password/forgot', { email }).then(res => res.data);
}

export function resetPassword(
    smsToken: string,
    token: string,
    email: string,
    oldPassword: string,
    newPassword: string
): Promise<any> {
    return axiosInstance
        .post('/password/reset', { smsToken, token, email, oldPassword, password: newPassword })
        .then(res => res.data);
}

export function acceptTerms(
    isMemberCommunicationAccepted: boolean,
    isMarketingAccepted: boolean,
    isFeedbackAccepted: boolean
): Promise<any> {
    return axiosInstance
        .post('/acceptTerms', {
            terms_accepted: true,
            marketing_accepted: isMarketingAccepted,
            member_communication_accepted: isMemberCommunicationAccepted,
            feedback_accepted: isFeedbackAccepted
        })
        .then(res => res.data);
}

export function saveNPS(
    npsUUID: string,
    scoreNps: number,
    scoreDetail1: number | undefined,
    scoreDetail2: number | undefined,
    comment: string,
    contactPermission: boolean
): Promise<any> {
    return axiosInstance
        .post('/nps/' + npsUUID, { scoreNps, scoreDetail1, scoreDetail2, comment, contactPermission })
        .then(res => res.data);
}

export function getUrlForExportFile(lastName: string, token: string) {
    return axiosInstance.post('/download/export/getUrl', { lastName, token });
}

export function getUrlForEident() {
    return axiosInstance.get('/e-ident/getUrl?s=member');
}

export function callEIdentVerification(urlParams: string) {
    return axiosInstance.get('/e-ident' + urlParams).then(response => response.data);
}

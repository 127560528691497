import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { LoadingQuery, LoadingQueryResult } from '@heltti/components';

import QuestionnaireResponse from './QuestionnaireResponse';
import { questionnaireResponseQuery } from '../../data/queries';
import { QuestionnaireResponseQuery } from '../../graphql-schema';

import './questionnaire.less';

type Props = RouteComponentProps<any>;

export const Questionnaire: React.FC<Props> = props => {
    const renderWithResult = (result: LoadingQueryResult<QuestionnaireResponseQuery>) => {
        const { questionnaireResponse } = result.data?.root ?? {};

        if (!questionnaireResponse) {
            return null;
        }

        return (
            <div id="questionnaire">
                <QuestionnaireResponse
                    questionnaire={questionnaireResponse.questionnaire}
                    questionnaireResponse={questionnaireResponse}
                />
            </div>
        );
    };

    return (
        <LoadingQuery
            query={questionnaireResponseQuery}
            variables={{ id: props.match.params.id }}
            onResult={renderWithResult}
        />
    );
};

import { History } from 'history';

/**
 * Push local URLs to history, open external links in new tab / window
 *
 * @param url       URL to open
 * @param history   instance of History, i.e. acquired from useHistory() hook
 */
export function openURL(url: URL, history: History) {
    if (url.host === window.location.host) {
        history.push(url.pathname);
    } else {
        window.open(url, '_blank');
    }
}

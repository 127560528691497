import React, { Component } from 'react';

interface Props {
    id?: string;
    backgroundColor?: string;
    width?: 'full' | 'wide' | 'medium' | 'narrow';
    height?: 'full';
    padded?: boolean;
}

export default class Section extends Component<Props> {
    public render() {
        const { backgroundColor, width, padded, id } = this.props;

        const classNames = `contentContainer ${width || 'wide'} ${this.props.height === 'full' ? 'fullHeight' : ''}`;

        return (
            <div
                id={id ? id : undefined}
                style={{
                    backgroundColor: backgroundColor ? backgroundColor : 'transparent',
                    height: '100%',
                    padding: padded ? '30px 0' : 0
                }}
            >
                <div className={classNames}>{this.props.children}</div>
            </div>
        );
    }
}

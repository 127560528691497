import React, { useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Icon } from 'semantic-ui-react';

import { QuestionnaireResponseBatchQuery_root_questionnaireResponseBatches_edges_node_questionnaireResponses_edges_node } from '../../graphql-schema';
import { questionnaireResponseTokenUrl } from '../../util/tokenUrls';
import { DescriptionWithEllipsis } from './QuestionnaireDescriptionWithEllipsis';

type QuestionnaireResponse =
    QuestionnaireResponseBatchQuery_root_questionnaireResponseBatches_edges_node_questionnaireResponses_edges_node;

type QuestionnaireResponseListItemProps = RouteComponentProps<any> & {
    response: QuestionnaireResponse;
};

const QuestionnaireResponseListItem = (props: QuestionnaireResponseListItemProps) => {
    const { response, history } = props;
    const { batchId, memberId, token } = props.match.params;

    const openQuestionnaire = useCallback(() => {
        if (token) {
            history.push(questionnaireResponseTokenUrl(memberId, batchId, token, response.id));
        } else {
            history.push(`/questionnaire/${response.id}`);
        }
    }, [response.id, memberId, batchId, token]);

    const isFinished = response.finishedAt !== null;

    const listItemExtra = isFinished ? (
        <Icon name="check circle" color="green" />
    ) : (
        <Icon name="circle outline" color="red" />
    );

    return (
        <div className="listItem" onClick={openQuestionnaire}>
            <div className="listItemTitle">
                <h4>{response.questionnaire.title}</h4>

                <DescriptionWithEllipsis
                    description={response.questionnaire.description || ''}
                    onShowMoreClicked={openQuestionnaire}
                />
            </div>

            <div className="listItemExtra">{listItemExtra}</div>
        </div>
    );
};

const InjectedQuestionnaireResponseListItem = withRouter(QuestionnaireResponseListItem);

export { InjectedQuestionnaireResponseListItem as QuestionnaireResponseListItem };

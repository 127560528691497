import React, { Component } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Grid } from 'semantic-ui-react';

import t from '../translations';
import { ExternalLink } from './ExternalLink';

type Props = WrappedComponentProps;

class Footer extends Component<Props> {
    public render() {
        const { intl } = this.props;

        return (
            <div id="footer">
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Link to="/faq">{intl.formatMessage(t.supportFAQPageHeader)}</Link>
                        </Grid.Column>

                        <Grid.Column>
                            <ExternalLink href={intl.formatMessage(t.dataProtectionDescriptionUrl)}>
                                {intl.formatMessage(t.dataProtectionDescriptionLink)}
                            </ExternalLink>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

const InjectedFooter = injectIntl(Footer);

export { InjectedFooter as Footer };

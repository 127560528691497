import { AnyAction } from 'redux';

export const OPEN_VIDEO = 'heltti/modal/openVideo';
export const CLOSE_MODAL = 'heltti/modal/close';

export interface ModalState {
    videoHandle: string | null;
}

function getInitialState(): ModalState {
    return {
        videoHandle: null
    };
}

export default function flow(state: ModalState = getInitialState(), action: AnyAction): ModalState {
    switch (action.type) {
        case OPEN_VIDEO:
            return {
                ...state,
                videoHandle: action.videoHandle
            };

        case CLOSE_MODAL:
            return getInitialState();

        default:
            return state;
    }
}

export function openVideo(videoHandle: string): AnyAction {
    return { type: OPEN_VIDEO, videoHandle };
}

export function closeModal(): AnyAction {
    return { type: CLOSE_MODAL };
}

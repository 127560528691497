import React from 'react';

import { Grid } from 'semantic-ui-react';

import { Error, useLoadingQuery } from '@heltti/components';

import { Content } from '../../components/Content';
import { NewDocuments } from './NewDocuments';
import { ProfileQuery } from '../../graphql-schema';
import { profileQuery } from '../../data/queries';
import { QuestionnaireList } from '../Questionnaire/QuestionnaireList';
import { NotificationsList } from './NotificationsList';
import CalendarEventList, { CalendarEventCategory } from '../../components/CalendarEventList';
import { Footer } from '../../components/Footer';
import { AvailableGlobalAppointments, AvailablePersonalAppointments } from '../Appointments/AvailableAppointmentsList';
import NoEmploymentInfoCard from '../Profile/NoEmploymentInfoCard';
import { getNodes } from '@heltti/common';
import { ChatsHighlight } from '../Paths/ChatsHighlight';
import { CompanyInstructionsCard } from './CompanyInstructionsCard';

import './home.less';

export const Home: React.FC = () => {
    const { data, error, loading } = useLoadingQuery<ProfileQuery>(profileQuery);

    if (error) {
        return <Error showRefreshButton />;
    }

    const [employment] = getNodes(data?.root?.me?.employments);

    return (
        <div id="home" style={{ paddingTop: '20px' }}>
            <Content containerWidth="wide">
                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column tablet={16} computer={10}>
                            {!employment && !loading && <NoEmploymentInfoCard />}
                            <ChatsHighlight />
                            <NewDocuments hideIfNoItems />
                            <QuestionnaireList hideFinished hideIfNoItems />
                            <NotificationsList />
                        </Grid.Column>

                        <Grid.Column tablet={16} computer={6}>
                            <CompanyInstructionsCard />
                            <CalendarEventList eventCategory={CalendarEventCategory.FutureEvents} />
                            <AvailablePersonalAppointments />
                            <AvailableGlobalAppointments />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Content>
            <Footer />
        </div>
    );
};

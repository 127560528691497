import { useCallback, useMemo } from 'react';
import { useLoadingQuery } from '@heltti/components';
import { calendarReservationAppointmentDaysQuery } from '../../data/queries';
import {
    AppointmentContactType,
    AppointmentFilters,
    AppointmentTimeOfDay,
    CalendarReservationAppointmentDaysQuery,
    CalendarReservationAppointmentDaysQueryVariables
} from '../../graphql-schema';

export const AppointmentRemoteContactTypeValues: readonly AppointmentContactType[] = [
    AppointmentContactType.Phone,
    AppointmentContactType.Video
] as const;

export type AppointmentDaysHookParams = {
    calendarReservationAccessId: string;
    start: string;
    end: string;
    regionId?: string;
    locationId?: string;
    timeOfDay?: AppointmentTimeOfDay;
    filters?: AppointmentFilters;
};

export function useAppointmentDays(params: AppointmentDaysHookParams) {
    const { calendarReservationAccessId, start, end, regionId, locationId, filters } = params;

    const { data, loading, error, fetchMore } = useLoadingQuery<
        CalendarReservationAppointmentDaysQuery,
        CalendarReservationAppointmentDaysQueryVariables
    >(calendarReservationAppointmentDaysQuery, {
        variables: {
            craId: calendarReservationAccessId,
            start,
            end,
            regionId,
            locationId,
            // Undefined region id implies "remote times" only
            filters: regionId ? filters : { ...filters, contactTypes: AppointmentRemoteContactTypeValues }
        }
    });

    const fetchMoreAppointmentDays = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        async (from: string, to: string) => {
            try {
                await fetchMore({ variables: { start: from, end: to } });
            } catch (err: unknown) {
                console.error(err);
            }
        },
        [fetchMore]
    );

    return {
        loading,
        error,
        appointmentDays: data?.root?.calendarReservationAccess?.appointmentDays,
        fetchMoreAppointmentDays
    };
}

export const appointmentDaysTypePolicy = {
    keyArgs: ['craId', 'regionId', 'locationId', 'filters'],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    merge(existing: any[] = [], incoming: any[]) {
        // Merge existing and incoming arrays by object `__ref` to prevent duplicates if there are overlapping queries

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const identity = ({ __ref }: any) => __ref;
        const set = new Set([...existing.map(identity), ...incoming.map(identity)]);

        return [...set].map(ref => ({ __ref: ref }));
    }
};

import React, { Component } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Button, Divider, Grid, Header } from 'semantic-ui-react';

import Section from '../../components/Section';
import t from '../../translations';
import { QuestionnaireFragment } from '../../graphql-schema';

type Props = OwnProps & WrappedComponentProps;

interface OwnProps {
    questionnaire: QuestionnaireFragment;
    saveOutroQuestions(): Promise<any>;
    renderQuestion: Function;
    goBack: Function;
}

interface State {
    isOutroQuestionLoading?: boolean;
}

class QuestionnaireOutro extends Component<Props, State> {
    public state: State = {
        isOutroQuestionLoading: false
    };

    private saveOutroQuestions = () => {
        this.setState({ isOutroQuestionLoading: true }, () => {
            this.props.saveOutroQuestions().catch(() => this.setState({ isOutroQuestionLoading: false }));
        });
    };

    public render() {
        const { isOutroQuestionLoading } = this.state;

        const { intl, questionnaire, renderQuestion, goBack } = this.props;

        const hasOutroQuestions = questionnaire.outroQuestions && questionnaire.outroQuestions.edges.length > 0;

        if (!hasOutroQuestions) {
            return null;
        }

        return (
            <Section backgroundColor="#EEF7FB" width="medium" padded>
                <Grid>
                    <Divider hidden />

                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Header as="h2">{questionnaire.outroQuestionsTitle}</Header>

                            <Divider hidden />

                            {questionnaire.outroQuestions.edges.map((edge, i) => renderQuestion(edge?.node, i + 1))}
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Button basic fluid onClick={goBack as any} tabIndex="2" type="button">
                                {intl.formatMessage(t.questionnairePreviousButton)}
                            </Button>
                        </Grid.Column>

                        <Grid.Column>
                            <Button
                                primary
                                fluid
                                onClick={this.saveOutroQuestions}
                                loading={isOutroQuestionLoading === true}
                                tabIndex="1"
                                type="submit"
                            >
                                {intl.formatMessage(t.questionnaireNextButton)}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Section>
        );
    }
}

export default injectIntl(QuestionnaireOutro);

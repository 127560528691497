import React, { Component } from 'react';
import { QueryResult } from '@apollo/client';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Divider, Grid, Header } from 'semantic-ui-react';

import { Error, Loader } from '@heltti/components';

import { ProfileForm } from '../../components/ProfileForm';
import Card from '../../components/Card';
import Logout from './../../components/Logout';
import { profileQuery } from '../../data/queries';
import { ProfileQuery } from '../../graphql-schema';
import t from '../../translations';
import NoEmploymentInfoCard from './NoEmploymentInfoCard';

import './Profile.less';

type Props = OwnProps & WrappedComponentProps;

interface OwnProps {
    data: QueryResult & ProfileQuery;
}

function EmploymentInfoCard(intl, employment) {
    return (
        <Card title={intl.formatMessage(t.profileEmployment)}>
            <Header as="h4">
                {employment.company.name}
                {!!employment.position && <br />} {employment.position}
            </Header>
        </Card>
    );
}

class Profile extends Component<Props> {
    public render() {
        const { data, intl } = this.props;

        if (data.loading || !data?.root?.me?.employments) {
            return <Loader />;
        } else if (data.error) {
            return <Error />;
        }

        const me = data.root.me;

        const [employmentEdge] = me.employments?.edges ?? [];
        const employment = !!employmentEdge && employmentEdge.node;

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column computer={8} tablet={16}>
                        <Card title={intl.formatMessage(t.profile.title)}>
                            <Divider />
                            <ProfileForm
                                initialValues={me}
                                allowInlinePasswordEditing={false}
                                allowFeedbackPermissionEditing={true}
                            />
                        </Card>

                        <p style={{ marginBottom: 20, textAlign: 'center' }}>
                            <a
                                href={intl.formatMessage(t.dataProtectionDescriptionUrl)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {intl.formatMessage(t.dataProtectionDescriptionLink)}
                            </a>
                        </p>
                    </Grid.Column>

                    <Grid.Column computer={8} tablet={16}>
                        {employment && EmploymentInfoCard(intl, employment)}
                        {!employment && <NoEmploymentInfoCard />}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row only="mobile">
                    <Grid.Column mobile={16}>
                        <Logout isButton />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default compose(injectIntl, graphql(profileQuery))(Profile);

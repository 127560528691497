import { LanguageCode } from '../graphql-schema';

export const INIT_LANGUAGE = 'heltti/profile/initLanguage';
export const SET_LANGUAGE = 'heltti/profile/setLanguage';

const defaultLanguage = 'fi';

export const languages = {
    1: { shortName: 'fi', name: 'suomi', type: LanguageCode.Fi },
    3: { shortName: 'en', name: 'English', type: LanguageCode.En }
};

export interface ProfileState {
    language?: 'fi' | 'en';
}

function getInitialState() {
    return {
        language: undefined
    };
}

export function auth(state: ProfileState = getInitialState(), action: any): ProfileState {
    switch (action.type) {
        case INIT_LANGUAGE:
        case SET_LANGUAGE: {
            document.documentElement.lang = action.payload.language;
            return {
                ...state,
                language: action.payload.language
            };
        }
        default:
            return state;
    }
}

export function initLanguage() {
    return dispatch => {
        const language = (window.location.href.includes('?lang=en') && 'en') || window.localStorage.getItem('language');

        if (!language) {
            dispatch({ type: INIT_LANGUAGE, payload: { language: defaultLanguage } });

            return window.localStorage.setItem('language', defaultLanguage);
        }

        return dispatch({ type: INIT_LANGUAGE, payload: { language } });
    };
}

export function setLanguage(language: string) {
    return dispatch => {
        dispatch({ type: INIT_LANGUAGE, payload: { language } });

        return window.localStorage.setItem('language', language);
    };
}

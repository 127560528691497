import React, { PureComponent } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Button } from 'semantic-ui-react';

import { ChatNotificationActionFragment } from '../../../graphql-schema';
import t from '../../../translations';
import { valueForKey } from '../../../util/keyvalue';

type Props = WrappedComponentProps & {
    action: ChatNotificationActionFragment;
};

class Call extends PureComponent<Props> {
    public render() {
        const { intl, action } = this.props;
        const phone = valueForKey(action.params, 'phone');
        return (
            <a href={`tel:${phone.replace(/ /g, '')}`} style={{ borderBottom: 0 }}>
                <Button primary>{intl.formatMessage(t.chatNotificationActionsCallButton)}</Button>
            </a>
        );
    }
}

export default injectIntl(Call);

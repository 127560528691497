import React, { Component } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { QueryResult } from '@apollo/client';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { Loader } from '@heltti/components';

import SickLeaveForm from './SickLeaveForm';
import Card from '../../components/Card';
import PageHeader from '../../components/PageHeader';
import { Content } from '../../components/Content';
import { profileQuery } from '../../data/queries';
import { ProfileQuery } from '../../graphql-schema';
import t from '../../translations';

type Props = OwnProps & RouteComponentProps<any> & WrappedComponentProps;

interface OwnProps {
    data: QueryResult & ProfileQuery;
}

class SickLeave extends Component<Props> {
    private handleSuccessDismiss = () => {
        this.props.history.goBack();
    };

    public render() {
        const { data, intl } = this.props;

        if (data.loading) {
            return <Loader />;
        }

        const {
            data: {
                root: { me }
            }
        } = this.props;

        const [employment] = me.employments.edges;

        if (!employment) {
            return null;
        }

        const { sickLeaveMinDays, sickLeaveMaxDays, membersCanReportSickLeave } = employment.node.company;

        // TODO: Show some notification instead of Redirect if sick leave can not be reported?

        return (
            <div id="sick-leave">
                <PageHeader
                    hideBackgroundImage
                    title={intl.formatMessage(t.reportSickLeaveTitle)}
                    description={intl.formatMessage(t.reportSickLeaveDescription)}
                />

                <Content>
                    <Card>
                        {!membersCanReportSickLeave && <Redirect to="/chats" />}

                        {membersCanReportSickLeave && (
                            <SickLeaveForm
                                minimumDays={sickLeaveMinDays}
                                maximumDays={sickLeaveMaxDays}
                                onDismissSuccessModal={this.handleSuccessDismiss}
                            />
                        )}
                    </Card>
                </Content>
            </div>
        );
    }
}

export default compose(injectIntl, graphql(profileQuery))(SickLeave);

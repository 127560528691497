import React, { Component } from 'react';
import { documentsQuery } from '../../data/queries';

import { Loader, LoadingQuery, LoadingQueryResult } from '@heltti/components';

import t from '../../translations/index';
import { DocumentsQuery, ListDocumentFragment } from '../../graphql-schema';
import ListItem from '../../components/ListItem';
import { MemberDocumentModal } from './MemberDocumentModal';
import { Translation } from '../../components/Message';
import Card from '../../components/Card';
import { getNodes } from '@heltti/common';

interface State {
    showAll: boolean;
    selectedDocumentId?: string;
}

export default class Documents extends Component<{}, State> {
    public state: State = {
        showAll: false,
        selectedDocumentId: undefined
    };

    private showAll = (e: any) => {
        e.preventDefault();

        this.setState({ showAll: true });
    };

    private openModal = (document: ListDocumentFragment) => {
        this.setState({ selectedDocumentId: document.id });
    };

    private dismissModal = () => {
        this.setState({ selectedDocumentId: undefined });
    };

    private renderWithResult = (result: LoadingQueryResult<DocumentsQuery>) => {
        const { selectedDocumentId, showAll } = this.state;

        if (result.loading) {
            return <Loader />;
        } else if (result.error) {
            return null;
        }

        const documents = getNodes(result.data?.root?.documents);

        return (
            <div className="documents">
                {selectedDocumentId && (
                    <MemberDocumentModal documentId={selectedDocumentId} onDismiss={this.dismissModal} />
                )}

                <Card title={<Translation message={t.profileDocuments} />}>
                    {documents.length === 0 && (
                        <p>
                            <Translation message={t.profileNoDocuments} />
                        </p>
                    )}

                    {documents.slice(0, this.state.showAll ? undefined : 5).map(document => (
                        <ListItem
                            key={document.id}
                            id={document.id}
                            title={document.filename}
                            date={document.addedDate}
                            onClick={() => this.openModal(document)}
                            notification={document.memberSeenAt ? undefined : 1}
                        />
                    ))}

                    {!showAll && documents.length > 5 && (
                        <p style={{ marginTop: 10, textAlign: 'right' }}>
                            <a href="#" onClick={this.showAll}>
                                <Translation message={t.profileDocumentsShowAll} />
                            </a>
                        </p>
                    )}
                </Card>
            </div>
        );
    };

    public render() {
        return <LoadingQuery query={documentsQuery} onResult={this.renderWithResult} />;
    }
}

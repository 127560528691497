import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Card, Header } from 'semantic-ui-react';
import { Loader, useLoadingQuery } from '@heltti/components';

import { DocumentsQuery } from '../../graphql-schema';
import { documentsQuery } from '../../data/queries';
import t from './../../translations';

import ListItem from '../../components/ListItem';
import { getNodes } from '@heltti/common';
import { Translation } from '../../components/Message';
import { MemberDocumentModal } from '../Documents/MemberDocumentModal';

type Props = {
    hideIfNoItems: boolean;
};

export const NewDocuments: React.FC<Props> = props => {
    const { data, loading, error } = useLoadingQuery<DocumentsQuery>(documentsQuery, { variables: { unseen: true } });

    const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null);

    const { hideIfNoItems } = props;

    if (loading && !hideIfNoItems) {
        return <Loader inline />;
    } else if (error) {
        return null;
    }

    if (!data?.root?.documents) {
        return null;
    }

    const unseenDocuments = getNodes(data?.root?.documents).filter(document => !document.memberSeenAt);

    if (hideIfNoItems && unseenDocuments.length === 0) {
        return null;
    }

    return (
        <div style={{ marginTop: '40px' }}>
            {selectedDocumentId && (
                <MemberDocumentModal documentId={selectedDocumentId} onDismiss={() => setSelectedDocumentId(null)} />
            )}

            <Header>
                <Translation message={t.homeDocumentsNew} />

                <div style={{ float: 'right', fontSize: '80%' }}>
                    <Link to="/paths/documents">
                        <Translation message={t.showAll} />
                    </Link>
                </div>
            </Header>

            {unseenDocuments.map(document => (
                <Card fluid key={document.id} onClick={() => setSelectedDocumentId(document.id)}>
                    <Card.Content>
                        <ListItem
                            key={document.id}
                            id={document.id}
                            title={document.filename}
                            date={document.addedDate}
                            onClick={() => setSelectedDocumentId(document.id)}
                            notification={document.memberSeenAt ? undefined : 1}
                        />
                    </Card.Content>
                </Card>
            ))}

            {unseenDocuments.length === 0 && (
                <p>
                    <Translation message={t.homeDocumentsNewNone} />
                </p>
            )}
        </div>
    );
};

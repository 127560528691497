import React from 'react';
import { Form, Header, Input, TextArea } from 'semantic-ui-react';

import { QuestionnaireQuestionFragment, QuestionnaireQuestionType } from '../../graphql-schema';
import { QuestionnaireQuestionValue } from './QuestionnaireResponse';
import { FormatMarkdown } from '../../components/FormatMarkdown';
import { CheckboxGroup } from './CheckboxGroup';
import { SliderInput } from './SliderInput';

const checkBoxInputTypes = new Set([
    QuestionnaireQuestionType.Radio,
    QuestionnaireQuestionType.RadioIcons,
    QuestionnaireQuestionType.Select,
    QuestionnaireQuestionType.SelectIcons,
    QuestionnaireQuestionType.Boolean
]);

type Props = {
    question: QuestionnaireQuestionFragment;
    onChange(question: QuestionnaireQuestionFragment, value: QuestionnaireQuestionValue): void;
    value: any;
    renderTitle: boolean;
};

export const QuestionnaireQuestion: React.FC<Props> = props => {
    const { question, onChange, value, renderTitle } = props;

    let inputComponent;

    if (checkBoxInputTypes.has(question.type)) {
        inputComponent = <CheckboxGroup question={question} value={value} onChange={onChange} />;
    } else {
        const changeHandler = (event: any, input: any) => onChange(question, { strValue: input.value });

        if (question.type === QuestionnaireQuestionType.Int) {
            inputComponent = (
                <div className="questionInput">
                    <Input
                        name={question.id}
                        value={value || ''}
                        type="number"
                        step="1"
                        // min="0" max="100"  // TODO support range
                        onChange={changeHandler}
                    />
                </div>
            );
        } else if (question.type === QuestionnaireQuestionType.TextShort) {
            inputComponent = (
                <div className="questionInput">
                    <Input name={question.id} value={value || ''} onChange={changeHandler} />
                </div>
            );
        } else if (question.type === QuestionnaireQuestionType.TextLong) {
            inputComponent = (
                <div className="questionInput">
                    <TextArea name={question.id} value={value || ''} onChange={changeHandler} />
                </div>
            );
        } else if (question.type === QuestionnaireQuestionType.Slider) {
            inputComponent = <SliderInput question={question} value={value} onChange={onChange} />;
        }
    }

    return (
        <Form.Field className="questionnaireQuestion">
            {renderTitle && <Header as="h3">{question.title}</Header>}

            {question.description && (
                <div className="questionDescription">
                    <FormatMarkdown source={question.description} />
                </div>
            )}

            {inputComponent}
        </Form.Field>
    );
};

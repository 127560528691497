import React from 'react';
import { Divider, Grid } from 'semantic-ui-react';

import Section from '../../components/Section';
import { QuestionnaireResponseFragment } from '../../graphql-schema';
import QuestionnaireSummaryBars from './QuestionnaireSummaryBars';
import { FormatMarkdown } from '../../components/FormatMarkdown';

type Props = {
    questionnaireResponse: QuestionnaireResponseFragment;
};

export const QuestionnaireSummary: React.FC<Props> = props => {
    const { questionnaireResponse } = props;

    if (!questionnaireResponse.summary) {
        return null;
    }

    return (
        <Section width="medium" padded>
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <QuestionnaireSummaryBars questionnaireResponse={questionnaireResponse} />

                        <Divider hidden />

                        {questionnaireResponse.summary.description && (
                            <FormatMarkdown source={questionnaireResponse.summary.description} />
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Section>
    );
};

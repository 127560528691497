import React from 'react';
import { Button, Label, SemanticCOLORS } from 'semantic-ui-react';
import { FormatMarkdown } from '../../components/FormatMarkdown';
import { InteractionTheme } from '../../util/types';

import './InteractionMessage.less';

type InteractionMessageProps = {
    label?: string;
    title?: string;
    description?: string;
    actionLabel: string;
    theme: InteractionTheme;
    onClick: () => Promise<void> | void;
};

type InteractionThemeType = {
    labelColor: SemanticCOLORS;
    buttonColor: SemanticCOLORS;
};
const interactionThemes: { [key in InteractionTheme]: InteractionThemeType } = {
    blue: {
        labelColor: 'orange',
        buttonColor: 'blue'
    }
};

export const InteractionMessage = (props: InteractionMessageProps) => {
    const { label, title, description, actionLabel, onClick, theme } = props;

    const messageTheme = interactionThemes[theme];

    return (
        <div className={`interaction-message interaction-message--${theme}`}>
            <Label color={messageTheme.labelColor}>{label}</Label>
            {title && <h3 className="interaction-message__header">{title}</h3>}
            {description && (
                <p className="interaction-message__description">
                    <FormatMarkdown source={description} />
                </p>
            )}
            <Button onClick={onClick} size="medium" color={messageTheme.buttonColor}>
                {actionLabel}
            </Button>
        </div>
    );
};

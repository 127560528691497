import React from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { Error, Loader, useLoadingQuery } from '@heltti/components';

import { ChatsQuery } from '../../graphql-schema';
import { chatsQuery } from '../../data/queries';
import t from './../../translations';
import { Content } from '../../components/Content';
import { ListChat } from '../../components/ListChat';
import { Page } from '../../components/Page';
import { CompactHeaderWithNavigation } from '../../components/CompactHeaderWithNavigation';
import { getNodes } from '@heltti/common';

type Props = RouteComponentProps;

export const Chats: React.FC<Props> = () => {
    const { formatMessage } = useIntl();
    const { data, loading, error } = useLoadingQuery<ChatsQuery>(chatsQuery);

    const chats = getNodes(data?.root?.chats);

    if (loading || !chats) {
        return <Loader />;
    } else if (error) {
        return <Error showRefreshButton />;
    }

    return (
        <Page subNavigation id="chats">
            <CompactHeaderWithNavigation backTo="/paths" title={formatMessage(t.chatsTitleHistory)} color="red" />

            <Content containerWidth="full">
                <div className="scrollableContent">
                    {chats.length === 0 && <p>{formatMessage(t.chatsNoAvailableChats)}</p>}

                    {chats.map(chat => (
                        <ListChat key={chat.id} chat={chat} />
                    ))}
                </div>
            </Content>
        </Page>
    );
};

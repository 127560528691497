import React from 'react';

import { Loader as SemanticLoader } from 'semantic-ui-react';

type Props = {
    inline?: boolean;
};

export const Loader: React.FC<Props> = props => {
    const { inline } = props;

    return (
        <div>
            <SemanticLoader active={true} inline={inline} size={inline ? 'small' : 'medium'} />
        </div>
    );
};

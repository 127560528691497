import React, { FunctionComponent } from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';

import { Translation } from './Message';
import t from '../translations';

type Props = {
    loading: boolean;
    onCancel: Function;
    onAbortCancel: () => void;
    onClose: () => void;
};

const ConfirmCancelModal: FunctionComponent<Props> = ({ loading, onCancel, onAbortCancel, onClose }) => {
    return (
        <Modal open centered={false} size="small" onClose={onClose}>
            <Modal.Header>
                <Header as="h3">
                    <Translation message={t.appointmentCancel} />
                </Header>
            </Modal.Header>

            <Modal.Content>
                <Translation message={t.appointmentCancelDescription} />
            </Modal.Content>

            <Modal.Actions>
                <Button basic onClick={onAbortCancel} disabled={loading}>
                    <Translation message={t.close} />
                </Button>

                <Button className="confirm-cancellation-button" negative onClick={() => onCancel()} disabled={loading}>
                    <Translation message={t.appointmentCancel} />
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ConfirmCancelModal;

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Card, Divider, Grid, Header } from 'semantic-ui-react';
import { useLoadingQuery } from '@heltti/components';

import PageHeader from '../../components/PageHeader';
import { HelttiLogo } from '../../components/Logo';
import { SignUpType, SignUpTypeQuery } from '../../graphql-schema';
import { signUpTypeQuery } from '../../data/queries';
import { EmailSignUpForm } from './EmailSignUpForm';
import VerifyIdentityButton from '../../components/VerifyIdentityButton';

import { Loader } from '../../components/Loader';
import { Translation } from '../../components/Message';
import t from '../../translations';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import cover from '../../assets/images/Heltti20_8687.jpg';

type VerifyEmailRouteParams = {
    token: string;
};
type Props = RouteComponentProps<VerifyEmailRouteParams>;

export const EmailSignUp: React.FC<Props> = props => {
    const { token } = props.match.params;

    const { data } = useLoadingQuery<SignUpTypeQuery>(signUpTypeQuery, {
        variables: { token },
        skip: !token
    });

    const signUpType = data?.token?.signUpType;

    return (
        <Grid id="hello-page">
            <Grid.Row centered>
                <Grid.Column computer={12} tablet={12} mobile={16} textAlign="center">
                    <Card fluid style={{ marginTop: '60px' }}>
                        <PageHeader banner title={''} description={''} backgroundImage={cover} />
                        <HelttiLogo />

                        {!signUpType && (
                            <Card.Content>
                                <Loader inline />
                            </Card.Content>
                        )}

                        {signUpType === SignUpType.Unsupported && (
                            <Card.Content>
                                <Card.Description textAlign="left">
                                    <Header>
                                        <Translation message={t.emailSignUp.notAvailableHeader} />
                                    </Header>
                                    <p>
                                        <Translation message={t.emailSignUp.notAvailableDescription} />
                                    </p>
                                </Card.Description>
                            </Card.Content>
                        )}
                        {signUpType === SignUpType.Email && (
                            <Card.Content>
                                <Card.Description textAlign="left">
                                    <Header>
                                        <Translation message={t.emailSignUp.emailSignUpHeader} />
                                    </Header>
                                    <p>
                                        <Translation message={t.emailSignUp.emailSignUpDescription} />
                                    </p>
                                </Card.Description>

                                <Divider hidden />

                                <EmailSignUpForm token={token} />
                            </Card.Content>
                        )}

                        {signUpType === SignUpType.Identification && (
                            <Card.Content>
                                <Card.Description textAlign="left">
                                    <Header>
                                        <Translation message={t.emailSignUp.identificationSignUpHeader} />
                                    </Header>
                                    <p>
                                        <Translation message={t.emailSignUp.identificationSignUpDescription} />
                                    </p>
                                </Card.Description>
                                <Divider hidden />
                                <VerifyIdentityButton />
                            </Card.Content>
                        )}
                    </Card>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

import React from 'react';

import { useLoadingQuery } from '@heltti/components';
import { getNodes } from '@heltti/common';

import ArticleCard from '../Article/ArticleCard';
import { articleQuery } from '../../data/queries';
import { ArticleQuery, ArticleType } from '../../graphql-schema';

export const CompanyInstructionsCard: React.FC = () => {
    const { data, loading, error } = useLoadingQuery<ArticleQuery>(articleQuery, {
        variables: { tag: 'root_explore' }
    });

    const [companyArticle] = getNodes(data?.root?.article.childArticles).filter(
        article => article.type == ArticleType.CompanyInstructions
    );

    if (loading || error || !companyArticle) {
        return null;
    }

    return (
        <ArticleCard
            fluid
            title={companyArticle.title}
            photoUrl={companyArticle.coverPhotoUrl}
            highlight={companyArticle.highlight}
            linkTo={`/explore/${companyArticle.id}`}
        />
    );
};

export const OPEN_FLOW = 'heltti/flow/open';
export const CLOSE_FLOW = 'heltti/flow/close';

export interface FlowState {
    isFlowOpen: boolean;
}

function getInitialState() {
    return {
        isFlowOpen: false
    };
}

export default function flow(state: FlowState = getInitialState(), action: any): FlowState {
    switch (action.type) {
        case OPEN_FLOW:
            return {
                ...state,
                isFlowOpen: true
            };

        case CLOSE_FLOW:
            return {
                ...state,
                isFlowOpen: false
            };

        default:
            return state;
    }
}

export function openFlow() {
    return dispatch => dispatch({ type: OPEN_FLOW });
}

export function closeFlow() {
    return dispatch => dispatch({ type: CLOSE_FLOW });
}

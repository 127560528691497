import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { Card, Divider, Form, Grid, Header, Input, Message } from 'semantic-ui-react';

import PageHeader from '../../components/PageHeader';
import { HelttiLogo } from '../../components/Logo';
import t from '../../translations';
import { Translation } from '../../components/Message';
import { verifyEmailMutation } from '../../data/mutations';
import { VerifyEmailMutation } from '../../graphql-schema';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import cover from '../../assets/images/Heltti20_8687.jpg';

type FormData = {
    email: string;
};

type Props = RouteComponentProps;

export const VerifyEmail: React.FC<Props> = () => {
    const { formatMessage } = useIntl();

    const [success, setSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>();
    const [verifyEmail] = useMutation<VerifyEmailMutation>(verifyEmailMutation);

    const handleSave = useCallback(
        (values: FormData) => {
            if (!values.email) {
                return;
            }
            setLoading(true);
            setError(undefined);

            verifyEmail({ variables: { data: { email: values.email } } })
                .then(result => {
                    setLoading(false);

                    if (result.data?.verifyEmail?.success) {
                        setSuccess(true);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    setError(error);
                });
        },
        [verifyEmail]
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                email: Yup.string().email(formatMessage(t.validatorsRequired))
            }),
        [formatMessage]
    );

    const {
        handleChange,
        handleSubmit,
        values: formValues,
        errors,
        isValid
    } = useFormik<FormData>({
        initialValues: {
            email: ''
        },
        onSubmit: handleSave,
        validationSchema
    });

    return (
        <Grid id="hello-page">
            <Grid.Row centered>
                <Grid.Column computer={12} tablet={12} mobile={16} textAlign="center">
                    <Card fluid style={{ marginTop: '60px' }}>
                        <PageHeader banner title={''} description={''} backgroundImage={cover} />
                        <HelttiLogo />

                        {!success ? (
                            <Card.Content>
                                <Card.Description textAlign="left">
                                    <Header>
                                        <Translation message={t.emailVerification.header} />{' '}
                                    </Header>
                                    <Divider hidden />
                                    <p>
                                        <Translation message={t.emailVerification.description} />
                                    </p>
                                </Card.Description>

                                <Divider hidden />

                                <Form onSubmit={handleSubmit} loading={loading} error={!isValid}>
                                    {error && <Message error>{error.message}</Message>}

                                    <Form.Group>
                                        <Form.Field width={10}>
                                            <Input
                                                id="email"
                                                name="email"
                                                type="email"
                                                placeholder={formatMessage(t.emailVerification.workEmail)}
                                                onChange={handleChange}
                                                value={formValues.email}
                                                error={!!errors.email}
                                            />
                                        </Form.Field>

                                        <Form.Button
                                            primary
                                            type="submit"
                                            width={6}
                                            disabled={!isValid || !formValues.email}
                                        >
                                            <Translation message={t.emailVerification.submit} />
                                        </Form.Button>
                                    </Form.Group>
                                </Form>
                            </Card.Content>
                        ) : (
                            <Card.Content>
                                <Card.Description textAlign="left">
                                    <Header>
                                        <Translation message={t.emailVerification.successHeader} />{' '}
                                    </Header>
                                    <Divider hidden />
                                    <p>
                                        <Translation message={t.emailVerification.successDescription} />
                                    </p>
                                </Card.Description>
                            </Card.Content>
                        )}
                    </Card>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

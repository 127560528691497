import React, { PureComponent } from 'react';
import moment from 'moment';

import Notification from './Notification';

import './ListItem.less';

interface Props {
    id: string;
    title: string;
    date?: string;
    onClick?: any;
    extra?: any;
    notification?: any;
    dateFormat?: string;
}

export default class ListItem extends PureComponent<Props> {
    public render() {
        const { id, title, date, onClick, extra, notification, dateFormat } = this.props;

        const formattedDate = date ? moment(date).format(dateFormat || 'LL') : null;

        return (
            <div key={id} className="listItem" onClick={onClick}>
                <div className="listItemTitle">
                    <h4>
                        {title} {notification && <Notification>{notification}</Notification>}
                    </h4>

                    <aside>{formattedDate}</aside>
                </div>

                <div className="listItemExtra">{extra}</div>
            </div>
        );
    }
}

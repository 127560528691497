import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { WherebyEmbed } from './WherebyEmbed';

import { useLoadingQuery, Error } from '@heltti/components';

import { videoMeetingInfoQuery } from '../../data/queries';
import { Loader } from '../../components/Loader';
import { VideoMeetingInfoQuery, VideoMeetingInfoQueryVariables } from '../../graphql-schema';
import t from '../../translations';
import { Translation } from '../../components/Message';

import './VideoMeeting.less';

type MatchParams = {
    meetingUUID: string;
};

type Props = RouteComponentProps<MatchParams>;

export const VideoMeeting: React.FC<Props> = props => {
    const { meetingUUID } = props.match.params;

    const { data, loading, error } = useLoadingQuery<VideoMeetingInfoQuery, VideoMeetingInfoQueryVariables>(
        videoMeetingInfoQuery,
        {
            variables: {
                meetingUUID
            }
        }
    );

    const meetingInformation = data?.public.meetingInformation;

    if (loading) {
        return <Loader />;
    } else if (error) {
        return <Error showRefreshButton />;
    } else if (!meetingInformation) {
        return (
            <div>
                <Translation message={t.errorNotFoundTitle} />
            </div>
        );
    }

    if (meetingInformation.provider === 'WHEREBY') {
        return <WherebyEmbed roomUrl={meetingInformation.url} />;
    }

    return (
        <div>
            <Translation message={t.errorGeneralTitle} />
        </div>
    );
};

import React, { FunctionComponent } from 'react';
import { groupBy, map, reverse, slice } from 'lodash';
import moment from 'moment';

import { ChatMessageFragment } from '../../graphql-schema';
import { useInteraction } from '../../hooks/interaction';
import {
    chatMessageInteractionToInteraction,
    isFileAttachment,
    isIdAttachment,
    isInteractionAttachment
} from '../../util/types';
import ChatEvent from './ChatEvent';
import { ChatMessage, isEvent } from './ChatMessage';
import { InteractionMessage } from './InteractionMessage';

interface Props {
    messages: ChatMessageFragment[];
}

export const ChatMessagesGrouped: FunctionComponent<Props> = props => {
    const groupByDate = (message: ChatMessageFragment) => message.createdAt.split('T').shift();

    const { messages } = props;
    const { onAction } = useInteraction();

    const groupedByDate = groupBy(reverse(slice(messages)), groupByDate);

    return (
        <>
            {map(groupedByDate, (chatMessages: ChatMessageFragment[], date: string) => {
                let lastWho: string | null = null;

                return (
                    <div className="messageGroup" key={date}>
                        <div className="messageSeparator">{moment(date).format('dddd D.M.YYYY')}</div>

                        <div>
                            {chatMessages.map(message => {
                                const person = message.member ? message.member : message.user;

                                const who = person ? `${person.firstName} ${person.lastName}` : '';
                                const hideWho = who === lastWho;

                                lastWho = who;

                                const whoType = message.member ? 'member' : 'heltti';
                                const attachments = message.attachmentsV2.filter(isFileAttachment).map(attachment => ({
                                    id: attachment.id,
                                    filename: attachment.filename,
                                    url: attachment.url ?? null
                                }));

                                const interactions = message.attachmentsV2
                                    .filter(isInteractionAttachment)
                                    .map(chatMessageInteractionToInteraction);
                                const idAttachments = message.attachmentsV2.filter(isIdAttachment);

                                if (interactions.length > 0) {
                                    return interactions.map(interaction => {
                                        return (
                                            <InteractionMessage
                                                key={interaction.id}
                                                label={interaction.label}
                                                title={interaction.title}
                                                description={interaction.description}
                                                actionLabel={interaction.actions[0].title} // TODO: Support only for single action interactions
                                                theme={interaction.theme}
                                                onClick={() => onAction(interaction.id, interaction.actions[0])}
                                            />
                                        );
                                    });
                                } else if (idAttachments.length > 0) {
                                    // Show just id attachment title as regular chat message
                                    return idAttachments.map(idAttachment => {
                                        const mergedMessage = {
                                            ...message,
                                            message: idAttachment.title
                                        };
                                        return (
                                            <ChatMessage
                                                key={mergedMessage.id}
                                                id={mergedMessage.id}
                                                type={mergedMessage.type}
                                                createdAt={mergedMessage.createdAt}
                                                deletedAt={mergedMessage.deletedAt}
                                                message={mergedMessage.message ?? ''}
                                                who={who}
                                                hideName={hideWho}
                                                whoType={whoType}
                                                attachments={attachments}
                                                avatarUrl={person?.avatarSmallUrl}
                                            />
                                        );
                                    });
                                } else {
                                    if (!isEvent(message.type) && attachments.length === 0 && !message.message) {
                                        return;
                                    }
                                    const Component = isEvent(message.type) ? ChatEvent : ChatMessage;

                                    return (
                                        <Component
                                            key={message.id}
                                            id={message.id}
                                            type={message.type}
                                            createdAt={message.createdAt}
                                            deletedAt={message.deletedAt}
                                            message={message.message}
                                            who={who}
                                            hideName={hideWho}
                                            whoType={whoType}
                                            attachments={attachments}
                                            avatarUrl={person && person.avatarSmallUrl}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Button, Divider, Feed, Grid, Header, List, Modal, Table } from 'semantic-ui-react';
import { getNodes } from '@heltti/common';
import { useLoadingQuery } from '@heltti/components';

import t from '../../translations';
import { Avatar } from '../../components/Avatar';
import { FormatMarkdown } from '../../components/FormatMarkdown';
import { MarkdownTranslation, Translation } from '../../components/Message';
import { apolloClient } from '../../index';
import { createMemberDocumentExportTokenMutation, deleteMemberDocumentExportTokenMutation } from '../../data/mutations';
import { documentQuery, profileQuery } from '../../data/queries';
import { DocumentQuery, ProfileQuery } from '../../graphql-schema';
import { HelttiLogo } from '../../components/Logo';

function onCreateExportToken(documentId: string) {
    return apolloClient.mutate({
        mutation: createMemberDocumentExportTokenMutation,
        variables: { data: { documentId } }
    });
}

function onDeleteExportToken(tokenId: string) {
    return apolloClient.mutate({
        mutation: deleteMemberDocumentExportTokenMutation,
        variables: { data: { tokenId } }
    });
}

function onDownload(url: string) {
    window.open(url, '_blank');
}

const ExportLastName: React.FC = () => {
    const { loading, error, data } = useLoadingQuery<ProfileQuery>(profileQuery);

    if (error || loading || !data) {
        return <span>&nbsp;</span>;
    }

    return (
        <span
            className="exportLastName"
            style={{
                fontSize: '120%',
                fontWeight: 'bold',
                letterSpacing: '1px'
            }}
        >
            {data?.root?.me?.lastName.toUpperCase()}
        </span>
    );
};

interface Props {
    documentId: string;

    onDismiss: () => void;
}

export const MemberDocumentModal: FunctionComponent<Props> = props => {
    const { documentId } = props;

    const { loading, error, data } = useLoadingQuery<DocumentQuery>(documentQuery, { variables: { documentId } });

    if (!documentId) {
        return null;
    }

    const document = data?.root?.document;

    if (loading || error || !document) {
        return null;
    }

    const selectedDocumentExportTokens = getNodes(document.exportTokens).filter(token => !token.deletedAt);

    const createdBy = !!document && document.createdBy;

    return (
        <Modal
            className="documentModal"
            defaultOpen
            onClose={() => props.onDismiss()}
            dimmer="inverted"
            centered={false}
        >
            <Modal.Content>
                <Header>
                    <Translation message={t.document} />
                </Header>
                <div>
                    <strong>
                        <Translation message={t.documentName} />:
                    </strong>{' '}
                    {document.filename}
                    <br />
                    <strong>
                        <Translation message={t.documentAddedAt} />:{' '}
                    </strong>{' '}
                    {moment(document.addedDate).format('L')}
                </div>

                <Divider section />

                <Header>
                    <Translation message={t.documentMemberVisibleMessage} />
                </Header>

                <Feed>
                    <Feed.Event className="notificationCard">
                        <Feed.Label>
                            {createdBy ? <Avatar url={createdBy.avatarSmallUrl} /> : <HelttiLogo />}
                        </Feed.Label>

                        <Feed.Content>
                            {createdBy && (
                                <Feed.Date>
                                    {createdBy.firstName} {createdBy.lastName}, {createdBy.title}
                                </Feed.Date>
                            )}

                            {!!document.memberVisibleComment && (
                                <FormatMarkdown source={document.memberVisibleComment} />
                            )}
                        </Feed.Content>
                    </Feed.Event>
                </Feed>

                <Divider section />

                <Header>
                    <Translation message={t.documentExportTokens} />
                </Header>
                <Grid stackable>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            {selectedDocumentExportTokens.map(token => (
                                <div key={token.id}>
                                    <Table compact unstackable>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell textAlign="right">
                                                    <Translation message={t.referralFormLastName} />
                                                </Table.Cell>

                                                <Table.Cell>
                                                    <ExportLastName />
                                                </Table.Cell>
                                            </Table.Row>

                                            <Table.Row>
                                                <Table.Cell textAlign="right">
                                                    <Translation message={t.documentExportTokensToken} />
                                                </Table.Cell>

                                                <Table.Cell>
                                                    <span
                                                        className="exportToken"
                                                        style={{
                                                            fontSize: '120%',
                                                            fontWeight: 'bold',
                                                            letterSpacing: '1px'
                                                        }}
                                                    >
                                                        {token.token}
                                                    </span>
                                                </Table.Cell>
                                            </Table.Row>

                                            <Table.Row>
                                                <Table.Cell textAlign="right">
                                                    <Translation message={t.documentExportTokensValidUntil} />
                                                </Table.Cell>

                                                <Table.Cell>{moment(token.validUntil).format('L LT')}</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>

                                        <Table.Footer fullWidth>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan={2} textAlign="right">
                                                    <Button
                                                        basic
                                                        color="red"
                                                        size="tiny"
                                                        onClick={() => {
                                                            onDeleteExportToken(token.id);
                                                        }}
                                                    >
                                                        <Translation message={t.documentExportTokensDelete} />
                                                    </Button>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>
                                </div>
                            ))}

                            {!selectedDocumentExportTokens.length && (
                                <Button
                                    size="tiny"
                                    onClick={() => {
                                        onCreateExportToken(document.id);
                                    }}
                                >
                                    <Translation message={t.documentExportTokensCreate} />
                                </Button>
                            )}
                        </Grid.Column>

                        <Grid.Column>
                            <List relaxed>
                                <List.Item>
                                    <List.Icon name="info circle" size="large" verticalAlign="middle" />
                                    <List.Content>
                                        <List.Description>
                                            <MarkdownTranslation message={t.documentExportTokensHelp} />
                                        </List.Description>

                                        <Divider hidden />

                                        <List.Description>
                                            <Link to="/faq">
                                                <Translation message={t.documentExportTokensReadMore} />
                                            </Link>
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider section />

                <Button
                    primary
                    onClick={() => {
                        document.url && onDownload(document.url);
                    }}
                >
                    <Translation message={t.profileDocumentsDownload} />
                </Button>

                <Button onClick={() => props.onDismiss()} floated="right">
                    <Translation message={t.profileDocumentsDownloadModalClose} />
                </Button>
            </Modal.Content>
        </Modal>
    );
};

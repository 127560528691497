import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import PasswordForgotForm from './PasswordForgotForm';
import { WrappedComponentProps } from 'react-intl';
import { Content } from '../../components/Content';
import { Grid } from 'semantic-ui-react';
import { Page } from '../../components/Page';
import { SCREEN_WIDTH_MAX_MOBILE } from '../../constants';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import backgroundImage from '../../assets/images/heltti-cover.jpg';

type LocationState = {
    email?: string;
};
type Props = RouteComponentProps<any, any, LocationState> & WrappedComponentProps;

export const PasswordForgot: React.FC<Props> = props => {
    const { location } = props;

    const prefilledEmail = location.state ? location.state.email : undefined;
    const migrationEmail = location.search ? decodeURIComponent(location.search.replace(/^.*?=/, '')) : undefined;

    const isMobile = window.innerWidth <= SCREEN_WIDTH_MAX_MOBILE;

    return (
        <Page id="login-page">
            <Content>
                <Grid id="loginGrid">
                    <Grid.Row columns={isMobile ? 1 : 2}>
                        <Grid.Column textAlign="center" verticalAlign="middle">
                            <div id="loginFormContainer">
                                <div className="loginFormContent">
                                    <PasswordForgotForm email={prefilledEmail || migrationEmail} />
                                </div>
                            </div>
                        </Grid.Column>

                        {!isMobile && (
                            <Grid.Column>
                                <div
                                    id="pageHeader"
                                    style={{
                                        backgroundImage: `url(${backgroundImage})`,
                                        height: '100%'
                                    }}
                                />
                            </Grid.Column>
                        )}
                    </Grid.Row>
                </Grid>
            </Content>
        </Page>
    );
};

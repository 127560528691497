import React from 'react';

import { Card, Divider, Grid, Header } from 'semantic-ui-react';

import { Translation } from '../../components/Message';
import messages from '../../translations';
import PageHeader from '../../components/PageHeader';
import VerifyIdentityButton from '../../components/VerifyIdentityButton';
import { HelttiLogo } from '../../components/Logo';

import './hello.less';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import cover from '../../assets/images/Heltti20_8687.jpg';

export const Hello: React.FC = () => {
    return (
        <Grid id="hello-page">
            <Grid.Row centered>
                <Grid.Column computer={12} tablet={12} mobile={16} textAlign="center">
                    <Card fluid image style={{ marginTop: '60px' }}>
                        <PageHeader banner title={''} description={''} backgroundImage={cover} />
                        <HelttiLogo />

                        <Card.Content>
                            <Card.Description textAlign="left">
                                <Header>
                                    <Translation message={messages.hello.title} />{' '}
                                    <Translation message={messages.hello.description} />
                                </Header>

                                <Translation message={messages.notVerifiedIndexDescription} />
                            </Card.Description>

                            <Divider hidden />

                            <VerifyIdentityButton />

                            <Divider hidden />
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

import { useLoadingQuery } from '@heltti/components';

import { chatQuery } from '../data/queries';
import { chatSubscription } from '../data/subscriptions';
import { ChatQuery, ChatQueryVariables } from '../graphql-schema';

export const useChat = (chatId: string) => {
    const { loading, error, data } = useLoadingQuery<ChatQuery, ChatQueryVariables>(chatQuery, {
        variables: { chatID: chatId },
        subscription: chatSubscription,
        subscriptionVariables: { chatId }
    });

    return { loading, error, data };
};

import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Button, ButtonGroup, Divider, Form, Grid, Header, Input, Message } from 'semantic-ui-react';

import t from '../../translations';
import { Content } from '../../components/Content';
import { Page } from '../../components/Page';
import { getUrlForExportFile } from '../../api';
import { MarkdownTranslation, Translation } from '../../components/Message';

import './referral-download.less';

type Props = WrappedComponentProps;

type State = {
    lastName: string;
    token: string;

    error: boolean;
    loading: boolean;
    downloadUrl: string | null;
};

class PlainReferralDownloadPage extends Component<Props, State> {
    public state: State = {
        lastName: '',
        token: '',

        error: false,
        loading: false,
        downloadUrl: null
    };

    private onDownload = () => {
        const { downloadUrl } = this.state;

        if (downloadUrl) {
            window.open(downloadUrl, '_blank');
        }
    };

    private onClear = () => {
        this.setState({ loading: false, error: false, downloadUrl: null, lastName: '', token: '' });
    };

    private onFormSubmit = () => {
        const { lastName, token } = this.state;

        this.setState({ loading: true, error: false, downloadUrl: null });

        getUrlForExportFile(lastName, token)
            .then(result => {
                if (result.data.result.url) {
                    this.setState({ loading: false, downloadUrl: result.data.result.url });
                } else {
                    this.setState({ loading: false, error: true });
                }
            })
            .catch(() => {
                this.setState({ loading: false, downloadUrl: null, error: true });
            });
    };

    private handleLastNameChange = (e: any, { value }: any) => {
        this.setState({ lastName: value.toUpperCase() } as State);
    };

    private handleTokenChange = (e: any, { value }: any) => {
        this.setState({ token: value.toUpperCase().replace(' ', '') } as State);
    };

    public render() {
        const { intl } = this.props;
        const { lastName, token, loading, error, downloadUrl } = this.state;

        const formValid = !!lastName && !!token;

        return (
            <Page id="referral-download-page">
                <Content>
                    <Grid id="grid" stackable relaxed>
                        <Grid.Row columns={2} centered>
                            <Grid.Column textAlign="center" verticalAlign="middle">
                                <Form error onSubmit={this.onFormSubmit} id="referralForm">
                                    <Header as="h1">{intl.formatMessage(t.referralTitle)}</Header>

                                    <Divider hidden />

                                    {error && <Message error>{intl.formatMessage(t.referralError)}</Message>}

                                    <Form.Field>
                                        <Input
                                            type="text"
                                            name="lastName"
                                            value={lastName}
                                            disabled={loading || !!downloadUrl}
                                            placeholder={intl.formatMessage(t.referralFormLastName)}
                                            onChange={this.handleLastNameChange}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <Input
                                            type="text"
                                            name="token"
                                            value={token}
                                            disabled={loading || !!downloadUrl}
                                            placeholder={intl.formatMessage(t.referralFormSecurityCode)}
                                            onChange={this.handleTokenChange}
                                        />
                                    </Form.Field>

                                    <Button
                                        fluid
                                        primary
                                        size="large"
                                        disabled={!formValid || loading || !!downloadUrl}
                                        loading={loading}
                                    >
                                        <Translation message={t.referralFormOpen} />
                                    </Button>
                                </Form>

                                {!!downloadUrl && (
                                    <div id="download-container">
                                        <p>{intl.formatMessage(t.referralTokenValid)}</p>

                                        <ButtonGroup fluid widths={8} size="large">
                                            <Button primary onClick={this.onDownload}>
                                                {intl.formatMessage(t.referralDownloadButton)}
                                            </Button>

                                            <Button onClick={this.onClear}>
                                                {intl.formatMessage(t.referralClearForm)}
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                )}
                            </Grid.Column>

                            <Grid.Column textAlign="left" verticalAlign="middle">
                                <Header>{intl.formatMessage(t.referralCustomerServiceInstructionsTitle)}</Header>
                                <p>
                                    <MarkdownTranslation message={t.referralCustomerServiceInstructions} />
                                </p>

                                <Header>{intl.formatMessage(t.referralMemberInstructionsTitle)}</Header>
                                <p>
                                    <MarkdownTranslation message={t.referralMemberInstructions} />
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Content>
            </Page>
        );
    }
}

export const ReferralDownloadPage = injectIntl(PlainReferralDownloadPage);

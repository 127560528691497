import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Divider } from 'semantic-ui-react';

import {
    QuestionnaireResponseBatchQuery_root_questionnaireResponseBatches_edges_node,
    QuestionnaireResponseBatchQuery_root_questionnaireResponseBatches_edges_node_questionnaireResponses_edges_node
} from '../../graphql-schema';
import Card from '../Card';
import translations from '../../translations';
import moment from 'moment';
import { QuestionnaireResponseListItem } from './QuestionnaireResponseListItem';

type QuestionnaireResponseBatch = QuestionnaireResponseBatchQuery_root_questionnaireResponseBatches_edges_node;
type QuestionnaireResponse =
    QuestionnaireResponseBatchQuery_root_questionnaireResponseBatches_edges_node_questionnaireResponses_edges_node;

type Props = {
    questionnaireResponseBatch: QuestionnaireResponseBatch;
    hideFinished?: boolean;
};

function isQuestionnaireFinished(questionnaireResponse: QuestionnaireResponse) {
    return questionnaireResponse.finishedAt !== null;
}

export function QuestionnaireResponseBatchCard(props: Props) {
    const { questionnaireResponseBatch, hideFinished } = props;

    // Don't render if questionnaire is closed and hideFinished
    if (hideFinished && moment().isAfter(moment(questionnaireResponseBatch.closeAt))) {
        return null;
    }

    let responses =
        questionnaireResponseBatch.questionnaireResponses?.edges
            .filter(edge => !!edge && !!edge.node)
            .map(edge => edge!.node!) ?? [];

    // Don't render if no unfinished questionnaires and hideFinished
    if (hideFinished) {
        responses = responses.filter(response => !isQuestionnaireFinished(response));

        if (responses.length === 0) {
            return null;
        }
    }

    const isClosed = moment(questionnaireResponseBatch.closeAt).isBefore(moment());

    const analysedAtDates = responses.map(response => {
        return response.analysedAt;
    });

    const allResponsesAnalysed = analysedAtDates.every((date: Date) => !!date);

    const footerMessageId =
        isClosed || allResponsesAnalysed
            ? translations.questionnaireBatchClosedAt.id
            : translations.questionnaireBatchOpenUntil.id;

    const date = !isClosed && allResponsesAnalysed ? analysedAtDates.sort()[0] : questionnaireResponseBatch.closeAt;

    return (
        <Card
            title={
                <FormattedMessage
                    id={translations.questionnaireBatchHeader.id}
                    values={{ date: moment(questionnaireResponseBatch.openAt) }}
                />
            }
            footer={<FormattedMessage id={footerMessageId} values={{ closeAt: moment(date) }} />}
        >
            <p>{questionnaireResponseBatch.message}</p>

            <Divider />

            {responses?.map(response => {
                return <QuestionnaireResponseListItem key={response.id} response={response} />;
            })}
        </Card>
    );
}

import { AnyAction } from 'redux';

import {
    authenticate,
    refreshAuthentication as apiRefreshAuthentication,
    logout as apiLogout,
    acceptTerms as apiAcceptTerms,
    callEIdentVerification
} from '../api';

export const LOGIN_INIT = 'heltti/auth/login/init';
export const LOGIN_SUCCESS = 'heltti/auth/loginSuccess';
export const LOGIN_FAILURE = 'heltti/auth/loginFailure';
export const LOGOUT = 'heltti/auth/logout';
export const AUTHENTICATION_INIT_SUCCESS = 'heltti/auth/authenticatetionInitSuccess';
export const AUTHENTICATION_INIT_FAILURE = 'heltti/auth/authenticationInitFailure';
export const AUTHENTICATION_REFRESH_SUCCESS = 'heltti/auth/authenticationRefreshSuccess';
export const TERMS_NOT_ACCEPTED = 'heltti/auth/termsNotAccepted';
export const TERMS_ACCEPTED = 'heltti/auth/termsAccepted';

export type AuthState = {
    isLoading: boolean;

    isAuthenticated?: boolean;
    hasAcceptedTerms?: boolean;
    isEmployee?: boolean;
    isVerified?: boolean;
    verificationRequired?: boolean;

    error?: string;
};

export type MemberAuthRequestState = {
    isLoading: boolean;
    error?: string;
};

function getInitialState() {
    return {
        isLoading: false,

        isAuthenticated: undefined,
        hasAcceptedTerms: undefined,
        isEmployee: undefined,
        isVerified: undefined,

        error: undefined
    };
}

export default function auth(state: AuthState = getInitialState(), action: AnyAction): AuthState {
    switch (action.type) {
        case LOGIN_INIT:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                isVerified: action.payload.verified,
                verificationRequired: action.payload.verification_required,
                hasAcceptedTerms: action.payload.terms_accepted,
                isEmployee: action.payload.is_employee
            };

        case LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                isVerified: false,
                verificationRequired: false,
                hasAcceptedTerms: false,
                isEmployee: false,
                error: action.payload.error
            };

        case LOGOUT:
            return {
                ...getInitialState(),
                isAuthenticated: false,
                isVerified: false,
                verificationRequired: false,
                hasAcceptedTerms: false,
                isEmployee: false
            };

        case AUTHENTICATION_INIT_SUCCESS:
        case AUTHENTICATION_REFRESH_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isVerified: action.payload.verified,
                verificationRequired: action.payload.verification_required,
                hasAcceptedTerms: action.payload.terms_accepted,
                isEmployee: action.payload.is_employee
            };

        case AUTHENTICATION_INIT_FAILURE:
            return {
                ...state,
                isAuthenticated: false
            };

        case TERMS_ACCEPTED:
            return {
                ...state,
                hasAcceptedTerms: true
            };

        case TERMS_NOT_ACCEPTED:
            return {
                ...state,
                hasAcceptedTerms: false
            };

        default:
            return state;
    }
}

export function initAuthentication() {
    return dispatch => {
        return apiRefreshAuthentication()
            .then(({ success, result }) => {
                if (success) {
                    dispatch({ type: AUTHENTICATION_INIT_SUCCESS, payload: result });
                } else {
                    dispatch({ type: AUTHENTICATION_INIT_FAILURE });
                }
            })
            .catch(() => {
                dispatch({ type: AUTHENTICATION_INIT_FAILURE });
            });
    };
}

export function refreshAuthentication() {
    return dispatch => {
        return apiRefreshAuthentication()
            .then(({ success, result }) => {
                if (success) {
                    dispatch({ type: AUTHENTICATION_REFRESH_SUCCESS, payload: result });
                } else {
                    logout()(dispatch);
                }
            })
            .catch(() => {
                logout()(dispatch);
            });
    };
}

export function login(username: string, password: string) {
    return dispatch => {
        dispatch({ type: LOGIN_INIT });
        return authenticate(username, password)
            .then(({ result }) => {
                dispatch({ type: LOGIN_SUCCESS, payload: result });
            })
            .catch(e => {
                const error =
                    {
                        401: 'login.failed.credentials',
                        423: 'login.failed.expired',
                        429: 'login.failed.limit'
                    }[e.response?.status] ?? 'login.failed.credentials';

                dispatch({ type: LOGIN_FAILURE, payload: { error } });
                throw new Error();
            });
    };
}

export function logout() {
    return dispatch => {
        return apiLogout().then(() => {
            dispatch({ type: LOGOUT });
        });
    };
}

export function acceptTerms(
    isMemberCommunicationAccepted: boolean,
    isMarketingAccepted: boolean,
    isFeedbackAccepted: boolean
) {
    return dispatch => {
        return apiAcceptTerms(isMemberCommunicationAccepted, isMarketingAccepted, isFeedbackAccepted).then(() =>
            dispatch({ type: TERMS_ACCEPTED })
        );
    };
}

export function eidentVerification(urlParams: string) {
    return dispatch => {
        return callEIdentVerification(urlParams)
            .then(({ success, result }) => {
                const { nonce } = result;
                dispatch({ type: LOGIN_SUCCESS, payload: result });
                return nonce;
            })
            .catch(error => {
                console.log('ERR', error);
                dispatch({ type: LOGIN_FAILURE, payload: { error: 'login.failed.credentials' } });
            });
    };
}

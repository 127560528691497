import React, { Component } from 'react';
import { QueryResult } from '@apollo/client';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';

import { Card, Grid, Header } from 'semantic-ui-react';

import { MemberActionFragment, MemberActionsQuery, MemberActionType } from '../../graphql-schema';
import { memberActionsQuery } from '../../data/queries';
import t from '../../translations';
import { Translation } from '../../components/Message';
import { apolloClient } from '../../index';
import { memberActionMutation } from '../../data/mutations';
import { componentForMemberAction } from './member-action-components';
import { SCREEN_WIDTH_MAX_MOBILE } from '../../constants';

type Props = OwnProps & WrappedComponentProps & RouteComponentProps<any>;

interface MemberActionCategory {
    title: any;
    typesIncluded: MemberActionType[];
    availableActions: MemberActionFragment[];
}

class ActiveItemsCategory implements MemberActionCategory {
    public title = (<Translation message={t.memberActionActiveChatsTitle} />);
    public typesIncluded = [MemberActionType.ActiveChat];
    public availableActions: MemberActionFragment[] = [];
}

class ContactCategory implements MemberActionCategory {
    public title = (<Translation message={t.memberActionContactNowTitle} />);
    public typesIncluded = [MemberActionType.HelttiChat, MemberActionType.Call];
    public availableActions: MemberActionFragment[] = [];
}

class LinkCategory implements MemberActionCategory {
    public title = '';
    public typesIncluded = [MemberActionType.ActionUrl];
    public availableActions: MemberActionFragment[] = [];
}

class NonBusyActionsCategory implements MemberActionCategory {
    public title = (<Translation message={t.memberActionNonBusyActionsTitle} />);
    public typesIncluded = [
        MemberActionType.OwnNurse,
        MemberActionType.Feedback,
        MemberActionType.Faq,
        MemberActionType.ReportSickLeave
    ];
    public availableActions: MemberActionFragment[] = [];
}

interface OwnProps {
    data: QueryResult & MemberActionsQuery;
    showActions: number[];
    showSickLeave: boolean;
    showSupportAndFeedback: boolean;
    showFAQ: boolean;
}

class StartHere extends Component<Props> {
    private onActionClicked = (action: MemberActionFragment) => {
        if (action.actionUrl) {
            return this.navigateToUrl(action.actionUrl);
        }

        apolloClient
            .mutate({
                mutation: memberActionMutation,
                variables: {
                    data: { typeNew: action.typeNew }
                }
            })
            .then(response => {
                this.navigateToUrl(response.data.memberAction.url);
            });
    };

    private navigateToUrl = (url: string) => {
        const { history } = this.props;

        if (url.startsWith('tel:')) {
            return (window.location.href = url);
        }

        history.push(url);
    };

    public render() {
        const { data, intl } = this.props;

        if (data.loading) {
            return null;
        }

        const isMobile = window.innerWidth <= SCREEN_WIDTH_MAX_MOBILE;

        const isActionIncluded = (category: MemberActionCategory, action: MemberActionFragment): boolean => {
            return category.typesIncluded.includes(action.typeNew as MemberActionType);
        };

        const availableActions = (data.root?.availableActions?.actions || []) as MemberActionFragment[];

        const activeItemsCategory = new ActiveItemsCategory();
        const contactCategory = new ContactCategory();
        const linkCategory = new LinkCategory();
        const nonBusyCategory = new NonBusyActionsCategory();

        availableActions.forEach(action => {
            if (isActionIncluded(activeItemsCategory, action)) {
                activeItemsCategory.availableActions.push(action);
            }

            if (isActionIncluded(contactCategory, action)) {
                contactCategory.availableActions.push(action);
            }

            // TODO: better way of filtering out unwanted actions
            if (isActionIncluded(linkCategory, action) && action.category == 2) {
                linkCategory.availableActions.push(action);
            }

            if (isActionIncluded(nonBusyCategory, action)) {
                nonBusyCategory.availableActions.push(action);
            }
        });

        return (
            <Grid centered>
                <Grid.Row style={{ marginTop: '25px' }}>
                    <Grid.Column>
                        {activeItemsCategory.availableActions.length > 0 && (
                            <React.Fragment>
                                <Header>{activeItemsCategory.title}</Header>

                                {activeItemsCategory.availableActions.map((action, index) => {
                                    return componentForMemberAction(action, this.onActionClicked, index);
                                })}
                            </React.Fragment>
                        )}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ marginTop: '25px' }}>
                    <Grid.Column>
                        {contactCategory.availableActions.length > 0 && (
                            <React.Fragment>
                                <Header>{contactCategory.title}</Header>

                                <Card.Group itemsPerRow={isMobile ? 1 : 2}>
                                    {contactCategory.availableActions.map((action, index) =>
                                        componentForMemberAction(action, this.onActionClicked, index)
                                    )}
                                </Card.Group>
                            </React.Fragment>
                        )}

                        {linkCategory.availableActions.length > 0 && (
                            <Card.Group>
                                {linkCategory.availableActions.map((action, index) =>
                                    componentForMemberAction(action, this.onActionClicked, index)
                                )}
                            </Card.Group>
                        )}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ marginTop: '25px' }}>
                    <Grid.Column>
                        {nonBusyCategory.availableActions.length > 0 && (
                            <React.Fragment>
                                <Header>{nonBusyCategory.title}</Header>

                                <Card.Group>
                                    {nonBusyCategory.availableActions.map((action, index) => {
                                        return componentForMemberAction(action, this.onActionClicked, index);
                                    })}
                                </Card.Group>
                            </React.Fragment>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default compose(
    injectIntl,
    withRouter,
    graphql(memberActionsQuery, {
        options: {
            fetchPolicy: 'cache-and-network',
            pollInterval: 30 * 1000
        }
    })
)(StartHere);

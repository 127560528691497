import React, { PureComponent } from 'react';

interface Props {
    text?: string;
}

export default class ChatMessageText extends PureComponent<Props> {
    public render() {
        const { text } = this.props;

        return (
            text &&
            text.split('\n').map((value, key) => (
                <span key={key}>
                    {value}
                    <br />
                </span>
            ))
        );
    }
}

import React from 'react';

import { Button, Card, Icon, Image } from 'semantic-ui-react';

import ListItem from '../../components/ListItem';
import { Avatar } from '../../components/Avatar';
import { Translation } from '../../components/Message';
import t from '../../translations';
import { FormatMarkdown } from '../../components/FormatMarkdown';
import { ListChatFragment, MemberActionFragment, MemberActionType } from '../../graphql-schema';

import helttiChatImage from '../../assets/images/helttichat.jpg';
import helttiLineImage from '../../assets/images/helttilinja.jpg';

interface MemberActionComponentProps {
    action: MemberActionFragment;
    onActionClicked: any;
}

const LIST_ITEM_ACTION = MemberActionType.OwnNurse;

const MemberActionActiveChat = (props: MemberActionComponentProps) => {
    const { action, onActionClicked } = props;

    const chat = action.chat as ListChatFragment;

    return (
        <Card
            fluid
            onClick={() => {
                onActionClicked(action);
            }}
        >
            <Card.Content>
                <ListItem
                    id={chat.id}
                    title={action.title || ''}
                    notification={(chat?.unreadMessagesCountV2 ?? 0) > 0 && chat.unreadMessagesCountV2}
                    date={chat.lastMessageAt ? chat.lastMessageAt : chat.modifiedAt}
                    dateFormat="LLL"
                    extra={
                        <div className="list-chat-users">
                            {chat.users.edges
                                .filter(edge => edge?.node?.leftAt === null)
                                .map((edge, key) => (
                                    <Avatar
                                        key={key}
                                        url={edge?.node?.user.avatarSmallUrl || ''}
                                        title={`${edge?.node?.user.firstName} ${edge?.node?.user.lastName}`}
                                    />
                                ))}
                        </div>
                    }
                />
            </Card.Content>
        </Card>
    );
};

const MemberActionCard = (props: MemberActionComponentProps) => {
    const { action, onActionClicked } = props;

    let buttonMessage;
    let image;

    if (action.typeNew === MemberActionType.HelttiChat) {
        buttonMessage = t.start;
        image = helttiChatImage;
    } else if (action.typeNew === MemberActionType.Call) {
        buttonMessage = t.memberActionCallButton;
        image = helttiLineImage;
    }

    return (
        <Card fluid>
            {image && <Image wrapped src={image} ui={false} />}
            <Card.Content>
                <Card.Header>{action.title}</Card.Header>

                <Card.Description>
                    {action.description && <FormatMarkdown source={action.description} />}
                </Card.Description>
            </Card.Content>

            <Card.Content extra>
                <span>
                    <Icon name="clock outline" /> {action.responseTimeSla}
                </span>

                <Button
                    primary
                    onClick={() => {
                        onActionClicked(action);
                    }}
                    style={{ float: 'right' }}
                >
                    <Translation message={buttonMessage} />
                </Button>
            </Card.Content>
        </Card>
    );
};

const MemberActionSimpleCard = (props: MemberActionComponentProps) => {
    const { action, onActionClicked } = props;

    return (
        <Card
            fluid
            onClick={() => {
                onActionClicked(action);
            }}
        >
            <Card.Content>
                <Card.Header>{action.title}</Card.Header>
                <Card.Description>
                    {action.description && <FormatMarkdown source={action.description} />}
                </Card.Description>
            </Card.Content>
        </Card>
    );
};

export const componentForMemberAction = (action: MemberActionFragment, onActionClicked: any, key?: number) => {
    switch (action.typeNew) {
        case MemberActionType.ActiveChat:
            return <MemberActionActiveChat key={key} action={action} onActionClicked={onActionClicked} />;

        case MemberActionType.HelttiChat:
        case MemberActionType.Call:
            return <MemberActionCard key={key} action={action} onActionClicked={onActionClicked} />;

        case MemberActionType.OwnNurse:
        case MemberActionType.Feedback:
        case MemberActionType.ActionUrl:
        case MemberActionType.Faq:
        case MemberActionType.ReportSickLeave:
        case LIST_ITEM_ACTION:
            return <MemberActionSimpleCard key={key} action={action} onActionClicked={onActionClicked} />;
    }
};

import React from 'react';

import { Image, SemanticSIZES } from 'semantic-ui-react';

interface Props {
    url?: string | null;
    title?: string;
    size?: SemanticSIZES;
    style?: any;
}

export const Avatar: React.FC<Props> = props => {
    const { url, title, size, style } = props;

    return <Image avatar src={url} title={title ? title : ''} size={size} style={style} />;
};

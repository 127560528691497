import { useQuery, useSubscription } from '@apollo/client';

import { BadgesQuery, BadgeSubscription } from '../graphql-schema';
import { badgesQuery } from '../data/queries';
import { badgeSubscription } from '../data/subscriptions';
import { useEffect, useState } from 'react';

type BadgeData = {
    openCalendarReservationAccesses?: number | null;
    unseenChatMessages?: number | null;
    unseenDocuments?: number | null;
    openQuestionnaires?: number | null;
};

const initialBadgeData: BadgeData = {
    openCalendarReservationAccesses: 0,
    unseenChatMessages: 0,
    unseenDocuments: 0,
    openQuestionnaires: 0
};

export const useBadges = (): BadgeData => {
    const [badgeData, setBadgeData] = useState<BadgeData>(initialBadgeData);

    const { loading, error, data: badgeQueryData } = useQuery<BadgesQuery>(badgesQuery);
    const { data: badgeSubscriptionData } = useSubscription<BadgeSubscription>(badgeSubscription);

    useEffect(() => {
        if (!loading && !error) {
            setBadgeData(badgeQueryData?.root?.badges ?? initialBadgeData);
        }
    }, [loading, error, badgeQueryData]);

    useEffect(() => {
        if (badgeSubscriptionData && badgeSubscriptionData.badge) {
            setBadgeData(badgeSubscriptionData?.badge?.badges ?? initialBadgeData);
        }
    }, [badgeSubscriptionData]);

    return badgeData || initialBadgeData;
};

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Card, Divider, Grid, Header } from 'semantic-ui-react';
import { Button, LoadingQuery, LoadingQueryResult } from '@heltti/components';

import QuestionnaireResponse from './QuestionnaireResponse';
import { questionnaireResponseBatchWithTokenQuery } from '../../data/queries';
import { QuestionnaireResponseBatchWithTokenQuery } from '../../graphql-schema';
import { MarkdownTranslation, Translation } from '../../components/Message';
import messages from '../../translations';
import { QuestionnaireResponseBatchCard } from '../../components/QuestionnaireBatch/QuestionnaireResponseBatchCard';

import './questionnaire.less';
import { questionnaireBatchTokenUrl } from '../../util/tokenUrls';

type Props = RouteComponentProps<any>;

export const TokenAuthQuestionnaire: React.FC<Props> = props => {
    const { token, batchId, responseId, memberId } = props.match.params;

    const onLogin = () => {
        props.history.push('/login');
    };

    const renderWithResult = (result: LoadingQueryResult<QuestionnaireResponseBatchWithTokenQuery>) => {
        if (result.error) {
            return (
                <Grid>
                    <Grid.Row centered>
                        <Grid.Column computer={12} tablet={12} mobile={16} textAlign="center">
                            <Card fluid image style={{ marginTop: '60px' }}>
                                <Card.Content>
                                    <Card.Description textAlign="center">
                                        <Header>
                                            <Translation message={messages.questionnaireTokenInvalid.title} />{' '}
                                        </Header>
                                        <Translation message={messages.questionnaireTokenInvalid.description} />{' '}
                                        <Divider hidden />
                                        <Button onClick={onLogin}>
                                            <Translation message={messages.questionnaireTokenInvalid.loginButton} />
                                        </Button>
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }

        const { questionnaireResponseBatch } = result.data?.token ?? {};

        if (!questionnaireResponseBatch) {
            return null;
        }

        const questionnaireResponse = responseId
            ? questionnaireResponseBatch.questionnaireResponses?.edges
                  .map(edge => edge?.node)
                  .find(node => node?.id === responseId)
            : null;

        return (
            <div id="questionnaire" style={{ marginTop: '40px' }}>
                {!questionnaireResponse && (
                    <QuestionnaireResponseBatchCard questionnaireResponseBatch={questionnaireResponseBatch} />
                )}

                {!!questionnaireResponse && (
                    <QuestionnaireResponse
                        accessToken={token}
                        returnPath={`${questionnaireBatchTokenUrl(memberId, batchId, token)}?isFinished=1`}
                        questionnaire={questionnaireResponse.questionnaire}
                        questionnaireResponse={questionnaireResponse}
                    />
                )}

                <div id="footer">
                    <MarkdownTranslation message={messages.questionnairesByToken.footerDescription} />
                </div>
            </div>
        );
    };

    return (
        <LoadingQuery
            renderWithoutData
            query={questionnaireResponseBatchWithTokenQuery}
            variables={{ batchId, memberId, token }}
            onResult={renderWithResult}
        />
    );
};

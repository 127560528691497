import React, { Component, Fragment, PropsWithChildren } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Button, Form } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';

import { HelttiLogo } from '../../components/Logo';
import t from '../../translations';
import { saveNPS } from '../../api';
import { Translation } from '../../components/Message';

import './NPSForm.less';

type Props = OwnProps & WrappedComponentProps;

interface OwnProps {
    npsUUID: string;
    isCompanyResponse: boolean;
    isMindyResponse: boolean;
    isMindyOrgResponse: boolean;
    scoreNps?: number;
    scoreDetail1?: number;
}

interface State {
    scoreNps?: number;
    scoreDetail1?: number;
    scoreDetail2?: number;

    comment: string;
    contactPermission: boolean;

    isSent: boolean;
    isLoading: boolean;
}

type ScoreFieldName = 'scoreNps' | 'scoreDetail1' | 'scoreDetail2';

const NPSFormSection: React.FC<PropsWithChildren<unknown>> = ({ children }) => (
    <div className="nps-form__section">{children}</div>
);
const NPSFormHints: React.FC<PropsWithChildren<unknown>> = ({ children }) => (
    <div className="nps-form__hints">{children}</div>
);

class PlainNPSForm extends Component<Props, State> {
    public state: State = {
        scoreNps: undefined,
        scoreDetail1: undefined,
        scoreDetail2: undefined,
        comment: '',
        contactPermission: false,

        isSent: false,
        isLoading: false
    };

    public componentDidMount() {
        if (this.props.scoreNps) {
            this.setState({ scoreNps: this.props.scoreNps });
        } else {
            this.setState({ scoreDetail1: this.props.scoreDetail1 });
        }
    }

    private setScore = (event, name: ScoreFieldName, value: number) => {
        event.preventDefault();

        this.setState({ [name]: value } as unknown as State);
    };

    private setComment = (event: any) => {
        this.setState({ comment: event.target.value });
    };

    private setContactPermission = (e: any, { checked }: any) => {
        this.setState({ contactPermission: checked });
    };

    public render() {
        const { intl, isCompanyResponse, isMindyResponse, isMindyOrgResponse } = this.props;
        const { isSent, scoreNps, scoreDetail1, scoreDetail2 } = this.state;

        if (isSent) {
            return (
                <div>
                    <HelttiLogo />

                    <h3 style={{ marginBottom: '40px' }}>{intl.formatMessage(t.npsThankYou)}</h3>
                </div>
            );
        }

        let detail1Translation;
        let detail2Translation;
        let detail1HighScoreHint;
        let detail2HighScoreHint;
        const npsTitle = isMindyResponse || isMindyOrgResponse ? t.npsTitleMindy : t.npsTitle;

        if (isCompanyResponse || isMindyOrgResponse) {
            detail1Translation = t.npsCompanyDetail1Title;
            detail2Translation = t.npsCompanyDetail2Title;
            detail1HighScoreHint = t.npsVeryValuable;
            detail2HighScoreHint = t.npsVerySatisfied;
        } else if (isMindyResponse) {
            detail1Translation = t.npsMindyDetail1Title;
            detail1HighScoreHint = t.npsVeryValuable;
        } else {
            detail1Translation = t.npsMemberDetail1Title;
            detail2Translation = t.npsMemberDetail2Title;
            detail1HighScoreHint = t.npsVeryWell;
            detail2HighScoreHint = t.npsVeryWell;
        }

        return (
            <Form onSubmit={this.handleSubmit} className="nps-form">
                <NPSFormSection>
                    <HelttiLogo />

                    <h3>{intl.formatMessage(t.npsFormTitle)}</h3>
                </NPSFormSection>

                <Fragment>
                    <NPSFormSection>
                        <Form.Field>
                            <label>
                                <Translation message={detail1Translation} />
                            </label>

                            <Form.Field>
                                <div className="scoreButtonGroup scoreDetail1">
                                    {this.renderScores('scoreDetail1', [1, 2, 3, 4, 5], scoreDetail1)}
                                </div>
                            </Form.Field>
                        </Form.Field>

                        <NPSFormHints>
                            <div>
                                <Translation message={t.npsNotAtAll} />
                            </div>
                            <div>
                                <Translation message={detail1HighScoreHint} />
                            </div>
                        </NPSFormHints>
                    </NPSFormSection>

                    {detail2Translation && (
                        <NPSFormSection>
                            <Form.Field>
                                <label>
                                    <Translation message={detail2Translation} />
                                </label>

                                <div className="scoreButtonGroup scoreDetail2">
                                    {this.renderScores('scoreDetail2', [1, 2, 3, 4, 5], scoreDetail2)}
                                </div>
                            </Form.Field>

                            <NPSFormHints>
                                <div>
                                    <Translation message={t.npsNotAtAll} />
                                </div>
                                <div>
                                    <Translation message={detail2HighScoreHint} />
                                </div>
                            </NPSFormHints>
                        </NPSFormSection>
                    )}
                </Fragment>

                <NPSFormSection>
                    <Form.Field className="scoreNps">
                        <label>
                            <Translation message={npsTitle} />
                        </label>
                        <div className="scoreButtonGroup">
                            {this.renderScores('scoreNps', [0, 1, 2, 3, 4, 5], scoreNps)}
                        </div>
                        <div className="scoreButtonGroup">
                            {this.renderScores('scoreNps', [6, 7, 8, 9, 10], scoreNps)}
                        </div>
                    </Form.Field>

                    <div className="nps-form__hints">
                        <div>{intl.formatMessage(t.npsNotLikely)}</div>
                        <div>{intl.formatMessage(t.npsVeryLikely)}</div>
                    </div>
                </NPSFormSection>

                <NPSFormSection>
                    <Form.Field>
                        <label>{intl.formatMessage(t.npsComment)}</label>

                        <TextareaAutosize
                            name="comment"
                            value={this.state.comment}
                            onChange={this.setComment}
                            placeholder={intl.formatMessage(t.npsCommentPlaceholder)}
                            minRows={1}
                            rows={2}
                            maxRows={8}
                            maxLength={4096}
                            style={{ maxWidth: '560px' }}
                        />
                    </Form.Field>
                </NPSFormSection>

                <NPSFormSection>
                    <Form.Field>
                        <Form.Checkbox
                            name="contactPermission"
                            onChange={this.setContactPermission}
                            label={intl.formatMessage(t.npsContactPermission)}
                            checked={this.state.contactPermission}
                        />
                    </Form.Field>
                </NPSFormSection>

                <NPSFormSection>
                    <Button primary size="large" disabled={this.state.scoreNps === undefined || this.state.isLoading}>
                        {intl.formatMessage(t.npsSave)}
                    </Button>

                    <p style={{ marginTop: '20px' }}>
                        <a
                            href={intl.formatMessage(t.dataProtectionDescriptionUrl)}
                            style={{ fontSize: '80%', color: 'grey', border: 'none' }}
                        >
                            {intl.formatMessage(t.dataProtectionDescriptionLink)}
                        </a>
                    </p>
                </NPSFormSection>
            </Form>
        );
    }

    private renderScores = (name: ScoreFieldName, scores: number[], currentScore?: number) => {
        return scores.map(scoreItem => (
            <Button
                compact
                key={scoreItem}
                className="scoreButton"
                primary={scoreItem === currentScore}
                onClick={event => this.setScore(event, name, scoreItem)}
            >
                {scoreItem}
            </Button>
        ));
    };

    private handleSubmit = () => {
        this.setState({ isLoading: true });

        const { npsUUID } = this.props;
        const { scoreNps, scoreDetail1, scoreDetail2, comment, contactPermission } = this.state;

        if (scoreNps === null || scoreNps === undefined) {
            return;
        }

        saveNPS(npsUUID, scoreNps, scoreDetail1, scoreDetail2, comment, contactPermission)
            .then(() => this.setState({ isSent: true }))
            .catch(() => this.setState({ isLoading: false }));
    };
}

export const NPSForm = injectIntl(PlainNPSForm);

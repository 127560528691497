import React, { FunctionComponent, useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import { Breadcrumb, BreadcrumbDivider, BreadcrumbSection, Header } from 'semantic-ui-react';
import { Error, Loader } from '@heltti/components';

import { chatMessagesSeenMutation } from '../../data/mutations';
import { Content } from '../../components/Content';
import { SendChatMessage } from './SendChatMessage';
import { ChatMessages } from './ChatMessages';
import { ChatMeta } from './ChatMeta';
import ReOpenChatButton from './ReOpenChatButton';
import t from '../../translations';
import { CompactHeaderWithNavigation } from '../../components/CompactHeaderWithNavigation';
import { Translation } from '../../components/Message';
import { FormatMarkdown } from '../../components/FormatMarkdown';
import { apolloClient } from '../../index';
import { useChat } from '../../hooks/useChat';

import './chat.less';

type RouteMatchParams = {
    id: string;
};

type Props = RouteComponentProps<RouteMatchParams>;

export const Chat: FunctionComponent<Props> = props => {
    const { loading, error, data } = useChat(props.match.params.id);

    const chat = data?.root?.chat;

    const onMarkAsSeen = useCallback(
        (messageIds: string[]) => {
            if (!chat) {
                return;
            }

            apolloClient
                .mutate({
                    mutation: chatMessagesSeenMutation,
                    variables: {
                        data: {
                            chatId: chat.id,
                            messageIds
                        }
                    }
                })
                .finally(() => {
                    // No op
                });
        },
        [chat]
    );

    const handleGoBack = () => {
        props.history.goBack();
    };

    if (loading || !chat) {
        return <Loader />;
    } else if (error) {
        return <Error showRefreshButton />;
    }

    const chatIsClosed = chat.closedAt !== null;

    return (
        <Content id="chat" containerWidth="full">
            <div className="meta">
                <div className="chatHeader">
                    <Breadcrumb>
                        <BreadcrumbSection>
                            <Link to="/paths">
                                <Translation message={t.menuPaths} />
                            </Link>
                        </BreadcrumbSection>
                        <BreadcrumbDivider />
                        <BreadcrumbSection>
                            <Link to="/chats/archive">
                                <Translation message={t.menuDiscussion} />
                            </Link>
                        </BreadcrumbSection>
                    </Breadcrumb>

                    <Header>{chat.title.toUpperCase()}</Header>
                    {chat.description && (
                        <aside>
                            <FormatMarkdown source={chat.description} />
                        </aside>
                    )}
                </div>

                <ChatMeta chat={chat} />
            </div>

            <div className="chatColumnDivider" />

            <div className="messaging">
                <CompactHeaderWithNavigation backTo={handleGoBack} title={chat.title} color="red" />

                <ChatMessages
                    chat={chat}
                    chatType={chat.type}
                    onMarkAsSeen={onMarkAsSeen}
                    responseTimeSla={chat.responseTimeSla}
                />

                <SendChatMessage chatId={chat.id} isDisabled={chatIsClosed || chat.readonly} />

                {chatIsClosed && <ReOpenChatButton chatType={chat.type} />}
                {chat.readonly && (
                    <div className="readonlyNotification">
                        <p>
                            <Translation message={t.chatReadonlyNotification} />
                        </p>
                    </div>
                )}
            </div>
        </Content>
    );
};

import React, { useEffect, useMemo } from 'react';
import moment from 'moment';

import { Icon, Menu } from 'semantic-ui-react';
import { Error as ErrorComponent } from '@heltti/components';

import { AppointmentContactType } from '../../graphql-schema';
import t from '../../translations';
import { Translation } from '../../components/Message';
import { useAppointmentDays } from './useAppointmentDaysHook';

const FETCH_UNTIL_DATA_WITH_EVENTS_FOR_DAYS = 14;
const FETCH_UNTIL_DATA_FOR_DAYS = 90;
const BATCH_SIZE = 7;

type Props = {
    craId: string;
    selectedDate?: string | null;
    onSelectDate(date: string | null): void;
    regionId?: string | null;
    locationId?: string | null;
    showRemoteOnly: boolean;
};

export const ReserveAppointmentModalDateSelect: React.FC<Props> = props => {
    const { craId, onSelectDate, selectedDate, regionId, locationId, showRemoteOnly } = props;

    const { appointmentDays, loading, error, fetchMoreAppointmentDays } = useAppointmentDays({
        start: moment().startOf('day').format('YYYY-MM-DD'),
        end: moment().add(BATCH_SIZE, 'days').startOf('day').format('YYYY-MM-DD'),
        calendarReservationAccessId: craId,
        regionId: regionId ?? undefined,
        locationId: locationId ?? undefined,
        filters: showRemoteOnly ? { contactTypes: [AppointmentContactType.Video, AppointmentContactType.Phone] } : {}
    });

    const appointmentDaysWithAppointments = useMemo(() => {
        return appointmentDays?.filter(appointmentDay => appointmentDay.eventCount) ?? [];
    }, [appointmentDays]);

    const dateOptions = useMemo(
        () => appointmentDaysWithAppointments.map(appointmentDay => moment(appointmentDay.date).format('l')) ?? [],
        [appointmentDaysWithAppointments]
    );

    useEffect(() => {
        if ((dateOptions.length && !selectedDate) || !dateOptions.find(option => option == selectedDate)) {
            onSelectDate(dateOptions[0]);
        } else if (!loading && appointmentDays?.length === 0) {
            onSelectDate(null);
        }
    }, [appointmentDays?.length, dateOptions, dateOptions.length, loading, onSelectDate, selectedDate]);

    useEffect(() => {
        if (appointmentDays === undefined || appointmentDays.length === 0) {
            return;
        }

        const latestAppointmentDate = moment.max(appointmentDays.map(item => moment.utc(item.date)));

        const start = moment(latestAppointmentDate).add(1, 'days');
        const end = moment(start).add(BATCH_SIZE, 'days').endOf('day');

        if (
            start &&
            appointmentDaysWithAppointments.length < FETCH_UNTIL_DATA_WITH_EVENTS_FOR_DAYS &&
            appointmentDays.length < FETCH_UNTIL_DATA_FOR_DAYS
        ) {
            fetchMoreAppointmentDays(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')).catch(console.error);
        }
    }, [appointmentDays, appointmentDaysWithAppointments.length, fetchMoreAppointmentDays]);

    if (error) {
        return <ErrorComponent title={error.name} description={error.message} />;
    } else if (loading) {
        return (
            <div>
                <Translation message={t.loading} />
            </div>
        );
    }

    return (
        <Menu secondary compact fluid>
            <Menu.Item header icon>
                <Icon name="calendar outline" />
            </Menu.Item>

            {dateOptions.map(dateOption => (
                <Menu.Item
                    key={dateOption}
                    name={dateOption}
                    active={dateOption === selectedDate}
                    onClick={() => onSelectDate(dateOption)}
                >
                    {dateOption}
                </Menu.Item>
            ))}
        </Menu>
    );
};

import React, { PureComponent } from 'react';
import moment from 'moment';
import { injectIntl } from 'react-intl';

import { isEvent, Props } from './ChatMessage';
import t from '../../translations';

const eventTranslationKeys = {
    10: t.chatJoined,
    11: t.chatLeft,
    12: t.chatClosed,
    13: t.chatReopened,
    14: t.chatEscalated
};

export const getEventTranslationKey = genericType => eventTranslationKeys[genericType];

class ChatEvent extends PureComponent<Props> {
    public render() {
        const { type, createdAt, who, intl } = this.props;

        if (!isEvent(type)) {
            return null;
        }

        return (
            <div className="chatMessage from-generic">
                {moment(createdAt).format('HH:mm')} {who && <span>&nbsp;{who}</span>}{' '}
                {getEventTranslationKey(type) && intl.formatMessage(getEventTranslationKey(type))}
            </div>
        );
    }
}

export default injectIntl(ChatEvent);

import React from 'react';
import { FormattedDate as Date, FormattedTime as Time } from 'react-intl';

import { Card, Divider, Header, Image } from 'semantic-ui-react';

import { CalendarEventFragment } from '../graphql-schema';
import { APPOINTMENT_EVENT_TYPE_TRANSLATIONS } from '../constants';
import { Translation } from './Message';

type Props = OwnProps;

interface OwnProps {
    compact?: boolean;
    event: CalendarEventFragment;
    onClick: () => void;
}

export const CalendarEventCard: React.FC<Props> = props => {
    const {
        event: {
            start,
            description,
            type,
            calendar: { user },
            location
        },
        onClick
    } = props;

    return (
        <Card fluid onClick={onClick}>
            <Card.Content>
                <Header size="small" style={{ marginTop: '5px' }}>
                    <Translation message={APPOINTMENT_EVENT_TYPE_TRANSLATIONS[type]} /> <Date value={start} />{' '}
                    <Time value={start} />
                </Header>

                <Divider />

                {user ? (
                    <Header size="small" style={{ marginTop: 0, marginBottom: 0 }}>
                        <Image circular size="mini" src={user.avatarSmallUrl} verticalAlign="middle" />
                        <Header.Content style={{ paddingTop: 0, paddingBottom: 0 }}>
                            {user.firstName} {user.lastName}
                            <Header.Subheader>{user.title}</Header.Subheader>
                        </Header.Content>
                    </Header>
                ) : (
                    <p className="newlines">{description}</p>
                )}

                {location && (
                    <>
                        <Divider />
                        <Header size="small" style={{ marginTop: 0 }}>
                            {location.name}
                        </Header>
                        {location.address1}
                        {!!location.address2 && ` ${location.address2}`}, {location.zip} {location.city}
                    </>
                )}
            </Card.Content>
        </Card>
    );
};

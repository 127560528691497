import React, { Component } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { isLowercase, isUppercase } from 'validator';
import { upperFirst } from 'lodash';

import t from '../translations';

import './PasswordIndicator.less';

type Props = OwnProps & WrappedComponentProps;

interface OwnProps {
    password?: string;
    passwordAgain?: string;
}

const validatePassword = (password: string, passwordAgain: string) => [
    { name: 'lowercase', isValid: !isUppercase(password) },
    { name: 'uppercase', isValid: !isLowercase(password) },
    { name: 'number', isValid: password && password.match('[0-9]') != null },
    { name: 'length', isValid: password && password.length >= 10 },
    { name: 'match', isValid: password && password === passwordAgain }
];

export const isPasswordValid = (password: string, passwordAgain: string) => {
    return validatePassword(password, passwordAgain)
        .map(p => p.isValid)
        .every(value => value === true);
};

class PasswordIndicator extends Component<Props> {
    public render() {
        const { intl, password, passwordAgain } = this.props;

        return (
            <div id="passwordIndicator">
                <p>{intl.formatMessage(t.resetPasswordRequirements)}</p>

                <ul>
                    {validatePassword(password ?? '', passwordAgain ?? '').map((part, key) => (
                        <li key={key}>
                            <div className={`passwordValidityCheck ${part.isValid && 'passwordValidityCheckValid'}`}>
                                {part.isValid ? <span>&#10003;</span> : <span>&nbsp;</span>}
                            </div>

                            <div>{intl.formatMessage(t[`resetPasswordRequirements${upperFirst(part.name)}`])}</div>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default injectIntl(PasswordIndicator);

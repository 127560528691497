import { AuthState, default as auth } from './auth';
import { auth as profile, ProfileState } from './profile';
import { default as password, PasswordResetState } from './password';
import { default as flow, FlowState } from './flow';
import { default as modal, ModalState } from './modal';

export interface RootState {
    auth: AuthState;
    flow: FlowState;
    modal: ModalState;
    password: PasswordResetState;
    profile: ProfileState;
}

const reducers = {
    auth,
    flow,
    modal,
    password,
    profile
};

export default reducers;

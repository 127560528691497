import { defineMessages } from 'react-intl';

const emailVerification = defineMessages({
    header: { id: 'emailVerification.header', defaultMessage: 'Welcome!' },
    description: {
        id: 'emailVerification.description',
        defaultMessage: 'Verify your work email to activate services provided by your employer'
    },
    successHeader: { id: 'emailVerification.success.header', defaultMessage: "The email is on it's way!" },
    successDescription: {
        id: 'emailVerification.success.description',
        defaultMessage:
            "You'll receive a confirmation email shortly. If you cannot see the email in your inbox after a while, please check your junk mail folder."
    },
    submit: { id: 'emailVerification.submit', defaultMessage: 'Send verification email' },
    workEmail: { id: 'emailVerification.workEmail', defaultMessage: 'Work email' }
});

const emailSignUp = defineMessages({
    acceptTerms: { id: 'emailSignUp.acceptTerms', defaultMessage: 'Accept terms and conditions' },
    signUpButton: { id: 'emailSignUp.signUpButton', defaultMessage: 'Sign up' },
    errorGeneric: {
        id: 'emailSignUp.error.generic',
        defaultMessage: 'Oh no, something went wrong! Please try again or contact support.'
    },
    emailSignUpHeader: { id: 'emailSignUp.emailSignUp.header', defaultMessage: 'Thank you for verifying your email!' },
    emailSignUpDescription: {
        id: 'emailSignUp.emailSignUp.description',
        defaultMessage: 'Please provide a few more details to create your account'
    },

    identificationSignUpHeader: {
        id: 'emailSignUp.identificationSignUp.header'
    },
    identificationSignUpDescription: {
        id: 'emailSignUp.identificationSignUp.description',
        defaultMessage: 'To finish signing up please identify yourself with bank credentials or mobile ID'
    },
    notAvailableHeader: {
        id: 'emailSignUp.notAvailable.header',
        defaultMessage: 'Thank you for verifying your email!'
    },
    notAvailableDescription: {
        id: 'emailSignUp.notAvailable.description',
        defaultMessage:
            "Unfortunately your employer doesn't have active services with us or signing up with company email isn't activated. Please contact your company's representative for assistance."
    }
});

const emailChange = defineMessages({
    unsupportedHeader: {
        id: 'emailSignUp.notAvailable.header',
        defaultMessage: 'Thank you for verifying your email!'
    },

    emailUpdateLoadingHeader: {
        id: 'emailUpdate.loading.header',
        defaultMessage: '"Your email is being verified...'
    },
    emailUpdateSuccesHeader: {
        id: 'emailUpdate.success.header',
        defaultMessage: 'Thank you for verifying your email!'
    },
    emailUpdateSuccesDescription: {
        id: 'emailUpdate.success.description',
        defaultMessage: 'Your email address have now been updated'
    },
    emailUpdateErrorNotLoggedIn: {
        id: 'emailUpdate.loginRequired.description',
        defaultMessage: 'Please login to finalise updating your email'
    }
});

const hello = defineMessages({
    title: { id: 'hello.title', defaultMessage: 'Hello new member!' },
    description: { id: 'hello.description', defaultMessage: 'Let' }
});

const profile = defineMessages({
    loginCredentials: { id: 'profile.loginCredentials', defaultMessage: 'Login credentials' },
    contactDetails: { id: 'profile.contactDetails', defaultMessage: 'Contact details' },

    address1: { id: 'profile.address1', defaultMessage: ' Address 1' },
    address2: { id: 'profile.address2', defaultMessage: 'Address 2' },
    city: { id: 'profile.city', defaultMessage: 'City' },
    email: { id: 'profile.email', defaultMessage: 'E-mail' },

    firstName: { id: 'profile.firstName', defaultMessage: 'First name' },
    language: { id: 'profile.language', defaultMessage: 'Language' },
    lastName: { id: 'profile.lastName', defaultMessage: 'Last name' },
    phone: { id: 'profile.phone', defaultMessage: 'Phone number' },
    save: { id: 'profile.save', defaultMessage: 'Save' },
    title: { id: 'profile.title', defaultMessage: 'Profile information' },
    zip: { id: 'profile.zip', defaultMessage: 'Zip code' },

    password: { id: 'profile.password', defaultMessage: 'Password' },
    passwordAgain: { id: 'profile.password.again', defaultMessage: 'Confirm password' },
    passwordExplanation: {
        id: 'profile.password.explanation',
        defaultMessage: 'You can log in to MyHeltti via password by setting a password.'
    },
    initialPassword: {
        id: 'profile.initialPassword',
        defaultMessage: 'Set a password that you can use further on when signing in'
    },

    usernameTakenError: {
        id: 'profile.usernameTakenError',
        defaultMessage: 'Another account uses that email address, please select another'
    },

    formNameChangeHintPopUpContent: {
        id: 'profileForm.NameChangeHintPopUp.content',
        defaultMessage: 'If your name has changed, please authenticate again with your bank account to update it.'
    },
    formNameChangeHintPopUpButtonTitle: {
        id: 'profileForm.NameChangeHintPopUp.buttonTitle',
        defaultMessage: 'Authenticate'
    }
});

const questionnairesByToken = defineMessages({
    footerDescription: {
        id: 'questionnairesByToken.footerDescription',
        defaultMessage: 'Please login to see your full profile'
    }
});

const questionnaireTokenInvalid = defineMessages({
    title: { id: 'questionnaire.tokenInvalid.title', defaultMessage: 'Invalid token' },
    description: {
        id: 'questionnaire.tokenInvalid.description',
        defaultMessage: 'Unfortunately the token is not valid'
    },
    loginButton: { id: 'questionnaire.tokenInvalid.loginButton', defaultMessage: 'Login to MyHeltti' }
});

// noinspection TsLint
const unsortedMessages = defineMessages({
    appointment: { id: 'appointment', defaultMessage: 'Appointment' },
    appointmentAppointments: { id: 'appointment.appointments', defaultMessage: 'Appointments' },
    appointmentAppointmentsAll: { id: 'appointment.appointments.all', defaultMessage: 'All' },
    appointmentAvailable: { id: 'appointment.availableAppointments', defaultMessage: 'Available appointments' },
    appointmentCancel: { id: 'appointment.cancel', defaultMessage: 'Cancel appointment' },
    appointmentCancelDescription: {
        id: 'appointment.cancel.description',
        defaultMessage:
            'Please note that the appointment can be canceled free of charge up to 24 hours before the starting time.'
    },
    appointmentModify: { id: 'appointment.modify', defaultMessage: 'Modify appointment' },
    appointmentReserveAppointment: { id: 'appointment.reserveAppointment', defaultMessage: 'Reserve appointment' },
    appointmentReserveAppointmentConfirm: { id: 'appointment.reserveAppointment.confirm', defaultMessage: 'Confirm' },
    appointmentReserveAppointmentBack: { id: 'appointment.reserveAppointment.back', defaultMessage: 'Back' },
    appointmentReserveAppointmentChangeTime: {
        id: 'appointment.reserveAppointment.changeTime',
        defaultMessage: 'Change time'
    },
    appointmentReserveErrorTitle: {
        id: 'appointment.reserveAppointment.error.title',
        defaultMessage: 'Time not available'
    },
    appointmentReserveErrorDescription: {
        id: 'appointment.reserveAppointment.error.description',
        defaultMessage: 'Sorry, this time is no longer available, please select another.'
    },
    appointmentReserveTerms: {
        id: 'appointment.reserveAppointment.terms',
        defaultMessage:
            'Changes to appointments must be made at least 24 hours before the time of the appointment. For late changes and missed appointments we charge the listed price in full.'
    },
    appointmentReservationAccessValidUntil: {
        id: 'appointment.reservationAccess.validUntil',
        defaultMessage: 'Valid until'
    },
    appointmentsNotAvailable: {
        id: 'appointment.notAvailable',
        defaultMessage: 'Unfortunately there are no appointments available at the moment.'
    },
    appointmentFilterRemoteOnly: { id: 'appointment.filter.remoteOnly', defaultMessage: 'Only remote' },
    appointmentsNotFound: { id: 'appointment.notFound', defaultMessage: 'No available appointments.' },
    appointmentTime: { id: 'appointment.time', defaultMessage: 'Appointment' },
    appointmentTimeAcute: { id: 'appointment.time.acute', defaultMessage: 'Acute' },
    appointmentTimeLaboratory: { id: 'appointment.time.laboratory', defaultMessage: 'Laboratory' },
    appointmentTimePhone: { id: 'appointment.time.phone', defaultMessage: 'Phone call' },
    appointmentTimeVideo: { id: 'appointment.time.video', defaultMessage: 'Video call' },
    appointmentTimeVideoJoin: { id: 'appointment.time.video.join', defaultMessage: 'Join video call' },

    hi: { id: 'hi', defaultMessage: 'Hi' },

    errorGeneralTitle: { id: 'error.general.title', defaultMessage: 'Oops!' },
    errorGeneralDescription: {
        id: 'error.general.description',
        defaultMessage: 'Something went wrong, apologies! Please contact Heltti if this happens again.'
    },
    errorGeneralRefresh: { id: 'error.general.refresh', defaultMessage: 'Refresh the page' },
    errorNotFoundTitle: { id: 'error.notFound.title', defaultMessage: 'Not found' },
    errorNotFoundDescription: { id: 'error.notFound.description', defaultMessage: 'Please try some other link.' },
    errorDismiss: { id: 'error.dismiss', defaultMessage: 'Close' },

    helttiPhoneNumber: { id: 'heltti.phoneNumber', defaultMessage: '+358 20 1470 770' }, // This is used in callto links.
    helttiPhoneNumberForDisplay: { id: 'heltti.phoneNumber.forDisplay', defaultMessage: '020 1470 770' },
    helttiTechnicalSupportEmail: { id: 'heltti.technicalSupportEmail', defaultMessage: 'myheltti-tuki@heltti.fi' },

    menuHome: { id: 'menu.home', defaultMessage: 'Home' },
    menuPaths: { id: 'menu.paths', defaultMessage: 'Paths' },
    menuDiscussion: { id: 'menu.discussion', defaultMessage: 'Discussions' },
    menuExplore: { id: 'menu.explore', defaultMessage: 'Explore' },
    menuProfile: { id: 'menu.profile', defaultMessage: 'Profile' },

    calendarAppointments: { id: 'calendar.appointments', defaultMessage: 'Appointments' },
    calendarPastAppointments: { id: 'calendar.pastAppointments', defaultMessage: 'Past appointments' },
    calenderEventInformation: {
        id: 'calendar.event.information',
        defaultMessage:
            'Please notice that only laboratory appointments are shown here. All the appointment information are shown here from the beginning of 2019'
    },

    calendarEventTime: { id: 'calendar.event.time', defaultMessage: 'at' },

    chatJoined: { id: 'chat.joined', defaultMessage: 'joined the chat' },
    chatLeft: { id: 'chat.left', defaultMessage: 'left the chat' },
    chatClosed: { id: 'chat.closed', defaultMessage: 'chat closed' },
    chatReadonlyNotification: {
        id: 'chat.readonlyNotification',
        defaultMessage: 'Messages cannot be sent to this discussion'
    },
    chatReopened: { id: 'chat.reopened', defaultMessage: 'chat re-opened' },
    chatEscalated: { id: 'chat.escalated', defaultMessage: 'chat escalated' },
    chatsTitle: { id: 'chats.title', defaultMessage: 'Discussions' },
    chatsTitleActive: { id: 'chats.title.active', defaultMessage: 'Active' },
    chatsTitleLatest: { id: 'chats.title.latest', defaultMessage: 'Latest' },
    chatsTitleHistory: { id: 'chats.title.history', defaultMessage: 'History' },
    chatsNoAvailableChats: { id: 'chats.none', defaultMessage: 'You have no discussions' },
    chatEditMessage: { id: 'chat.message.edit', defaultMessage: 'Edit' },
    chatDeleteMessage: { id: 'chat.message.delete', defaultMessage: 'Delete' },
    chatMessageDeleted: { id: 'chat.message.deleted', defaultMessage: 'This message has been deleted' },
    chatFeedback: { id: 'chat.feedback', defaultMessage: 'Give us feedback' },
    chatSupport: { id: 'chat.support', defaultMessage: 'MyHeltti Support' },

    chooseLanguageFi: { id: 'choose.language.fi', defaultMessage: 'In Finnish' },
    chooseLanguageEn: { id: 'choose.language.en', defaultMessage: 'In English' },

    close: { id: 'close', defaultMessage: 'Close' },

    contactFlowButtonTitle: { id: 'contact.flow.button.title', defaultMessage: 'Start here' },
    contactFlowStartNewChat: { id: 'contact.flow.chats.startNewChat', defaultMessage: 'Start new chat' },

    dataProtectionDescriptionQuestionnaire: {
        id: 'dataProtectionDescription.questionnaire',
        defaultMessage:
            'By answering to the questionnaire you grant us permission to store the answers and use them to provide you a better service'
    },
    dataProtectionDescriptionLink: {
        id: 'dataProtectionDescription.link',
        defaultMessage: 'Data protection description'
    },
    dataProtectionDescriptionUrl: {
        id: 'dataProtectionDescription.url',
        defaultMessage: 'https://heltti.fi/wp-content/uploads/2018/03/MyHeltti-Data-Protection-Description-20180228.pdf'
    },

    document: { id: 'document', defaultMessage: 'Document' },
    documentAddedAt: { id: 'document.addedAt', defaultMessage: 'Added' },
    documentMemberVisibleMessage: { id: 'document.memberVisibleMessage', defaultMessage: 'Attached message' },
    documentName: { id: 'document.name', defaultMessage: 'Name' },
    documentExportTokens: { id: 'document.exportTokens', defaultMessage: 'Security codes' },
    documentExportTokensCreate: { id: 'document.exportTokens.create', defaultMessage: 'Create new code' },
    documentExportTokensDelete: { id: 'document.exportTokens.delete', defaultMessage: 'Delete code' },
    documentExportTokensHelp: { id: 'document.exportTokens.help', defaultMessage: 'Help' },
    documentExportTokensReadMore: { id: 'document.exportTokens.readMore', defaultMessage: 'Read more.' },
    documentExportTokensToken: { id: 'document.exportTokens.token', defaultMessage: 'Token' },
    documentExportTokensValidUntil: { id: 'document.exportTokens.validUntil', defaultMessage: 'Valid until' },
    footerSupportAndFeedback: {
        id: 'footer.supportAndFeedback',
        defaultMessage: 'Support and feedback'
    },
    formSavedSuccessfullyMessage: { id: 'form.saved.successfully.message', defaultMessage: 'Successfully saved' },
    marketingTermsUrl: {
        id: 'marketingTerms.url',
        defaultMessage:
            'https://heltti.fi/wp-content/uploads/2018/05/Suoramarkkinointirekisteri-Tietosuojaseloste-20180521.pdf'
    },
    memberActionIsOpen: {
        id: 'memberAction.isOpen',
        defaultMessage: 'Open'
    },
    memberActionActiveChatsTitle: {
        id: 'memberAction.activeChats.title',
        defaultMessage: 'Active chats'
    },
    memberActionContactNowTitle: {
        id: 'memberAction.contactNow.title',
        defaultMessage: 'Contact now'
    },
    memberActionNonBusyActionsTitle: {
        id: 'memberAction.nonUrgentActions.title',
        defaultMessage: 'Non busy actions' // TODO: Better translation
    },
    memberActionCallNumber: {
        id: 'memberAction.call.number',
        defaultMessage: 'Call {number}'
    },
    memberActionCallButton: {
        id: 'memberAction.call.button',
        defaultMessage: 'Call'
    },

    termsOfUseUrl: { id: 'termsOfUse.url', defaultMessage: 'https://heltti.fi/myheltti-yleiset-kayttoehdot-20180517/' },

    homeTitle: { id: 'home.title', defaultMessage: 'Welcome' },
    homeTopBannerSubHeader: { id: 'home.topBanner.subHeader', defaultMessage: 'This is MyHeltti' },
    homeMessagesNew: { id: 'home.messages.new', defaultMessage: 'New messages' },
    homeMessagesNewNone: { id: 'home.messages.new.none', defaultMessage: 'No new messages' },
    homeDocumentsNew: { id: 'home.documents.new', defaultMessage: 'New documents' },
    homeDocumentsNewDocuments: { id: 'home.documents.newDocuments', defaultMessage: 'You have new documents' },
    homeDocumentsNewNone: { id: 'home.documents.new.none', defaultMessage: 'No new documents' },
    homeIntroductionMyHeltti: {
        id: 'home.introduction.myheltti',
        defaultMessage:
            'MyHeltti is the communication channel between you and your occupational health care team. You can contact us via MyHeltti in anything about health or illnesses. We will answer you by next business day.'
    },
    homeIntroductionHelttiLine: {
        id: 'home.introduction.helttiLine',
        defaultMessage: 'In urgent matters you can reach us also via HelttiLine, '
    },
    homeBenefitsTitle: { id: 'home.benefits.title', defaultMessage: 'Heltti membership benefits' },
    homeBenefitsDescription: {
        id: 'home.benefits.description',
        defaultMessage: 'Check out the membership benefits and redemption instructions'
    },
    homeNewsTitle: { id: 'home.newsTitle', defaultMessage: 'News' },

    call: { id: 'contact.call', defaultMessage: 'Call' },
    helttiLocationsTitle: { id: 'heltti.locations.title', defaultMessage: 'Heltti locations' },
    helttiLocationsDescription: {
        id: 'heltti.locations.description',
        defaultMessage: 'Check out our locations on a map.'
    },
    helttiLocationsButton: { id: 'heltti.locations.button', defaultMessage: 'Open the map' },

    reportSickLeaveTitle: { id: 'sickLeave.report.title', defaultMessage: 'Report absence due to illness' },
    reportSickLeaveDescription: {
        id: 'sickLeave.report.description',
        defaultMessage: 'Report short absences from work.'
    },

    showAll: { id: 'showAll', defaultMessage: 'Show all' },
    sickLeaveFormStartDay: { id: 'sickLeave.form.startDay', defaultMessage: 'First day of absence' },
    sickLeaveFormDays: { id: 'sickLeave.form.days', defaultMessage: 'Number of days' },
    sickLeaveFormDescription: { id: 'sickLeave.form.description', defaultMessage: 'Description...' },
    sickLeaveSuccessTitle: { id: 'sickLeave.success.title', defaultMessage: 'Thank you!' },
    sickLeaveSuccessDescription: { id: 'sickLeave.success.description', defaultMessage: 'Get well soon!' },
    sickLeaveSuccessContinue: { id: 'sickLeave.success.continue', defaultMessage: 'Continue' },

    chatSend: { id: 'chat.send', defaultMessage: 'Send message' },
    chatWrite: { id: 'chat.writeHere', defaultMessage: 'Write here...' },
    chatMetaUsers: { id: 'chat.meta.users', defaultMessage: 'People' },
    chatMessagesShowMore: { id: 'chat.message.showMore', defaultMessage: 'Show more' },
    chatEscalate: { id: 'chat.escalate', defaultMessage: 'Transform to livechat' },

    chatNotificationHide: { id: 'chat.notification.hide', defaultMessage: 'Hide' },
    chatNotificationActionsCallButton: {
        id: 'chat.notification.actions.callButton',
        defaultMessage: 'Call to HelttiLinja'
    },

    chatMessageSendFailedTitle: { id: 'chat.message.send.failed.title', defaultMessage: 'Oops, something went wrong' },
    chatMessageSendFailedDescription: {
        id: 'chat.message.send.failed.description',
        defaultMessage: 'Please try sending the message again.'
    },

    landingPitchTitle: { id: 'landingPitch.title', defaultMessage: 'Landing' },
    landingPitchContactSubtitle: { id: 'landingPitch.contact.subtitle', defaultMessage: 'HelttiLinja and Live Chat' },
    landingPitchContactTitle: {
        id: 'landingPitch.contact.title',
        defaultMessage: 'Call or chat with us when the matter is urgent'
    },
    landingPitchContactHighlight: {
        id: 'landingPitch.contact.highlight',
        defaultMessage: 'Call or chat with us when the matter is urgent'
    },
    landingPitchContactDescription: {
        id: 'landingPitch.contact.description',
        defaultMessage: 'Call or chat with us when the matter is urgent'
    },

    landingPitchContactCallDescription: { id: 'landingPitch.contact.call.description', defaultMessage: 'HelttiLinja' },
    landingPitchContactChatDescription: { id: 'landingPitch.contact.chat.description', defaultMessage: 'HelttiChat' },

    landingPitchOwnNurseSubtitle: { id: 'landingPitch.ownNurse.subtitle', defaultMessage: 'Own Nurse chat' },
    landingPitchOwnNurseTitle: { id: 'landingPitch.ownNurse.title', defaultMessage: 'Own nurse' },
    landingPitchOwnNurseDescription: {
        id: 'landingPitch.ownNurse.description',
        defaultMessage: 'Your healthcare team is only a few clicks away!'
    },
    landingPitchVisitUsSubtitle: { id: 'landingPitch.visitUs.subtitle', defaultMessage: 'Visit us' },
    landingPitchVisitUsTitle: { id: 'landingPitch.visitUs.title', defaultMessage: 'Heltti locations' },
    landingPitchVisitUsDescription: {
        id: 'landingPitch.visitUs.description',
        defaultMessage: 'Find your closest Heltti location'
    },
    landingPitchVisitUsButton: {
        id: 'landingPitch.visitUs.button',
        defaultMessage: 'Find your closest Heltti location'
    },
    landingPitchVisitUsButtonUrl: {
        id: 'landingPitch.visitUs.button.url',
        defaultMessage: 'https://heltti.fi'
    },
    landingPitchHelttiViewSubtitle: {
        id: 'landingPitch.helttiView.subtitle',
        defaultMessage: 'Company representatives'
    },
    landingPitchSupportTitle: { id: 'landingPitch.support.title', defaultMessage: 'Support' },
    landingPitchSupportPortalTitle: { id: 'landingPitch.supportPortal.title', defaultMessage: 'Support' },
    landingPitchSupportPortalDescription: { id: 'landingPitch.supportPortal.description', defaultMessage: 'Support' },
    landingPitchHelttiViewTitle: { id: 'landingPitch.helttiView.title', defaultMessage: 'Manage and give feedback' },
    landingPitchHelttiViewDescriptionPart1: {
        id: 'landingPitch.helttiView.description.part1',
        defaultMessage: "Manage your company's services in our HelttiView portal."
    },
    landingPitchHelttiViewDescriptionPart2: {
        id: 'landingPitch.helttiView.description.part2',
        defaultMessage: 'Changes to employee information, billing, feedback'
    },
    landingPitchHelttiViewButton: { id: 'landingPitch.helttiView.button', defaultMessage: 'Login to HelttiView' },

    loading: { id: 'loading', defaultMessage: 'Loading' },

    loginFailedCredentials: { id: 'login.failed.credentials', defaultMessage: 'Wrong credentials' },
    loginUsername: { id: 'login.username', defaultMessage: 'Username' },
    loginPassword: { id: 'login.password', defaultMessage: 'Password' },
    loginButton: { id: 'login.button', defaultMessage: 'Log in' },
    loginTitle: { id: 'login.title', defaultMessage: 'Log in to MyHeltti' },
    logoutButton: { id: 'logout.button', defaultMessage: 'Log out' },
    loginHelttiMeNotification: {
        id: 'login.heltti.me.notification',
        defaultMessage:
            'Please check your email for a MyHeltti invitation or use the Forgotten password -functionality above to activate your MyHeltti-account.'
    },
    loginHelttiRequireHelp: {
        id: 'login.help',
        defaultMessage: 'Need help with MyHeltti? Please contact us'
    },
    loginAboutCookies: { id: 'login.about.cookies', defaultMessage: 'MyHeltti uses cookies' },
    loginAboutCookiesReadMore: { id: 'login.about.cookies.readMore', defaultMessage: 'Read more' },
    loginAboutCookiesUrl: { id: 'login.about.cookies.url', defaultMessage: 'https://heltti.fi/en/about-us/privacy/' },

    loginVerificationTitle: {
        id: 'login.verification.title',
        defaultMessage: 'New member or cant access to your account?'
    },
    loginVerificationButtonTitle: { id: 'login.verification.button.title', defaultMessage: 'Verify your identity' },

    profileQuestionnaires: { id: 'profile.questionnaires', defaultMessage: 'Questionnaires' },
    profileDocuments: { id: 'profile.documents', defaultMessage: 'Documents' },
    profileDocumentsShowAll: { id: 'profile.documents.showAll', defaultMessage: 'Show all' },
    profileDocumentsDownload: { id: 'profile.documents.download', defaultMessage: 'Download file' },
    profileDocumentsDownloadModalClose: { id: 'profile.documents.download.close', defaultMessage: 'Close' },

    profileNavigationTitle: { id: 'profile.navigationTitle', defaultMessage: 'Profile' },
    profileNoDocuments: { id: 'profile.documents.none', defaultMessage: 'You have no documents' },
    profilePermissions: { id: 'profile.permissions', defaultMessage: 'Permissions' },
    profilePermissionsFeedback: { id: 'profile.permissions.feedback', defaultMessage: 'Feedback' },
    profilePermissionsFeedbackHint: { id: 'profile.permissions.feedbackHint', defaultMessage: 'Hint' },
    profilePermissionsMarketing: { id: 'profile.permissions.marketing', defaultMessage: 'Marketing' },
    profilePermissionsMarketingHint: { id: 'profile.permissions.marketingHint', defaultMessage: 'Hint' },
    profilePermissionsMemberInformation: {
        id: 'profile.permissions.memberInformation',
        defaultMessage: 'Member related news'
    },
    profilePermissionsMemberInformationHint: {
        id: 'profile.permissions.memberInformationHint',
        defaultMessage: 'Hint'
    },
    profileEmployment: { id: 'profile.employment', defaultMessage: 'Employment' },
    profileEmploymentMissingTitle: {
        id: 'profile.employmentMissing.title',
        defaultMessage: 'Hi, nice that you are here!'
    },
    profileEmploymentMissingDescription: {
        id: 'profile.employmentMissing.description',
        defaultMessage: "It looks like you don't yet have occupational healthcare services from Heltti."
    },

    questionnaireAnalysed: {
        id: 'questionnaire.analysed',
        defaultMessage:
            'The questionnaire has been analysed and our healthcare professionals will be in contact with you if needed.'
    },
    questionnaireDescriptionShowMore: {
        id: 'questionnaire.description.showMore',
        defaultMessage: 'Show more'
    },
    questionnaireClosed: { id: 'questionnaire.closed', defaultMessage: 'This questionnaire was closed at' },
    questionnairePreviousButton: { id: 'questionnaire.previousButton', defaultMessage: 'Previous' },
    questionnaireMoveOnButton: { id: 'questionnaire.moveOn', defaultMessage: 'All done!' },
    questionnaireNextButton: { id: 'questionnaire.nextButton', defaultMessage: 'Next' },
    questionnaireListHeader: { id: 'questionnaire.listHeader', defaultMessage: 'Questionnaires' },
    questionnaireListHeaderOtherPending: {
        id: 'questionnaire.listHeader.otherPending',
        defaultMessage: 'Other pending questionnaires'
    },
    questionnaireLetsStart: { id: 'questionnaire.letsStart', defaultMessage: "Let's start" },
    questionnaireOutroSave: { id: 'questionnaire.outroSave', defaultMessage: 'Save answers' },
    questionnaireTitleDone: { id: 'questionnaire.title.done', defaultMessage: 'Done!' },
    questionnairesNone: { id: 'questionnaire.none', defaultMessage: 'No questionnaires' },

    questionnaireResponsesSummary: { id: 'questionnaire.responses.summary', defaultMessage: 'Summary' },
    questionnaireResponseSummaryQuestionTitle: {
        id: 'questionnaire.responses.summary.question.title',
        defaultMessage: 'Question'
    },
    questionnaireResponseSummaryResponseTitle: {
        id: 'questionnaire.responses.summary.response.title',
        defaultMessage: 'Response'
    },

    questionnaireBatchHeader: {
        id: 'questionnaireBatch.header',
        defaultMessage: 'Questionnaires {date, date, ::dd.MM.yyyy}'
    },
    questionnaireBatchClosedAt: {
        id: 'questionnaireBatch.closedAt',
        defaultMessage: 'Response time closed at {closedAt, date, ::dd.MM.yyyy}.'
    },
    questionnaireBatchOpenUntil: {
        id: 'questionnaireBatch.openUntil',
        defaultMessage: 'Response time until {closesAt, date, ::dd.MM.yyyy}, please answer before that!'
    },

    referralError: { id: 'referral.error', defaultMessage: 'The token is not valid. Please check it and try again!' },
    referralFormOpen: { id: 'referral.open', defaultMessage: 'Open referral' },
    referralFormLastName: { id: 'referral.lastName', defaultMessage: 'Last name' },
    referralFormSecurityCode: { id: 'referral.securityCode', defaultMessage: 'Security code' },
    referralDownloadButton: { id: 'referral.downloadButton', defaultMessage: 'Download' },
    referralClearForm: { id: 'referral.clearForm', defaultMessage: 'Download another' },
    referralTokenValid: { id: 'referral.tokenValid', defaultMessage: 'Token valid' },
    referralCustomerServiceInstructionsTitle: {
        id: 'referral.customerServiceInstructionsTitle',
        defaultMessage: 'Instructions to customer service'
    },
    referralCustomerServiceInstructions: {
        id: 'referral.customerServiceInstructions',
        defaultMessage: "Enter member's last name and security code to the form on the left and click Open referral."
    },
    referralMemberInstructionsTitle: {
        id: 'referral.memberInstructionsTitle',
        defaultMessage: 'Instructions for member'
    },
    referralMemberInstructions: {
        id: 'referral.memberInstructions',
        defaultMessage:
            "Member can find their security code in MyHeltti documents. If they don't have the code, they can generate it from there."
    },
    referralTitle: { id: 'referral.title', defaultMessage: 'Download referral' },

    forgotPasswordTitle: { id: 'forgotPassword.title', defaultMessage: 'I forgot my password' },
    forgotPasswordEmail: { id: 'forgotPassword.email', defaultMessage: 'My email address associated with Heltti' },
    forgotPasswordMigrationTitle: {
        id: 'forgotPassword.migrationTitle',
        defaultMessage: 'Create a password for MyHeltti'
    },
    forgotPasswordSend: { id: 'forgotPassword.send', defaultMessage: 'Send reset email and SMS' },
    forgotPasswordEmailSent: {
        id: 'forgotPassword.emailSent',
        defaultMessage: 'We have sent you a reset password email. Please check your mail'
    },
    forgotPasswordEmailSentHelp: {
        id: 'forgotPassword.didNotReceiveEmail',
        defaultMessage: 'If you do not receive the email and SMS, please contact us at'
    },
    forgotPasswordLink: { id: 'forgotPassword.link', defaultMessage: 'I have forgotten my password' },
    forgotPasswordUseStrongIdentification: {
        id: 'forgotPassword.useStrongIdentification',
        defaultMessage: 'Please use strong identification'
    },
    resetPasswordTitle: { id: 'resetPassword.title', defaultMessage: 'Set a new password' },
    resetPasswordSmsToken: { id: 'resetPassword.smsToken', defaultMessage: 'Verification token from SMS' },
    resetPasswordCurrentPassword: { id: 'resetPassword.currentPassword', defaultMessage: 'Current password' },
    resetPasswordInput: { id: 'resetPassword.input', defaultMessage: 'New password' },
    resetPasswordVerify: { id: 'resetPassword.verify', defaultMessage: 'New password again' },
    resetPasswordSet: { id: 'resetPassword.set', defaultMessage: 'Change password' },
    resetPasswordFailure: {
        id: 'resetPassword.failure',
        defaultMessage: 'Password reset failed. Please try again via forgot password form.'
    },
    resetPasswordRequirements: {
        id: 'resetPassword.requirements',
        defaultMessage:
            'Password must be at least 10 characters. It should include an uppercase letter, a lowercase letter, and a number.'
    },
    resetPasswordRequirementsLowercase: {
        id: 'resetPassword.requirements.lowercase',
        defaultMessage: 'a lowercase letter'
    },
    resetPasswordRequirementsUppercase: {
        id: 'resetPassword.requirements.uppercase',
        defaultMessage: 'an uppercase letter'
    },
    resetPasswordRequirementsNumber: { id: 'resetPassword.requirements.number', defaultMessage: 'a number' },
    resetPasswordRequirementsLength: { id: 'resetPassword.requirements.length', defaultMessage: '10 characters long' },
    resetPasswordRequirementsMatch: {
        id: 'resetPassword.requirements.match',
        defaultMessage: 'and verification matches'
    },

    gdprFormReadAndAcceptTerms: {
        id: 'gdpr.form.readAndAcceptTerms',
        defaultMessage: 'I have read and accept the terms of use'
    },
    gdprFormReadFullTerms: {
        id: 'gdpr.form.readFullTerms',
        defaultMessage: 'Read the full data protection description'
    },
    gdprFormReadFullTermsOfUse: { id: 'gdpr.form.readFullTermsOfUse', defaultMessage: 'Read the full terms of use' },
    gdprFormReadMarketingTerms: { id: 'gdpr.form.readMarketingTerms', defaultMessage: 'Read the marketing terms' },
    gdprFormRead: { id: 'gdpr.form.readMarketingTerms', defaultMessage: 'Read the marketing terms' },
    gdprFormSave: { id: 'gdpr.form.save', defaultMessage: 'Save and continue' },
    gdprFormTitle: { id: 'gdpr.form.title', defaultMessage: 'Terms of use' },
    gdprFormIntro: { id: 'gdpr.form.intro', defaultMessage: 'Intro' },
    gdprFormBulletsWhatTitle: { id: 'gdpr.form.bullets.what.title', defaultMessage: 'Title' },
    gdprFormBulletsWhatDescription: { id: 'gdpr.form.bullets.what.description', defaultMessage: 'Description' },
    gdprFormBulletsHowTitle: { id: 'gdpr.form.bullets.how.title', defaultMessage: 'Title' },
    gdprFormBulletsHowDescription: { id: 'gdpr.form.bullets.how.description', defaultMessage: 'Description' },
    gdprFormBulletsWhyTitle: { id: 'gdpr.form.bullets.why.title', defaultMessage: 'Title' },
    gdprFormBulletsWhyDescription: { id: 'gdpr.form.bullets.why.description', defaultMessage: 'Description' },

    npsCompanyDetail1Title: {
        id: 'nps.companyDetail1Title',
        defaultMessage: 'How valuable did you find our latest meeting?'
    },
    npsMindyDetail1Title: {
        id: 'nps.mindyDetail1Title',
        defaultMessage: 'How useful were the Mindy-coaching sessions?'
    },
    npsCompanyDetail2Title: {
        id: 'nps.companyDetail2Title',
        defaultMessage: 'How satisfied are you with our cooperation overall?'
    },
    npsFormTitle: { id: 'nps.formTitle', defaultMessage: 'How did it go?' },
    npsTitle: {
        id: 'nps.title',
        defaultMessage: 'How likely would you recommend Heltti to your friends or colleagues?'
    },
    npsTitleMindy: {
        id: 'nps.titleMindy',
        defaultMessage: 'How likely would you recommend Mindy to your friends or colleagues?'
    },
    npsMemberDetail1Title: { id: 'nps.memberDetail1Title', defaultMessage: 'Do you feel like you were heard?' },
    npsMemberDetail2Title: { id: 'nps.memberDetail2Title', defaultMessage: 'Did you get help?' },
    npsNotAtAll: { id: 'nps.notAtAll', defaultMessage: '1 - Not at all' },
    npsNotLikely: { id: 'nps.notLikely', defaultMessage: '0 - Not likely' },
    npsVeryLikely: { id: 'nps.veryLikely', defaultMessage: 'Very likely - 10' },
    npsVeryWell: { id: 'nps.veryWell', defaultMessage: 'Very well - 5' },
    npsComment: { id: 'nps.comment', defaultMessage: 'Please specify why' },
    npsCommentPlaceholder: { id: 'nps.commentPlaceholder', defaultMessage: 'Write here...' },
    npsContactPermission: { id: 'nps.contactPermission', defaultMessage: 'I can be contacted regarding this feedback' },
    npsSave: { id: 'nps.save', defaultMessage: 'Send' },
    npsThankYou: { id: 'nps.thankYou', defaultMessage: 'Thank you!' },
    npsVerySatisfied: { id: 'nps.verySatisfied', defaultMessage: 'Very satisfied' },
    npsVeryValuable: { id: 'nps.veryValuable', defaultMessage: 'Very valuable' },

    notVerifiedIndexTitle: { id: 'notVerified.index.title', defaultMessage: 'Verify your identity' },
    notVerifiedIndexDescription: {
        id: 'notVerified.index.description',
        defaultMessage: 'Please verify yourself so you can continue using MyHeltti'
    },

    selfHelpCourseSubscribe: { id: 'selfHelpCourse.subscribe', defaultMessage: 'Subscribe' },
    selfHelpCourseSubscribeConfirm: {
        id: 'selfHelpCourse.subscribe.confirm',
        defaultMessage: 'You are about to subscribe to: '
    },
    selfHelpCourseSubscribeConfirmed: {
        id: 'selfHelpCourse.subscribe.confirmed',
        defaultMessage: 'You have been subscribed.'
    },
    selfHelpCourseSubscribeSuccess: { id: 'selfHelpCourse.subscribe.success', defaultMessage: 'Success!' },
    selfHelpCourseSubscribed: { id: 'selfHelpCourse.subscribed', defaultMessage: 'You have been subscribed.' },
    supportFAQPageHeader: { id: 'support.FAQPageHeader', defaultMessage: 'Frequent asked questions' },
    supportHeader: { id: 'support.header', defaultMessage: 'Support and feedback' },
    supportHeaderDescription: {
        id: 'support.headerDescription',
        defaultMessage: 'Here you can send us feedback, get support and check frequent asked questions'
    },
    supportCallToActionHeader: { id: 'support.FAQCallToActionHeader', defaultMessage: 'Frequent asked questions' },
    supportCallToActionDescription: {
        id: 'support.FAQCallToActionDescription',
        defaultMessage: 'Check here if your question is already answered!'
    },

    start: {
        id: 'start',
        defaultMessage: 'Start'
    },

    signUpFormTitle: { id: 'signUpForm.title', defaultMessage: 'Verify that your details are correct' },
    signUpErrorTitle: {
        id: 'signUp.error.title',
        defaultMessage: 'Woops, something went wrong when verifying your identity'
    },
    signUpErrorDescription: {
        id: 'signUp.error.description',
        defaultMessage: 'Something went wrong while verifying your identity. You can try again or contact our support.'
    },
    signUpErrorContactSupport: { id: 'signUp.error.contactSupport', defaultMessage: 'Contact us' },
    remoteLocation: { id: 'remoteLocation', defaultMessage: 'Online / Remote' },
    validatorsRequired: { id: 'validators.required', defaultMessage: 'Required field' }
});

export default {
    emailSignUp,
    emailChange,
    emailVerification,
    hello,
    profile,
    questionnairesByToken,
    questionnaireTokenInvalid,
    ...unsortedMessages
};

import React, { Component } from 'react';
import { Button, Form, Modal, TextArea } from 'semantic-ui-react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { reportSickLeaveMutation } from '../../data/mutations';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose, range } from 'lodash';
import moment, { Moment } from 'moment';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';

import { Error } from '@heltti/components';

import t from '../../translations';
import { Translation } from '../../components/Message';

type Props = OwnProps & WrappedComponentProps;

interface OwnProps {
    minimumDays: number;
    maximumDays: number;
    onReportSickLeave: Function;
    onDismissSuccessModal: Function;
}

interface State {
    startDate: Moment;
    numberOfDays: number;
    description: string;
    isLoading: boolean;
    isSuccess: boolean;
    hasError: boolean;
    focused: boolean;
}

const DaySelector = ({ minimumDays, maximumDays, selectedValue, handleClick }) => {
    const days = range(minimumDays, maximumDays + 1);

    return (
        <Button.Group size="huge">
            {days.map(value => (
                <Button positive={value === selectedValue} key={value} basic onClick={handleClick(value)}>
                    {value}
                </Button>
            ))}
        </Button.Group>
    );
};

class SickLeaveForm extends Component<Props, State> {
    public state: State = {
        startDate: moment(),
        numberOfDays: 1,
        description: '',
        isLoading: false,
        isSuccess: false,
        hasError: false,
        focused: false
    };

    private handleDescriptionChange = (e: any, { value }: any) => {
        this.setState({ description: value });
    };

    private handleStartDateChange = (date: any) => {
        this.setState({ startDate: date });
    };

    private handleDayChange = (numberOfDays: number) => {
        return (e: any) => {
            e.preventDefault();

            this.setState({ numberOfDays });
        };
    };

    private handleFocus = ({ focused }: any) => {
        this.setState({ focused });
    };

    private handleSave = () => {
        this.setState({ isLoading: true }, () => {
            this.props
                .onReportSickLeave(this.state.startDate, this.state.numberOfDays, this.state.description)
                .then(response => this.setState({ isLoading: false, isSuccess: true }))
                .catch(e => this.setState({ isLoading: false, hasError: true }));
        });
    };

    private handleModalDismiss = () => {
        this.setState({ hasError: false });
    };

    private isDayBlocked = () => false;

    public render() {
        const { intl, minimumDays, maximumDays, onDismissSuccessModal } = this.props;

        return (
            <div>
                <Form onSubmit={this.handleSave} loading={this.state.isLoading}>
                    <Form.Field error={!this.state.startDate}>
                        <label>{intl.formatMessage(t.sickLeaveFormStartDay)}</label>

                        <SingleDatePicker
                            date={this.state.startDate}
                            onDateChange={this.handleStartDateChange}
                            focused={this.state.focused}
                            onFocusChange={this.handleFocus}
                            noBorder
                            hideKeyboardShortcutsPanel
                            isOutsideRange={this.isDayBlocked}
                            numberOfMonths={1}
                            placeholder=""
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>{intl.formatMessage(t.sickLeaveFormDays)}</label>

                        <DaySelector
                            handleClick={this.handleDayChange}
                            maximumDays={maximumDays}
                            minimumDays={minimumDays}
                            selectedValue={this.state.numberOfDays}
                        />
                    </Form.Field>

                    <Form.Field>
                        <TextArea
                            value={this.state.description}
                            onChange={this.handleDescriptionChange}
                            placeholder={intl.formatMessage(t.sickLeaveFormDescription)}
                        />
                    </Form.Field>

                    <Button
                        fluid
                        primary
                        size="large"
                        disabled={
                            this.state.isSuccess ||
                            this.state.hasError ||
                            !(this.state.numberOfDays && this.state.startDate)
                        }
                    >
                        <Translation message={t.profile.save} />
                    </Button>
                </Form>

                {this.state.hasError && <Error onDismiss={this.handleModalDismiss} />}

                {this.state.isSuccess && (
                    <Modal defaultOpen dimmer="inverted" onClose={onDismissSuccessModal as any}>
                        <Modal.Header>{intl.formatMessage(t.sickLeaveSuccessTitle)}</Modal.Header>

                        <Modal.Content>
                            <Modal.Description>
                                <p>{intl.formatMessage(t.sickLeaveSuccessDescription)}</p>

                                <Button primary onClick={onDismissSuccessModal as any}>
                                    {intl.formatMessage(t.sickLeaveSuccessContinue)}
                                </Button>
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>
                )}
            </div>
        );
    }
}

export default compose(
    injectIntl,
    graphql(reportSickLeaveMutation, {
        props: props => ({
            onReportSickLeave: (startDate: Moment, numberOfDays: number, description?: string) => {
                return props.mutate({
                    variables: {
                        data: {
                            sickLeaveStart: startDate.format('YYYY-MM-DD'),
                            sickLeaveDays: numberOfDays,
                            sickLeaveReason: description
                        }
                    }
                });
            }
        })
    })
)(SickLeaveForm);

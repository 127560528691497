import React, { Component } from 'react';
import { QueryResult } from '@apollo/client';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import { Link } from 'react-router-dom';
import { WrappedComponentProps } from 'react-intl';

import { Breadcrumb, BreadcrumbDivider, BreadcrumbSection, Card, Divider, Header, Image } from 'semantic-ui-react';

import { Error, Loader } from '@heltti/components';

import { articleQuery } from '../../data/queries';
import { ArticleQuery } from '../../graphql-schema';
import { FormatMarkdown } from '../../components/FormatMarkdown';
import ArticleCard from './ArticleCard';
import { SCREEN_WIDTH_MAX_MOBILE } from '../../constants';
import { getNodes } from '@heltti/common';

type Props = WrappedComponentProps & OwnProps;

interface OwnProps {
    data: QueryResult & ArticleQuery;

    id?: string;
    tag?: string;

    hideBreadcrumb?: boolean;
    urlPath: string;
}

class Article extends Component<Props> {
    public render() {
        const { data, hideBreadcrumb, urlPath } = this.props;

        const article = data.root?.article;

        if (data.loading) {
            return <Loader />;
        } else if (!article) {
            return <Error />;
        }

        const { title, highlight, content, coverPhotoUrl, parentArticle, childArticles } = article;

        const children = getNodes(childArticles).filter(article => article?.title);

        const isMobile = window.innerWidth <= SCREEN_WIDTH_MAX_MOBILE;

        return (
            <div id="article">
                {!hideBreadcrumb && parentArticle && (
                    <Breadcrumb style={{ marginTop: '10px', marginBottom: '10px' }}>
                        {parentArticle.parentArticle && (
                            <BreadcrumbSection>
                                <Link to={`/${urlPath}/${parentArticle.parentArticle.id}`}>
                                    {parentArticle.parentArticle.title}
                                </Link>
                            </BreadcrumbSection>
                        )}

                        {parentArticle.parentArticle && <BreadcrumbDivider />}

                        <BreadcrumbSection>
                            <Link to={`/${urlPath}/${parentArticle.id}`}>{parentArticle.title}</Link>
                        </BreadcrumbSection>

                        <BreadcrumbDivider />

                        <BreadcrumbSection>{title}</BreadcrumbSection>
                    </Breadcrumb>
                )}

                <Card fluid>
                    {coverPhotoUrl && <Image src={coverPhotoUrl} />}

                    <Card.Content>
                        <Card.Header>
                            <Header as="h3">
                                {title}
                                {highlight && <Header.Subheader>{highlight}</Header.Subheader>}
                            </Header>
                        </Card.Header>

                        {content && (
                            <Card.Description>
                                <Divider hidden />

                                <FormatMarkdown source={content} />
                            </Card.Description>
                        )}
                    </Card.Content>
                </Card>

                <Card.Group className="subArticles" itemsPerRow={isMobile ? 1 : 3}>
                    {children.map(article => (
                        <ArticleCard
                            key={article.id}
                            photoUrl={article.coverPhotoUrl}
                            videoHandle={article.videoHandle}
                            title={article.title}
                            highlight={article.highlight}
                            linkTo={!article.videoHandle ? `/${urlPath}/${article.id}` : null}
                        />
                    ))}
                </Card.Group>
            </div>
        );
    }
}

export default compose(
    graphql(articleQuery, {
        options: (props: Props) => ({
            variables: {
                id: props.id,
                tag: props.tag
            }
        })
    })
)(Article);

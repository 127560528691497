import React from 'react';

import { Header, Image, List } from 'semantic-ui-react';

import { ChatFragment } from '../../graphql-schema';
import t from '../../translations';
import { getNodes } from '@heltti/common';
import { Translation } from '../../components/Message';

type Props = {
    chat: ChatFragment;
};

export const ChatMeta: React.FC<Props> = props => {
    const { chat } = props;

    const users = getNodes(chat.users)
        .filter(chatUser => chatUser.leftAt == null)
        .map(chatUser => chatUser.user);

    if (users.length === 0) {
        return null;
    }

    return (
        <div id="chatMeta">
            <Header as="h5">
                <Translation message={t.chatMetaUsers} />{' '}
            </Header>

            <List style={{ fontSize: '90%' }}>
                {users.map(user => (
                    <List.Item key={user.id}>
                        <Image avatar src={user.avatarSmallUrl} />

                        <List.Content>
                            <List.Header>
                                {user.firstName} {user.lastName}
                            </List.Header>

                            <List.Description>{user.title}</List.Description>
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        </div>
    );
};

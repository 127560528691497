import React, { Component } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';
import { mapBy, mapMultipleBy } from '@heltti/common';

import { Accordion, Button, Divider, Grid, Header, Table } from 'semantic-ui-react';

import Section from '../../components/Section';
import {
    QuestionnaireFragment,
    QuestionnaireQuestionType,
    QuestionnaireQuestionCategoryFragment,
    QuestionnaireResponseFragment,
    QuestionnaireType
} from '../../graphql-schema';
import { QuestionnaireList } from './QuestionnaireList';
import { FormatMarkdown } from '../../components/FormatMarkdown';
import t from '../../translations';
import QuestionnaireSummaryBars, { iconForCategoryIconName } from './QuestionnaireSummaryBars';

import './QuestionnaireEndCredits.less';

type Category = QuestionnaireQuestionCategoryFragment;

const questionCategoryKeyOrDefault = item =>
    item.questionnaireQuestion.category ? item.questionnaireQuestion.category.key : 'default';
const questionCategoryOrDefault = item => item.questionnaireQuestion.category || { key: 'default', orderNumber: 999 };

const sortByOrderNumber = (a: Category, b: Category) => {
    return a.orderNumber - b.orderNumber;
};

const isEditable = (questionnaireResponse: QuestionnaireResponseFragment) => {
    const finishedWithinWeek = moment().subtract(7, 'days').isBefore(questionnaireResponse.finishedAt);

    return !questionnaireResponse.analysedAt && finishedWithinWeek;
};

type Props = OwnProps & WrappedComponentProps & RouteComponentProps<any>;

interface OwnProps {
    goBack(): void;
    questionnaire: QuestionnaireFragment;
    questionnaireResponse: QuestionnaireResponseFragment;

    usingTokenAuth: boolean;
    returnPath?: string;
}

class QuestionnaireEndCredits extends Component<Props> {
    public render() {
        const { questionnaireResponse, questionnaire, goBack, intl, usingTokenAuth, history, returnPath } = this.props;

        const summaryCategoriesByKey = mapBy(questionnaireResponse?.summary?.categories || [], item => item?.key);

        const questionResponses = questionnaireResponse.responses.edges.map(edge => edge?.node);

        const sortedCategories = Array.from(
            mapBy(questionResponses, questionCategoryKeyOrDefault, questionCategoryOrDefault, true).values()
        ).sort(sortByOrderNumber);

        const questionResponsesByCategory = mapMultipleBy(questionResponses, questionCategoryKeyOrDefault);

        const questionnaireEditable = isEditable(questionnaireResponse);

        const analysedCapacityQuestionnaire =
            !!questionnaireResponse.analysedAt && questionnaire.type == QuestionnaireType.Capacity;

        const responsesPanel = {
            key: 'responses',
            title: {
                content: (
                    <span className="responseSummaryHeader">{intl.formatMessage(t.questionnaireResponsesSummary)}</span>
                )
            },
            content: {
                content: (
                    <Grid.Column>
                        {!!sortedCategories &&
                            sortedCategories.map(category => {
                                const categoryInfo = summaryCategoriesByKey.get(category.key) || {
                                    title: '',
                                    icon: '',
                                    color: ''
                                };
                                const categoryResponses = questionResponsesByCategory.get(category.key);

                                return (
                                    <div key={category.key} style={{ marginTop: '30px' }}>
                                        <Header style={{ color: categoryInfo.color || null }}>
                                            {categoryInfo.icon && (
                                                <img src={iconForCategoryIconName(categoryInfo.icon)} />
                                            )}
                                            {categoryInfo.title}
                                        </Header>

                                        <Table celled padded style={{ fontSize: '80%' }}>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell width={10}>
                                                        {intl.formatMessage(
                                                            t.questionnaireResponseSummaryQuestionTitle
                                                        )}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell width={6}>
                                                        {intl.formatMessage(
                                                            t.questionnaireResponseSummaryResponseTitle
                                                        )}
                                                    </Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {!!categoryResponses &&
                                                    categoryResponses.map(response => {
                                                        const findOption = responseValue =>
                                                            response.questionnaireQuestion.options.find(
                                                                option => option.value === responseValue
                                                            );
                                                        const responseOptions =
                                                            response?.questionnaireQuestion.type !==
                                                            QuestionnaireQuestionType.Slider
                                                                ? response?.listValue
                                                                      ?.map(findOption)
                                                                      .filter(option => !!option)
                                                                : [];

                                                        const sortedOptionsWithWeights =
                                                            response.questionnaireQuestion.options
                                                                .filter(option => option.weight !== null)
                                                                .sort((a, b) => a.weight - b.weight);

                                                        const worstOption = sortedOptionsWithWeights[0];

                                                        const responseIsLowestWeight =
                                                            !!responseOptions && worstOption
                                                                ? responseOptions.map(
                                                                      item => item.weight === worstOption.weight
                                                                  )
                                                                : [];

                                                        return (
                                                            <Table.Row
                                                                key={response.questionnaireQuestion.id}
                                                                className={
                                                                    responseIsLowestWeight.includes(true)
                                                                        ? 'showWarning'
                                                                        : ''
                                                                }
                                                            >
                                                                <Table.Cell>
                                                                    <strong>
                                                                        {response.questionnaireQuestion.title}
                                                                    </strong>
                                                                    <br />
                                                                    <i>{response.questionnaireQuestion.description}</i>
                                                                </Table.Cell>

                                                                <Table.Cell>
                                                                    {!!responseOptions &&
                                                                        responseOptions.map((responseOption, index) => (
                                                                            <p key={index}>{responseOption.title}</p>
                                                                        ))}
                                                                    {!responseOptions &&
                                                                        !!response &&
                                                                        response.strValue}
                                                                    {response?.questionnaireQuestion.type ===
                                                                        QuestionnaireQuestionType.Slider &&
                                                                        response.listValue[0]}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        );
                                                    })}
                                            </Table.Body>
                                        </Table>
                                    </div>
                                );
                            })}
                    </Grid.Column>
                )
            }
        };

        return (
            <Section width="medium" padded>
                <Grid>
                    {!!questionnaireResponse.closedAt && (
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                {intl.formatMessage(t.questionnaireClosed)}{' '}
                                {moment(questionnaireResponse.closedAt).format('LL')}.
                            </Grid.Column>
                        </Grid.Row>
                    )}

                    {analysedCapacityQuestionnaire && !questionnaireResponse.closedAt && (
                        <Grid.Row columns={1}>
                            <Grid.Column>{intl.formatMessage(t.questionnaireAnalysed)}</Grid.Column>
                        </Grid.Row>
                    )}

                    {!analysedCapacityQuestionnaire && questionnaire.outro && (
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <FormatMarkdown source={questionnaire.outro} />
                            </Grid.Column>
                        </Grid.Row>
                    )}

                    {!!questionnaireResponse.summary && (
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <QuestionnaireSummaryBars questionnaireResponse={questionnaireResponse} />

                                {questionnaireResponse.summary.description && (
                                    <FormatMarkdown source={questionnaireResponse.summary.description} />
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    )}

                    <Grid.Row>
                        <Accordion fluid panels={[responsesPanel]} />
                    </Grid.Row>

                    <Grid.Row columns={questionnaireEditable ? 2 : 1}>
                        {questionnaireEditable && (
                            <Grid.Column>
                                <Button basic fluid onClick={goBack as any}>
                                    {intl.formatMessage(t.questionnairePreviousButton)}
                                </Button>
                            </Grid.Column>
                        )}

                        <Grid.Column>
                            <Button
                                primary
                                fluid
                                onClick={() => {
                                    if (returnPath) {
                                        history.push(returnPath);
                                    } else {
                                        history.push('/paths/questionnaires');
                                    }
                                }}
                            >
                                {intl.formatMessage(t.questionnaireMoveOnButton)}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>

                    {!usingTokenAuth && (
                        <Grid.Row columns={1} textAlign="center">
                            <Grid.Column>
                                <Divider hidden />

                                <QuestionnaireList
                                    hideFinished
                                    hideIfNoItems
                                    title={intl.formatMessage(t.questionnaireListHeaderOtherPending)}
                                />

                                <Divider hidden />
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>
            </Section>
        );
    }
}

export default withRouter(injectIntl(QuestionnaireEndCredits));

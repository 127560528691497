import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { IntlProvider as BaseIntlProvider } from 'react-intl';
import moment from 'moment';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/fi';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/fi';
import 'moment/locale/fi';

import { Loader } from '@heltti/components';

import { initLanguage } from '../ducks/profile';
import { RootState } from '../ducks';

const messages = {
    fi: require('./../translations/fi.json'),
    en: require('./../translations/en.json')
};

interface Props {
    language: string;
    initLanguage: () => void;
}

class IntlProvider extends PureComponent<Props> {
    public componentDidMount() {
        this.props.initLanguage();
    }

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.language !== this.props.language) {
            moment.locale(this.props.language);
        }
    }

    public render() {
        const { language, children } = this.props;

        if (!language) {
            return <Loader />;
        }

        return (
            <BaseIntlProvider key={language} locale={language} messages={messages[language]}>
                {children}
            </BaseIntlProvider>
        );
    }
}

export default connect(
    (state: RootState) => ({
        language: state.profile.language
    }),
    {
        initLanguage
    }
)(IntlProvider);

import { FormattedMessage } from 'react-intl';
import translations from '../../translations';
import React from 'react';
import { useDeviceType } from '@heltti/components';
import { shortenStringAddingEllipsis } from '@heltti/common';

export enum QuestionnaireDescriptionEllipsisThreshold {
    SHORT = 200,
    LONG = 400
}

type DescriptionWithEllipsisProps = {
    description: string;
    onShowMoreClicked();
};

export function DescriptionWithEllipsis(props: DescriptionWithEllipsisProps) {
    const { description, onShowMoreClicked } = props;

    const { mobile, tablet } = useDeviceType();

    const ellipsisThreshold = mobile
        ? QuestionnaireDescriptionEllipsisThreshold.SHORT
        : QuestionnaireDescriptionEllipsisThreshold.LONG;

    const needsToBeEllipsed = description.length > ellipsisThreshold;

    const shortenedDescription = needsToBeEllipsed
        ? shortenStringAddingEllipsis(description, ellipsisThreshold)
        : description;

    return (
        <aside>
            {shortenedDescription}{' '}
            {needsToBeEllipsed && (
                <a onClick={onShowMoreClicked}>
                    <FormattedMessage id={translations.questionnaireDescriptionShowMore.id} />
                </a>
            )}
        </aside>
    );
}

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { QueryResult } from '@apollo/client';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { Error, Loader } from '@heltti/components';

import SelfHelpCourseSubscribeForm from './SelfHelpCourseSubscribeForm';
import Card from '../../components/Card';
import PageHeader from '../../components/PageHeader';
import { Content } from '../../components/Content';
import { selfHelpCourseQuery } from '../../data/queries';
import { SelfHelpCourseQuery } from '../../graphql-schema';

type Props = OwnProps & RouteComponentProps<any> & WrappedComponentProps;

interface OwnProps {
    data: QueryResult & SelfHelpCourseQuery;
}

// TODO: This Component is deprecated and should be removed after all Article SelfHelp subscribe CTAs have been
//       migrated to use Steroids
class SelfHelpCourse extends Component<Props> {
    private handleSuccessDismiss = () => {
        this.props.history.push('/paths/');
    };

    public render() {
        const { loading, error } = this.props.data;

        const selfHelpCourse = this.props.data.root?.selfHelpCourse;

        if (loading) {
            return <Loader />;
        } else if (error || !selfHelpCourse) {
            return <Error />;
        }

        return (
            <div>
                <PageHeader title={selfHelpCourse.title ?? ''} />

                <Content>
                    <Card>
                        <SelfHelpCourseSubscribeForm
                            title={selfHelpCourse.title}
                            courseId={this.props.match.params.id}
                            onDismissSuccessModal={this.handleSuccessDismiss}
                        />
                    </Card>
                </Content>
            </div>
        );
    }
}

export default compose(
    injectIntl,
    graphql(selfHelpCourseQuery, {
        options: (props: Props) => ({
            variables: {
                id: props.match.params.id
            }
        })
    })
)(SelfHelpCourse);

import React, { useState } from 'react';
import { IntlShape, useIntl, WrappedComponentProps } from 'react-intl';
import { ApolloError } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Button, Divider, DropdownProps, Form, Grid, Header, Icon, Popup } from 'semantic-ui-react';

import { HintText } from '@heltti/components';

import { languages } from '../ducks/profile';
import t from '../translations';
import { apolloClient } from '../';
import { Translation } from './Message';
import { LanguageCode, ProfileFragment } from '../graphql-schema';
import { profileEditMutation } from '../data/mutations';
import VerifyIdentityButton from './VerifyIdentityButton';
import PasswordIndicator from './PasswordIndicator';

export type LanguageOption = {
    key: number;
    value: LanguageCode;
    text: string;
};

export const languageOptions: LanguageOption[] = Object.values(languages).map((key, value) => {
    return {
        key: value,
        value: key['type'],
        text: key['name']
    };
});

type Props = {
    intl: IntlShape;
    initialValues: ProfileFragment;
    hideNameChangeHintPopUp?: boolean;
    onSuccess?: () => void;
    allowInlinePasswordEditing: boolean;
    allowFeedbackPermissionEditing: boolean;

    nonce?: string;
};

type FormData = {
    email: string;
    phone: string;
    address1: string;
    address2: string;
    zip: string;
    city: string;
    languageCode: LanguageCode;
    marketingPermission: boolean;
    memberCommunicationPermission: boolean;
    feedbackPermission: boolean;
    password: string;
    confirmPassword: string;
};

export const ProfileForm: React.FC<Props> = props => {
    const intl = useIntl();

    const {
        allowFeedbackPermissionEditing,
        allowInlinePasswordEditing,
        hideNameChangeHintPopUp = false,
        initialValues,
        nonce,
        onSuccess
    } = props;

    const [showPasswordFields, setShowPasswordFields] = useState(
        !initialValues.hasUsablePassword && nonce !== undefined
    );
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState<ApolloError | null>(null);

    const handleSave = (values: FormData) => {
        setLoading(true);
        const {
            languageCode,
            marketingPermission,
            feedbackPermission,
            memberCommunicationPermission,
            email,
            phone,
            address1,
            address2,
            city,
            zip,
            password
        } = values;

        const variables: any = {
            data: {
                languageCode,
                marketingPermission,
                feedbackPermission,
                memberCommunicationPermission,
                email,
                phone,
                address1,
                address2,
                city,
                zip
            }
        };

        if (password !== null) {
            variables.data.password = password;
            variables.data.nonce = props.nonce;
        }

        apolloClient
            .mutate({
                mutation: profileEditMutation,
                variables,
                update: cache => {
                    if (initialValues.language !== variables.language) {
                        cache.evict({ id: 'ROOT_QUERY', fieldName: 'root' });
                        cache.gc();
                    }
                }
            })
            .then(handleSuccess)
            .catch((error: ApolloError) => {
                setFormError(error);
                setLoading(false);
            });
    };

    const validationSchema = Yup.object().shape({
        address1: Yup.string().required(intl.formatMessage(t.validatorsRequired)),
        city: Yup.string().required(intl.formatMessage(t.validatorsRequired)),
        email: Yup.string().required(intl.formatMessage(t.validatorsRequired)),
        phone: Yup.string().required(intl.formatMessage(t.validatorsRequired)),
        zip: Yup.string().required(intl.formatMessage(t.validatorsRequired))
    });

    const {
        handleChange,
        handleSubmit,
        values: formValues,
        errors,
        isValid,
        setFieldValue
    } = useFormik<FormData>({
        initialValues: {
            languageCode: initialValues.lang ?? LanguageCode.Fi,
            phone: initialValues.phone || '',
            email: initialValues.email.includes('@') ? initialValues.email : '',
            address1: initialValues.address1 || '',
            address2: initialValues.address2 || '',
            zip: initialValues.zip || '',
            city: initialValues.city || '',
            marketingPermission: !!initialValues.marketingPermissionAt,
            memberCommunicationPermission: !!initialValues.memberCommunicationPermissionAt,
            feedbackPermission: !!initialValues.feedbackPermissionAt,

            password: '',
            confirmPassword: ''
        },
        onSubmit: handleSave,
        validationSchema
    });

    const handleLanguageSelect = (_, data: DropdownProps) => {
        setFieldValue('languageCode', data.value);
    };

    const handleSuccess = () => {
        setLoading(false);

        if (onSuccess) {
            onSuccess();
        }
    };

    const onChangePasswordClicked = () => {
        setShowPasswordFields(true);
    };

    const renderCredentialFields = () => {
        const { email: originalEmail } = initialValues;
        const { email, password, confirmPassword } = formValues;

        const usernameTakenError = formError && formError.message.includes('Duplicate entry');
        const emailError = formError && formError.message.includes('Email verification');

        return (
            <React.Fragment>
                <Form.Field>
                    <Form.Input
                        name="email"
                        error={
                            (!errors.email && errors.email) ||
                            (usernameTakenError && <Translation message={t.profile.usernameTakenError} />) ||
                            (emailError && <Translation message={t.emailSignUp.errorGeneric} />)
                        }
                        value={email}
                        onChange={handleChange}
                        label={intl.formatMessage(t.profile.email)}
                    />
                </Form.Field>

                {showPasswordFields ? (
                    <PasswordFields
                        intl={intl}
                        handleChange={handleChange}
                        password={password}
                        confirmPassword={confirmPassword}
                    />
                ) : (
                    <p style={{ marginTop: '20px' }}>
                        {allowInlinePasswordEditing ? (
                            <ResetPasswordInlineLink onChangePasswordClicked={onChangePasswordClicked} />
                        ) : (
                            <ResetPasswordFormLink email={email} />
                        )}
                    </p>
                )}
            </React.Fragment>
        );
    };

    const renderContactDetailFields = () => {
        const { phone, address1, address2, city, zip, languageCode: languageCode } = formValues;
        return (
            <React.Fragment>
                {!hideNameChangeHintPopUp && (
                    <div style={{ float: 'right' }}>
                        <NameChangeHintPopUp />
                    </div>
                )}

                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input
                            disabled={true}
                            name="firstName"
                            value={initialValues.firstName}
                            label={intl.formatMessage(t.profile.firstName)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            disabled={true}
                            name="lastName"
                            value={initialValues.lastName}
                            label={intl.formatMessage(t.profile.lastName)}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Field>
                    <Form.Input
                        name="phone"
                        value={phone}
                        onChange={handleChange}
                        error={errors.phone}
                        label={intl.formatMessage(t.profile.phone)}
                    />
                </Form.Field>

                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input
                            name="address1"
                            value={address1}
                            onChange={handleChange}
                            label={intl.formatMessage(t.profile.address1)}
                            error={errors.address1}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            name="address2"
                            value={address2}
                            onChange={handleChange}
                            label={intl.formatMessage(t.profile.address2)}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input
                            name="city"
                            value={city}
                            onChange={handleChange}
                            error={errors.city}
                            label={intl.formatMessage(t.profile.city)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            name="zip"
                            value={zip}
                            onChange={handleChange}
                            error={errors.zip}
                            label={intl.formatMessage(t.profile.zip)}
                        />
                    </Form.Field>
                </Form.Group>

                <Divider hidden />

                <Form.Field>
                    <Form.Select
                        id="language"
                        name="language"
                        options={languageOptions}
                        onChange={handleLanguageSelect}
                        label={intl.formatMessage(t.profile.language)}
                        placeholder={intl.formatMessage(t.profile.language)}
                        value={languageCode}
                    />
                </Form.Field>
            </React.Fragment>
        );
    };

    const renderPermissionFields = () => {
        const { marketingPermission, memberCommunicationPermission, feedbackPermission } = formValues;
        return (
            <React.Fragment>
                <Form.Field>
                    <Form.Checkbox
                        id="memberCommunicationPermission"
                        name="memberCommunicationPermission"
                        onChange={handleChange}
                        label={intl.formatMessage(t.profilePermissionsMemberInformation)}
                        checked={memberCommunicationPermission}
                    />

                    <HintText text={intl.formatMessage(t.profilePermissionsMemberInformationHint)} />
                </Form.Field>

                <Form.Field>
                    <Form.Checkbox
                        id="marketingPermission"
                        name="marketingPermission"
                        onChange={handleChange}
                        label={intl.formatMessage(t.profilePermissionsMarketing)}
                        checked={marketingPermission}
                    />

                    <HintText text={intl.formatMessage(t.profilePermissionsMarketingHint)} />
                </Form.Field>

                {allowFeedbackPermissionEditing && (
                    <Form.Field>
                        <Form.Checkbox
                            id="feedbackPermission"
                            name="feedbackPermission"
                            onChange={handleChange}
                            label={intl.formatMessage(t.profilePermissionsFeedback)}
                            checked={feedbackPermission}
                        />

                        <HintText text={intl.formatMessage(t.profilePermissionsFeedbackHint)} />
                    </Form.Field>
                )}
            </React.Fragment>
        );
    };

    return (
        <div className="profile-form">
            <Form onSubmit={handleSubmit} loading={loading} error={!isValid}>
                <Header as="h5">
                    <Translation message={t.profile.loginCredentials} />
                </Header>

                {renderCredentialFields()}

                <Divider section hidden />

                <Header as="h5">
                    <Translation message={t.profile.contactDetails} />
                </Header>

                {renderContactDetailFields()}

                <Header as="h5">
                    <Translation message={t.profilePermissions} />
                </Header>

                {renderPermissionFields()}

                <Divider section hidden />

                <Button fluid primary disabled={loading}>
                    <Translation message={t.profile.save} />
                </Button>
            </Form>
        </div>
    );
};

const NameChangeHintPopUp = () => {
    return (
        <Popup trigger={<Icon name="question circle outline" />} hoverable>
            <Popup.Content>
                <Translation message={t.profile.formNameChangeHintPopUpContent} />
                <Divider hidden />
                <VerifyIdentityButton />
            </Popup.Content>
        </Popup>
    );
};

type PasswordFieldProps = {
    handleChange: any;
    password: string;
    confirmPassword: string;
} & WrappedComponentProps;

const PasswordFields = (props: PasswordFieldProps) => {
    const { intl, handleChange, password, confirmPassword } = props;

    return (
        <Grid stackable>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Form.Field>
                        <Form.Input
                            type="password"
                            name="password"
                            required
                            onChange={handleChange}
                            label={intl.formatMessage(t.profile.password)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            type="password"
                            name="confirmPassword"
                            required
                            onChange={handleChange}
                            label={intl.formatMessage(t.profile.passwordAgain)}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column>
                    <PasswordIndicator password={password} passwordAgain={confirmPassword} />

                    <Translation message={t.profile.passwordExplanation} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

type ResetPasswordInlineLinkProps = {
    onChangePasswordClicked: any;
};

const ResetPasswordInlineLink = (props: ResetPasswordInlineLinkProps) => {
    return (
        <Button basic color="red" onClick={props.onChangePasswordClicked}>
            <Translation message={t.resetPasswordSet} />
        </Button>
    );
};

type ResetPasswordFormLinkProps = {
    email: string;
};

const ResetPasswordFormLink = (props: ResetPasswordFormLinkProps) => {
    return (
        <Link to={`/reset-password/${props.email}/`} className="button-link">
            <Button basic color="red">
                <Translation message={t.resetPasswordSet} />
                <Icon name="arrow right" />
            </Button>
        </Link>
    );
};

import React from 'react';

import { Icon, Menu } from 'semantic-ui-react';
import { RegionFragment } from '../../graphql-schema';

type Props = {
    regions: readonly RegionFragment[];
    selectedRegion?: RegionFragment;
    onSelect: (region?: RegionFragment) => void;
};

export const ReserveAppointmentModalRegionMenu: React.FC<Props> = props => {
    const { regions, onSelect, selectedRegion } = props;

    if (regions.length === 0) {
        return null;
    }

    return (
        <Menu secondary compact fluid>
            <Menu.Item header icon>
                <Icon name="map marker alternate" />
            </Menu.Item>

            {regions.map(region => {
                return (
                    <Menu.Item
                        key={`region-${region.id}`}
                        active={selectedRegion?.id === region.id}
                        onClick={() => {
                            onSelect(region);
                        }}
                    >
                        <span>{region.name}</span>
                    </Menu.Item>
                );
            })}
        </Menu>
    );
};

import React, { Component } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Grid, Icon, Message } from 'semantic-ui-react';

import { Content } from '../../components/Content';
import t from './../../translations';

type Props = WrappedComponentProps & RouteComponentProps<any>;

class NotFound404 extends Component<Props> {
    public render() {
        const { intl } = this.props;

        return (
            <Content id="not-found" containerWidth="narrow">
                <Grid centered style={{ height: '100%' }}>
                    <Grid.Row columns={1} verticalAlign="middle">
                        <Grid.Column>
                            <Message icon warning>
                                <Icon name="umbrella" loading />

                                <Message.Header>{intl.formatMessage(t.errorNotFoundTitle)}</Message.Header>

                                <Message.Content>{intl.formatMessage(t.errorNotFoundDescription)}</Message.Content>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Content>
        );
    }
}

export default injectIntl(NotFound404);

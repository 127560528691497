import React from 'react';

import { Menu } from 'semantic-ui-react';

import { SCREEN_WIDTH_MAX_MOBILE } from '../constants';
import { Translation } from './Message';
import t from '../translations';

import { HelttiLogo } from './Logo';

export const VideoMeetingMenu: React.FC = () => {
    const isMobile = window.innerWidth <= SCREEN_WIDTH_MAX_MOBILE;

    return (
        <div id="menu">
            <Menu fluid borderless size={isMobile ? 'mini' : undefined}>
                <Menu.Item header>
                    <HelttiLogo />
                </Menu.Item>
                <Menu.Item>
                    <Translation message={t.appointmentTimeVideo} />
                </Menu.Item>
            </Menu>
        </div>
    );
};

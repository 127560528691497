import React, { FunctionComponent, useState } from 'react';
import { flowRight as compose } from 'lodash';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Button } from 'semantic-ui-react';

import { escalateToLiveChatMutation } from '../../../data/mutations';
import { chatsQuery } from '../../../data/queries';
import { ChatFragment } from '../../../graphql-schema';
import { apolloClient } from '../../../index';
import t from '../../../translations';

type Props = {
    chat: ChatFragment;
} & WrappedComponentProps;

const EscalateToLiveChat: FunctionComponent<Props> = ({ chat, intl }) => {
    const [loading, setLoading] = useState(false);
    const handleClick = () => {
        setLoading(true);

        apolloClient
            .mutate({
                mutation: escalateToLiveChatMutation,
                variables: { data: { chatId: chat.id } },
                refetchQueries: [
                    {
                        query: chatsQuery
                    }
                ]
            })
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                // TODO: Error handling
                setLoading(false);
            });
    };
    return (
        <Button primary loading={loading} onClick={handleClick}>
            {intl.formatMessage(t.chatEscalate)}
        </Button>
    );
};

export default compose(injectIntl)(EscalateToLiveChat);

import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { logout } from '../ducks/auth';

import t from './../translations';

type Props = OwnProps & WrappedComponentProps;

interface OwnProps {
    isButton: boolean;
    logout: Function;
}

class Logout extends Component<Props> {
    private logout = (e: any) => {
        e.preventDefault();

        this.props.logout();
    };

    public render() {
        const { isButton, intl } = this.props;

        if (isButton) {
            return (
                <Button fluid size="large" onClick={this.logout}>
                    {intl.formatMessage(t.logoutButton)}
                </Button>
            );
        }

        return (
            <a href="#" onClick={this.logout}>
                <div className="menuLabel">{intl.formatMessage(t.logoutButton)}</div>
            </a>
        );
    }
}

export default connect(state => ({}), {
    logout
})(injectIntl(Logout));

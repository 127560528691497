import { Image, Table } from 'semantic-ui-react';
import { FormattedTime as Time } from 'react-intl';
import { AppointmentContactType, AppointmentFragment } from '../../graphql-schema';
import React from 'react';
import { formatContactType } from '../../util/contactType';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import helttiLogo from '../../assets/heltti-icon.png';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import aavaLogo from '../../assets/aava-icon.png';

type ProviderAndLocationNameProps = {
    appointment: AppointmentFragment;
};

const ProviderAndLocationName: React.FC<ProviderAndLocationNameProps> = props => {
    const { appointment } = props;

    const provider = JSON.parse(appointment.meta)['source'];

    // TODO: Hard coded Aava as partner
    const providerIcon = provider === 'database' ? helttiLogo : aavaLogo;
    const providerName = provider === 'database' ? 'Heltti' : 'Aava';

    const location = appointment?.location ? appointment.location.name : providerName;

    return (
        <>
            <img
                src={providerIcon}
                alt="Appointment provider logo"
                style={{ width: '12px', height: '12px', marginRight: '2px', position: 'relative', top: '1px' }}
            />
            <span>{location}</span>
        </>
    );
};

type Props = {
    appointments: AppointmentFragment[];
    onSelect: (appointment: AppointmentFragment) => void;
};

export const ReserveAppointmentModalAppointmentList: React.FC<Props> = props => {
    const { appointments, onSelect } = props;

    // return a middle dot separated list of contact type names
    const formatContactTypes = (contactTypes: readonly AppointmentContactType[]): string => {
        return contactTypes
            .map(contactType => {
                return formatContactType(contactType);
            })
            .join(' \u00B7 ');
    };

    return (
        <Table selectable unstackable>
            <Table.Body>
                {appointments.map(appointment => {
                    const contactTypes = formatContactTypes(appointment.contactTypes);

                    return (
                        <Table.Row key={appointment.id} onClick={() => onSelect(appointment)}>
                            <Table.Cell width={2}>
                                <strong>
                                    <Time value={appointment.start} />
                                </strong>
                            </Table.Cell>
                            <Table.Cell width={2} style={{ color: 'grey' }}>
                                {appointment.duration} min
                            </Table.Cell>

                            <Table.Cell width={10}>
                                <strong>
                                    {appointment.user.firstName} {appointment.user.lastName}
                                </strong>
                                <br />
                                {appointment.user.title}
                                {contactTypes && (
                                    <>
                                        <br />
                                        {contactTypes}
                                    </>
                                )}
                                <br />
                                <ProviderAndLocationName appointment={appointment} />
                            </Table.Cell>

                            <Table.Cell width={2}>
                                <Image avatar size="mini" src={appointment.user.avatarSmallUrl} />
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};

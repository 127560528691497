import React from 'react';
import { Route } from 'react-router-dom';

import { HelttiMenu } from './HelttiMenu';
import { AuthState } from '../ducks/auth';
import { LandingMenu } from '../views/LandingPage/LandingMenu';
import { NotEmployeeMenu } from './NotEmployeeMenu';
import { VideoMeetingMenu } from './VideoMeetingMenu';

type Props = {
    memberAuthState: AuthState;
};

export const TopNavigationBar: React.FC<Props> = props => {
    const { isAuthenticated, isVerified, verificationRequired, isEmployee } = props.memberAuthState;

    let component: React.FC | React.ComponentClass = LandingMenu;

    if (window.location.pathname.startsWith('/meet/')) {
        component = VideoMeetingMenu;
    } else if (isAuthenticated && (isVerified || !verificationRequired) && isEmployee) {
        component = HelttiMenu;
    } else if (isAuthenticated && !isEmployee) {
        component = NotEmployeeMenu;
    }

    return <Route path="/:page?/:id?" component={component} />;
};

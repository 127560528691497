import { gql } from '@apollo/client';
import { badgesFragment, chatFragment, chatMessageFragment } from './fragments';

export const badgeSubscription = gql`
    subscription BadgeSubscription {
        badge {
            badges {
                ...BadgesFragment
            }
        }
    }

    ${badgesFragment}
`;

export const chatSubscription = gql`
    subscription ChatSubscription($chatId: ID!) {
        chat(chatId: $chatId) {
            chat {
                ...ChatFragment
            }
        }
    }

    ${chatFragment}
`;

export const chatMessagesSubscription = gql`
    subscription ChatMessagesSubscription($chatId: ID!) {
        chat(chatId: $chatId) {
            messageAdd {
                ...ChatMessageFragment
            }

            messageEdit {
                ...ChatMessageFragment
            }
        }
    }

    ${chatMessageFragment}
`;

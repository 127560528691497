import React from 'react';

import { Image } from 'semantic-ui-react';

import './Logo.less';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import helttiLogo from './../assets/images/heltti.svg';

export const HelttiLogo: React.FC = () => {
    return <Image src={helttiLogo} alt="Heltti" id="helttiLogo" />;
};

import React, { Component } from 'react';
import { AuthState } from '../ducks/auth';
import { Redirect, Route, RouteComponentProps, RouteProps, withRouter } from 'react-router';

import { paths } from '../constants';
import WithProfile from './WithProfile';

function RenderWithProfile(props) {
    const { component: Component } = props;

    return (
        <WithProfile>
            <Component {...props} />
        </WithProfile>
    );
}

type OwnProps = {
    memberAuthState: AuthState;
};

type Props = OwnProps & RouteProps & RouteComponentProps<any>;

class NotEmployeeRoute extends Component<Props> {
    public render() {
        const { memberAuthState, component, ...restProps } = this.props;

        if (!memberAuthState.isAuthenticated) {
            return <Redirect to={{ pathname: paths.welcome, state: { from: this.props.location } }} />;
        }

        if (!memberAuthState.isVerified && memberAuthState.verificationRequired) {
            return <Redirect to={{ pathname: paths.notVerifiedIndex, state: { from: this.props.location } }} />;
        }

        return <Route {...restProps} render={props => <RenderWithProfile {...props} component={component} />} />;
    }
}

export default withRouter(NotEmployeeRoute);

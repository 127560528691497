import React from 'react';
import '@whereby.com/browser-sdk';

// https://docs.whereby.com/embedding-rooms/in-a-web-page/using-the-whereby-embed-element
type WherebyEmbedProps = {
    room: string;
    displayName?: string; // Set displayname of participant

    background?: 'off';
    minimal?: 'off';
};

// Declare the MarkdownCTA in JSX IntrinsicElements to make FormatMarkdown components prop typecheck to pass
declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        // This merges with the existing intrinsic elements
        interface IntrinsicElements {
            'whereby-embed': WherebyEmbedProps;
        }
    }
}

type WherebyMeetingProps = {
    roomUrl: string;
    displayName?: string;
};

export const WherebyEmbed: React.FC<WherebyMeetingProps> = props => {
    return <whereby-embed room={props.roomUrl} displayName={props.displayName ?? ''} background="off" />;
};

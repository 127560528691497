import { useHistory } from 'react-router-dom';

import { openURL } from '../util/navigation';
import { InteractionAction } from '../util/types';

export const useInteraction = () => {
    const history = useHistory();

    const isAppDeepLink = (url: string): boolean => {
        // All app deep links have /open/-prefix
        return /\/open\//.test(url);
    };

    // transforms app urls to corresponding member web urls
    const transformAppUrl = (url: string): URL => {
        if (!isAppDeepLink(url)) {
            // links in development use heltti:// scheme, for other environments
            // domain is same as member and don't need transforming
            return new URL(url.replace('heltti://', ''), window.location.origin);
        }
        const parts = url.split('/');
        const resource = parts[parts.length - 2];
        const id = parts[parts.length - 1];

        if (resource == 'appointment-reservations') {
            return new URL(`/paths/appointments/${id}`, window.location.origin);
        }
        throw new Error('Unidentified resource in internal link ' + url);
    };

    const onAction = (interactionId: string, action: InteractionAction) => {
        const { handle, value } = action;
        if (handle === 'Open') {
            openURL(transformAppUrl(value!), history);
        } else {
            throw new Error(`Received unknown handle ${handle}`);
        }
    };

    return { onAction };
};

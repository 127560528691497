import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import Profile from './Profile';
import { Content } from '../../components/Content';
import { Footer } from '../../components/Footer';

type Props = RouteComponentProps<any> & WrappedComponentProps;

class ProfileLanding extends Component<Props> {
    public render() {
        return (
            <div id="profile">
                <Content>
                    <Profile />
                </Content>

                <Footer />
            </div>
        );
    }
}

export default injectIntl(ProfileLanding);

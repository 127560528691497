import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Card } from 'semantic-ui-react';

import { ListChatFragment } from '../graphql-schema';
import ListItem from './ListItem';
import { Avatar } from './Avatar';
import { getNodes } from '@heltti/common';

import './ListChat.less';

type Props = {
    chat: ListChatFragment;
};

export const ListChat: React.FC<Props> = props => {
    const history = useHistory();

    const { chat } = props;
    const chatUsers = getNodes(chat.users).filter(chatUser => chatUser.leftAt === null);

    const onClick = useCallback(() => {
        history.push(`/chats/${chat.id}`);
    }, [chat.id, history]);

    return (
        <Card fluid onClick={onClick}>
            <Card.Content>
                <ListItem
                    id={chat.id}
                    title={chat.title}
                    notification={(chat?.unreadMessagesCountV2 ?? 0) > 0 && chat.unreadMessagesCountV2}
                    date={chat.lastMessageAt ? chat.lastMessageAt : chat.modifiedAt}
                    dateFormat="LLL"
                    extra={
                        <div className="list-chat-users">
                            {chatUsers.map((chatUser, i) => (
                                <Avatar
                                    key={i}
                                    url={chatUser.user.avatarSmallUrl}
                                    title={`${chatUser.user.firstName} ${chatUser.user.lastName}`}
                                />
                            ))}
                        </div>
                    }
                />
            </Card.Content>
        </Card>
    );
};

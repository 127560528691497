import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Checkbox, DropdownItemProps, DropdownProps, Icon, Menu, Select } from 'semantic-ui-react';

import { LocationFragment, LocationType } from '../../graphql-schema';
import t from '../../translations';

type Props = {
    locations: readonly LocationFragment[];
    onLocationSelect: (locationId?: string) => void;
    onShowRemoteOnlyToggle: (remoteOnly: boolean) => void;
    selectedLocation?: LocationFragment;
    remoteAvailable: boolean;
    remoteOnly: boolean;
    showRemoteOnly: boolean;
};

export const ReserveAppointmentModalLocationSelect: React.FC<Props> = props => {
    const {
        locations,
        onLocationSelect,
        selectedLocation,
        onShowRemoteOnlyToggle,
        remoteAvailable,
        remoteOnly,
        showRemoteOnly
    } = props;
    const { formatMessage } = useIntl();

    const sortedLocations = useMemo(
        () =>
            Array.from(locations).sort((a, b) => {
                if (a.type === b.type) {
                    return a.name.localeCompare(b.name);
                }

                const aValue = a.type === LocationType.Heltti ? 1 : 2;
                const bValue = b.type === LocationType.Heltti ? 1 : 2;

                return aValue - bValue;
            }),
        [locations]
    );

    const options: DropdownItemProps[] = useMemo(() => {
        const optionsList: DropdownItemProps[] = sortedLocations.map(location => ({
            value: location.id,
            text: location.name
        }));
        optionsList.unshift({ value: false, text: formatMessage(t.appointmentAppointmentsAll) });

        return optionsList;
    }, [formatMessage, sortedLocations]);

    const onLocationChange = useCallback(
        (_, data: DropdownProps) => {
            if (data.value) {
                onLocationSelect(data.value as string);
            } else {
                onLocationSelect(undefined);
            }
        },
        [onLocationSelect]
    );

    return (
        <Menu secondary compact fluid>
            <Menu.Item header icon>
                <Icon name="map pin" />
            </Menu.Item>

            {!remoteOnly && (
                <Menu.Item style={{ paddingLeft: 2 }}>
                    <Select
                        options={options}
                        value={selectedLocation?.id ?? false}
                        onChange={onLocationChange}
                        disabled={showRemoteOnly}
                    />
                </Menu.Item>
            )}

            {remoteAvailable && (
                <Menu.Item style={{ paddingLeft: 2 }}>
                    <label>{formatMessage(t.appointmentFilterRemoteOnly)} &nbsp;</label>

                    <Checkbox
                        value={showRemoteOnly ? 1 : 0}
                        checked={remoteOnly}
                        onChange={() => onShowRemoteOnlyToggle(!remoteOnly)}
                    />
                </Menu.Item>
            )}
        </Menu>
    );
};

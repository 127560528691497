import React, { useState } from 'react';
import moment from 'moment';

import { Card, Header } from 'semantic-ui-react';
import { LoadingQuery, LoadingQueryResult } from '@heltti/components';

import {
    CalendarReservationAccessesQuery,
    CalendarReservationAccessFragment,
    GlobalCalendarReservationAccessesQuery
} from '../../graphql-schema';
import t from '../../translations';
import { Translation } from '../../components/Message';
import { calendarReservationAccessesQuery, globalCalendarReservationAccessesQuery } from '../../data/queries';
import { ReservationMode, ReserveAppointmentModal } from './ReserveAppointmentModal';

import { FormatMarkdown } from '../../components/FormatMarkdown';

type Props = {
    calendarReservationAccesses: CalendarReservationAccessFragment[];
    selectedCra?: string;
    showHeader?: boolean;
    className?: string;
};

const AvailableAppointmentsList: React.FC<Props> = ({
    calendarReservationAccesses,
    className,
    showHeader = true,
    selectedCra
}) => {
    const initiallySelectedCra = () => {
        if (selectedCra) {
            return calendarReservationAccesses.find(cra => cra.id === selectedCra) ?? null;
        }
        return null;
    };

    const [selectedReservationAccess, setSelectedReservationAccess] =
        useState<CalendarReservationAccessFragment | null>(initiallySelectedCra);

    const onRowClick = (reservationAccess: CalendarReservationAccessFragment) => {
        setSelectedReservationAccess(reservationAccess);
    };

    const dismissModal = () => {
        setSelectedReservationAccess(null);
    };

    if (calendarReservationAccesses.length === 0) {
        return null;
    }

    return (
        <div className={className} style={{ marginBottom: '40px' }}>
            {!!selectedReservationAccess && (
                <ReserveAppointmentModal
                    reservationAccess={selectedReservationAccess}
                    dismiss={dismissModal}
                    onConfirm={dismissModal}
                    mode={ReservationMode.RESERVE}
                />
            )}

            {showHeader && (
                <Header>
                    <Translation message={t.appointmentAvailable} />
                </Header>
            )}

            {calendarReservationAccesses.map(reservationAccess => {
                return (
                    <Card fluid key={reservationAccess.id} onClick={() => onRowClick(reservationAccess)}>
                        <Card.Content>
                            <Header size="small">
                                <Translation message={t.appointmentReserveAppointment} />
                            </Header>

                            {!!reservationAccess.description && (
                                <FormatMarkdown source={reservationAccess.description} />
                            )}
                        </Card.Content>
                    </Card>
                );
            })}
        </div>
    );
};

const oldestFirst = (a: CalendarReservationAccessFragment, b: CalendarReservationAccessFragment) => {
    return moment(b.createdAt).diff(a.createdAt);
};

export type AvailableAppointmentsProps = {
    selectedCra?: string;
};

export const AvailablePersonalAppointments: React.FC<AvailableAppointmentsProps> = props => {
    const { selectedCra } = props;

    const renderWithResult = (result: LoadingQueryResult<CalendarReservationAccessesQuery>) => {
        const calendarReservationAccesses = [...(result.data?.root?.calendarReservationAccesses ?? [])].sort(
            oldestFirst
        );

        return (
            <AvailableAppointmentsList
                calendarReservationAccesses={calendarReservationAccesses}
                selectedCra={selectedCra}
                className="available-appointments-table"
            />
        );
    };

    return <LoadingQuery query={calendarReservationAccessesQuery} onResult={renderWithResult} />;
};

export const AvailableGlobalAppointments: React.FC<AvailableAppointmentsProps> = props => {
    const { selectedCra } = props;

    const renderWithResult = (result: LoadingQueryResult<GlobalCalendarReservationAccessesQuery>) => {
        const calendarReservationAccesses = result.data?.root?.globalCalendarReservationAccesses ?? [];
        return (
            <AvailableAppointmentsList
                calendarReservationAccesses={[...calendarReservationAccesses].sort(oldestFirst)}
                className="available-global-appointments-table"
                selectedCra={selectedCra}
                showHeader={false}
            />
        );
    };

    return <LoadingQuery query={globalCalendarReservationAccessesQuery} onResult={renderWithResult} />;
};

import React, { Component } from 'react';
import { QueryResult } from '@apollo/client';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouterProps, withRouter } from 'react-router';

import { Button } from 'semantic-ui-react';

import { chatsQuery, memberActionsQuery } from '../../data/queries';
import t from '../../translations/index';

import { ChatType, MemberActionsQuery, MemberActionType } from '../../graphql-schema';
import { memberActionMutation } from '../../data/mutations';

import './chat.less';

const chatTypeOpenActionType = {
    [ChatType.A_1]: MemberActionType.HelttiChat,
    [ChatType.A_2]: MemberActionType.HelttiChat,
    [ChatType.A_100]: MemberActionType.OwnNurse
};

type Props = OwnProps & WrappedComponentProps & RouterProps;

interface OwnProps {
    chatType: ChatType;
    data: QueryResult & MemberActionsQuery;

    onClick(actionType: MemberActionType): Promise<any>;
}

interface State {
    isLoading: boolean;
}

export class ReOpenChatButton extends Component<Props, State> {
    public state: State = {
        isLoading: false
    };

    private startConversation = (actionType: MemberActionType): void => {
        if (this.state.isLoading) {
            return;
        }

        this.setState({ isLoading: true });

        this.props.onClick(actionType).then(response => {
            const {
                data: {
                    memberAction: { url }
                }
            } = response;

            this.setState({ isLoading: false }, () => this.props.history.push(url));
        });
    };

    public render(): React.ReactNode {
        const { intl, data, chatType } = this.props;

        const actionTypeForChat = chatTypeOpenActionType[chatType];

        const action =
            !data.error &&
            data?.root?.availableActions?.actions?.find(
                availableAction => availableAction?.typeNew === actionTypeForChat
            );

        if (!action) {
            return null;
        }

        return (
            <Button
                primary
                loading={this.state.isLoading}
                className="reopenLiveChatButton"
                onClick={() => this.startConversation(action.typeNew)}
            >
                {intl.formatMessage(t.contactFlowStartNewChat, { title: action.title })}
            </Button>
        );
    }
}

export default compose(
    injectIntl,
    graphql(memberActionsQuery, {
        options: {
            fetchPolicy: 'cache-and-network'
        }
    }),
    graphql(memberActionMutation, {
        props: props => ({
            onClick: (actionType: MemberActionType) => {
                return props.mutate?.({
                    variables: {
                        data: {
                            typeNew: actionType
                        }
                    },
                    refetchQueries: [{ query: chatsQuery }]
                });
            }
        })
    }),
    withRouter
)(ReOpenChatButton);

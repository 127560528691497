import React from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkDirective from 'remark-directive';
import { visit } from 'unist-util-visit';

import { MarkdownCTAButton } from './FormatMarkdown/MarkdownCTAButton';

const components: Components = {
    MarkdownCTA: props => <MarkdownCTAButton {...props} />
};

type Props = {
    source: string;
};

const customDirectivePlugin = () => {
    return tree => {
        visit(tree, node => {
            if (node.type === 'textDirective' && (node.name === 'youtube' || node.name === 'cta')) {
                console.error('Text directives for `youtube` or `cta` not supported', node);
                return;
            }

            if (node.type === 'leafDirective' || node.type === 'containerDirective') {
                const data = node.data || (node.data = {});

                if (node.name === 'youtube') {
                    const videoId: string = node.attributes?.v;

                    if (!videoId) {
                        console.error('Missing video id', node);
                    }

                    data.hName = 'iframe';
                    data.hProperties = {
                        src: `https://www.youtube.com/embed/${videoId}`,
                        width: 400,
                        height: 300,
                        frameBorder: 0,
                        allow: 'picture-in-picture',
                        allowFullScreen: true
                    };
                } else if (node.name === 'cta') {
                    if (!node.attributes?.href) {
                        console.error('Missing href for CTA', node);
                    }

                    data.hName = 'MarkdownCTA';
                    data.hProperties = {
                        align: node.attributes?.align,
                        href: node.attributes.href,
                        primary: node.attributes?.type === 'primary',
                        size: node.attributes?.size,
                        title: node.children[0].value
                    };
                }
            }
        });
    };
};

export const FormatMarkdown: React.FC<Props> = props => {
    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm, remarkDirective, customDirectivePlugin]}
            components={components}
            className="markdown"
        >
            {props.source}
        </ReactMarkdown>
    );
};

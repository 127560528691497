import React, { PureComponent } from 'react';
import moment, { Moment } from 'moment';
import { LoadingQuery, LoadingQueryResult } from '@heltti/components';

import { Header } from 'semantic-ui-react';

import { calendarEventsQuery } from '../data/queries';
import { CalendarEventFragment, CalendarEventsQuery } from '../graphql-schema';
import { CalendarEventCard } from './CalendarEventCard';
import t from '../translations';
import { Translation } from './Message';
import { CalendarEventModal } from './CalendarEventModal';
import { apolloClient } from '../index';
import { cancelAppointmentMutation } from '../data/mutations';
import { getNodes } from '@heltti/common';

import './CalendarEventList.less';

export const calendarEventQueryStartTime = (eventCategory: CalendarEventCategory): Moment => {
    return eventCategory === CalendarEventCategory.PastEvents
        ? moment().subtract(1, 'years').startOf('day')
        : moment().startOf('day');
};

export const calendarEventQueryEndTime = (eventCategory: CalendarEventCategory): Moment => {
    return eventCategory === CalendarEventCategory.PastEvents
        ? moment().endOf('day').subtract(1, 'days')
        : moment().endOf('day').add(1, 'years');
};

export enum CalendarEventCategory {
    FutureEvents,
    PastEvents
}

interface Props {
    eventCategory: CalendarEventCategory;
}

interface State {
    calendarEvent: CalendarEventFragment | null;
    loading: boolean;
}

export default class CalendarEventList extends PureComponent<Props, State> {
    public state: State = {
        calendarEvent: null,
        loading: false
    };

    private onModalDismiss = () => {
        this.setState({ calendarEvent: null });
    };

    private onRowClick = (calendarEvent: CalendarEventFragment) => {
        this.setState({ calendarEvent });
    };

    private doCancel = () => {
        const { eventCategory } = this.props;
        const { calendarEvent } = this.state;

        if (!calendarEvent) {
            return;
        }

        this.setState({ loading: true });

        apolloClient
            .mutate({
                mutation: cancelAppointmentMutation,
                variables: { data: { eventId: calendarEvent.id } },
                refetchQueries: [
                    {
                        query: calendarEventsQuery,
                        variables: {
                            start: calendarEventQueryStartTime(eventCategory),
                            end: calendarEventQueryEndTime(eventCategory)
                        }
                    }
                ]
            })
            .then(() => {
                this.setState({ loading: false });

                this.onModalDismiss();
            })
            .catch(() => {
                // TODO: Error handling
                this.setState({ loading: false });
            });
    };

    private renderWithResult = (result: LoadingQueryResult<CalendarEventsQuery>) => {
        const { eventCategory } = this.props;
        const { data } = result;
        const { calendarEvent, loading } = this.state;

        const calendarEvents = getNodes(data?.root?.calendarEvents);

        const showFutureEvents = eventCategory === CalendarEventCategory.FutureEvents;

        if (calendarEvents.length === 0) {
            return null;
        }

        return (
            <div className="calendar-event-table">
                {!!calendarEvent && (
                    <CalendarEventModal
                        calendarEvent={calendarEvent}
                        onDismiss={this.onModalDismiss}
                        onCancelEvent={this.doCancel}
                        loading={loading}
                    />
                )}

                <Header>
                    <Translation message={showFutureEvents ? t.calendarAppointments : t.calendarPastAppointments} />
                </Header>

                {calendarEvents.map(event => (
                    <CalendarEventCard key={event.id} event={event} onClick={() => this.onRowClick(event)} />
                ))}
            </div>
        );
    };

    public render(): React.ReactNode {
        const { eventCategory } = this.props;

        const variables = {
            start: calendarEventQueryStartTime(eventCategory),
            end: calendarEventQueryEndTime(eventCategory)
        };

        return <LoadingQuery query={calendarEventsQuery} variables={variables} onResult={this.renderWithResult} />;
    }
}

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as Sentry from '@sentry/react';

import { Loader } from '@heltti/components';

import { eidentVerification } from '../../ducks/auth';
import { paths } from '../../constants';
import { RootState } from '../../ducks';

interface OwnProps {
    isLoading: boolean;

    eidentVerification(urlParams: string): Promise<any>;
}

type Props = RouteComponentProps<any> & OwnProps;

export class EidentIndex extends Component<Props> {
    private handleSuccess = (nonce: string) => {
        const { history } = this.props;

        history.push({
            pathname: paths.signUpIndex,
            state: { nonce: nonce }
        });
    };

    private handleError = search => {
        const { history } = this.props;
        const searchParams = new URLSearchParams(search);
        const error = searchParams.get('error') ?? 'unknown';
        const code = searchParams.get('code') ?? 'unknown';

        if (code !== 'cancel') {
            Sentry.captureMessage(`Eident: ${error} ${code}`, Sentry.Severity.Error);
        }
        history.push(paths.eIdentError);
    };

    componentDidMount() {
        this.props
            .eidentVerification(window.location.search)
            .then((nonce: string) => {
                this.handleSuccess(nonce);
            })
            .catch(error => {
                this.handleError(window.location.search);
            });
    }

    public render() {
        return <Loader />;
    }
}

export default compose(
    connect(
        (state: RootState) => ({
            isLoading: state.auth.isLoading
        }),
        {
            eidentVerification: eidentVerification
        }
    )
)(EidentIndex);

import React, { FunctionComponent } from 'react';
import { ChatFragment, ChatNotificationActionFragment } from '../../../graphql-schema';

import Call from './Call';
import EscalateToLiveChat from './EscalateToLiveChat';

const actions = {
    CALL: Call,
    ESCALATE: EscalateToLiveChat
};

type ActionProps = {
    action: ChatNotificationActionFragment;
    chat: ChatFragment;
};

const Action: FunctionComponent<ActionProps> = ({ action, chat }) => {
    const Component = actions[action.type];

    return Component ? <Component action={action} chat={chat} /> : null;
};

export default Action;

import React from 'react';
import { RouteComponentProps } from 'react-router';

import { Content } from '../../components/Content';
import { Footer } from '../../components/Footer';
import Article from '../Article';
import { Page } from '../../components/Page';

type MatchParams = {
    id: string;
};

type Props = RouteComponentProps<MatchParams>;

export const Explore: React.FC<Props> = props => {
    const { id } = props.match.params;

    const tagOrNull = id ? null : 'root_explore';

    return (
        <Page id="explore">
            <Content containerWidth="wide">
                <Article id={id} tag={tagOrNull} urlPath="explore" />
            </Content>

            <Footer />
        </Page>
    );
};

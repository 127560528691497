import React, { useState } from 'react';

import { Button, Header, Modal } from 'semantic-ui-react';

import { modifyAppointmentMutation, reserveAppointmentMutation } from '../../data/mutations';
import { AppointmentContactType, AppointmentFragment, CalendarReservationAccessFragment } from '../../graphql-schema';
import t from './../../translations';
import { calendarEventsQuery, calendarReservationAccessesQuery } from '../../data/queries';
import { Translation } from '../../components/Message';
import { apolloClient } from '../../index';
import { ReserveAppointmentModalSelectedAppointment } from './ReserveAppointmentModalSelectedAppointment';
import {
    CalendarEventCategory,
    calendarEventQueryEndTime,
    calendarEventQueryStartTime
} from '../../components/CalendarEventList';
import { ReserveAppointmentModalAvailableAppointmentsList } from './ReserveAppointmentModalAvailableAppointmentsList';

export enum ReservationMode {
    RESERVE,
    MODIFY
}

type Props = {
    dismiss: () => void;
    onConfirm: () => void;
    reservationAccess: CalendarReservationAccessFragment;
    mode: ReservationMode;
    cancellableEventId?: string;
};

export const ReserveAppointmentModal: React.FC<Props> = props => {
    const { dismiss, onConfirm, reservationAccess, mode, cancellableEventId } = props;

    const [appointment, setAppointment] = useState<AppointmentFragment | null>(null);
    const [loading, setLoading] = useState(false);
    const [reserveError, setReserveError] = useState<Error | undefined>(undefined);
    const [contactType, setContactType] = useState<AppointmentContactType>();

    const onModifyAppointment = () => {
        setAppointment(null);
        setReserveError(undefined);
    };

    const onReserve = () => {
        if (!appointment || loading) {
            return;
        }

        const mutationData: any = {
            startTime: appointment.start,
            meta: appointment.meta,
            duration: appointment.duration,
            contactType: contactType ?? appointment.contactTypes[0]
        };

        if (cancellableEventId) {
            mutationData.eventId = cancellableEventId;
        }

        setLoading(true);

        apolloClient
            .mutate({
                mutation: mode === ReservationMode.RESERVE ? reserveAppointmentMutation : modifyAppointmentMutation,
                variables: {
                    data: mutationData
                },
                refetchQueries: [
                    {
                        query: calendarEventsQuery,
                        variables: {
                            start: calendarEventQueryStartTime(CalendarEventCategory.FutureEvents),
                            end: calendarEventQueryEndTime(CalendarEventCategory.FutureEvents)
                        }
                    },
                    { query: calendarReservationAccessesQuery }
                ]
            })
            .then(() => {
                setLoading(false);
                onConfirm();
            })
            .catch(error => {
                setReserveError(error);
                setLoading(false);
            });
    };

    const onContactTypeChange = (contactType: AppointmentContactType) => {
        setContactType(contactType);
    };

    return (
        <Modal open centered={false} size={appointment ? 'tiny' : 'small'} id="reserveAppointmentModal">
            <Modal.Header>
                <Header as="h1">
                    <Header.Subheader>
                        <Translation message={t.appointmentReserveAppointment} />
                    </Header.Subheader>
                </Header>
            </Modal.Header>

            {!appointment ? (
                <ReserveAppointmentModalAvailableAppointmentsList
                    calendarReservationAccess={reservationAccess}
                    onSelectAppointment={setAppointment}
                />
            ) : (
                <ReserveAppointmentModalSelectedAppointment
                    appointment={appointment}
                    reserveError={reserveError}
                    onContactTypeChange={onContactTypeChange}
                />
            )}

            <Modal.Actions>
                {!appointment && (
                    <Button basic disabled={loading} onClick={dismiss}>
                        <Translation message={t.appointmentReserveAppointmentBack} />
                    </Button>
                )}

                {!!appointment && (
                    <Button basic disabled={loading} onClick={onModifyAppointment}>
                        <Translation message={t.appointmentReserveAppointmentChangeTime} />
                    </Button>
                )}

                {!!appointment && (
                    <Button positive loading={loading} disabled={!!reserveError || loading} onClick={onReserve}>
                        <Translation message={t.appointmentReserveAppointmentConfirm} />
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

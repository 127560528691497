import React, { Component } from 'react';
import { Header as SemanticHeader } from 'semantic-ui-react';

import './PageHeader.less';

import defaultImage from './../assets/images/home.jpg';

interface Props {
    title?: string | JSX.Element;
    children?: any;
    description?: string | JSX.Element;
    backgroundImage?: string;
    hideBackgroundImage?: boolean;
    banner?: boolean;
}

export default class PageHeader extends Component<Props> {
    public render() {
        const { title, description, backgroundImage, hideBackgroundImage, banner, children } = this.props;

        return (
            <div
                id="pageHeader"
                className={banner && 'banner'}
                style={{
                    backgroundImage: !hideBackgroundImage ? `url(${backgroundImage || defaultImage})` : undefined
                }}
            >
                {(!!title || !!description) && (
                    <SemanticHeader as="h1">
                        {title}

                        <SemanticHeader.Subheader>{description || <span>&nbsp;</span>}</SemanticHeader.Subheader>
                    </SemanticHeader>
                )}

                {children}
            </div>
        );
    }
}

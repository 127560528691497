import { connect } from 'react-redux';

import { Login } from '@heltti/components';

import { getUrlForEident } from '../../api';
import { RootState } from '../../ducks';
import { login as loginAction } from '../../ducks/auth';

export default connect(
    (state: RootState) => ({
        loading: state.auth.isLoading,
        error: state.auth.error,
        getVerificationUrl: getUrlForEident
    }),
    {
        login: loginAction
    }
)(Login);

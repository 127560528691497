import React, { Component, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { QueryResult } from '@apollo/client';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import { connect } from 'react-redux';

import { Dropdown, Icon, Menu } from 'semantic-ui-react';

import { HelttiLogo } from './Logo';
import { paths, SCREEN_WIDTH_MAX_MOBILE, SCREEN_WIDTH_MAX_TABLET } from '../constants';
import messages from '../translations';
import { ProfileQuery } from '../graphql-schema';
import { profileQuery } from '../data/queries';
import { logout } from '../ducks/auth';
import {
    TopNavigationBarItemLink,
    TopNavigationBarSubmenu,
    TopNavigationBarSubmenuItem,
    TopNavigationBarSubmenuItemLink
} from '@heltti/components';
import { useBadges } from '../hooks/useBadges';

type Props = {
    logout: () => void;
    profileData?: QueryResult & ProfileQuery;
} & RouteComponentProps<any> &
    WrappedComponentProps;

type DesktopMenuProps = {
    isTablet: boolean;
    firstName: string | undefined;
} & Props;

const MobileMenu = (props: Props) => {
    const { intl } = props;

    return (
        <div id="menu">
            <Menu fluid borderless size="mini" widths={3} icons="labeled">
                <Menu.Item as={NavLink} to="/" exact>
                    <Icon size="big" name="home" />
                    {intl.formatMessage(messages.menuHome)}
                </Menu.Item>

                <Menu.Item as={NavLink} to="/paths">
                    <Icon size="big" name="comments outline" />
                    {intl.formatMessage(messages.menuPaths)}
                </Menu.Item>

                <Menu.Item as={NavLink} to="/profile">
                    <Icon size="big" name="user circle outline" />
                    {intl.formatMessage(messages.menuProfile)}
                </Menu.Item>
            </Menu>
        </div>
    );
};

const DesktopMenu = (props: DesktopMenuProps) => {
    const { intl, isTablet, firstName } = props;

    const [notificationCount, setNotificationCount] = useState(0);
    const { unseenChatMessages, unseenDocuments, openCalendarReservationAccesses, openQuestionnaires } = useBadges();

    useEffect(() => {
        setNotificationCount(
            (unseenChatMessages || 0) +
                (unseenDocuments || 0) +
                (openCalendarReservationAccesses || 0) +
                (openQuestionnaires || 0)
        );
    }, [unseenChatMessages, unseenDocuments, openCalendarReservationAccesses, openQuestionnaires]);

    const onClickLogout = (e: any) => {
        e.preventDefault();

        props.logout();
    };

    return (
        <div id="menu">
            <Menu fluid borderless>
                <Menu.Item header as={NavLink} to="/" exact>
                    <HelttiLogo />
                </Menu.Item>

                <TopNavigationBarItemLink title={intl.formatMessage(messages.menuHome)} to="/" />

                <TopNavigationBarItemLink
                    title={intl.formatMessage(messages.menuPaths)}
                    to={paths.paths}
                    notificationCount={notificationCount}
                />

                <TopNavigationBarSubmenu
                    title={isTablet || !firstName ? intl.formatMessage(messages.menuProfile) : firstName}
                    position="right"
                >
                    <TopNavigationBarSubmenuItemLink
                        title={intl.formatMessage(messages.menuProfile)}
                        to={paths.profile}
                        className="profile-link"
                    />

                    <Dropdown.Divider />

                    <TopNavigationBarSubmenuItem
                        title={intl.formatMessage(messages.logoutButton)}
                        onClick={onClickLogout}
                        iconName="log out"
                    />
                </TopNavigationBarSubmenu>
            </Menu>
        </div>
    );
};

class PlainNotEmployeeMenu extends Component<Props> {
    public render() {
        const { profileData, ...restProps } = this.props;

        const isMobile = window.innerWidth <= SCREEN_WIDTH_MAX_MOBILE;
        const isTablet = window.innerWidth <= SCREEN_WIDTH_MAX_TABLET;

        let firstName: string | undefined;

        if (profileData && !profileData.error && profileData.root && profileData.root.me) {
            const { me } = profileData.root;

            firstName = me.firstName;
        }

        function renderContent() {
            if (isMobile) {
                return <MobileMenu {...restProps} />;
            } else {
                return <DesktopMenu isTablet={isTablet} firstName={firstName} {...restProps} />;
            }
        }

        return <div id="menu">{renderContent()}</div>;
    }
}

export const NotEmployeeMenu = compose(
    withRouter,
    connect(() => ({}), {
        logout
    }),
    graphql(profileQuery, { name: 'profileData' }),
    injectIntl
)(PlainNotEmployeeMenu);

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

import { SemanticSIZES } from 'semantic-ui-react';
import { Button } from '@heltti/components';

import { triggerInteractionMutation } from '../../data/mutations';
import { globalInteractionsQuery } from '../../data/queries';
import { TriggerInteractionMutation, TriggerInteractionMutationVariables } from '../../graphql-schema';
import { openURL } from '../../util/navigation';

// Declare the MarkdownCTA in JSX IntrinsicElements to make FormatMarkdown components prop typecheck to pass
declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        // This merges with the existing intrinsic elements
        interface IntrinsicElements {
            MarkdownCTA: Props;
        }
    }
}

type Props = {
    align?: 'left' | 'center' | 'right';
    href: string;
    primary: boolean;
    size?: SemanticSIZES;
    title: string;
};

export const MarkdownCTAButton: React.FC<Props> = props => {
    const { align = 'left', href, primary = true, title, size = 'medium' } = props;

    const history = useHistory();
    const apolloClient = useApolloClient();

    const onClick = () => {
        const url = new URL(href);

        if (url.pathname.includes('triggerInteraction')) {
            const pathParts = url.pathname
                .split('/')
                .filter(item => item.length !== 0 && item !== 'triggerInteraction');

            const [handle, actionHandle] = pathParts;

            apolloClient
                .mutate<TriggerInteractionMutation, TriggerInteractionMutationVariables>({
                    mutation: triggerInteractionMutation,
                    variables: { data: { handle: atob(handle), actionHandle: atob(actionHandle) } },
                    // TODO: This ought to be replaced with pushing these changes through a subscription
                    refetchQueries: [globalInteractionsQuery]
                })
                .then(response => {
                    const interactionResult = response.data?.interactionTrigger?.interactionResult;

                    if (interactionResult?.redirectTo) {
                        openURL(new URL(interactionResult.redirectTo), history);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        } else {
            openURL(url, history);
        }
    };

    return (
        <div style={{ textAlign: align, padding: '20px' }}>
            <Button primary={primary} size={size} onClick={onClick}>
                {title}
            </Button>
        </div>
    );
};

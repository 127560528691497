import React, { FunctionComponent } from 'react';

import { Feed, Header } from 'semantic-ui-react';
import { Logo } from '@heltti/components';

import { Translation } from '../../components/Message';

import t from '../../translations';

export const NoEmploymentInfoCard: FunctionComponent = () => {
    return (
        <Feed className="notificationsList">
            <Feed.Event>
                <Feed.Label>
                    <Logo />
                </Feed.Label>

                <Feed.Content>
                    <Header>
                        <Translation message={t.profileEmploymentMissingTitle} />
                    </Header>

                    <p>
                        <Translation message={t.profileEmploymentMissingDescription} />
                    </p>
                </Feed.Content>
            </Feed.Event>
        </Feed>
    );
};

export default NoEmploymentInfoCard;

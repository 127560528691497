import React from 'react';
import moment from 'moment';

import { Feed, Header } from 'semantic-ui-react';
import { Loader, useLoadingQuery } from '@heltti/components';
import { getNodes } from '@heltti/common';

import { NotificationsQuery, NotificationsQueryVariables, UserFragment } from '../../graphql-schema';
import { notificationsQuery } from '../../data/queries';
import { FormatMarkdown } from '../../components/FormatMarkdown';
import { Avatar } from '../../components/Avatar';
import { Translation } from '../../components/Message';
import t from '../../translations';

import './NotificationsList.less';

interface NotificationItemProps {
    title: string;
    message: string;
    sender?: UserFragment | null;
    notificationDate?: string;
}

const NotificationItem: React.FC<NotificationItemProps> = props => {
    const { title, message, sender, notificationDate } = props;

    const date = moment(notificationDate).format('L');

    return (
        <Feed.Event className="notificationCard">
            <Feed.Label>{sender && <Avatar url={sender.avatarSmallUrl} />}</Feed.Label>

            <Feed.Content>
                <Feed.Date>
                    {sender && `${sender.firstName} ${sender.lastName}, ${sender.title}, `} {date}
                </Feed.Date>

                <Feed.Summary>{title}</Feed.Summary>

                <FormatMarkdown source={message} />
            </Feed.Content>
        </Feed.Event>
    );
};

export const NotificationsList: React.FC = () => {
    const { data, loading, error } = useLoadingQuery<NotificationsQuery, NotificationsQueryVariables>(
        notificationsQuery
    );

    if (loading) {
        return <Loader inline />;
    }

    if (error) {
        return null;
    }

    const notifications = getNodes(data?.root?.notifications).slice(0, 3);

    if (!notifications.length) {
        return null;
    }

    return (
        <>
            <Header>
                <Translation message={t.homeNewsTitle} />
            </Header>

            <Feed className="notificationsList">
                {notifications.map(notification => (
                    <NotificationItem
                        key={notification.id}
                        title={notification.title}
                        message={notification.message}
                        sender={notification.sender}
                        notificationDate={notification.createdAt}
                    />
                ))}
            </Feed>
        </>
    );
};

import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';

import { Button, Form, Modal } from 'semantic-ui-react';
import { Error } from '@heltti/components';

import { selfHelpCourseSubscribeMutation } from '../../data/mutations';
import t from '../../translations';

type Props = OwnProps & WrappedComponentProps;

interface OwnProps {
    courseId: string;
    title: string;
    onSubscribe: (courseId: string) => Promise<void>;
    onDismissSuccessModal: () => void;
}

interface State {
    isLoading: boolean;
    isSuccess: boolean;
    hasError: boolean;
    focused: boolean;
}

class SelfHelpCourseSubscribeForm extends Component<Props, State> {
    public state: State = {
        isLoading: false,
        isSuccess: false,
        hasError: false,
        focused: false
    };

    private handleSave = () => {
        this.setState({ isLoading: true }, () => {
            this.props
                .onSubscribe(this.props.courseId)
                .then(() => this.setState({ isLoading: false, isSuccess: true }))
                .catch(() => this.setState({ isLoading: false, hasError: true }));
        });
    };

    private handleModalDismiss = () => {
        this.setState({ hasError: false });
    };

    public render() {
        const { intl, onDismissSuccessModal } = this.props;

        return (
            <div>
                {intl.formatMessage(t.selfHelpCourseSubscribeConfirm)} {this.props.title}
                <Form onSubmit={this.handleSave} loading={this.state.isLoading}>
                    <Button fluid primary size="large" disabled={this.state.isSuccess || this.state.hasError}>
                        {intl.formatMessage(t.selfHelpCourseSubscribe)}
                    </Button>
                </Form>
                {this.state.hasError && <Error onDismiss={this.handleModalDismiss} />}
                {this.state.isSuccess && (
                    <Modal defaultOpen dimmer="inverted" onClose={onDismissSuccessModal}>
                        <Modal.Header>{intl.formatMessage(t.selfHelpCourseSubscribeSuccess)}</Modal.Header>

                        <Modal.Content>
                            <Modal.Description>
                                <p>{intl.formatMessage(t.selfHelpCourseSubscribeConfirmed)}</p>

                                <Button primary onClick={onDismissSuccessModal}>
                                    OK
                                </Button>
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>
                )}
            </div>
        );
    }
}

export default compose(
    injectIntl,
    graphql(selfHelpCourseSubscribeMutation, {
        props: props => ({
            onSubscribe: (courseId: string) => {
                return props.mutate?.({
                    variables: {
                        data: {
                            courseId
                        }
                    }
                });
            }
        })
    })
)(SelfHelpCourseSubscribeForm);

import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Card } from 'semantic-ui-react';

import { Content } from '../../components/Content';
import { NPSForm } from './NPSForm';

import './nps.less';

type Params = {
    npsUUID: string;
    scoreNps: string;
    scoreDetail1: string;
};

type Props = {
    companyEventResponse?: boolean;
    mindyEventResponse?: boolean;
    mindyOrgEventResponse?: boolean;
} & RouteComponentProps<Params>;

export const CompanyEventNPSResponse: FC<Props> = props => <NPSResponse {...props} companyEventResponse />;
export const MindyNPSResponse: FC<Props> = props => <NPSResponse {...props} mindyEventResponse />;
export const MindyOrgNPSResponse: FC<Props> = props => <NPSResponse {...props} mindyOrgEventResponse />;

export const NPSResponse: React.FC<Props> = props => {
    const { companyEventResponse = false, mindyEventResponse = false, mindyOrgEventResponse = false } = props;
    const { npsUUID, scoreNps, scoreDetail1 } = props.match.params;

    const scoreNpsNumber = scoreNps ? parseInt(scoreNps, 10) : undefined;
    const scoreDetail1Number = scoreDetail1 ? parseInt(scoreDetail1, 10) : undefined;

    return (
        <Content containerWidth="medium">
            <div style={{ textAlign: 'center' }}>
                <Card fluid className="npsContainer">
                    <Card.Content>
                        <NPSForm
                            npsUUID={npsUUID}
                            scoreNps={scoreNpsNumber}
                            scoreDetail1={scoreDetail1Number}
                            isCompanyResponse={companyEventResponse}
                            isMindyResponse={mindyEventResponse}
                            isMindyOrgResponse={mindyOrgEventResponse}
                        />
                    </Card.Content>
                </Card>
            </div>
        </Content>
    );
};

import React from 'react';

import { RouteComponentProps } from 'react-router';
import { Divider } from 'semantic-ui-react';

import { AvailableGlobalAppointments, AvailablePersonalAppointments } from './AvailableAppointmentsList';
import CalendarEventList, { CalendarEventCategory } from '../../components/CalendarEventList';

type MatchParams = {
    craId: string;
};

type Props = RouteComponentProps<MatchParams>;

export const Appointments: React.FC<Props> = props => {
    const { craId } = props.match.params;

    return (
        <>
            <AvailablePersonalAppointments selectedCra={craId} />
            <AvailableGlobalAppointments selectedCra={craId} />
            <CalendarEventList eventCategory={CalendarEventCategory.FutureEvents} />
            <Divider hidden section />
            <CalendarEventList eventCategory={CalendarEventCategory.PastEvents} />
        </>
    );
};

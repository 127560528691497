import React, { createRef, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import TextareaAutosize from 'react-textarea-autosize';

import { Button, Form, Input } from 'semantic-ui-react';
import { Error } from '@heltti/components';

import t from '../../translations';
import { chatMessageAddMutation } from '../../data/mutations';
import { apolloClient } from '../../index';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import sendIcon from './../../assets/images/send.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import attachmentIcon from './../../assets/images/attachment.svg';

type Props = OwnProps;

interface OwnProps {
    chatId: string;
    isDisabled: boolean;
}

export const SendChatMessage: React.FC<Props> = props => {
    const intl = useIntl();
    const [message, setMessage] = useState<string>('');
    const [file, setFile] = useState<File | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);

    const fileInputRef = createRef<HTMLInputElement>();

    const handleChange = useCallback((e: any) => {
        setMessage(e.target.value);
    }, []);

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const { chatId } = props;

        setIsLoading(true);

        apolloClient
            .mutate({
                mutation: chatMessageAddMutation,
                variables: {
                    data: {
                        chatId,
                        message,
                        file: file ?? undefined
                    }
                }
            })
            .then(() => {
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            })
            .then(() => {
                setMessage('');
                setFile(undefined);
            })
            .catch(() => {
                setIsErrorVisible(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleFile = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.currentTarget.files) {
            return;
        }

        setFile(event.currentTarget.files[0]);
    }, []);

    const dismissError = useCallback(() => {
        setIsErrorVisible(false);
    }, []);

    const { isDisabled } = props;

    if (isDisabled && message.length < 1) {
        return null;
    }

    const disabledSendButton = isDisabled || (message.length < 1 && !file) || isLoading;

    return (
        <div className="chatForm">
            <Form size="large">
                <div className="fileUploadContainer">
                    <Input
                        type="file"
                        name="attachment"
                        id="attachment"
                        className="attachmentField"
                        onChange={handleFile}
                    >
                        <input ref={fileInputRef} className="fileInput" />
                    </Input>

                    <label htmlFor="attachment" className={`attachmentLabel ${file ? 'hasFile' : ''}`}>
                        <img src={attachmentIcon} />{' '}
                        {file?.name && <span className="filename">{file.name.slice(0, 60)}</span>}
                    </label>
                </div>

                <div className="messageSubmitContainer">
                    <TextareaAutosize
                        value={message}
                        onChange={handleChange}
                        placeholder={intl.formatMessage(t.chatWrite)}
                        className="messageField"
                        minRows={1}
                        maxRows={8}
                    />

                    <Button
                        color={disabledSendButton ? 'grey' : 'blue'}
                        disabled={disabledSendButton}
                        loading={isLoading}
                        className="send"
                        onClick={handleSubmit}
                    >
                        {!isLoading && <img src={sendIcon} />}
                    </Button>
                </div>
            </Form>

            {isErrorVisible && (
                <Error
                    title={intl.formatMessage(t.chatMessageSendFailedTitle)}
                    description={intl.formatMessage(t.chatMessageSendFailedDescription)}
                    onDismiss={dismissError}
                />
            )}
        </div>
    );
};

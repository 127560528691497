import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button, Icon, Menu } from 'semantic-ui-react';

import { HelttiLogo } from '../../components/Logo';
import t from '../../translations';
import { SCREEN_WIDTH_MAX_MOBILE } from '../../constants';
import ChangeLanguage from '../../components/ChangeLanguage';
import { Translation } from '../../components/Message';

type Props = RouteComponentProps<any>;

class PlainLandingMenu extends Component<Props> {
    private openLogin = () => {
        this.props.history.push('/login');
    };

    public render() {
        const { location } = this.props;

        const isMobile = window.innerWidth <= SCREEN_WIDTH_MAX_MOBILE;

        const isHello = location.pathname.includes('/hello') || location.pathname.includes('/notVerified');
        const isLoggingIn = location.pathname.includes('/login');

        const loginItem = isLoggingIn ? (
            <Menu.Item as={NavLink} to="/login">
                <Translation message={t.loginButton} />
            </Menu.Item>
        ) : (
            <Menu.Item>
                <Button color="red" onClick={this.openLogin}>
                    <Translation message={t.loginButton} />
                </Button>
            </Menu.Item>
        );

        if (isMobile) {
            return (
                <div id="menu">
                    <Menu fluid borderless size="tiny" widths={4} icon="labeled">
                        <Menu.Item as={NavLink} to="/welcome" exact>
                            <Icon size="big" name="home" />
                            <Translation message={t.menuHome} />
                        </Menu.Item>

                        {!isHello && (
                            <Menu.Item as={NavLink} to="/referrals" exact>
                                <Icon size="big" name="download" />
                                <Translation message={t.referralTitle} />
                            </Menu.Item>
                        )}

                        <Menu.Item active={false}>
                            <ChangeLanguage />
                        </Menu.Item>

                        {!isHello && loginItem}
                    </Menu>
                </div>
            );
        }

        return (
            <div id="menu">
                <Menu fluid borderless>
                    <Menu.Item header as={NavLink} to="/" exact>
                        <HelttiLogo />
                    </Menu.Item>

                    <Menu.Item as={NavLink} to="/welcome" exact>
                        <Translation message={t.menuHome} />
                    </Menu.Item>

                    {!isHello && (
                        <Menu.Item as={NavLink} to="/referrals" exact>
                            <Translation message={t.referralTitle} />
                        </Menu.Item>
                    )}

                    <Menu.Menu position="right">
                        <Menu.Item>
                            <ChangeLanguage />
                        </Menu.Item>

                        {!isHello && loginItem}
                    </Menu.Menu>
                </Menu>
            </div>
        );
    }
}

export const LandingMenu = withRouter(PlainLandingMenu);

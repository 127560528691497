import React, { useState } from 'react';
import { FormattedDate as Date, FormattedTime as Time } from 'react-intl';

import { Divider, Form, Grid, Header, Icon, Image, Message, Modal, Radio } from 'semantic-ui-react';

import { AppointmentContactType, AppointmentFragment } from '../../graphql-schema';
import { MarkdownTranslation, Translation } from '../../components/Message';
import t from '../../translations';
import { formatContactType } from '../../util/contactType';

interface Props {
    appointment: AppointmentFragment;
    reserveError?: Error;
    onContactTypeChange: (contactType: AppointmentContactType) => void;
}

export const ReserveAppointmentModalSelectedAppointment: React.FC<Props> = props => {
    const { appointment, reserveError } = props;
    const { location } = appointment;

    const contactTypes = appointment.contactTypes ?? [];

    const [selectedContactType, setContactType] = useState(contactTypes ? contactTypes[0] : undefined);

    const visitHeader = (
        <Header size="small">
            <Icon name="map pin" />
            <Header.Content>
                <strong>
                    {formatContactType(AppointmentContactType.Visit)}{' '}
                    {location?.name || <Translation message={t.remoteLocation} />}
                </strong>
                {location && (
                    <Header.Subheader>
                        {location.address1} {location.address2}
                        {', '}
                        {location.zip} {location.city}
                    </Header.Subheader>
                )}
            </Header.Content>
        </Header>
    );

    const phoneHeader = (
        <Header size="small">
            <Icon name="phone" />
            <Header.Content>
                <strong>{formatContactType(AppointmentContactType.Phone)}</strong>
            </Header.Content>
        </Header>
    );

    const videoHeader = (
        <Header size="small">
            <Icon name="video" />
            <Header.Content>
                <strong>{formatContactType(AppointmentContactType.Video)}</strong>
            </Header.Content>
        </Header>
    );

    const contactTypeRadioButtons = contactTypes.map(contactType => {
        return (
            <Grid.Row key={`contact-type-${contactType}`}>
                <Grid.Column width={1} verticalAlign="middle">
                    <Form.Field
                        checked={selectedContactType === contactType}
                        control={Radio}
                        id={`contact-type=${contactType}`}
                        name="contactType"
                        onChange={(_event: object, data: { value: AppointmentContactType }) => {
                            setContactType(data.value);
                            props.onContactTypeChange(data.value);
                        }}
                        value={contactType}
                    />
                </Grid.Column>
                <Grid.Column width={15}>
                    <label htmlFor={`contact-type=${contactType}`}>
                        {contactType === AppointmentContactType.Visit && visitHeader}
                        {contactType === AppointmentContactType.Phone && phoneHeader}
                        {contactType === AppointmentContactType.Video && videoHeader}
                    </label>
                </Grid.Column>
            </Grid.Row>
        );
    });

    return (
        <Modal.Content scrolling>
            {!!reserveError && (
                <Message warning>
                    <strong>
                        <Translation message={t.appointmentReserveErrorTitle} />
                    </strong>
                    <br />
                    <MarkdownTranslation message={t.appointmentReserveErrorDescription} />
                </Message>
            )}

            <Header>
                <Icon name="clock outline" />
                <Header.Content>
                    <span style={{ marginRight: '20px' }}>
                        <Date value={appointment.start} />
                    </span>
                    <Time value={appointment.start} />

                    <span style={{ marginLeft: '10px', color: 'grey' }}>{appointment.duration} min</span>
                </Header.Content>
            </Header>

            <Header>
                <Image avatar size="tiny" src={appointment.user.avatarSmallUrl} />

                <Header.Content>
                    {appointment.user.firstName} {appointment.user.lastName}
                    <Header.Subheader>{appointment.user.title}</Header.Subheader>
                </Header.Content>
            </Header>

            <Divider hidden />

            {contactTypes && <Grid columns={2}>{contactTypeRadioButtons}</Grid>}

            <Divider hidden />

            <p style={{ color: 'gray' }}>
                <Translation message={t.appointmentReserveTerms} />
            </p>
        </Modal.Content>
    );
};

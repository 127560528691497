// Polyfills for bad browsers
import 'core-js';
import 'whatwg-fetch';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { AppTheme, AppThemeProvider } from '@heltti/components';

import { createStore } from './reduxSetup';
import { config } from './config';
import { setupWithAuthentication as setupApolloWithAuthentication } from './apollo';
import appReducers from './ducks';
import { App } from './components/App';
import IntlProvider from './components/IntlProvider';

export const reduxStore = createStore(appReducers, []);
export const apolloClient = setupApolloWithAuthentication();

if (__ENV__ !== 'development') {
    Sentry.init({
        dsn: config?.sentry?.dsn,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.05,
        release: __VERSION__,
        environment: __ENV__
    });
}

const RootComponent: React.FC = () => {
    return (
        <AppThemeProvider value={AppTheme.RED}>
            <Provider store={reduxStore}>
                <ApolloProvider client={apolloClient}>
                    <IntlProvider>
                        <App />
                    </IntlProvider>
                </ApolloProvider>
            </Provider>
        </AppThemeProvider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('app-container')!);

root.render(<RootComponent />);

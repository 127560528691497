import { useEffect, useState } from 'react';
import { unionWith } from 'lodash';
import { UpdateQueryFn } from '@apollo/client/core/watchQueryOptions';
import { DeepWritable } from 'ts-essentials';

import { deepCopy, isSubscriptionInitData } from '@heltti/common';
import { useLoadingQuery } from '@heltti/components';

import { chatMessagesQuery, getCursorForLastEdge } from '../data/queries';
import { chatMessagesSubscription } from '../data/subscriptions';
import { ChatMessagesQuery, ChatMessagesSubscription, ChatMessagesSubscriptionVariables } from '../graphql-schema';

export const useChatMessages = (chatId: string) => {
    const [initialData, setInitialData] = useState(true);

    const updateQuery: UpdateQueryFn<ChatMessagesQuery, ChatMessagesSubscriptionVariables, ChatMessagesSubscription> = (
        previous,
        data
    ) => {
        const { subscriptionData } = data;

        if (isSubscriptionInitData(subscriptionData.data)) {
            return previous;
        }

        setInitialData(false);

        const { messageAdd } = subscriptionData.data.chat ?? {};

        if (messageAdd && previous?.root?.chat?.messages) {
            const copiedChatData = deepCopy(previous) as DeepWritable<ChatMessagesQuery>;

            copiedChatData.root.chat.messages.edges = [
                {
                    __typename: 'ChatMessageNodeEdge',
                    node: messageAdd
                },
                ...copiedChatData.root.chat.messages.edges
            ];

            return copiedChatData;
        }

        return previous;
    };

    const { fetchMore, loading, error, data, refetch } = useLoadingQuery<ChatMessagesQuery>(chatMessagesQuery, {
        variables: { chatID: chatId },
        subscription: chatMessagesSubscription,
        subscriptionVariables: { chatId },
        subscriptionUpdateQuery: updateQuery
    });

    useEffect(() => {
        // TODO: Move online state handling to useLoadingQuery in heltti-web-components (refactor crew-ui to use it too)

        const refetchWhenOnline = () => {
            if (!loading) {
                refetch().finally(() => {
                    //
                });
            }
        };
        window.addEventListener('app-online', refetchWhenOnline);

        return () => {
            window.removeEventListener('app-online', refetchWhenOnline);
        };
    }, [loading, refetch]);

    const fetchHistory = () => {
        const edges = data?.root?.chat?.messages?.edges ?? [];

        return fetchMore({
            variables: { messagesAfter: getCursorForLastEdge(edges) },
            updateQuery: (previousResult, { fetchMoreResult: result }) => {
                const writableResult = deepCopy(result) as DeepWritable<ChatMessagesQuery>;

                if (writableResult.root?.chat?.messages) {
                    writableResult.root.chat.messages.edges = unionWith(
                        previousResult.root?.chat?.messages?.edges ?? [],
                        result.root?.chat?.messages?.edges ?? [],
                        (a, b) => a?.node?.id === b?.node?.id
                    );
                }

                return writableResult;
            }
        }).catch(() => {
            // No-op. TODO: What do we do here?
        });
    };

    return { loading, error, data, initialData, fetchHistory };
};

import { gql } from '@apollo/client';

import {
    calendarEventFragment,
    chatFragment,
    chatMessageFragment,
    documentFragment,
    interactionResultFragment,
    profileFragment,
    questionnaireResponseFragment,
    uiInteractionFragment
} from './fragments';

export const verifyEmailMutation = gql`
    mutation VerifyEmailMutation($data: VerifyEmailMutationInput!) {
        verifyEmail(input: $data) {
            success
        }
    }
`;

export const signUpWithEmailMutation = gql`
    mutation SignUpWithEmailMutation($data: SignUpWithEmailMutationInput!) {
        signUpWithEmail(input: $data) {
            member {
                ...ProfileFragment
            }
        }
    }

    ${profileFragment}
`;

export const triggerInteractionMutation = gql`
    mutation TriggerInteractionMutation($data: TriggerInteractionMutationInput!) {
        interactionTrigger(input: $data) {
            uiInteraction {
                ...UIInteractionFragment
            }
            interactionResult {
                ...InteractionResultFragment
            }
        }
    }

    ${uiInteractionFragment}
    ${interactionResultFragment}
`;

export const interactionMutation = gql`
    mutation InteractionMutation($data: InteractionMutationInput!) {
        interaction(input: $data) {
            uiInteraction {
                ...UIInteractionFragment
            }
            interactionResult {
                ...InteractionResultFragment
            }
        }
    }

    ${uiInteractionFragment}
    ${interactionResultFragment}
`;

export const memberActionMutation = gql`
    mutation MemberActionMutation($data: MemberActionMutationInput!) {
        memberAction(input: $data) {
            url
        }
    }
`;

export const reserveAppointmentMutation = gql`
    mutation ReserveAppointmentMutation($data: ReserveAppointmentMutationInput!) {
        reserveAppointment(input: $data) {
            clientMutationId
        }
    }
`;

export const cancelAppointmentMutation = gql`
    mutation CancelAppointmentMutation($data: CancelAppointmentMutationInput!) {
        cancelAppointment(input: $data) {
            calendarEvent {
                ...CalendarEventFragment
            }
        }
    }

    ${calendarEventFragment}
`;

export const modifyAppointmentMutation = gql`
    mutation ModifyAppointmentMutation($data: ModifyAppointmentMutationInput!) {
        modifyAppointment(input: $data) {
            clientMutationId
        }
    }
`;

export const chatMessageAddMutation = gql`
    mutation ChatMessageAddMutation($data: ChatMessageAddMutationInput!) {
        chatMessageAdd(input: $data) {
            chat {
                ...ChatFragment
            }
            message {
                ...ChatMessageFragment
            }
        }
    }

    ${chatFragment}
    ${chatMessageFragment}
`;

export const chatMessagesSeenMutation = gql`
    mutation ChatMessagesSeenMutation($data: ChatMessagesSeenMutationInput!) {
        chatMessagesSeen(input: $data) {
            messages {
                id
            }
            chatMember {
                id
                lastMessageSeen {
                    id
                }
            }
        }
    }
`;

export const escalateToLiveChatMutation = gql`
    mutation EscalateToLiveChatMutation($data: EscalateToLiveChatMutationInput!) {
        escalateToLiveChat(input: $data) {
            chat {
                id
            }
        }
    }
`;

export const profileEditMutation = gql`
    mutation ProfileEditMutation($data: ProfileEditMutationInput!) {
        profileEdit(input: $data) {
            member {
                ...ProfileFragment
            }
        }
    }

    ${profileFragment}
`;

export const reportSickLeaveMutation = gql`
    mutation ReportSickLeaveMutation($data: ReportSickLeaveMutationInput!) {
        reportSickLeave(input: $data) {
            clientMutationId
        }
    }
`;

export const questionnaireResponseMutation = gql`
    mutation QuestionnaireResponseMutation($data: QuestionnaireResponseMutationInput!) {
        questionnaireResponse(input: $data) {
            response {
                ...QuestionnaireResponseFragment
            }
        }
    }

    ${questionnaireResponseFragment}
`;

export const selfHelpCourseSubscribeMutation = gql`
    mutation SelfHelpCourseSubscribeMutation($data: SelfHelpCourseSubscribeMutationInput!) {
        selfHelpCourseSubscribe(input: $data) {
            clientMutationId
        }
    }
`;

export const createMemberDocumentExportTokenMutation = gql`
    mutation CreateMemberDocumentExportTokenMutation($data: CreateMemberDocumentExportTokenMutationInput!) {
        documentExportTokenCreate(input: $data) {
            document {
                ...DocumentFragment
            }
        }
    }

    ${documentFragment}
`;

export const deleteMemberDocumentExportTokenMutation = gql`
    mutation DeleteMemberDocumentExportTokenMutation($data: DeleteMemberDocumentExportTokenMutationInput!) {
        documentExportTokenDelete(input: $data) {
            document {
                ...DocumentFragment
            }
        }
    }

    ${documentFragment}
`;

import React from 'react';
import { Link } from 'react-router-dom';

import { Header } from 'semantic-ui-react';
import { getNodes } from '@heltti/common';
import { Loader, useLoadingQuery } from '@heltti/components';

import { QuestionnaireResponseBatchesQuery } from '../../graphql-schema';
import { questionnaireResponseBatchesQuery } from '../../data/queries';
import { QuestionnaireResponseBatchCard } from '../../components/QuestionnaireBatch/QuestionnaireResponseBatchCard';
import { Translation } from '../../components/Message';
import t from '../../translations';

type Props = {
    hideIfNoItems?: boolean;
    title?: string;
    hideFinished?: boolean;
};

export const QuestionnaireList: React.FC<Props> = props => {
    const { hideIfNoItems, hideFinished } = props;

    const { data, loading, error } = useLoadingQuery<QuestionnaireResponseBatchesQuery>(
        questionnaireResponseBatchesQuery
    );

    if (loading && !hideIfNoItems) {
        return <Loader />;
    } else if (error) {
        return null;
    }

    // Questionnaire batches
    const batches = getNodes(data?.root?.questionnaireResponseBatches);

    if (hideIfNoItems && batches.length === 0) {
        return null;
    }

    return (
        <>
            <Header>
                <Translation message={t.profileQuestionnaires} />

                <div style={{ float: 'right', fontSize: '80%' }}>
                    <Link to="/paths/questionnaires">
                        <Translation message={t.showAll} />
                    </Link>
                </div>
            </Header>

            {batches.map(batch => (
                <QuestionnaireResponseBatchCard
                    key={batch.id}
                    questionnaireResponseBatch={batch}
                    hideFinished={hideFinished}
                />
            ))}
        </>
    );
};
